import styled, { css } from 'styled-components';
import { COLORS } from 'assets/styled/colors';
import { Level } from './type';
import { CSSProperties } from 'react';
import Icon from 'components/Icon/Icon';
import { respondTo } from 'assets/styled/breakpoints';

interface IBackDrop {
  level: Level;
}

interface IModal {
  level: Level;
  style: CSSProperties;
}

const levelStyle = {
  FIRST: {
    backDropStyle: css`
      z-index: 8000;
      background-color: ${COLORS.BACKDROP};
    `,
    modalStyle: css`
      z-index: 8100;
    `,
  },
  SECOND: {
    backDropStyle: css`
      z-index: 8200;
      background-color: ${COLORS.BACKDROP};
    `,
    modalStyle: css`
      z-index: 8300;
    `,
  },
  THIRD: {
    backDropStyle: css`
      z-index: 8300;
      background-color: ${COLORS.BACKDROP};
    `,
    modalStyle: css`
      z-index: 8400;
    `,
  },
};

export const BackDrop = styled('div')`
  text-transform: uppercase;
  position: fixed;
  left: 0;
  top: 0;
  ${({ level }: IBackDrop) => levelStyle[level].backDropStyle}
  width: 100%;
  height: 100%;
  overflow: auto;
  backdrop-filter: blur(0.8rem);
`;

export const StyledModal = styled('div')`
  position: absolute;
  height: fit-content;
  max-height: 90vh;
  width: 98%;
  ${({ level }: IModal) => levelStyle[level].modalStyle}
  ${({ level }: IModal) =>
    level !== 'FIRST'
      ? css`
          max-width: 500rem;
        `
      : css`
          max-width: 70%;
        `}
  inset: 0;
  margin: auto;
  ${({ level }: IModal) =>
    level === 'FIRST'
      ? css`
          margin-top: 100rem;
        `
      : css`
          margin-top: 200rem;
        `}
  background-color: ${COLORS.WHITE};
  border-radius: 20rem;
  box-shadow: 0rem 8rem 48rem rgba(0, 0, 0, 0.12);
  overflow: auto;
  ${({ style }: IModal) => style}
`;

export const CloseIcon = styled(Icon)`
  position: absolute;
  width: 30rem;
  height: 30rem;
  top: 20rem;
  right: 20rem;

  ${respondTo.bigPhone`
    top: 15rem;
    right: 15rem;
  `};
  ${respondTo.smallPhone`
    top:10rem;
    right: 10rem;
  `}
`;
