import { COLORS } from 'assets/styled';
import styled, { css } from 'styled-components';

interface StyledProps {
  isChecked: boolean;
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30rem;
  width: 60rem;
  cursor: pointer;
`;

export const Line = styled.div`
  pointer-events: none;
  position: relative;
  width: 50rem;
  height: 5rem;
  border-radius: 5rem;

  align-self: center;
  ${(props: StyledProps) =>
    props.isChecked &&
    css`
      background-color: ${COLORS.GREEN_DISABLED};
    `};
  ${(props: StyledProps) =>
    !props.isChecked &&
    css`
      background-color: ${COLORS.BORDER};
    `};
`;

export const Circle = styled.div`
  pointer-events: none;
  width: 25rem;
  height: 25rem;
  border-radius: 13rem;

  position: absolute;
  top: -10rem;
  ${(props: StyledProps) =>
    props.isChecked &&
    css`
      left: 60%;
      transition: 200ms ease-out;
      background-color: ${COLORS.GREEN};
    `};
  ${(props: StyledProps) =>
    !props.isChecked &&
    css`
      left: -5rem;
      transition: 200ms ease-out;
      background-color: ${COLORS.GREEN_DISABLED};
    `};
`;
