import {
  Container,
  NameLabel,
  RowCenter,
  StyledSkeleton,
} from '../SupplierCard.styled';
import { Skeleton } from '@mui/material';

interface ISupplierCardSkeletonProps {
  index: number;
  opacityCoeficient?: number;
}

const SupplierCardSkeleton = ({
  index,
  opacityCoeficient = 0.2,
}: ISupplierCardSkeletonProps) => {
  return (
    <Container opacityIndex={index} opacityCoeficient={opacityCoeficient}>
      <StyledSkeleton
        width={'100%'}
        height={'200rem'}
        animation="wave"
        variant="rectangular"
      />
      <RowCenter>
        <NameLabel>
          <Skeleton width={'100rem'} animation="wave" />
        </NameLabel>
      </RowCenter>
    </Container>
  );
};
export default SupplierCardSkeleton;
