import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';

export const useIsAuthenticated = () => {
  // If user has finished logging in as supplier and/or company
  const { isLoggedIn } = useSelector(
    (state: IRootReducerState) => state.userInfo
  );
  const { intentUUID } = useSelector(
    (state: IRootReducerState) => state.submitConfigurationInfo
  );
  const isAuthenticated = isLoggedIn || intentUUID;
  return isAuthenticated;
};
