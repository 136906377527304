import { UrlParamsProcessor } from 'listeners/UrlParamsProcessor';
import AppNavigationRouter from 'navigation/AppNavigationRouter';
import { AppThemeProvider } from 'providers/AppThemeProvider/AppThemeProvider';
import { HtmlThemeProvider } from 'providers/HtmlThemeProvider/HtmlThemeProvider';
import { ToastProvider } from 'providers/ToastProvider/ToastProvider';
import usePreventScrollOnFocusedNumberInput from 'utils/hooks/usePreventScrollOnFocusedNumberInput';

function App() {
  usePreventScrollOnFocusedNumberInput();
  return (
    <UrlParamsProcessor>
      <AppThemeProvider>
        <HtmlThemeProvider>
          <ToastProvider>
            <AppNavigationRouter />
          </ToastProvider>
        </HtmlThemeProvider>
      </AppThemeProvider>
    </UrlParamsProcessor>
  );
}

export default App;
