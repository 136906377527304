import { IConfiguredProductData } from 'pages/ConfigurePage/ConfigureForm/hooks';
import apiClient from 'services/api/apiService';
import { getUrlWithQueryParams } from './api/getUrlWithQueryParams';

const BASE_URL = '/v1/external';

export const submitConfiguration = async (
  configurationData: IConfiguredProductData,
  base64Metadata?: string
): Promise<any> => {
  const url = `${BASE_URL}/submit-configuration`;
  const { data } = await apiClient.post<any>(
    url,
    { data: configurationData, base64_metadata: base64Metadata },
    {
      headers: {
        'Content-type': 'application/json',
      },
    }
  );
  return data.data;
};

export const getEditConfiguration = async (
  base64Metadata?: string
): Promise<any> => {
  const url = `${BASE_URL}/get-edit-configuration`;
  const queryParamsObj = {
    base64_metadata: base64Metadata,
  };
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);
  const { data } = await apiClient.get<any>(fullUrl, {
    headers: {
      'Content-type': 'application/json',
    },
  });

  return data.data;
};
