import {
  Container,
  ProfileCard,
  H3,
  HeadingWrapper,
} from './ProfilePage.styled';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IRootReducerState } from 'store/store';
import ProfileCardRow from './ProfileCardRow/ProfileCardRow';
import { EditableProfileUserDataType } from './constants';
import { usePageTitle } from 'utils/hooks/usePageTitle';

const ProfilePage = () => {
  const { t } = useTranslation();
  const {
    email,
    first_name: firstName,
    last_name: lastName,
  } = useSelector((state: IRootReducerState) => state.userInfo);

  usePageTitle('Profile');

  return (
    <Container>
      <HeadingWrapper>
        <H3>{t('Profile')}</H3>
      </HeadingWrapper>
      <ProfileCard>
        <ProfileCardRow
          labelName={'Name'}
          labelValue={firstName}
          editableFieldType={EditableProfileUserDataType.NAME}
        />
        <ProfileCardRow
          labelName={'Last name'}
          labelValue={lastName}
          editableFieldType={EditableProfileUserDataType.LAST_NAME}
        />
        <ProfileCardRow
          labelName={'E-mail'}
          labelValue={email}
          editableFieldType={EditableProfileUserDataType.EMAIL}
          disableChange
        />
        <ProfileCardRow
          labelName={'Password'}
          labelValue={'********'}
          editableFieldType={EditableProfileUserDataType.PASSWORD}
        />
      </ProfileCard>
    </Container>
  );
};

export default ProfilePage;
