import { COLORS, marginMd, paddingMd } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

export const Title = styled.label`
  font-weight: 400;
  font-size: 24rem;
  line-height: 32rem;
  margin-bottom: ${paddingMd};
  color: ${COLORS.BLACK};
  display: inline-block;

  ${respondTo.bigTablet`
    font-size: 22rem;
  `}

  ${respondTo.smallTablet`
    font-size: 20rem;
  `}

  ${respondTo.bigPhone`
    font-size: 18rem;
    margin-bottom: 20rem;
  `}
`;

export const InputWrapper = styled.div`
  width: 462rem;
  margin-bottom: ${marginMd};

  ${respondTo.bigPhone`
    width: 100%;
  `}
`;

export const Container = styled.div`
  padding: ${paddingMd};

  ${respondTo.bigPhone`
    padding: 35rem 20rem 20rem;
  `}
`;
