import { ContainerRelative } from '../FormImagesSwiper.styled';
import {
  IImageLayer,
  IImageSection,
} from 'pages/ConfigurePage/FormImages/hooks';
import { Dispatch, SetStateAction } from 'react';
import { LargeFormImageLayer } from './LargeFormImageLayer';

interface ILargeFormImageProps {
  image: IImageSection;
  setError: Dispatch<SetStateAction<boolean>>;
  isPreview?: boolean;
  previewWidth?: number;
  previewHeight?: number;
}

export const LargeFormImage = ({
  image,
  setError,
  isPreview = false,
  previewWidth,
  previewHeight,
}: ILargeFormImageProps) => {
  const imageLayerKeys: string[] = image.layers
    ? Object.keys(image.layers)
    : [];
  return (
    <ContainerRelative>
      {imageLayerKeys.map((layerKey: string, index: number) => {
        const layer: IImageLayer = image.layers[layerKey];
        return (
          <LargeFormImageLayer
            key={`${index}-${layerKey}`}
            layerKey={`${index}-${layerKey}`}
            layer={layer}
            setError={setError}
            isPreview={isPreview}
            imageWidth={isPreview ? previewWidth : undefined}
            imageHeight={isPreview ? previewHeight : undefined}
          />
        );
      })}
    </ContainerRelative>
  );
};
