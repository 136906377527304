import { useTranslation } from 'react-i18next';
import { Container, Title } from './RecentlyViewedSection.styled';
import { ViewType } from 'components/ViewTypePicker/ViewTypePicker';
import RecentlyViewedSectionGrid from './RecentlyViewedSectionGrid/RecentlyViewedSectionGrid';
import RecentlyViewedSectionList from './RecentlyViewedSectionList/RecentlyViewedSectionList';

interface IRecentlyViewedSectionProps {
  selectedViewType: ViewType;
}

const RecentlyViewedSection = ({
  selectedViewType,
}: IRecentlyViewedSectionProps) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Title>{t('Recently viewed')}</Title>
      {selectedViewType === ViewType.GRID ? (
        <RecentlyViewedSectionGrid />
      ) : (
        <RecentlyViewedSectionList />
      )}
    </Container>
  );
};

export default RecentlyViewedSection;
