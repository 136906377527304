import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Formik, Form, FormikProps } from 'formik';
import { IRootReducerState } from 'store/store';
import {
  IConfiguration,
  ICreateConfigurationData,
  ITestCompanyModelConfiguration,
  IUpdateConfigurationData,
} from 'types/Configuration.types';
import Button from 'components/Button/Button';
import { Input } from 'components/Input/InputFormik';
import {
  Container,
  Title,
  FormWrapper,
  ButtonsWrapper,
  InputWrapper,
  ErrorMessage,
  BackDrop,
  checkboxLabelStyling,
} from './EditTestConfigurationModal.styled';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { testConfigurationPreviewFormValidationSchema } from './validation';
import { Modal } from 'components/Modals/Modal/Modal';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';
import {
  setIsTestConfigurationModalOpen,
  setTestCompanyModelConfiguration,
} from 'store/SubmitConfiguration/actions/submitConfiguration';
import { useLocation, useNavigate } from 'react-router-dom';
import { ITestDealerPanelConfigurationNavigationState } from 'pages/DealerPanel/DealerPanelModelsPage/SelectVATAmountModal/SelectVATAmountModal';
import { ConfirmModal } from '../ConfirmModal/ConfirmModal';
import { useRef, useState } from 'react';
import { useGetTaxRate } from 'providers/QueryProvider/hooks';
import {
  useCreateConfiguration,
  useUpdateConfiguration,
} from 'pages/DealerPanel/DealerPanelModelsPage/DealerPanelCategory/ModelConfiguration/hooks';
import { generateNewTestConfigurationFromFormikValues } from './helpers';

export const EditTestConfigurationModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formikRef = useRef<FormikProps<any> | any>();
  const testConfiguration: ITestCompanyModelConfiguration | null = useSelector(
    (state: IRootReducerState) =>
      state.submitConfigurationInfo.testCompanyModelConfiguration
  );

  const isTestConfigurationModalOpen: boolean = useSelector(
    (state: IRootReducerState) =>
      state.submitConfigurationInfo.isTestConfigurationModalOpen
  );
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);

  const { isPhone } = useBreakpointFlag();
  const { state }: { state: ITestDealerPanelConfigurationNavigationState } =
    useLocation();

  const taxRate = useGetTaxRate();
  const updateTaxRateParam = (newTaxRate: number) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('taxRate', newTaxRate.toString());
    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
      state: state,
    });
  };
  const updateExistingConfigurationIdInNavigationState = (configId: number) => {
    const searchParams = new URLSearchParams(location.search);
    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
      state: { ...state, existingConfigurationId: configId },
    });
  };

  const { mutate: createConfiguration } = useCreateConfiguration();
  const { mutate: updateConfiguration } = useUpdateConfiguration(
    state?.existingConfigurationId as number
  );

  if (!testConfiguration) {
    return null;
  }

  const updateTestConfigurationAndTaxRate = (
    testConfiguration: ITestCompanyModelConfiguration,
    values: any,
    state: ITestDealerPanelConfigurationNavigationState
  ) => {
    const newTestConfiguration: ITestCompanyModelConfiguration =
      generateNewTestConfigurationFromFormikValues(
        testConfiguration,
        values,
        state
      );
    updateTaxRateParam(Number(values.tax_rate));
    dispatch(setTestCompanyModelConfiguration(newTestConfiguration));
  };

  const handleApply = () => {
    const values = formikRef?.current?.values;
    if (!values) {
      return;
    }

    if (!state.existingConfigurationId) {
      const createConfigurationData: ICreateConfigurationData = {
        model_id: testConfiguration.model_id,
        base_markup: values.base_markup,
        base_purchase_discount: values.base_purchase_discount,
        surcharge_markup: values.surcharge_markup,
        surcharge_purchase_discount: values.surcharge_purchase_discount,
        fixed_amount: values.fixed_amount,
        exclude_surcharge: values.exclude_surcharge,
        round_prices: values.round_prices,
        is_active: values.is_active,
        is_using_supplier_system_discounts:
          values.is_using_supplier_system_discounts,
      };
      createConfiguration(createConfigurationData, {
        onSuccess: (data: IConfiguration) => {
          updateExistingConfigurationIdInNavigationState(data.id);
          updateTestConfigurationAndTaxRate(testConfiguration, values, state);
          dispatch(setIsTestConfigurationModalOpen(false));
          setIsConfirmModalOpen(false);
        },
      });
    } else {
      const updateConfigurationData: IUpdateConfigurationData = {
        base_markup: values.base_markup,
        base_purchase_discount: values.base_purchase_discount,
        surcharge_markup: values.surcharge_markup,
        surcharge_purchase_discount: values.surcharge_purchase_discount,
        fixed_amount: values.fixed_amount,
        exclude_surcharge: values.exclude_surcharge,
        round_prices: values.round_prices,
        is_active: values.is_active,
        is_using_supplier_system_discounts:
          values.is_using_supplier_system_discounts,
      };
      updateConfiguration(updateConfigurationData, {
        onSuccess: () => {
          updateTestConfigurationAndTaxRate(testConfiguration, values, state);
          dispatch(setIsTestConfigurationModalOpen(false));
          setIsConfirmModalOpen(false);
        },
      });
    }
  };

  return (
    <Modal
      isOpen={isTestConfigurationModalOpen}
      onClose={() => dispatch(setIsTestConfigurationModalOpen(false))}
      modalStyle={{
        margin: 'auto',
        height: 'fit-content',
        width: isPhone ? '90%' : '600rem',
        minWidth: isPhone ? '90%' : 'auto',
        position: 'fixed',
      }}
      pwId="edit-test-configuration-modal"
    >
      <Container isConfirmModalOpen={isConfirmModalOpen}>
        <Title>{t('Edit Test Configuration')}</Title>
        <Formik
          initialValues={{ ...testConfiguration, tax_rate: taxRate }}
          validationSchema={testConfigurationPreviewFormValidationSchema}
          onSubmit={() => {
            //
          }}
          innerRef={formikRef}
        >
          {({ errors, setFieldValue, values, isValid }) => (
            <Form>
              <FormWrapper>
                <InputWrapper>
                  <Input
                    height={'41rem'}
                    wrapperStyles={{ width: '100%' }}
                    width={'100%'}
                    name="tax_rate"
                    label={t('VAT percentage')}
                    placeholder={t('VAT percentage')}
                    type="number"
                  />
                  <ErrorMessage>{errors.tax_rate}</ErrorMessage>
                </InputWrapper>
                <InputWrapper>
                  <Input
                    height={'41rem'}
                    wrapperStyles={{ width: '100%' }}
                    width={'100%'}
                    name="base_purchase_discount"
                    label={t('Base purchase discount')}
                    placeholder={t('Base purchase discount')}
                    type="number"
                  />
                  <ErrorMessage>{errors.base_purchase_discount}</ErrorMessage>
                </InputWrapper>
                <InputWrapper>
                  <Input
                    height={'41rem'}
                    wrapperStyles={{ width: '100%' }}
                    width={'100%'}
                    name="base_markup"
                    label={t('Base markup')}
                    placeholder={t('Base markup')}
                    type="number"
                  />
                  <ErrorMessage>{errors.base_markup}</ErrorMessage>
                </InputWrapper>
                <InputWrapper>
                  <Input
                    height={'41rem'}
                    wrapperStyles={{ width: '100%' }}
                    width={'100%'}
                    name="fixed_amount"
                    label={t('Fixed amount')}
                    placeholder={t('Fixed amount')}
                    type="number"
                  />
                  <ErrorMessage>{errors.fixed_amount}</ErrorMessage>
                </InputWrapper>
                <InputWrapper>
                  <Input
                    height={'41rem'}
                    wrapperStyles={{ width: '100%' }}
                    width={'100%'}
                    name="surcharge_purchase_discount"
                    label={t('Surcharge purchase discount')}
                    placeholder={t('Surcharge purchase discount')}
                    type="number"
                  />
                  <ErrorMessage>
                    {errors.surcharge_purchase_discount}
                  </ErrorMessage>
                </InputWrapper>
                <InputWrapper>
                  <Input
                    height={'41rem'}
                    wrapperStyles={{ width: '100%' }}
                    width={'100%'}
                    name="surcharge_markup"
                    label={t('Surcharge markup')}
                    placeholder={t('Surcharge markup')}
                    type="number"
                  />
                  <ErrorMessage>{errors.surcharge_markup}</ErrorMessage>
                </InputWrapper>
                <Checkbox
                  isChecked={values.exclude_surcharge}
                  label={t('Exclude surcharge amount')}
                  style={checkboxLabelStyling}
                  onChange={() =>
                    setFieldValue(
                      'exclude_surcharge',
                      !values.exclude_surcharge
                    )
                  }
                />
                <Checkbox
                  isChecked={values.round_prices}
                  label={t('Round prices')}
                  style={checkboxLabelStyling}
                  onChange={() =>
                    setFieldValue('round_prices', !values.round_prices)
                  }
                />
                {state?.supplierHasSystemIntegration ? (
                  <Checkbox
                    isChecked={values.is_using_supplier_system_discounts}
                    label={t('Supplier discount')}
                    onChange={() =>
                      setFieldValue(
                        'is_using_supplier_system_discounts',
                        !values.is_using_supplier_system_discounts
                      )
                    }
                  />
                ) : null}
              </FormWrapper>
              <ButtonsWrapper>
                <Button
                  secondary
                  label={t('Cancel')}
                  onClick={() =>
                    dispatch(setIsTestConfigurationModalOpen(false))
                  }
                  width="150rem"
                  height="40rem"
                />
                <Button
                  primary
                  disabled={!isValid}
                  label={t('Test')}
                  onClick={() => {
                    updateTestConfigurationAndTaxRate(
                      testConfiguration,
                      values,
                      state
                    );
                    dispatch(setIsTestConfigurationModalOpen(false));
                  }}
                  width="150rem"
                  height="40rem"
                />
                <Button
                  primary
                  disabled={!isValid}
                  label={t('Apply')}
                  width="150rem"
                  height="40rem"
                  onClick={() => setIsConfirmModalOpen(true)}
                />
              </ButtonsWrapper>
            </Form>
          )}
        </Formik>
      </Container>
      {isConfirmModalOpen && (
        <BackDrop onClick={() => setIsConfirmModalOpen(false)} />
      )}
      <ConfirmModal
        isOpen={isConfirmModalOpen}
        setIsOpen={setIsConfirmModalOpen}
        title={t('Confirm submission')}
        description={t(
          'This action will update the actual pricing configuration of the model'
        )}
        onConfirm={handleApply}
        onCancel={() => setIsConfirmModalOpen(false)}
      />
    </Modal>
  );
};
