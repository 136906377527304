import { useInfiniteQuery } from 'react-query';
import { getCompanyUsers } from 'services/CompanyService';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';

export const useGetCompanyUsersInfinite = (
  companyId: number,
  perPage: number,
  searchBy: string
) =>
  useInfiniteQuery({
    queryKey: [
      ReactQueryKeys.GET_COMPANY_USERS_INFINITE,
      companyId,
      perPage,
      searchBy,
    ],
    queryFn: ({ pageParam = 1 }) =>
      getCompanyUsers(companyId, pageParam, perPage, searchBy),
    getNextPageParam: (lastPage: any) => {
      if (!lastPage?.pagination) {
        return undefined;
      }
      if (
        lastPage.pagination.per_page * lastPage.pagination.page >=
        lastPage.pagination.total
      ) {
        // Return undefined here so hasNextPage equals to false
        return undefined;
      }
      return lastPage.pagination.page + 1;
    },
    onSuccess: (data) => {
      return data;
    },
  });
