import apiClient from 'services/api/apiService';
import { getUrlWithQueryParams } from './api/getUrlWithQueryParams';

const BASE_URL = '/v1/supplier-company-models';

export const getSupplierCompanyModels = async (
  page: number,
  perPage: number,
  searchBy: string,
  categoryId: number | null,
  supplierCompanyId: number | null,
  modelId: number | null,
  supplierId: number | null
): Promise<any> => {
  const url = `${BASE_URL}`;
  const queryParamsObj = {
    page: page,
    per_page: perPage,
    search_term: searchBy,
    category_id: categoryId,
    supplier_company_id: supplierCompanyId,
    model_id: modelId,
    supplier_id: supplierId,
  };
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);
  const { data } = await apiClient.get<any>(fullUrl);

  return data.data;
};

export const enableOrderSupplierCompanyModel = async (
  supplierCompanyModelId: number
): Promise<any> => {
  const url = `${BASE_URL}/${supplierCompanyModelId}/enable-order`;
  const { data } = await apiClient.patch<any>(url);

  return data.data;
};

export const disableOrderSupplierCompanyModel = async (
  supplierCompanyModelId: number
): Promise<any> => {
  const url = `${BASE_URL}/${supplierCompanyModelId}/disable-order`;
  const { data } = await apiClient.patch<any>(url);

  return data.data;
};

export const enableQuerySupplierCompanyModel = async (
  supplierCompanyModelId: number
): Promise<any> => {
  const url = `${BASE_URL}/${supplierCompanyModelId}/enable-query`;
  const { data } = await apiClient.patch<any>(url);

  return data.data;
};

export const disableQuerySupplierCompanyModel = async (
  supplierCompanyModelId: number
): Promise<any> => {
  const url = `${BASE_URL}/${supplierCompanyModelId}/disable-query`;
  const { data } = await apiClient.patch<any>(url);

  return data.data;
};

export const enableAllModelOrderingForSupplierCompany = async (
  supplierCompanyId: number
): Promise<any> => {
  const url = `${BASE_URL}/enable-all-order`;
  const { data } = await apiClient.patch<any>(url, {
    supplier_company_id: supplierCompanyId,
  });

  return data.data;
};

export const enableAllModelQueryingForSupplierCompany = async (
  supplierCompanyId: number
): Promise<any> => {
  const url = `${BASE_URL}/enable-all-query`;
  const { data } = await apiClient.patch<any>(url, {
    supplier_company_id: supplierCompanyId,
  });

  return data.data;
};

export const enableModelOrderingForAllSupplierCompanies = async (
  modelId: number,
  supplierId: number
): Promise<any> => {
  const url = `${BASE_URL}/enable-all-order`;
  const { data } = await apiClient.patch<any>(url, {
    model_id: modelId,
    supplier_id: supplierId,
  });

  return data.data;
};

export const enableModelQueryingForAllSupplierCompanies = async (
  modelId: number,
  supplierId: number
): Promise<any> => {
  const url = `${BASE_URL}/enable-all-query`;
  const { data } = await apiClient.patch<any>(url, {
    model_id: modelId,
    supplier_id: supplierId,
  });

  return data.data;
};

export const createSupplierCompanyModel = async (
  companyId: number,
  supplierId: number,
  modelId: number
): Promise<any> => {
  const url = `${BASE_URL}`;
  const payload = {
    company_id: companyId,
    supplier_id: supplierId,
    model_id: modelId,
  };
  const { data } = await apiClient.post<any>(url, payload);
  return data.data;
};

export const getEligibleCompaniesForCreatingSupplierCompanyModel = async (
  page: number,
  perPage: number,
  modelId: number,
  supplierId: number,
  searchBy: string
): Promise<any> => {
  const url = `${BASE_URL}/eligible-companies`;
  const queryParamsObj = {
    page: page,
    per_page: perPage,
    model_id: modelId,
    supplier_id: supplierId,
    search_term: searchBy,
  };
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);
  const { data } = await apiClient.get<any>(fullUrl);

  return data.data;
};

export const deleteSupplierCompanyModel = async (
  supplierCompanyModelId: number
): Promise<any> => {
  const url = `v1/supplier-company-models/${supplierCompanyModelId}`;
  const { data } = await apiClient.delete<any>(url);
  return data.data;
};

export const getEligibleModelsForCreatingSupplierCompanyModel = async (
  page: number,
  perPage: number,
  companyId: number,
  supplierId: number,
  searchBy: string
): Promise<any> => {
  const url = `${BASE_URL}/eligible-models`;
  const queryParamsObj = {
    page: page,
    per_page: perPage,
    company_id: companyId,
    supplier_id: supplierId,
    search_term: searchBy,
  };
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);
  const { data } = await apiClient.get<any>(fullUrl);

  return data.data;
};
