import { useTranslation } from 'react-i18next';
import {
  AddLabel,
  AddWrapper,
  ButtonsWrapper,
  CompaniesLabel,
  Container,
  FirstRow,
  InputWrapper,
  NoContentLabel,
  RowWrapper,
  enableButtonStyling,
  enableButtonWrapperStyling,
} from './SupplierModelCompanies.styled';
import Input from 'components/Input/Input';
import { useEffect, useState } from 'react';
import { useDebounce } from 'utils/hooks/useDebounce';
import {
  useDeleteSupplierCompanyModel,
  useEnableModelOrderingForAllSupplierCompanies,
  useEnableModelQueryingForAllSupplierCompanies,
} from './hooks';
import { SUPPLIER_COMPANY_MODELS_PER_PAGE } from './constants';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import Pagination from 'components/Pagination/Pagination';
import { queryClient } from 'index';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { useGetSupplierCompanyModels } from 'pages/Supplier/SupplierSingleCompanyPage/SupplierCompanyModels/hooks';
import { ISupplierCompanyModel } from 'types/SupplierCompanyModel';
import SupplierModelCompaniesRow from './SupplierModelCompaniesRow/SupplierModelCompaniesRow';
import SupplierModelCompaniesRowSkeleton from './SupplierModelCompaniesRow/SupplierModelCompaniesRowSkeleton/SupplierModelCompaniesRowSkeleton';
import { Plus, MagnifyingGlass as Search } from '@phosphor-icons/react';
import Icon from 'components/Icon/Icon';
import { AddCompanyToModelModal } from 'components/Modals/AddCompanyToModelModal/AddCompanyToModelModal';
import { DangerModal } from 'components/Modals/DangerModal/DangerModal';
import Button from 'components/Button/Button';
import { COLORS } from 'assets/styled';

interface ISupplierModelCompaniesProps {
  modelId: number;
}

const SupplierModelCompanies = ({ modelId }: ISupplierModelCompaniesProps) => {
  const { t } = useTranslation();
  const [searchBy, setSearchBy] = useState<string>('');
  const debouncedSearchBy = useDebounce(searchBy);
  const [page, setPage] = useState<number>(1);
  const [isAddCompanyModalOpen, setIsAddCompanyModalOpen] =
    useState<boolean>(false);
  const [
    isDeleteSupplierCompanyModelModalOpen,
    setIsDeleteSupplierCompanyModelModalOpen,
  ] = useState<boolean>(false);
  const [
    supplierCompanyModelIdForDeletion,
    setSupplierCompanyModelIdForDeletion,
  ] = useState<number | null>(null);

  const supplier = useSelector(
    (state: IRootReducerState) => state.userInfo.supplier
  );

  const {
    data: supplierCompanyModelsData,
    isLoading: isLoadingSupplierCompanyModels,
  } = useGetSupplierCompanyModels(
    page,
    SUPPLIER_COMPANY_MODELS_PER_PAGE,
    debouncedSearchBy,
    null,
    null,
    modelId,
    supplier.id!
  );

  const supplierCompanyModels: ISupplierCompanyModel[] =
    supplierCompanyModelsData?.data || [];

  const {
    mutate: enableOrderingForAllSupplierCompanies,
    isLoading: isLoadingEnableOrderingForAllSupplierCompanies,
    isSuccess: isSuccessEnableOrderingForAllSupplierCompanies,
    reset: resetEnableOrderingForAllSupplierCompanies,
  } = useEnableModelOrderingForAllSupplierCompanies();

  const {
    mutate: enableQueryingForAllSupplierCompanies,
    isLoading: isLoadingEnableQueryingForAllSupplierCompanies,
    isSuccess: isSuccessEnableQueryingForAllSupplierCompanies,
    reset: resetEnableQueryingForAllSupplierCompanies,
  } = useEnableModelQueryingForAllSupplierCompanies();

  useEffect(() => {
    if (
      isSuccessEnableOrderingForAllSupplierCompanies ||
      isSuccessEnableQueryingForAllSupplierCompanies
    ) {
      queryClient.invalidateQueries([
        ReactQueryKeys.GET_SUPPLIER_COMPANY_MODELS,
      ]);
      resetEnableOrderingForAllSupplierCompanies();
      resetEnableQueryingForAllSupplierCompanies();
    }
  }, [
    isSuccessEnableOrderingForAllSupplierCompanies,
    isSuccessEnableQueryingForAllSupplierCompanies,
  ]);

  const showPagination =
    supplierCompanyModelsData?.pagination?.total &&
    supplierCompanyModelsData?.pagination?.total >=
      SUPPLIER_COMPANY_MODELS_PER_PAGE;

  const isLoading =
    isLoadingSupplierCompanyModels ||
    isLoadingEnableOrderingForAllSupplierCompanies ||
    isLoadingEnableQueryingForAllSupplierCompanies;

  const {
    mutate: deleteSupplierCompanyModel,
    isLoading: isLoadingDeleteSupplierCompanyModel,
  } = useDeleteSupplierCompanyModel();

  return (
    <Container>
      <FirstRow>
        <RowWrapper>
          <CompaniesLabel>{t('Companies')}</CompaniesLabel>
          <InputWrapper>
            <Input
              placeholder={t('Search companies')}
              width={'100%'}
              wrapperStyles={{ width: '100%' }}
              onChange={(e: any) => setSearchBy(e.target.value)}
              icon={Search}
            />
          </InputWrapper>
          <ButtonsWrapper>
            <Button
              secondary
              label={t('Enable query for all companies')}
              onClick={() =>
                enableQueryingForAllSupplierCompanies({
                  modelId,
                  supplierId: supplier.id,
                })
              }
              wrapperStyle={enableButtonWrapperStyling}
              buttonStyle={enableButtonStyling}
            />
            <Button
              secondary
              label={t('Enable order for all companies')}
              onClick={() =>
                enableOrderingForAllSupplierCompanies({
                  modelId,
                  supplierId: supplier.id,
                })
              }
              wrapperStyle={enableButtonWrapperStyling}
              buttonStyle={enableButtonStyling}
            />
          </ButtonsWrapper>
          <AddWrapper onClick={() => setIsAddCompanyModalOpen(true)}>
            <Icon svg={Plus} color={COLORS.GREEN} size={20} />
            <AddLabel>{t('Add Company')}</AddLabel>
          </AddWrapper>
        </RowWrapper>
      </FirstRow>
      {supplierCompanyModels.length ? (
        supplierCompanyModels.map(
          (supplierCompanyModel: ISupplierCompanyModel, index: number) => (
            <SupplierModelCompaniesRow
              key={`company-models-row-${index}`}
              supplierCompanyModel={supplierCompanyModel}
              withoutLine={supplierCompanyModels.length - 1 === index}
              isLoadingParent={isLoading}
              onDelete={(supplierCompanyModelId: number) => {
                setIsDeleteSupplierCompanyModelModalOpen(true);
                setSupplierCompanyModelIdForDeletion(supplierCompanyModelId);
              }}
            />
          )
        )
      ) : isLoadingSupplierCompanyModels ? (
        Array.from({ length: SUPPLIER_COMPANY_MODELS_PER_PAGE }, (_, index) => (
          <SupplierModelCompaniesRowSkeleton
            key={index}
            index={index}
            opacityCoeficient={0.1}
            withoutLine={SUPPLIER_COMPANY_MODELS_PER_PAGE - 1 === index}
          />
        ))
      ) : (
        <NoContentLabel>
          {t('No companies found for this model')}
        </NoContentLabel>
      )}
      {showPagination ? (
        <Pagination
          page={page}
          perPage={SUPPLIER_COMPANY_MODELS_PER_PAGE}
          total={supplierCompanyModelsData?.pagination?.total}
          setPage={setPage}
        />
      ) : null}
      <AddCompanyToModelModal
        isOpen={isAddCompanyModalOpen}
        setIsOpen={setIsAddCompanyModalOpen}
        modelId={modelId}
      />
      <DangerModal
        isOpen={isDeleteSupplierCompanyModelModalOpen}
        setIsOpen={setIsDeleteSupplierCompanyModelModalOpen}
        title={t('Confirm removal')}
        submit={{
          onClick: () => {
            deleteSupplierCompanyModel(supplierCompanyModelIdForDeletion!);
            setIsDeleteSupplierCompanyModelModalOpen(false);
            setSupplierCompanyModelIdForDeletion(null);
          },
          text: t('Remove'),
          disabled: isLoadingDeleteSupplierCompanyModel,
        }}
        cancel={{
          onClick: () => {
            setIsDeleteSupplierCompanyModelModalOpen(false);
            setSupplierCompanyModelIdForDeletion(null);
          },
        }}
        description={t(
          'Are you sure you want to remove access of this Model for this Company?'
        )}
      />
    </Container>
  );
};

export default SupplierModelCompanies;
