import { Skeleton } from '@mui/material';
import { COLORS } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import Icon from 'components/Icon/Icon';
import styled, { css } from 'styled-components';

export const MODEL_ROW_CONTAINER_HEIGHT = 65;

interface StyledProps {
  withoutLine: boolean;
  opacityIndex: number;
  opacityCoeficient: number;
}

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: ${MODEL_ROW_CONTAINER_HEIGHT}rem;
  ${(props: StyledProps) =>
    !props.withoutLine &&
    css`
      border-bottom: 1rem solid ${COLORS.BORDER};
    `}
  ${(props: StyledProps) =>
    props.opacityIndex !== undefined &&
    css`
      opacity: ${1 - (props.opacityIndex * props.opacityCoeficient + 0.2)};
    `}

  ${respondTo.smallTablet`
    height: 50rem;
  `}
`;

export const LeftPart = styled.div`
  margin-left: 16rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5rem;

  ${respondTo.bigTablet`
    margin-left: 0;
  `}
`;

export const ModelNameLabel = styled.label`
  font-size: 18rem;
  font-weight: 400;
  color: ${COLORS.GREEN};

  ${respondTo.smallTablet`
    font-size: 16rem;
  `}
`;

export const RightPart = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20rem;
`;

export const Label = styled.label`
  font-size: 18rem;
  font-weight: 400;
  color: ${COLORS.BLACK};

  ${respondTo.smallTablet`
    font-size: 16rem;
  `}
`;

export const StyledIcon = styled(Icon)`
  &:hover {
    transform: scale(1.2);
    transition: transform ease-in-out 0.1s;
  }
`;

export const favoriteIconStyle = css`
  &:hover {
    transform: scale(1.2);
    transition: transform ease-in-out 0.1s;
  }
`;

export const StyledSkeleton = styled(Skeleton)`
  border-radius: 15rem;
`;

export const CircleSkeleton = styled(Skeleton)`
  border-radius: 13rem;
`;
