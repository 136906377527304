import { COLORS, marginSm } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

export const Container = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${respondTo.bigPhone`
    padding: 15rem;
    gap: 20rem;
  `}
`;

export const Title = styled.label`
  padding-top: 45rem;
  padding-bottom: 20rem;
  font-weight: 400;
  font-size: 24rem;
  color: ${COLORS.BLACK};
  text-align: center;
`;

export const SubTitle = styled.label`
  font-weight: 400;
  font-size: 16rem;
  padding-bottom: 30rem;
  color: ${COLORS.BLACK};
  text-align: center;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${marginSm};
  padding-bottom: 30rem;
  flex-wrap: wrap-reverse;
  justify-content: center;
`;
