import { useTranslation } from 'react-i18next';
import { StyledTabs, Tab } from './Tabs.styled';
import { Dispatch, SetStateAction } from 'react';
import { BuilderTabs } from '../../../types/Builder.types';

interface IProps {
  activeTab: BuilderTabs;
  setActiveTab: Dispatch<SetStateAction<BuilderTabs>>;
}

const Tabs = ({ activeTab, setActiveTab }: IProps) => {
  const { t } = useTranslation();
  return (
    <StyledTabs>
      <Tab
        isActive={activeTab === BuilderTabs.General}
        onClick={() => setActiveTab(BuilderTabs.General)}
      >
        {t('General')}
      </Tab>
      <Tab
        isActive={activeTab === BuilderTabs.InputFields}
        onClick={() => setActiveTab(BuilderTabs.InputFields)}
      >
        {t('Input fields')}
      </Tab>
      <Tab
        isActive={activeTab === BuilderTabs.Pricing}
        onClick={() => setActiveTab(BuilderTabs.Pricing)}
      >
        {t('Pricing')}
      </Tab>
    </StyledTabs>
  );
};

export default Tabs;
