import { IQuestion } from 'types/Question.types';
import { SET_COLOR_MODAL_DATA, CLOSE_COLOR_MODAL } from '../colorModal';

export const closeColorModal = () => ({
  type: CLOSE_COLOR_MODAL,
});

export const setColorModalData = (question: IQuestion) => ({
  type: SET_COLOR_MODAL_DATA,
  payload: { question },
});
