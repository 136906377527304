import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  IImageLayer,
  IImageSection,
} from 'pages/ConfigurePage/FormImages/hooks';
import { LargeFormImageLayer } from 'components/FormImagesSwiper/LargeFormImage/LargeFormImageLayer';
import { Backdrop, Fade } from '@mui/material';
import { useWindowDimensions } from 'utils/hooks/useWindowDimensions';
import {
  ImageContainer,
  ModalContent,
  StyledModal,
  ErrorMessage,
} from './PreviewImageModal.styled';
import { useTranslation } from 'react-i18next';

export interface IPreviewImageModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  image: IImageSection | null;
}

interface IDimensions {
  width: number;
  height: number;
}

export function PreviewImageModal({
  isOpen,
  setIsOpen,
  image,
}: IPreviewImageModalProps) {
  const { t } = useTranslation();
  const [imageDimensions, setImageDimensions] = useState<IDimensions | null>(
    null
  );
  const [error, setError] = useState(false);
  const { width: windowWidth, height: windowHeight } = useWindowDimensions();

  const imageLayerKeys: string[] = image?.layers
    ? Object.keys(image.layers)
    : [];

  useEffect(() => {
    if (!isOpen) {
      setError(false);
      setImageDimensions(null);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && image && imageLayerKeys.length > 0 && !imageDimensions) {
      const loadImage = (src: string) => {
        return new Promise<IDimensions>((resolve, reject) => {
          const img = new Image();
          img.onload = () => resolve({ width: img.width, height: img.height });
          img.onerror = reject;
          img.src = src;
        });
      };

      const tryLoadImage = async () => {
        const firstLayer = image.layers[imageLayerKeys[0]] as IImageLayer;
        try {
          const dimensions = await loadImage(firstLayer.source);
          setImageDimensions(dimensions);
        } catch {
          if (firstLayer.fallback) {
            try {
              const fallbackDimensions = await loadImage(firstLayer.fallback);
              setImageDimensions(fallbackDimensions);
            } catch {
              setError(true);
            }
          } else {
            setError(true);
          }
        }
      };

      tryLoadImage();
    }
  }, [isOpen, image, imageLayerKeys]);

  if (!image) {
    return null;
  }

  const modalWidth = windowWidth * 0.9;
  const modalHeight = windowHeight * 0.9;

  let imageWidth = 0;
  let imageHeight = 0;

  if (imageDimensions) {
    const imageAspectRatio = imageDimensions.width / imageDimensions.height;
    const modalAspectRatio = modalWidth / modalHeight;

    if (imageAspectRatio > modalAspectRatio) {
      imageWidth = modalWidth;
      imageHeight = modalWidth / imageAspectRatio;
    } else {
      imageHeight = modalHeight;
      imageWidth = modalHeight * imageAspectRatio;
    }
  }

  return (
    <StyledModal
      open={isOpen}
      onClose={() => setIsOpen(false)}
      closeAfterTransition
      slots={{
        backdrop: Backdrop,
      }}
      slotProps={{
        backdrop: {
          timeout: 200,
        },
      }}
    >
      <Fade in={isOpen}>
        <ModalContent onClick={() => setIsOpen(false)}>
          {error ? (
            <ErrorMessage>{t('Error loading image')}</ErrorMessage>
          ) : imageDimensions ? (
            <ImageContainer width={imageWidth} height={imageHeight}>
              {imageLayerKeys.map((layerKey: string, index: number) => {
                const layer = image.layers[layerKey] as IImageLayer;
                return (
                  <LargeFormImageLayer
                    key={`${index}-${layerKey}`}
                    layerKey={`${index}-${layerKey}`}
                    layer={layer}
                    setError={setError}
                    imageWidth={imageWidth}
                    imageHeight={imageHeight}
                  />
                );
              })}
            </ImageContainer>
          ) : (
            <ErrorMessage>Loading...</ErrorMessage>
          )}
        </ModalContent>
      </Fade>
    </StyledModal>
  );
}
