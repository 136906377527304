import { queryClient } from 'index';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import {
  editSupplierCompanyReference,
  getSupplierCompanyById,
} from 'services/SupplierCompanyService';
import { LogyxError, getToastErrorMessage } from 'services/api/errors';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';

export interface IEditSupplierCompanyReferenceDTO {
  supplier_company_id: number;
  reference: string;
}

export const useEditSupplierCompanyReference = () => {
  const { t } = useTranslation();
  return useMutation(
    (dto: IEditSupplierCompanyReferenceDTO) =>
      editSupplierCompanyReference(dto),
    {
      onSuccess: () => {
        toast.success(t('Company reference updated'));
        queryClient.invalidateQueries([
          ReactQueryKeys.GET_SINGLE_SUPPLIER_COMPANY,
        ]);
        queryClient.invalidateQueries([ReactQueryKeys.GET_SUPPLIER_COMPANIES]);
      },
      onError: (error: LogyxError) => {
        toast.error(getToastErrorMessage(error));
      },
      mutationKey: ReactMutationKeys.EDIT_SUPPLIER_COMPANY_REFERENCE,
    }
  );
};

export const useGetSingleSupplierCompany = (supplierCompanyId: number) =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_SINGLE_SUPPLIER_COMPANY, supplierCompanyId],
    queryFn: () => {
      return getSupplierCompanyById(Number(supplierCompanyId));
    },
    enabled: !!supplierCompanyId,
  });
