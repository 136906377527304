import styled from 'styled-components';
import { COLORS, gapSm, marginXs, paddingMd, paddingSm } from 'assets/styled';
import CustomSelect from 'components/Select/FormikSelect';
import { respondTo } from 'assets/styled/breakpoints';

interface StyledProps {
  height: string;
  overflowVisible: boolean;
}

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: ${gapSm};
  height: ${(props: StyledProps) => props.height};
`;

export const StyledCustomSelect = styled(CustomSelect)`
  margin-bottom: ${marginXs};
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: ${gapSm};
  padding: ${paddingSm};
`;

export const FormTitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${paddingMd};

  ${respondTo.desktop`
    padding-top: ${paddingSm};
    padding-bottom: ${paddingSm};
  `};
  ${respondTo.bigLaptop`
    padding-top: ${paddingMd};
    padding-bottom: ${paddingMd};
  `};
  ${respondTo.bigPhone`
    padding: ${paddingMd} ${paddingMd} 0 ${paddingMd};
  `};
`;

export const FormTitle = styled.label`
  width: 100%;
  font-size: 18rem;
  font-weight: 400;
  text-align: center;
  color: ${COLORS.BLACK};

  ${respondTo.bigPhone`
    font-size: 16rem;
  `}
`;

export const NoContentLabel = styled.label`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 24rem;
  line-height: 32rem;
  width: 100%;
  text-align: center;
  min-height: 200rem;
  color: ${COLORS.BLACK};
`;

export const QuestionWrapper = styled.div`
  height: 50rem;

  ${respondTo.bigPhone`
    height: 100%;
  `}
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10rem;
`;

export const Scrollable = styled.div`
  padding: 0 ${paddingMd} ${paddingMd} ${paddingMd};
  overflow: ${(props: StyledProps) =>
    props.overflowVisible ? 'visible' : 'scroll'};
`;

export const ErrorOcurredLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 18rem;
  font-weight: 500;
  height: 100%;
  color: ${COLORS.BLACK};
`;

export const QuestionLabel = styled.label`
  color: ${COLORS.BLACK};
  font-weight: 400;
  font-size: 14rem;
  line-height: 14rem;
`;
