import { SingleValueProps } from 'react-select';
import { components } from 'react-select';
import {
  ImageSmall,
  SingleValueContainer,
} from './ImageSmallSingleValue.styled';
import { adjustImageSourceToUseS3ImageTransformator } from 'utils/adjustImageSourceToUseS3ImageTransformator';

export const ImageSmallSingleValue = (props: SingleValueProps<any>) => {
  return (
    <components.SingleValue {...props}>
      <SingleValueContainer>
        {props.data.label}
        &nbsp; &nbsp;
        <ImageSmall
          src={adjustImageSourceToUseS3ImageTransformator(
            props.data.small_image,
            174
          )}
          alt={'img'}
        />
      </SingleValueContainer>
    </components.SingleValue>
  );
};
