import { useInfiniteQuery } from 'react-query';
import { getEligibleModelsForCreatingSupplierCompanyModel } from 'services/SupplierCompanyModelsService';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';

export const useGetEligibleModelsForCreatingSupplierCompanyModel = (
  perPage: number,
  companyId: number,
  supplierId: number,
  searchBy: string,
  isOpen: boolean
) =>
  useInfiniteQuery({
    queryKey: [
      ReactQueryKeys.GET_ELIGIBLE_MODELS_FOR_CREATING_SUPPLIER_COMPANY_MODEL,
      companyId,
      supplierId,
      perPage,
      searchBy,
    ],
    queryFn: ({ pageParam = 1 }) =>
      getEligibleModelsForCreatingSupplierCompanyModel(
        pageParam,
        perPage,
        companyId,
        supplierId,
        searchBy
      ),
    getNextPageParam: (lastPage: any) => {
      if (!lastPage?.pagination) {
        return undefined;
      }
      if (
        lastPage.pagination.per_page * lastPage.pagination.page >=
        lastPage.pagination.total
      ) {
        // Return undefined here so hasNextPage equals to false
        return undefined;
      }
      return lastPage.pagination.page + 1;
    },
    onSuccess: (data) => {
      return data;
    },
    enabled: isOpen,
  });
