import LocalStorageService from 'services/LocalStorageService';
import apiClient, { apiAuthClient } from './api/apiService';
import { LOCAL_STORAGE_REFRESH_TOKEN } from 'services/api/constants';
import { IUseRegisterDTO } from 'pages/Auth/RegisterPage/hooks';

const BASE_URL = '/v1';

export const register = async (params: IUseRegisterDTO): Promise<any> => {
  const { token, firstName, lastName, email, password } = params;
  const url = `${BASE_URL}/register`;
  const { data } = await apiAuthClient.post<any>(url, {
    token,
    first_name: firstName,
    last_name: lastName,
    email,
    password,
  });

  return data.data;
};

export const login = async (email: string, password: string): Promise<any> => {
  const url = `${BASE_URL}/login`;
  const { data } = await apiAuthClient.post<any>(url, { email, password });

  return data.data;
};

export const logout = async (): Promise<any> => {
  const url = `${BASE_URL}/logout`;
  const currentRefreshToken = LocalStorageService.getItem(
    LOCAL_STORAGE_REFRESH_TOKEN
  );
  const { data } = await apiClient.post<any>(url, {
    refresh_token: currentRefreshToken,
  });

  return data.data;
};

export const resetPassword = async (
  newPassword: string,
  confirmPassword: string,
  token: string
): Promise<any> => {
  const url = `${BASE_URL}/reset-password`;
  const { data } = await apiAuthClient.post<any>(url, {
    password: newPassword,
    password_confirmation: confirmPassword,
    token,
  });

  return data.data;
};

export const requestPasswordReset = async (email: string): Promise<any> => {
  const url = `${BASE_URL}/request-password-reset`;
  const { data } = await apiAuthClient.post<any>(url, {
    email,
  });
  return data.data;
};
