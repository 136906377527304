import { ICartItem } from 'types/CartItem.types';
import { calculateTotalNetPrice } from './ShoppingCartCard/helpers';
import { IRootReducerState } from 'store/store';
import { useSelector } from 'react-redux';
import { ISupplier } from 'types/Supplier.types';

export const calculateTotalNetPriceForCart = (lines: ICartItem[]) => {
  let totalNetPrice = 0;
  lines.forEach((line: ICartItem) => {
    const totalNetPriceForLine: number | null = calculateTotalNetPrice(
      line.configuration.pricing
    );
    if (totalNetPriceForLine) {
      totalNetPrice += totalNetPriceForLine;
    }
  });
  return totalNetPrice;
};

// Configuration is internal when all model configuratrions in all the cart lines come from a supplier to which the user has access to
export const useAreAllConfigurationsInternal = (lines: ICartItem[]) => {
  const { supplier } = useSelector(
    (state: IRootReducerState) => state.userInfo
  );
  const supplierId = supplier?.id;
  if (!supplierId) {
    return false;
  }
  return lines.every((line: ICartItem) => line.supplier.id === supplierId);
};

export interface ISupplierLine {
  supplier: ISupplier;
  lines: ICartItem[];
}

export const groupLinesBySupplier = (lines: ICartItem[]): ISupplierLine[] => {
  const supplierMap: { [key: number]: ISupplierLine } = {};

  lines.forEach((line) => {
    const supplierId = line.supplier.id;

    if (!supplierMap[supplierId]) {
      supplierMap[supplierId] = {
        supplier: line.supplier,
        lines: [],
      };
    }

    supplierMap[supplierId].lines.push(line);
  });

  return Object.values(supplierMap);
};
