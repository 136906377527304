import { AnyAction } from '@reduxjs/toolkit';
import { IQuestion } from 'types/Question.types';
import { SET_COLOR_MODAL_DATA, CLOSE_COLOR_MODAL } from '../colorModal';

export interface IColorModalReducerState {
  isOpen: boolean;
  question: IQuestion | null;
}

const INIT_STATE: IColorModalReducerState = {
  isOpen: true,
  question: null,
};

const colorModalReducer = (state = INIT_STATE, action: AnyAction) => {
  switch (action.type) {
    case CLOSE_COLOR_MODAL:
      return {
        ...state,
        isOpen: false,
        question: null,
      };
    case SET_COLOR_MODAL_DATA:
      return {
        ...state,
        isOpen: true,
        question: action.payload.question,
      };
    default:
      return state;
  }
};

export default colorModalReducer;
