import { COLORS } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled, { css } from 'styled-components';

interface StyledProps {
  active: boolean;
}

export const Container = styled.div`
  z-index: 100;
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  min-height: 100vh;
  background-color: ${COLORS.WHITE};
  transform: translateX(300%);
  transition: 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${(props: StyledProps) =>
    props.active &&
    css`
      transform: translateX(0);
    `}

  ${respondTo.smallTablet`
    width: 100%;
  `}
`;

export const Close = styled.div`
  position: absolute;
  width: 30rem;
  height: 30rem;
  top: 20rem;
  right: 20rem;
  cursor: pointer;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30rem;
  padding-top: 100rem;
`;

export const DownWrapper = styled.div`
  position: relative;
  padding: 15rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ByCodeflexText = styled.label`
  position: absolute;
  top: 20rem;
  font-weight: 400;
  font-size: 16rem;
  color: ${COLORS.BLACK};
`;

export const SwitchersWrapper = styled.div`
  gap: 30rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  ${respondTo.smallLaptop`
    gap: 20rem;
  `}
`;
