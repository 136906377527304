import { respondTo } from 'assets/styled/breakpoints';
import styled, { css } from 'styled-components';

interface StyledProps {
  zIndex: number;
  borderRadius: number;
  singleImage: boolean;
}

export const ContainerRelative = styled.div`
  position: relative;
  width: 152rem;
  height: 100rem !important;
  ${(props: StyledProps) =>
    props.singleImage
      ? css`
          width: 58rem;
        `
      : css`
          width: 152rem;
        `};

  ${respondTo.smallLaptop`
    width: 25%;
  `}
`;

export const SmallFormImageLayerImage = styled.img`
  position: absolute;
  inset: 0;
  user-select: none;
  cursor: pointer;
  ${(props: StyledProps) =>
    props.zIndex &&
    css`
      z-index: ${props.zIndex};
    `};
  ${(props: StyledProps) =>
    props.borderRadius &&
    css`
      border-radius: ${props.borderRadius};
    `};
  ${(props: StyledProps) =>
    props.singleImage
      ? css`
          width: 58rem;
        `
      : css`
          width: 152rem;
        `};

  ${respondTo.midTablet`
    width: 100%;
  `}
`;
