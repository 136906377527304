import { css } from 'styled-components';
import { COLORS } from './colors';

// ------------- GAP -------------
export const gapXs = '10rem';
export const gapSm = '20rem';
export const gapMd = '30rem';
export const gapLg = '50rem';

// ------------- PADDING -------------
export const paddingXs = '10rem';
export const paddingSm = '20rem';
export const paddingMd = '30rem';
export const paddingLg = '50rem';

// ------------- MARGIN -------------
export const marginXs = '10rem';
export const marginSm = '20rem';
export const marginMd = '30rem';
export const marginLg = '50rem';

// ------------- BORDER -------------
export const borderRadiusSm = '20rem';

export const borderWidthSm = '1rem';

// ------------- TRANSFORM -------------
export const transition = 'all 0.3s';

// ------------- BOX SHADOW -------------
export const boxShadowInput = `1rem 1rem 5rem 0rem ${COLORS.BLACK}`;

// Scrollbar
export const CustomScrollbar = css`
  overflow-y: scroll;
  padding-right: 12rem; /* Add padding to the right to accommodate the scrollbar */
  scrollbar-width: thin;
  scrollbar-color: ${COLORS.BORDER} ${COLORS.BORDER};
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: ${COLORS.BORDER};
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${COLORS.BORDER};
    border-radius: 5px;
    &:hover {
      background: ${COLORS.BORDER};
    }
  }
`;

// Handle words overflowing out of div
export const WordOverflow = css`
  display: inline-block;
  max-width: 100%;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;

  overflow: hidden;
  text-overflow: ellipsis;
`;
