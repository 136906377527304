import React from 'react';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { Overlay } from './OverlayProvider.styled';
import Loader from 'components/Loader/Loader';
import { useIsMutating } from 'react-query';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';

interface IOverlayProviderProps {
  children: React.ReactNode;
}

const OverlayProvider = ({ children }: IOverlayProviderProps) => {
  const queryRequestStatus = useSelector(
    (state: IRootReducerState) => state.modelInfo.queryStatus
  );
  const isSubmitConfigurationLoading = useIsMutating({
    mutationKey: ReactMutationKeys.SUBMIT_CONFIGURATION_WITH_WEBHOOK,
  });

  const isAddToCartLoading = useIsMutating({
    mutationKey: ReactMutationKeys.ADD_TO_SHOPPING_CART,
  });

  return (
    <>
      {queryRequestStatus.attempt ||
      isSubmitConfigurationLoading ||
      isAddToCartLoading ? (
        <Overlay onClick={() => console.log('overlay clicked')}>
          <Loader pwId={'configure-loader'} />
        </Overlay>
      ) : null}

      {children}
    </>
  );
};

export default OverlayProvider;
