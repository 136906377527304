import {
  ArrowWrapper,
  ButtonWrapper,
  Container,
  EyeWrapper,
  InputWrapper,
  Label,
  LabelValue,
  PasswordErrorMessage,
  PasswordInputWrapper,
  PasswordProfileCardRowWrapper,
} from './ProfileCardRow.styled';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button/Button';
import Input from 'components/Input/Input';
import { useEffect, useState } from 'react';
import { EditableProfileUserDataType } from '../constants';
import { useEditUserData } from '../hooks';
import { useDispatch } from 'react-redux';
import { setUpdatedUserInfo } from 'store/User/actions/user';
import { isButtonDisabled } from './helpers';
import Icon from 'components/Icon/Icon';
import { COLORS } from 'assets/styled';
import { CaretUp, Eye, EyeSlash } from '@phosphor-icons/react';

interface IProfileCardRowProps {
  labelName: string;
  labelValue: string;
  editableFieldType?: EditableProfileUserDataType;
  disableChange?: boolean;
}

const ProfileCardRow = ({
  labelName,
  labelValue,
  editableFieldType,
  disableChange,
}: IProfileCardRowProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [inputText, setInputText] = useState<string>(labelValue);
  const [inputErrorMessage, setInputErrorMessage] = useState<string>('');
  const [passwordInputErrorMessage, setPasswordInputErrorMessage] =
    useState<string>('');
  const [currentPassword, setCurrentPassword] = useState<string>('');
  const [hideCurrentPassword, setHideCurrentPassword] = useState<boolean>(true);
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [hideConfirmPassword, setHideConfirmPassword] = useState<boolean>(true);
  const [newPassword, setNewPassword] = useState<string>('');
  const [hideNewPassword, setHideNewPassword] = useState<boolean>(true);

  const { data, mutate, isSuccess, error } = useEditUserData();

  const resetPasswordForm = () => {
    setCurrentPassword('');
    setNewPassword('');
    setConfirmPassword('');
  };

  useEffect(() => {
    if (isSuccess || error) {
      resetPasswordForm();
      isSuccess && setIsExpanded(false);
    }
  }, [isSuccess, error]);

  useEffect(() => {
    if (data) {
      dispatch(setUpdatedUserInfo(data.user));
      switch (editableFieldType) {
        case EditableProfileUserDataType.NAME: {
          setInputText(data.user.first_name);
          break;
        }
        case EditableProfileUserDataType.LAST_NAME: {
          setInputText(data.user.last_name);
          break;
        }
      }
    }
  }, [data, dispatch]);

  const handleEyeClickCurrentPassword = () => {
    setHideCurrentPassword(!hideCurrentPassword);
  };

  const handleEyeClickNewPassword = () => {
    setHideNewPassword(!hideNewPassword);
  };

  const handleEyeClickConfirmPassword = () => {
    setHideConfirmPassword(!hideConfirmPassword);
  };

  const handleEditUserClick = () => {
    switch (editableFieldType) {
      case EditableProfileUserDataType.NAME: {
        mutate({ firstName: inputText });
        break;
      }
      case EditableProfileUserDataType.LAST_NAME: {
        mutate({ lastName: inputText });
        break;
      }
      case EditableProfileUserDataType.PASSWORD: {
        mutate({
          currentPassword: currentPassword,
          newPassword: newPassword,
        });
        break;
      }
    }
  };

  const validateInputText = (e: any) => {
    setInputText(e);
    setInputErrorMessage('');
  };

  const handleNewPasswordChange = (newPasswordInput: string) => {
    setNewPassword(newPasswordInput);
    doPasswordsMatch(newPasswordInput, confirmPassword)
      ? setPasswordInputErrorMessage('')
      : setPasswordInputErrorMessage('Error: Passwords do not match');
  };

  const handleConfirmPasswordChange = (confirmPasswordInput: string) => {
    setConfirmPassword(confirmPasswordInput);
    doPasswordsMatch(confirmPasswordInput, newPassword)
      ? setPasswordInputErrorMessage('')
      : setPasswordInputErrorMessage('Error: Passwords do not match');
  };

  const doPasswordsMatch = (firstPassword: string, secondPassword: string) => {
    return firstPassword === secondPassword;
  };

  return (
    <>
      {editableFieldType ? (
        <Container isExpanded={isExpanded}>
          {isExpanded ? (
            <>
              <Label>{t(labelName)}:</Label>
              {editableFieldType === EditableProfileUserDataType.PASSWORD ? (
                <PasswordProfileCardRowWrapper>
                  <PasswordInputWrapper>
                    <Input
                      pwId={`current-password-field`}
                      placeholder={'Current password'}
                      value={currentPassword}
                      type={hideCurrentPassword ? 'password' : ''}
                      onChange={(e) => setCurrentPassword(e.target.value)}
                      height={'41rem'}
                      width={'100%'}
                      wrapperStyles={{
                        width: '100%',
                      }}
                    />
                    <EyeWrapper onClick={() => handleEyeClickCurrentPassword()}>
                      <Icon svg={hideCurrentPassword ? Eye : EyeSlash} />
                    </EyeWrapper>
                  </PasswordInputWrapper>
                  <PasswordInputWrapper>
                    <Input
                      pwId={`new-password-field`}
                      placeholder={'New password'}
                      value={newPassword}
                      type={hideNewPassword ? 'password' : ''}
                      onChange={(e) => handleNewPasswordChange(e.target.value)}
                      height={'41rem'}
                      width={'100%'}
                      wrapperStyles={{
                        width: '100%',
                      }}
                    />
                    <EyeWrapper onClick={() => handleEyeClickNewPassword()}>
                      <Icon svg={hideNewPassword ? Eye : EyeSlash} />
                    </EyeWrapper>
                  </PasswordInputWrapper>
                  <PasswordInputWrapper>
                    <Input
                      pwId={`confirm-password-field`}
                      placeholder={'Confirm password'}
                      value={confirmPassword}
                      type={hideConfirmPassword ? 'password' : ''}
                      onChange={(e) =>
                        handleConfirmPasswordChange(e.target.value)
                      }
                      height={'41rem'}
                      error={true}
                      width={'100%'}
                      wrapperStyles={{
                        width: '100%',
                      }}
                    />

                    <EyeWrapper onClick={() => handleEyeClickConfirmPassword()}>
                      <Icon svg={hideConfirmPassword ? Eye : EyeSlash} />
                    </EyeWrapper>
                  </PasswordInputWrapper>
                  <PasswordErrorMessage>
                    {currentPassword &&
                      newPassword &&
                      confirmPassword &&
                      passwordInputErrorMessage}
                  </PasswordErrorMessage>
                </PasswordProfileCardRowWrapper>
              ) : (
                <InputWrapper>
                  <Input
                    placeholder=""
                    pwId={`${labelName}-field`}
                    value={inputText}
                    error={true}
                    errorMessage={inputErrorMessage}
                    onChange={(e) => validateInputText(e.target.value)}
                    height={'41rem'}
                    width={'100%'}
                    wrapperStyles={{
                      width: '100%',
                    }}
                  />
                </InputWrapper>
              )}
              <ButtonWrapper>
                <Button
                  data-testid={`change-${labelName}`}
                  onClick={() => handleEditUserClick()}
                  disabled={isButtonDisabled(
                    editableFieldType,
                    inputText,
                    currentPassword,
                    newPassword,
                    confirmPassword,
                    inputErrorMessage,
                    passwordInputErrorMessage
                  )}
                  label={t('Change')}
                  width="200rem"
                  height="41rem"
                  primary
                />
              </ButtonWrapper>
              <ArrowWrapper>
                <Icon
                  svg={CaretUp}
                  onClick={() => setIsExpanded(false)}
                  color={COLORS.BLACK}
                />
              </ArrowWrapper>
            </>
          ) : (
            <>
              <Label>{t(labelName)}:</Label>
              <LabelValue>{labelValue}</LabelValue>
              <ButtonWrapper>
                <Button
                  data-testid={`change-${labelName}`}
                  onClick={() => setIsExpanded(true)}
                  disabled={disableChange}
                  label={t('Change')}
                  width="97rem"
                  height="31rem"
                  primary
                />
              </ButtonWrapper>
            </>
          )}
        </Container>
      ) : (
        <Container>
          <Label>{t(labelName)}:</Label>
          <LabelValue>{labelValue}</LabelValue>
        </Container>
      )}
    </>
  );
};

export default ProfileCardRow;
