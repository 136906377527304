import { useQuery } from 'react-query';
import { getCompanyUsers } from 'services/CompanyService';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';

export const useGetCompanyUsers = (
  companyId: number,
  page: number,
  perPage: number,
  searchBy: string
) =>
  useQuery({
    queryKey: [
      ReactQueryKeys.GET_COMPANY_USERS,
      companyId,
      page,
      perPage,
      searchBy,
    ],
    queryFn: () => {
      return getCompanyUsers(companyId, page, perPage, searchBy);
    },
  });
