import { COLORS } from 'assets/styled';
import styled from 'styled-components';
import { css } from 'styled-components';

interface StyledProps {
  color?: string;
  bgColor?: string;
  isSelected?: boolean;
}

export const Container = styled.div`
  cursor: pointer;
  width: 100rem;
  height: 100rem;
  border-radius: 20rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${(props: StyledProps) =>
    props.bgColor &&
    css`
      background-color: ${(props: StyledProps) => props.bgColor};
    `};
  ${(props: StyledProps) =>
    props.isSelected
      ? css`
          box-shadow: 0px 0px 0px 5rem ${COLORS.GREEN};
        `
      : css`
          box-shadow: 0px 0px 0px 5rem ${COLORS.WHITE};
          &:hover {
            box-shadow: 0px 0px 0px 5rem ${COLORS.GREEN};
          }
        `};
`;

export const ColorNameLabel = styled.label`
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  ${(props: StyledProps) =>
    props.color &&
    css`
      color: ${props.color};
    `};
  text-align: center;
`;
