import { useMutation } from 'react-query';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { toast } from 'react-toastify';
import { register } from 'services/AuthService';
import { LogyxError, getToastErrorMessage } from 'services/api/errors';

export interface IUseRegisterDTO {
  token: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}
export const useRegister = () =>
  useMutation((params: IUseRegisterDTO) => register(params), {
    onSuccess: (data) => {
      // Success
    },
    onError: (error: LogyxError) => {
      toast.error(getToastErrorMessage(error));
    },
    onSettled: () => {
      // Finally
    },
    mutationKey: ReactMutationKeys.REGISTER,
  });
