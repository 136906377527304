import { OptionProps } from 'react-select';
import { components } from 'react-select';
import { ImageLarge, OptionContainer } from './ImageLargeOption.styled';
import { useState } from 'react';
import { Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { adjustImageSourceToUseS3ImageTransformator } from 'utils/adjustImageSourceToUseS3ImageTransformator';

export const ImageLargeOption = (props: OptionProps<any>) => {
  const { t } = useTranslation();
  const [imageLoaded, setImageLoaded] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  return (
    <components.Option {...props}>
      <OptionContainer data-testid={props.data.pwId}>
        <div data-testid={props.data.pwIdLabel}>{props.data.label}</div>
        {imageLoaded ? null : error ? (
          t('Error loading image')
        ) : (
          <Skeleton
            variant="rectangular"
            height={'98rem'}
            width={'174rem'}
            animation="wave"
            style={{ borderRadius: '10rem', backgroundColor: 'transparent' }}
          />
        )}
        <ImageLarge
          imageLoaded={imageLoaded}
          src={adjustImageSourceToUseS3ImageTransformator(
            props.data.large_image,
            174
          )}
          onLoad={() => setImageLoaded(true)}
          onError={() => setError(true)}
          alt={'img'}
        />
      </OptionContainer>
    </components.Option>
  );
};
