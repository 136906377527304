import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  right: 5rem;
  top: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 30rem;
  width: 30rem;
  user-select: none;
`;
