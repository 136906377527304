import styled from 'styled-components';
import { COLORS, borderRadiusSm } from 'assets/styled';
import { WordOverflow } from 'assets/styled/variables';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  background-color: ${COLORS.WHITE};
  max-width: 1340rem;
  width: calc(100% - 30rem);
  padding: 30rem;
  margin-top: 50rem;
  margin-left: 15rem;
  margin-right: 15rem;
  margin-bottom: 15rem;
  border-radius: ${borderRadiusSm};
`;

export const H3 = styled.div`
  font-size: 24rem;
  line-height: 36rem;
  color: ${COLORS.BLACK};
  margin-bottom: 20rem;
`;

export const HeadingWrapper = styled.div`
  width: 100%;
  display: flex;
  align-self: left;
  padding-bottom: 10rem;
`;

export const Title = styled.label`
  font-size: 30rem;
  font-weight: 300;
  color: ${COLORS.BLACK};
  ${WordOverflow}
`;
