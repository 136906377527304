import { COLORS } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

export const Container = styled.div`
  padding-left: 50rem;
  padding-right: 50rem;

  ${respondTo.smallLaptop`
    padding: 0 30rem;
  `}

  ${respondTo.smallTablet`
    padding: 0 15rem;
  `}
`;

export const ModelsContainer = styled.div`
  display: grid;
  grid-gap: 15rem;
  justify-content: space-between;
  grid-template-columns: repeat(
    auto-fill,
    300rem
  ); // MODEL_CARD_CONTAINER_WIDTH === 300rem

  ${respondTo.smallLaptop`
    grid-template-columns: repeat(
      auto-fill,
      30%
    );
  `}

  ${respondTo.bigPhone`
    grid-template-columns: repeat(
      auto-fill,
      48%
    );
  `}

  ${respondTo.smallPhone`
    grid-template-columns: repeat(
      auto-fill,
      100%
    );
  `}
`;

export const NoContentLabel = styled.label`
  width: 100%;
  text-align: center;
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
  margin-top: 20rem;
  color: ${COLORS.BLACK};

  ${respondTo.bigPhone`
    font-size: 16rem;
  `}
`;

export const NoContentLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
