import { COLORS } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';
import { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const ErrorMessage = styled.div`
  position: absolute;
  font-weight: 400;
  font-size: 12rem;
  color: ${COLORS.RED_100};
  left: 0rem;
`;

export const RelativeWrapper = styled.div`
  position: relative;
`;

export const ButtonWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  gap: 15rem;
  ${respondTo.midTablet`    
    top: 10rem;
  `};
  ${respondTo.smallPhone`    
    top: 50rem;
  `}
`;

export const inputWrapperStyling = css`
  width: 250rem;
  ${respondTo.smallPhone`    
    width: 100%;
  `};
`;

export const LabelValue = styled.label`
  font-size: 18rem;
  font-weight: 400;
  color: ${COLORS.BLACK};
`;

export const ChangeLabel = styled.label`
  font-size: 18rem;
  font-weight: 400;
  color: ${COLORS.GREEN};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
