import { COLORS } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

export const Grid = styled.div`
  position: relative;
  display: grid;
  grid-row-gap: 20rem;
  grid-column-gap: 50rem;
  grid-template-columns: repeat(2, minmax(25%, auto));

  ${respondTo.bigPhone`
    grid-column-gap: 20rem;
  `}
`;

export const LabelKey = styled.label`
  font-weight: 600;
  font-size: 14rem;
  line-height: 21rem;
  color: ${COLORS.BLACK};
`;

export const LabelValue = styled.label`
  font-weight: 300;
  font-size: 14rem;
  line-height: 21rem;
  color: ${COLORS.BLACK};
`;

export const HorizontalLine = styled.div`
  height: 1rem;
  background: ${COLORS.BORDER};
  margin-top: 20rem;
  margin-bottom: 20rem;
`;

export const AbsoluteTrash = styled.div`
  position: absolute;
  top: 0;
  right: 0rem;
  &:hover {
    transform: scale(1.1);
    transition: transform ease-in-out 0.1s;
  }
`;
