import styled from 'styled-components';
import { respondTo } from 'assets/styled/breakpoints';

export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  max-width: 100%;
  width: 100%;
`;

export const InputWrapper = styled.div`
  max-width: 570rem;
  width: 100%;

  ${respondTo.smallTablet`
    width: 100%;
    max-width: 100%;
  `}
`;

export const InputNumberWrapper = styled.div`
  max-width: 300rem;
  width: 100%;

  ${respondTo.smallTablet`
    width: 100%;
    max-width: 100%;
  `}
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
`;
