import { useTranslation } from 'react-i18next';
import {
  CompaniesLabel,
  Container,
  FirstRow,
  SelectWrapper,
  NoContentLabel,
  AddWrapper,
  AddLabel,
  RowWrapper,
  InputWrapper,
  SearchCategoryGroup,
  enableButtonWrapperStyling,
  enableButtonStyling,
} from './SupplierCompanyModels.styled';
import Input from 'components/Input/Input';
import { useEffect, useState } from 'react';
import { useDebounce } from 'utils/hooks/useDebounce';
import { SUPPLIER_COMPANY_MODELS_PER_PAGE } from './constants';
import SupplierCompanyModelRow from './SupplierCompanyModelsRow/SupplierCompanyModelsRow';
import Pagination from 'components/Pagination/Pagination';
import {
  useEnableAllModelOrderingForSupplierCompany,
  useEnableAllModelQueryingForSupplierCompany,
  useGetSupplierCompanyModels,
} from './hooks';
import { Select } from 'components/Select/Select';
import { useGetCategoryOptions } from 'pages/AllModelsPage/hooks';
import { ISupplierCompanyModel } from 'types/SupplierCompanyModel';
import { queryClient } from 'index';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import SupplierCompanyModelRowSkeleton from './SupplierCompanyModelsRow/SupplierCompanyModelsRowSkeleton/SupplierCompanyModelsRowSkeleton';
import { Plus, MagnifyingGlass as Search } from '@phosphor-icons/react';
import Icon from 'components/Icon/Icon';
import { useDeleteSupplierCompanyModel } from 'pages/Supplier/SupplierModelCompaniesPage/SupplierModelCompanies/hooks';
import { DangerModal } from 'components/Modals/DangerModal/DangerModal';
import { AddModelToCompanyModal } from 'components/Modals/AddModelToCompanyModal/AddModelToCompanyModal';
import Button from 'components/Button/Button';
import { COLORS } from 'assets/styled';

interface ISupplierCompanyModelsProps {
  isLoadingGetSupplierCompany: boolean;
  supplierCompanyId: number;
  companyId: number;
}

const SupplierCompanyModels = ({
  isLoadingGetSupplierCompany,
  supplierCompanyId,
  companyId,
}: ISupplierCompanyModelsProps) => {
  const { t } = useTranslation();
  const [searchBy, setSearchBy] = useState<string>('');
  const debouncedSearchBy = useDebounce(searchBy);
  const [categoriesSearchBy, setCategoriesSearchBy] = useState<string>('');
  const debouncedCategoriesSearchBy = useDebounce(categoriesSearchBy);
  const [page, setPage] = useState<number>(1);
  const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(
    null
  );
  const [isAddModelModalOpen, setIsAddModelModalOpen] =
    useState<boolean>(false);
  const [
    isDeleteSupplierCompanyModelModalOpen,
    setIsDeleteSupplierCompanyModelModalOpen,
  ] = useState<boolean>(false);
  const [
    supplierCompanyModelIdForDeletion,
    setSupplierCompanyModelIdForDeletion,
  ] = useState<number | null>(null);

  const { categoryOptions, fetchNextPage, hasNextPage } = useGetCategoryOptions(
    debouncedCategoriesSearchBy
  );

  const {
    data: supplierCompanyModelsData,
    isLoading: isLoadingSupplierCompanyModels,
  } = useGetSupplierCompanyModels(
    page,
    SUPPLIER_COMPANY_MODELS_PER_PAGE,
    debouncedSearchBy,
    selectedCategoryId,
    supplierCompanyId,
    null,
    null
  );

  const supplierCompanyModels: ISupplierCompanyModel[] =
    supplierCompanyModelsData?.data || [];

  const showPagination =
    supplierCompanyModelsData?.pagination?.total &&
    supplierCompanyModelsData?.pagination?.total >=
      SUPPLIER_COMPANY_MODELS_PER_PAGE;

  const {
    mutate: enableOrderingForAllModels,
    isLoading: isLoadingEnableOrderingForAllModels,
    isSuccess: isSuccessEnableOrderingForAllModels,
    reset: resetEnableOrderingForAllModels,
  } = useEnableAllModelOrderingForSupplierCompany();

  const {
    mutate: enableQueryingForAllModels,
    isLoading: isLoadingEnableQueryingForAllModels,
    isSuccess: isSuccessEnableQueryingForAllModels,
    reset: resetEnableQueryingForAllModels,
  } = useEnableAllModelQueryingForSupplierCompany();

  useEffect(() => {
    if (
      isSuccessEnableOrderingForAllModels ||
      isSuccessEnableQueryingForAllModels
    ) {
      queryClient.invalidateQueries([
        ReactQueryKeys.GET_SUPPLIER_COMPANY_MODELS,
      ]);
      resetEnableOrderingForAllModels();
      resetEnableQueryingForAllModels();
    }
  }, [
    isSuccessEnableOrderingForAllModels,
    isSuccessEnableQueryingForAllModels,
  ]);

  const isLoading =
    isLoadingSupplierCompanyModels ||
    isLoadingEnableOrderingForAllModels ||
    isLoadingEnableQueryingForAllModels;

  const {
    mutate: deleteSupplierCompanyModel,
    isLoading: isLoadingDeleteSupplierCompanyModel,
  } = useDeleteSupplierCompanyModel();

  return (
    <Container>
      <FirstRow>
        <RowWrapper>
          <CompaniesLabel>{t('Models')}</CompaniesLabel>
          <SearchCategoryGroup>
            <SelectWrapper>
              <Select
                defaultValue={{ value: '', label: 'All categories' }}
                width="100%"
                styles={{ width: '100%' }}
                name="category-select"
                placeholder={t('Category')}
                options={categoryOptions}
                onChange={(selectedOption: any) =>
                  setSelectedCategoryId(selectedOption.value)
                }
                onInputChange={(value: string) => setCategoriesSearchBy(value)}
                onMenuScrollToBottom={() => hasNextPage && fetchNextPage()}
              />
            </SelectWrapper>
            <InputWrapper>
              <Input
                placeholder={t('Search')}
                width={'100%'}
                wrapperStyles={{ width: '100%' }}
                onChange={(e: any) => setSearchBy(e.target.value)}
                icon={Search}
              />
            </InputWrapper>
          </SearchCategoryGroup>
          <Button
            secondary
            label={t('Enable query all models')}
            onClick={() =>
              supplierCompanyId && enableQueryingForAllModels(supplierCompanyId)
            }
            wrapperStyle={enableButtonWrapperStyling}
            buttonStyle={enableButtonStyling}
          />
          <Button
            secondary
            label={t('Enable order all models')}
            onClick={() =>
              supplierCompanyId && enableOrderingForAllModels(supplierCompanyId)
            }
            wrapperStyle={enableButtonWrapperStyling}
            buttonStyle={enableButtonStyling}
          />

          <AddWrapper onClick={() => setIsAddModelModalOpen(true)}>
            <Icon svg={Plus} color={COLORS.GREEN} size={20} />
            <AddLabel>{t('Add Model')}</AddLabel>
          </AddWrapper>
        </RowWrapper>
      </FirstRow>
      {supplierCompanyModels.length ? (
        supplierCompanyModels.map(
          (supplierCompanyModel: ISupplierCompanyModel, index: number) => (
            <SupplierCompanyModelRow
              key={`company-models-row-${index}`}
              supplierCompanyModel={supplierCompanyModel}
              withoutLine={supplierCompanyModels.length - 1 === index}
              isLoadingParent={isLoading}
              onDelete={(supplierCompanyModelId: number) => {
                setIsDeleteSupplierCompanyModelModalOpen(true);
                setSupplierCompanyModelIdForDeletion(supplierCompanyModelId);
              }}
            />
          )
        )
      ) : isLoadingSupplierCompanyModels || isLoadingGetSupplierCompany ? (
        Array.from({ length: SUPPLIER_COMPANY_MODELS_PER_PAGE }, (_, index) => (
          <SupplierCompanyModelRowSkeleton
            key={index}
            index={index}
            withoutLine={SUPPLIER_COMPANY_MODELS_PER_PAGE - 1 === index}
          />
        ))
      ) : (
        <NoContentLabel>{t('No models found for this company')}</NoContentLabel>
      )}
      {showPagination ? (
        <Pagination
          page={page}
          perPage={SUPPLIER_COMPANY_MODELS_PER_PAGE}
          total={supplierCompanyModelsData?.pagination?.total || '0'}
          setPage={setPage}
        />
      ) : null}
      <AddModelToCompanyModal
        isOpen={isAddModelModalOpen}
        setIsOpen={setIsAddModelModalOpen}
        companyId={companyId}
      />
      <DangerModal
        isOpen={isDeleteSupplierCompanyModelModalOpen}
        setIsOpen={setIsDeleteSupplierCompanyModelModalOpen}
        title={t('Confirm removal')}
        submit={{
          onClick: () => {
            deleteSupplierCompanyModel(supplierCompanyModelIdForDeletion!);
            setIsDeleteSupplierCompanyModelModalOpen(false);
            setSupplierCompanyModelIdForDeletion(null);
          },
          text: t('Remove'),
          disabled: isLoadingDeleteSupplierCompanyModel,
        }}
        cancel={{
          onClick: () => {
            setIsDeleteSupplierCompanyModelModalOpen(false);
            setSupplierCompanyModelIdForDeletion(null);
          },
        }}
        description={t(
          'Are you sure you want to remove access of this Model for this Company?'
        )}
      />
    </Container>
  );
};

export default SupplierCompanyModels;
