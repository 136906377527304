import {
  COLORS,
  borderRadiusSm,
  marginLg,
  marginMd,
  paddingLg,
  paddingMd,
  paddingSm,
} from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import Input from 'components/Input/Input';
import styled from 'styled-components';

export const Container = styled.div`
  background: ${COLORS.BACKGROUND};
  padding: ${marginLg};
  width: 100%;
  align-self: center;

  ${respondTo.bigPhone`
    padding: 15rem;
  `};
`;

export const Title = styled.label`
  font-weight: 600;
  font-size: 50rem;
  line-height: 68rem;
  font-weight: 300;
  color: ${COLORS.BLACK};
  ${respondTo.bigPhone`
    font-size: 40rem;
  `}
`;

export const BackLabel = styled.label`
  margin-left: 5rem;
  font-size: 18rem;
  color: ${COLORS.BLACK};
  ${respondTo.bigPhone`
    font-size: 16rem;
  `}
`;

export const BackWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  width: 100rem;
  &:hover {
    ${BackLabel} {
      cursor: pointer;
      text-decoration: underline;
      text-decoration-thickness: 1rem;
    }
  }
`;

export const BackChevron = styled.img`
  margin-right: 5rem;
  cursor: pointer;
`;

export const RowPaddingBottom = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${marginMd};

  ${respondTo.bigPhone`
    flex-direction: column;
    gap: 20rem;
  `}
`;

export const CategoriesContainer = styled.div`
  background-color: ${COLORS.WHITE};
  border-radius: ${borderRadiusSm};
  width: 100%;
  border: 1rem solid ${COLORS.GRAY_100};
  padding-left: ${paddingMd};
  padding-right: ${paddingMd};
  ${respondTo.bigPhone`
    padding-left: ${paddingSm};
    padding-right: ${paddingSm};
  `}
`;

export const SupplierCompanyName = styled.label`
  font-weight: 400;
  font-size: 24rem;
  line-height: 32rem;
  color: ${COLORS.BLACK};
  padding-left: 20rem;

  ${respondTo.bigPhone`
    font-size: 18rem;
  `}

  ${respondTo.smallTablet`
    padding-left: 0;
  `}
`;

export const NoContentLabelWrapper = styled.div`
  display: flex;
  flex: 1;
`;

export const NoContentLabel = styled.label`
  font-weight: 400;
  font-size: 24rem;
  line-height: 32rem;
  width: 100%;
  text-align: center;
  padding-top: ${paddingLg};
  color: ${COLORS.BLACK};
`;

export const InputWrapper = styled.div`
  display: flex;
  align-self: flex-end;
  width: 350rem;

  ${respondTo.bigPhone`
    width: 100%;
  `}
`;

export const StyledInput = styled(Input)`
  width: 300rem;
  ${respondTo.bigPhone`
    width: 100%;
  `}
`;

export const TitleAndSupplierLabelsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  ${respondTo.smallTablet`
    flex-direction: column;
    align-items: flex-start;
  `}
`;

export const SkeletonWrapper = styled.div`
  padding-left: 30rem;
  padding-right: 30rem;
  border-radius: ${borderRadiusSm};
  background-color: ${COLORS.WHITE};
`;

export const TooltipWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50rem;
`;
