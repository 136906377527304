import { COLORS, gapMd, marginMd } from 'assets/styled';
import styled from 'styled-components';
import { css } from 'styled-components';
import { HAS_IMAGES_BREAKPOINT } from '../hooks';
import { respondTo } from 'assets/styled/breakpoints';

interface StyledProps {
  width: string;
  isVisible: boolean;
  singleImage?: boolean;
}

export const FormImagesContainer = styled.div`
  position: relative; // Relative is needed here so sticky behavior is applied inside StickyContainer
  height: 100%; // FormImages has height: fit-content
  z-index: 9; // Configure form has 8, ReactImageMagnify should be above the configure form
  ${(props: StyledProps) =>
    props.isVisible
      ? css`
          max-width: 45%;
          width: 100%;

          @media screen and (max-width: ${HAS_IMAGES_BREAKPOINT}px) {
            max-width: 700rem;
            width: 100%;
            padding-bottom: 20rem;

            ${props.singleImage &&
            css`
              max-width: 400rem;
            `}
          }

          ${respondTo.smallTablet`
            max-width: 100%;

             ${
               props.singleImage &&
               css`
                 max-width: 400rem;
                 margin: 0 auto;
               `
             }
          `}

          ${respondTo.bigPhone`
            padding-bottom: 0;
             ${
               props.singleImage &&
               css`
                 max-width: 100%;
               `
             }
          `}
        `
      : css`
          height: 0;
          width: 0rem;
          overflow: hidden;
        `}
`;

export const StickyContainer = styled.div`
  position: sticky;
  top: 245rem;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 30rem;
`;

export const SwiperWrapper = styled.div``;

export const SmallFormImagesWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 20rem;

  ${respondTo.midTablet`
    gap: 15rem;
  `}
`;

export const DescriptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${marginMd};
`;

export const DescriptionLabel = styled.label`
  font-size: 14rem;
  font-weight: 400;
  color: ${COLORS.BLACK};
`;
