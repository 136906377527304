import styled, { css } from 'styled-components';
import { COLORS } from 'assets/styled/colors';
import { respondTo } from 'assets/styled/breakpoints';

interface StyledProps {
  isDragActive: boolean;
  isFileRejected: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.WHITE};
  width: 100%;
  height: 400rem;
  padding: 20rem;

  ${respondTo.bigPhone`
    padding: 10rem;
  `}
`;

export const ImgPreview = styled.img`
  border-radius: 20rem;
  margin: 10rem;
  transition: all 0.3s;
  height: 100rem;
  width: 120rem;
  object-fit: cover;

  &:hover {
    transform: scale(1.1);
  }
`;

export const ImgInnerWrap = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const IconWrap = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background-color: ${COLORS.STRICT_WHITE};
  border-radius: 50%;
`;

export const ImgWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 150rem;
  overflow-y: auto;
`;

export const Text = styled.span`
  color: ${COLORS.BLACK};
  text-align: center;
`;

export const Input = styled.input``;

export const UploadFromComputer = styled.span`
  color: ${COLORS.GREEN};
  text-align: center;
`;

export const DropContainer = styled.div`
  position: relative;
  display: flex;
  gap: 10rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 300rem;
  padding: 20rem;
  border-radius: 20rem;
  border: 2rem solid ${COLORS.BORDER};
  color: ${COLORS.BLACK};
  cursor: pointer;
  ${(props: StyledProps) =>
    props.isDragActive &&
    css`
      border-color: ${COLORS.GREEN};
      background-color: ${COLORS.BACKGROUND};
    `}

  ${(props: StyledProps) =>
    props.isFileRejected &&
    css`
      border-color: ${COLORS.RED_100};
    `}
  &:hover {
    span {
      transform: scale(1.1);
      transition: ease 500ms;
    }
  }
  &:not(:hover) {
    span {
      transition: ease 500ms;
    }
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  width: 100%;
  ${respondTo.midTablet`
      flex-direction: column-reverse;
      margin-bottom: 10rem;
  `}
`;

export const ButtonContainer = styled.div`
  margin-top: 2vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;
