import styled from 'styled-components';

export const DateInputContainer = styled.div``;

export const Container = styled.div`
  position: relative;
`;

export const CalendarWrapper = styled.div`
  pointer-events: none;
  position: absolute;
  width: 20rem;
  height: 20rem;
  right: 10rem;
  top: 50%;
  transform: translateY(-50%);
`;
