import { ITestDealerPanelConfigurationNavigationState } from 'pages/DealerPanel/DealerPanelModelsPage/SelectVATAmountModal/SelectVATAmountModal';
import { ITestCompanyModelConfiguration } from 'types/Configuration.types';

export const generateNewTestConfigurationFromFormikValues = (
  testConfiguration: ITestCompanyModelConfiguration,
  values: any,
  state: ITestDealerPanelConfigurationNavigationState
) => {
  const newTestConfiguration: ITestCompanyModelConfiguration = {
    model_id: Number(testConfiguration.model_id),
    base_markup: Number(values.base_markup),
    base_purchase_discount: Number(values.base_purchase_discount),
    surcharge_markup: Number(values.surcharge_markup),
    surcharge_purchase_discount: Number(values.surcharge_purchase_discount),
    fixed_amount: Number(values.fixed_amount),
    exclude_surcharge: values.exclude_surcharge,
    round_prices: values.round_prices,
    is_using_supplier_system_discounts: state.supplierHasSystemIntegration
      ? values.is_using_supplier_system_discounts
      : false,
    is_active: values.is_active,
  };
  return newTestConfiguration;
};
