import { queryClient } from 'index';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { addCompanyUser, deleteCompanyUser } from 'services/CompanyService';
import { LogyxError, getToastErrorMessage } from 'services/api/errors';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';

export const useAddCompanyUser = (companyId: number) => {
  const { t } = useTranslation();
  return useMutation((email: string) => addCompanyUser(email, companyId), {
    onError: (error: LogyxError) => {
      toast.error(getToastErrorMessage(error));
    },
    onSuccess: (data: { message: string }) => {
      if (data.message.includes('Registration link sent')) {
        toast.success(t('Registration link sent'));
      } else {
        toast.success(t('Added user to company'));
        queryClient.invalidateQueries([ReactQueryKeys.GET_COMPANY_USERS]);
        queryClient.invalidateQueries([
          ReactQueryKeys.GET_COMPANY_USERS_INFINITE,
        ]);
      }
    },
    mutationKey: ReactMutationKeys.ADD_COMPANY_USER,
  });
};

export const useDeleteCompanyUser = (companyId: number) => {
  const { t } = useTranslation();
  return useMutation((userId: number) => deleteCompanyUser(userId, companyId), {
    onError: (error: LogyxError) => {
      toast.error(getToastErrorMessage(error));
    },
    onSuccess: () => {
      toast.success(t('Removed user from company'));
      queryClient.invalidateQueries([ReactQueryKeys.GET_COMPANY_USERS]);
      queryClient.invalidateQueries([
        ReactQueryKeys.GET_COMPANY_USERS_INFINITE,
      ]);
    },
    mutationKey: ReactMutationKeys.DELETE_COMPANY_USER,
  });
};
