import { Container, Square } from './Pagination.styled';
import Icon from 'components/Icon/Icon';
import { COLORS } from 'assets/styled';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';
import { CaretLeft, CaretRight } from '@phosphor-icons/react';

interface IPaginationProps {
  total: number;
  page: number;
  perPage: number;
  setPage: (page: number) => void;
}

const Pagination = ({
  total,
  page: _page,
  perPage,
  setPage,
}: IPaginationProps) => {
  const page = _page && Number(_page);
  if (!total) {
    return <></>;
  }
  const totalPages = Math.ceil(total / perPage);

  const isFirstPage = page === 1;
  const isLastPage = page === totalPages;

  const handlePageClick = (pageNumber: number) => {
    if (pageNumber < 1 || pageNumber > totalPages) {
      return;
    }
    setPage(pageNumber);
  };

  const { isPhone, isSmallPhone } = useBreakpointFlag();

  const generatePageNumbers = () => {
    const pages = [];
    let startPage: number;
    let endPage: number;

    if (isSmallPhone || isPhone) {
      const pagesToShow = isSmallPhone ? 3 : 5;
      const halfRange = Math.floor(pagesToShow / 2);

      if (totalPages <= pagesToShow) {
        startPage = 1;
        endPage = totalPages;
      } else if (page <= halfRange + 1) {
        startPage = 1;
        endPage = pagesToShow;
      } else if (page >= totalPages - halfRange) {
        startPage = totalPages - pagesToShow + 1;
        endPage = totalPages;
      } else {
        startPage = page - halfRange;
        endPage = page + halfRange;
      }

      // Only return the middle numbers for phones
      return Array.from({ length: endPage - startPage + 1 }, (_, i) => (
        <Square
          key={startPage + i}
          isActive={page === startPage + i}
          onClick={() => handlePageClick(startPage + i)}
        >
          {startPage + i}
        </Square>
      ));
    } else {
      // Desktop display logic (unchanged)
      if (totalPages <= 7) {
        startPage = 1;
        endPage = totalPages;
      } else {
        if (page <= 4) {
          startPage = 1;
          endPage = 5;
        } else if (page > totalPages - 4) {
          startPage = totalPages - 4;
          endPage = totalPages;
        } else {
          startPage = page - 2;
          endPage = page + 2;
        }
      }
    }

    // First page and ellipsis
    if (startPage > 1) {
      pages.push(
        <Square
          key="1"
          isActive={page === 1}
          onClick={() => handlePageClick(1)}
        >
          1
        </Square>
      );
      if (startPage > 2) {
        pages.push(
          <Square key="ellipsis1" defaultCursor isEllipsis>
            ...
          </Square>
        );
      }
    }

    // Middle page numbers
    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <Square
          key={i}
          isActive={page === i}
          onClick={() => handlePageClick(i)}
        >
          {i}
        </Square>
      );
    }

    // Last page and ellipsis
    if (endPage < totalPages - 1) {
      pages.push(
        <Square key="ellipsis2" defaultCursor isEllipsis>
          ...
        </Square>
      );
    }
    if (endPage < totalPages) {
      pages.push(
        <Square
          key={totalPages}
          isActive={page === totalPages}
          onClick={() => handlePageClick(totalPages)}
        >
          {totalPages}
        </Square>
      );
    }

    return pages;
  };

  const isFirstSquareDisabled = isFirstPage || !total;
  const isLastSquareDisabled = isLastPage || !total;

  return (
    <Container>
      <Square
        isDisabled={isFirstSquareDisabled}
        onClick={() => handlePageClick(page - 1)}
      >
        <Icon
          svg={CaretLeft}
          weight="bold"
          size={20}
          color={isFirstSquareDisabled ? COLORS.BORDER : COLORS.GREEN}
          disabled={isFirstSquareDisabled}
        />
      </Square>
      {generatePageNumbers()}
      <Square
        isDisabled={isLastSquareDisabled}
        onClick={() => handlePageClick(page + 1)}
      >
        <Icon
          svg={CaretRight}
          weight="bold"
          size={20}
          color={isLastSquareDisabled ? COLORS.BORDER : COLORS.GREEN}
          disabled={isLastSquareDisabled}
        />
      </Square>
    </Container>
  );
};

export default Pagination;
