import { ISupplier } from 'types/Supplier.types';
import SupplierCategoriesList from './SupplierCategoriesList/SupplierCategoriesList';
import { Container } from './ModelsPageList.styled';

interface IModelsPageGrid {
  suppliers: ISupplier[];
}

const ModelsPageList = ({ suppliers }: IModelsPageGrid) => {
  return (
    <Container>
      {suppliers.map((supplier: ISupplier) => (
        <SupplierCategoriesList
          supplier={supplier}
          key={`supplier-list-${supplier.id}`}
        />
      ))}
    </Container>
  );
};

export default ModelsPageList;
