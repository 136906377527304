export enum BuilderTabs {
  General = 'General',
  InputFields = 'Input fields',
  Pricing = 'Pricing',
}

export enum FieldTypes {
  ShortText = 'short_text',
  LongText = 'long_text',
  List = 'list',
  Number = 'number',
  Toggle = 'toggle',
  Radio = 'radio',
}

interface IPrice {
  price_type: string;
  amount: number | null;
}

export interface IImportMatrixOptions {
  xAxis: string[];
  yAxis: string[];
}

export interface PriceOption {
  name: string;
  price: IPrice;
}

interface IGeneralTab {
  name: string | null;
  category: string | null;
  description: string;
}

export type OptionMatrixEntry = [string | number, string | number, number];

export interface IPriceMatrix {
  title: string;
  x_axis_id: string;
  x_axis_min: number | null;
  y_axis_id: string;
  y_axis_min: number | null;
  matrix: OptionMatrixEntry[] | null;
  filetype: string | null;
}
export interface InputField {
  id: string;
  field_type: FieldTypes;
  field_label: string;
  default_field_name: string;
  description: string;
}

export interface IShortTextInputField extends InputField {
  field_type: FieldTypes.ShortText;
  default: string;
  required: boolean;
  min: number | null;
  max: number | null;
}

export interface ILongTextInputField extends InputField {
  field_type: FieldTypes.LongText;
  default: string;
  required: boolean;
  min: number | null;
  max: number | null;
}

export interface INumberInputField extends InputField {
  field_type: FieldTypes.Number;
  default: number | null;
  required: boolean;
  min: number | null;
  max: number | null;
}

export interface IRadioInputField extends InputField {
  field_type: FieldTypes.Radio;
  default: number | null;
  options: PriceOption[];
}

export interface IListInputField extends InputField {
  field_type: FieldTypes.List;
  options: PriceOption[];
  default: number | null;
  required: boolean;
  multiple: boolean;
}

export interface IToggleInputField extends InputField {
  field_type: FieldTypes.Toggle;
  price: IPrice;
  default: boolean;
}

export type IInputField =
  | IShortTextInputField
  | ILongTextInputField
  | INumberInputField
  | IRadioInputField
  | IListInputField
  | IToggleInputField;

export interface ICustomModel {
  general: IGeneralTab;
  input_fields: IInputField[];
  price_matrices: IPriceMatrix[];
}

export interface IVersion {
  created_at: string;
  id: number;
  is_active: boolean;
  is_deleted: boolean;
  locked_by_user_id: number | null;
  model_id: number;
  number: number;
  state_id: number;
  updated_at: string;
}
