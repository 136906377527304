import { ISupplier } from 'types/Supplier.types';
import { Container } from './ModelsPageGrid.styled';
import SupplierCategoriesGrid from './SupplierCategoriesGrid/SupplierCategoriesGrid';

interface IModelsPageGrid {
  suppliers: ISupplier[];
}

const ModelsPageGrid = ({ suppliers }: IModelsPageGrid) => {
  return (
    <Container>
      {suppliers.map((supplier: ISupplier) => (
        <SupplierCategoriesGrid
          supplier={supplier}
          key={`supplier-grid-${supplier?.id}`}
        />
      ))}
    </Container>
  );
};

export default ModelsPageGrid;
