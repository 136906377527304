import { COLORS } from 'assets/styled';
import Icon from 'components/Icon/Icon';
import styled from 'styled-components';

export const CustomIcon = styled(Icon)`
  transition: transform ease-in-out 0.1s;
`;

export const ClearIndicatorContainer = styled.div`
  position: absolute;
  right: -12rem;
  top: -12rem;
  border-radius: 50%;
  background-color: ${COLORS.WHITE};
  border-width: 1rem !important;
  border-style: solid !important;
  border-color: ${COLORS.BORDER} !important;
  transition: border-color 0.1s ease-in-out !important;
  &:hover {
    border-color: ${COLORS.GREEN} !important;
    ${CustomIcon} {
      transform: scale(1.3);
    }
    background-color: ${COLORS.FORM_ELEMENT_HOVER};
  }
`;
