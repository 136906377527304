import { queryClient } from 'index';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { LogyxError, getToastErrorMessage } from 'services/api/errors';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import {
  addCategory,
  deleteCategory,
  editCategory,
} from 'services/CategoryService';

export const useDeleteCategory = () => {
  const { t } = useTranslation();
  return useMutation((categoryId: string) => deleteCategory(categoryId), {
    onError: (error: LogyxError) => {
      toast.error(getToastErrorMessage(error));
    },
    onSuccess: () => {
      toast.success(t('Deleted category'));
      queryClient.invalidateQueries([ReactQueryKeys.GET_CATEGORIES_INFINITE]);
    },
    mutationKey: ReactMutationKeys.DELETE_CATEGORY,
  });
};

export const useAddCategory = () => {
  const { t } = useTranslation();
  return useMutation((label: string) => addCategory(label), {
    onError: (error: LogyxError) => {
      toast.error(getToastErrorMessage(error));
    },
    onSuccess: () => {
      toast.success(t('Added category'));
      queryClient.invalidateQueries([ReactQueryKeys.GET_CATEGORIES_INFINITE]);
    },
    mutationKey: ReactMutationKeys.ADD_CATEGORY,
  });
};

export const useEditCategory = () => {
  const { t } = useTranslation();
  return useMutation(
    (params: { categoryId: string; label: string }) =>
      editCategory(params.categoryId, params.label),
    {
      onError: (error: LogyxError) => {
        toast.error(getToastErrorMessage(error));
      },
      onSuccess: () => {
        toast.success(t('Edited category'));
        queryClient.invalidateQueries([ReactQueryKeys.GET_CATEGORIES_INFINITE]);
      },
      mutationKey: ReactMutationKeys.ADD_CATEGORY,
    }
  );
};
