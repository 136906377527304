import {
  RESET_TOUCHED_QUESTION_NAMES,
  SET_ARE_ALL_FIELDS_TOUCHED,
  SET_IS_FORM_VALID,
  SET_MODEL_NOT_FOUND,
  SET_TOUCHED_QUESTION_NAME,
} from 'store/constants';

export const setAreAllFieldsTouched = (areAllFieldsTouched: boolean) => ({
  type: SET_ARE_ALL_FIELDS_TOUCHED,
  payload: areAllFieldsTouched,
});

export const setTouchedQuestionName = (questionName: string) => ({
  type: SET_TOUCHED_QUESTION_NAME,
  payload: questionName,
});

export const resetTouchedQuestionNames = () => ({
  type: RESET_TOUCHED_QUESTION_NAMES,
});

export const setIsFormValid = (isFormValid: boolean) => ({
  type: SET_IS_FORM_VALID,
  payload: isFormValid,
});

export const setModelNotFound = (modelNotFound: boolean) => ({
  type: SET_MODEL_NOT_FOUND,
  payload: modelNotFound,
});
