import { SortOrder } from 'components/Table/types';
import { queryClient } from 'index';
import { RoutesConfig } from 'navigation/routes';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getToastErrorMessage, LogyxError } from 'services/api/errors';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import {
  archiveCustomModel,
  copyVersion,
  restoreCustomModel,
  updateVersion,
} from 'services/BuilderService';
import { getVersions } from 'services/ModelService';

export const useGetVersions = (
  id: string,
  page: number,
  perPage: number,
  sortBy: string,
  sortDirection: SortOrder,
  isEnabled = true
) =>
  useQuery({
    queryKey: [
      ReactQueryKeys.GET_VERSIONS,
      id,
      page,
      perPage,
      sortBy,
      sortDirection,
    ],
    queryFn: () => {
      return getVersions(id, page, perPage, sortBy, sortDirection);
    },
    enabled: isEnabled,
  });

export const useUpdateVersion = (onSuccess?: () => void) => {
  const { t } = useTranslation();
  return useMutation(
    (params: {
      id: number;
      locked?: boolean;
      is_active?: boolean;
      publish?: boolean;
      redirectAfterSuccess?: boolean;
    }) => {
      const payload: any = {};
      if (params.locked !== undefined) payload.locked = params.locked;
      if (params.is_active !== undefined) payload.is_active = params.is_active;
      if (params.publish !== undefined) payload.publish = params.publish;
      return updateVersion(params.id, payload);
    },
    {
      onSuccess: (data, variables) => {
        if (variables.redirectAfterSuccess) {
          if (onSuccess) onSuccess();
          toast.success(t('Version updated'));
        }
        queryClient.invalidateQueries([ReactQueryKeys.GET_VERSIONS]);
      },
      onError: (error: LogyxError) => {
        toast.error(getToastErrorMessage(error));
      },
      mutationKey: ReactMutationKeys.UPDATE_VERSION,
    }
  );
};

export const useCopyVersion = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return useMutation(
    (params: { id: number; toNewModel: boolean }) => {
      return copyVersion(params.id, params.toNewModel);
    },
    {
      onSuccess: (data, params) => {
        toast.success(t('Version is copied'));
        queryClient.invalidateQueries([ReactQueryKeys.GET_VERSIONS]);

        // Redirect to new model version when it's copy to new model
        if (params.toNewModel) {
          const modelId = data.model_id;
          navigate(
            RoutesConfig.SingleCustomModel.fullPath.replace(
              ':id',
              modelId.toString()
            )
          );
        }
      },
      onError: (error: LogyxError) => {
        toast.error(getToastErrorMessage(error));
      },
      mutationKey: ReactMutationKeys.COPY_VERSION,
    }
  );
};

export const useArchiveCustomModel = () => {
  const { t } = useTranslation();
  return useMutation(
    (params: { id: number }) => {
      return archiveCustomModel(params.id);
    },
    {
      onSuccess: () => {
        toast.success(t('Model is successfully archived'));
        queryClient.invalidateQueries([ReactQueryKeys.GET_MODELS]);
      },
      onError: (error: LogyxError) => {
        toast.error(getToastErrorMessage(error));
      },
      mutationKey: ReactMutationKeys.ARCHIVE_MODEL,
    }
  );
};

export const useRestoreCustomModel = () => {
  const { t } = useTranslation();
  return useMutation(
    (params: { id: number }) => {
      return restoreCustomModel(params.id);
    },
    {
      onSuccess: () => {
        toast.success(t('Model is successfully restored'));
        queryClient.invalidateQueries([ReactQueryKeys.GET_MODELS]);
      },
      onError: (error: LogyxError) => {
        toast.error(getToastErrorMessage(error));
      },
      mutationKey: ReactMutationKeys.RESTORE_MODEL,
    }
  );
};
