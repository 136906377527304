import { queryClient } from 'index';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery, useMutation } from 'react-query';
import { toast } from 'react-toastify';
import {
  createSupplierCompany,
  getEligibleCompaniesForCreatingSupplierCompany,
} from 'services/SupplierCompanyService';
import { LogyxError, getToastErrorMessage } from 'services/api/errors';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';

export interface ICreateSupplierCompanyDTO {
  companyId: number;
  supplierId: number;
  reference: string;
}

export const useCreateSupplierCompany = () => {
  const { t } = useTranslation();
  return useMutation(
    (params: ICreateSupplierCompanyDTO) =>
      createSupplierCompany(
        params.companyId,
        params.supplierId,
        params.reference
      ),
    {
      onSuccess: () => {
        toast.success(t('Company access granted'));
        queryClient.invalidateQueries([ReactQueryKeys.GET_SUPPLIER_COMPANIES]);
        queryClient.invalidateQueries([
          ReactQueryKeys.GET_ELIGIBLE_COMPANIES_FOR_CREATING_SUPPLIER_COMPANY,
        ]);
      },
      onError: (error: LogyxError) => {
        toast.error(getToastErrorMessage(error));
      },
      mutationKey: ReactMutationKeys.CREATE_SUPPLIER_COMPANY_MODEL,
    }
  );
};

export const useGetEligibleCompaniesForCreatingSupplierCompany = (
  perPage: number,
  supplierId: number,
  searchBy: string,
  isOpen: boolean
) =>
  useInfiniteQuery({
    queryKey: [
      ReactQueryKeys.GET_ELIGIBLE_COMPANIES_FOR_CREATING_SUPPLIER_COMPANY,
      supplierId,
      perPage,
      searchBy,
    ],
    queryFn: ({ pageParam = 1 }) =>
      getEligibleCompaniesForCreatingSupplierCompany(
        pageParam,
        perPage,
        supplierId,
        searchBy
      ),
    getNextPageParam: (lastPage: any) => {
      if (!lastPage?.pagination) {
        return undefined;
      }
      if (
        lastPage.pagination.per_page * lastPage.pagination.page >=
        lastPage.pagination.total
      ) {
        // Return undefined here so hasNextPage equals to false
        return undefined;
      }
      return lastPage.pagination.page + 1;
    },
    onSuccess: (data) => {
      return data;
    },
    enabled: isOpen,
  });
