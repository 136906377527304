export interface IImageSection {
  hidden: boolean;
  layers: object;
  name: string;
}

export interface IImageLayer {
  source: string;
  offset: number;
  fallback?: string;
}

export const useFilterOutHiddenImages = (images: object): IImageSection[] => {
  const visibleImages = images;
  if (!visibleImages) {
    return [];
  }
  const imagesArray: IImageSection[] = (
    Object.values(visibleImages) as IImageSection[]
  ).filter((image) => !image.hidden);

  return imagesArray;
};
