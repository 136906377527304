import DndProvider from 'components/DragAndDrop/DndProvider/DndProvider';
import { Container, SideWrapper, MiddleWrapper } from './Builder.styled';
import { useEffect, useMemo, useState } from 'react';
import {
  BuilderTabs,
  ICustomModel,
  IInputField,
} from '../../types/Builder.types';
import ProductBuilder from './ProductBuilder/ProductBuilder';
import Tabs from './Tabs/Tabs';
import InputFieldsOptions from './InputFieldsOptions/InputFieldsOptions';
import { initModel } from './constants';
import FieldConfiguration from './FieldConfiguration/FieldConfiguration';
import { useParams } from 'react-router-dom';
import { useGetCustomVersion } from './hooks';
import Loader from 'components/Loader/Loader';
import debounce from 'lodash.debounce';
import { isEqual } from 'lodash';

const Builder = () => {
  const { id } = useParams();
  const [selectedFieldId, setSelectedFieldId] = useState<string>('');
  const [activeTab, setActiveTab] = useState<BuilderTabs>(BuilderTabs.General);
  const [model, setModel] = useState<ICustomModel>(initModel);
  const [isModelChanged, setIsModelChanged] = useState<boolean>(false);

  const { data: customVersionData, isLoading: isLoadingCustomVersionData } =
    useGetCustomVersion(id!);

  useEffect(() => {
    if (customVersionData && customVersionData.json_data.general) {
      setModel(customVersionData.json_data);
    }
  }, [customVersionData]);

  const checkIsModelChanged = useMemo(
    () =>
      debounce((newModel: ICustomModel) => {
        if (customVersionData) {
          const isChanged = !isEqual(customVersionData.json_data, newModel);
          setIsModelChanged(isChanged);
        }
      }, 300),
    [customVersionData]
  );

  useEffect(() => {
    checkIsModelChanged(model);

    return () => checkIsModelChanged.cancel();
  }, [model, checkIsModelChanged]);

  const resetModelChanged = () => {
    setIsModelChanged(false);
  };

  const currentSelectedField = model.input_fields.find(
    (f) => f.id === selectedFieldId
  ) as IInputField;

  return (
    <DndProvider>
      {isLoadingCustomVersionData ? (
        <Loader />
      ) : (
        <Container>
          {activeTab === BuilderTabs.InputFields && (
            <SideWrapper>
              <InputFieldsOptions />
            </SideWrapper>
          )}

          <MiddleWrapper>
            <Tabs activeTab={activeTab} setActiveTab={setActiveTab} />
            <ProductBuilder
              key={`${customVersionData}-${model.input_fields.length}-${customVersionData.version_id}`}
              activeTab={activeTab}
              model={model}
              setModel={setModel}
              setSelectedFieldId={setSelectedFieldId}
              versionId={Number(id)}
              modelId={customVersionData.model_id}
              isModelChanged={isModelChanged}
              resetModelChanged={resetModelChanged}
            />
          </MiddleWrapper>

          {activeTab === BuilderTabs.InputFields && (
            <SideWrapper>
              {selectedFieldId && (
                <FieldConfiguration
                  key={selectedFieldId}
                  currentSelectedField={currentSelectedField}
                  setModel={setModel}
                  model={model}
                  setSelectedFieldId={setSelectedFieldId}
                />
              )}
            </SideWrapper>
          )}
        </Container>
      )}
    </DndProvider>
  );
};

export default Builder;
