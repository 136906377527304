import { useTranslation } from 'react-i18next';
import {
  AddOption,
  ChangePriceCloseIcon,
  ChangePriceHeadline,
  ChangePriceModal,
  ChangePriceOptionName,
  ClearButton,
  Container,
  DefaultSelectWrapper,
  ErrorMessage,
  ErrorWrapper,
  FieldWrapper,
  IconsWrapper,
  OptionsWrapper,
  PriceLabel,
  Row,
  RowName,
  SwitchWrapper,
  ToggleText,
} from '../ConfigForms.styled';
import { Field, Form, Formik, FormikProps } from 'formik';
import { useRef, useState } from 'react';
import { Input } from 'components/Input/InputFormik';
import Icon from 'components/Icon/Icon';
import { Asterisk, Gear, Plus, Trash, X } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';
import {
  ICustomModel,
  IListInputField,
  PriceOption,
} from 'types/Builder.types';
import { ListValidationSchema } from '../validations';
import { OptionWrapper } from 'components/OptionsList/OptionsList.styled';
import { useOutsideAlerter } from 'utils/hooks/useOutsideAlerter';
import Price from '../../Components/Price/Price';
import CustomSelect from 'components/Select/FormikSelect';
import { IOption } from 'components/Select/type';
import Tooltip from 'components/Tooltip/Tooltip';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';

interface IProps {
  currentSelectedField: IListInputField;
  updateModel: (values: any) => void;
  model: ICustomModel;
}

export interface IValues {
  field_label: string;
  description: string;
  default: number | null;
  options: PriceOption[];
}

const ListForm = ({ currentSelectedField, updateModel, model }: IProps) => {
  const { t } = useTranslation();
  const formikRef = useRef<FormikProps<any> | any>();
  const priceRef = useRef<HTMLDivElement | null>(null);
  const [activeField, setActiveField] = useState<string | null>(null);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState<number | null>(
    null
  );

  const ChangePriceModalRef = useRef();
  useOutsideAlerter(ChangePriceModalRef, () => setSelectedOptionIndex(null));

  const initialValues = {
    field_label: currentSelectedField.field_label,
    description: currentSelectedField.description,
    default: currentSelectedField.default,
    options: currentSelectedField.options,
    required: currentSelectedField.required,
    multiple: currentSelectedField.multiple,
  };

  return (
    <Container>
      <Formik
        innerRef={formikRef}
        enableReinitialize
        initialValues={initialValues}
        validationSchema={ListValidationSchema}
        validateOnChange={true}
        validateOnMount={true}
        validateOnBlur={true}
        onSubmit={() => {
          //
        }}
      >
        {({ handleBlur, setFieldValue, isValid, values, errors, touched }) => {
          if (
            isValid &&
            activeField === null &&
            JSON.stringify(values) !== JSON.stringify(initialValues)
          ) {
            updateModel(values);
          }

          const handleBlurFunction = (
            e: any,
            name: string,
            index?: number,
            isPrice = false
          ) => {
            const value = e?.target ? e.target.value : e;

            // Only for price changes
            if (index !== undefined && isPrice) {
              let newAmount = values.options[index].price.amount;
              if (e.target.name.includes('price_type')) {
                const priceType = value;
                // Block amount to be less then -100%
                if (
                  priceType === 'percentage' &&
                  newAmount !== null &&
                  newAmount < -100
                ) {
                  newAmount = -100;
                }
                // Update price_type
                const updatedOption = {
                  ...values.options[index],
                  price: {
                    ...values.options[index].price,
                    price_type: priceType,
                    amount: newAmount,
                  },
                };
                setFieldValue(`options.${index}`, updatedOption);
              } else {
                // Update amount
                newAmount = value === '' ? null : Number(value);
                if (
                  newAmount !== null &&
                  values.options[index].price.price_type === 'percentage' &&
                  newAmount < -100
                ) {
                  newAmount = -100;
                }

                const updatedOption = {
                  ...values.options[index],
                  price: {
                    ...values.options[index].price,
                    amount: newAmount,
                  },
                };
                setFieldValue(`options.${index}`, updatedOption);
              }
              // Only for option name
            } else if (index !== undefined && !isPrice) {
              const updatedOption = {
                ...values.options[index],
                name: value,
              };
              setFieldValue(`options.${index}`, updatedOption);
              // Only for amount in price
            } else {
              // Rest of values in form
              setFieldValue(name, value);
            }

            handleBlur(e);
            setActiveField(null);
          };

          // Block setModal while user focus field
          const handleFocus = (name: string) => {
            setActiveField(name);
          };

          const addOption = () => {
            const newOptionIndex = values.options.length + 1;
            setFieldValue('options', [
              ...values.options,
              {
                name: `Option ${newOptionIndex}`,
                price: { price_type: 'fixed', amount: null },
              },
            ]);
          };

          const removeOption = (index: number) => {
            if (index === values.default) {
              return;
            }

            const updatedOptions = values.options.filter((_, i) => i !== index);
            setFieldValue('options', updatedOptions);
          };

          const defaultOptions = values.options.map((option, index) => ({
            value: index,
            label: option.name,
          }));

          return (
            <Form>
              <Row>
                <RowName>
                  {t('Field Label')}
                  <Icon svg={Asterisk} size={10} color={COLORS.RED_100} />
                </RowName>
                <Input
                  name="field_label"
                  placeholder={t('Field Label')}
                  height={'40rem'}
                  wrapperStyles={{ width: '100%' }}
                  onBlur={(e) => {
                    handleBlurFunction(e, 'field_label');
                  }}
                  onFocus={() => handleFocus('field_label')}
                />
                <ErrorWrapper>
                  <ErrorMessage>
                    {touched['field_label'] ? errors['field_label'] : null}
                  </ErrorMessage>
                </ErrorWrapper>
              </Row>
              <Row>
                <RowName>{t('Description')}</RowName>
                <Input
                  name="description"
                  isTextArea
                  placeholder={t('Description')}
                  height={'100rem'}
                  wrapperStyles={{ width: '100%' }}
                  onBlur={(e) => {
                    handleBlurFunction(e, 'description');
                  }}
                  onFocus={() => handleFocus('description')}
                />
                <ErrorWrapper>
                  <ErrorMessage>
                    {touched['description'] ? errors['description'] : null}
                  </ErrorMessage>
                </ErrorWrapper>
              </Row>
              <Row>
                <RowName>{t('Options')}</RowName>
                <OptionsWrapper>
                  {values.options.map((option: PriceOption, index: number) => (
                    <OptionWrapper key={index}>
                      <FieldWrapper>
                        <Input
                          name={`options.${index}.name`}
                          placeholder={t(`Option ${index + 1}`)}
                          height={'40rem'}
                          wrapperStyles={{
                            width: '100%',
                          }}
                          styles={{ paddingRight: '80rem' }}
                          onBlur={(e) =>
                            handleBlurFunction(
                              e,
                              `options.${index}.name`,
                              index
                            )
                          }
                          onFocus={() => handleFocus(`options.${index}.name`)}
                        />
                        <IconsWrapper>
                          <Icon
                            svg={Gear}
                            size={24}
                            color={COLORS.GREEN}
                            onClick={() => setSelectedOptionIndex(index)}
                          />
                          <Tooltip
                            content={
                              index === values.default
                                ? t('This options is default option')
                                : undefined
                            }
                          >
                            <Icon
                              svg={Trash}
                              size={24}
                              disabled={index === values.default}
                              color={COLORS.RED_100}
                              onClick={() => {
                                removeOption(index);
                                setSelectedOptionIndex(null);
                              }}
                            />
                          </Tooltip>
                        </IconsWrapper>
                      </FieldWrapper>
                      {option.price?.amount !== null ? (
                        <PriceLabel
                          optionPriceColor={
                            option.price.amount > 0
                              ? COLORS.GREEN
                              : option.price.amount < 0
                              ? COLORS.RED_100
                              : ''
                          }
                        >
                          {option.price.amount !== 0
                            ? `${option.price.amount}${
                                option.price.price_type === 'fixed' ? '€' : '%'
                              }`
                            : '-'}
                        </PriceLabel>
                      ) : (
                        <PriceLabel>-</PriceLabel>
                      )}
                      {selectedOptionIndex !== null &&
                        selectedOptionIndex === index && (
                          <ChangePriceModal ref={ChangePriceModalRef}>
                            <ChangePriceCloseIcon
                              onClick={() => setSelectedOptionIndex(null)}
                            >
                              <Icon svg={X} color={COLORS.GREEN} />
                            </ChangePriceCloseIcon>
                            <ChangePriceHeadline>
                              {t('Price change')}
                            </ChangePriceHeadline>
                            <ChangePriceOptionName>
                              {values.options[selectedOptionIndex]?.name}
                            </ChangePriceOptionName>
                            <Price
                              ref={priceRef}
                              name={`options.${index}.price`}
                              onBlur={(e) =>
                                handleBlurFunction(
                                  e,
                                  `options.${index}.price`,
                                  index,
                                  true
                                )
                              }
                              onFocus={() =>
                                handleFocus(`options.${index}.price`)
                              }
                              priceType={
                                values.options[selectedOptionIndex].price
                                  .price_type
                              }
                              amount={
                                values.options[selectedOptionIndex].price.amount
                              }
                              setFieldValue={setFieldValue}
                              optionIndex={index}
                              values={values}
                            />
                          </ChangePriceModal>
                        )}
                    </OptionWrapper>
                  ))}
                </OptionsWrapper>
                <AddOption onClick={addOption}>
                  <Icon
                    svg={Plus}
                    size={18}
                    color={COLORS.GREEN}
                    weight="regular"
                  />
                  {t('Add option')}
                </AddOption>
              </Row>
              <Row>
                <RowName>{t('Default')}</RowName>
                {defaultOptions.length > 0 && (
                  <DefaultSelectWrapper>
                    <Field
                      width="100%"
                      name={'default'}
                      options={defaultOptions || []}
                      component={CustomSelect}
                      placeholder={t('Default option')}
                      onSelect={(selectedOption: IOption) => {
                        const selectedIndex = values.options.findIndex(
                          (option) => option.name === selectedOption.value
                        );
                        handleBlurFunction(
                          {
                            target: {
                              name: 'default',
                              value: selectedIndex,
                            },
                          },
                          'default'
                        );
                      }}
                      errorMessage={
                        touched['default'] ? errors['default'] : null
                      }
                      menuPlacement={'top'}
                    />
                  </DefaultSelectWrapper>
                )}
                {values.default !== null && (
                  <ClearButton
                    onClick={() =>
                      handleBlurFunction(
                        {
                          target: {
                            name: 'default',
                            value: null,
                          },
                        },
                        'default'
                      )
                    }
                  >
                    {t('Clear')}
                  </ClearButton>
                )}
              </Row>
              <Row>
                <RowName>{t('Required')}</RowName>
                <SwitchWrapper>
                  <ToggleText isOff={values.required}>
                    {values.required ? t('On') : t('Off')}
                  </ToggleText>
                  <ToggleSwitch
                    isChecked={values.required}
                    size="large"
                    onClick={() => setFieldValue('required', !values.required)}
                  />
                </SwitchWrapper>
              </Row>
              <Row>
                <RowName>{t('Multiple')}</RowName>
                <SwitchWrapper>
                  <ToggleText isOff={!values.multiple}>
                    {t('Single')}
                  </ToggleText>
                  <ToggleSwitch
                    isChecked={values.multiple}
                    size="large"
                    disabled={
                      currentSelectedField.id ===
                        model.price_matrices[0].x_axis_id ||
                      currentSelectedField.id ===
                        model.price_matrices[0].y_axis_id
                    }
                    onClick={() => setFieldValue('multiple', !values.multiple)}
                  />
                  <ToggleText isOff={values.multiple}>
                    {t('Multiple')}
                  </ToggleText>
                </SwitchWrapper>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
};

export default ListForm;
