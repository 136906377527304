import { Skeleton } from '@mui/material';
import { COLORS } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled, { css } from 'styled-components';

export const MODEL_ROW_CONTAINER_HEIGHT = 65;

interface StyledProps {
  withoutLine: boolean;
  opacityIndex: number;
  opacityCoeficient: number;
}

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: ${MODEL_ROW_CONTAINER_HEIGHT}rem;
  ${(props: StyledProps) =>
    !props.withoutLine &&
    css`
      border-bottom: 1rem solid ${COLORS.BORDER};
    `}
  ${(props: StyledProps) =>
    props.opacityIndex !== undefined &&
    css`
      background-color: ${COLORS.WHITE};
      opacity: ${1 - (props.opacityIndex * props.opacityCoeficient + 0.2)};
    `}
`;

export const LeftPart = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5rem;
`;

export const CompanyNameLabel = styled.label`
  font-size: 18rem;
  font-weight: 400;
  color: ${COLORS.GREEN};

  ${respondTo.bigPhone`
    font-size: 16rem;
  `}
`;

export const RightPart = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20rem;
`;

export const Label = styled.label`
  font-size: 18rem;
  font-weight: 400;
  color: ${COLORS.BLACK};

  ${respondTo.bigPhone`
    font-size: 16rem;
  `}
`;

export const StyledSkeleton = styled(Skeleton)`
  border-radius: 15rem;
`;

export const CircleSkeleton = styled(Skeleton)`
  border-radius: 13rem;
`;

export const CompanyTooltipContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CompanyTooltipRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20rem;
`;

export const CompanyTooltipLabel = styled.label`
  color: ${COLORS.BLACK};
  font-weight: 400;
`;

export const CompanyTooltipLabelValue = styled.label`
  color: ${COLORS.BLACK};
  font-weight: 400;
  user-select: auto;
`;
