import { IColor } from 'components/Modals/ColorModal/ColorModal';
import { getIndexesOfInputsInOutputs } from 'pages/ConfigurePage/ConfigureForm/helpers';
import { IQuestion } from 'types/Question.types';

export const getChosenColorOptionFromQuestionOptions = (
  chosenColorValue: string | undefined,
  question: IQuestion
): IColor | undefined => {
  const indexesOfInputsInOutputs: number[] = getIndexesOfInputsInOutputs(
    question.inputs,
    question.outputs
  );
  for (const option of question.options) {
    let matchingOption = true;
    if (option[indexesOfInputsInOutputs[0]] !== chosenColorValue) {
      matchingOption = false;
      continue;
    }
    if (matchingOption) {
      const formattedOption = {};
      Object.keys(question.outputs).map(
        (outputKey: string, outputIndex: number) =>
          (formattedOption[outputKey] = option[outputIndex])
      );
      return formattedOption as IColor;
    }
  }
  return undefined;
};
