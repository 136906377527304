import { COLORS, marginLg, marginSm } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

export const Container = styled.div`
  background: ${COLORS.BACKGROUND};
  padding: ${marginLg};

  ${respondTo.smallLaptop`
    padding: 30rem;
  `}

  ${respondTo.smallTablet`
    padding: 30rem 15rem;
  `}
`;

export const Title = styled.label`
  font-weight: 600;
  font-size: 50rem;
  line-height: 68rem;
  font-weight: 300;
  color: ${COLORS.BLACK};

  ${respondTo.smallLaptop`
    font-size: 40rem;
    line-height: 50rem;
  `}

  ${respondTo.smallTablet`
    font-size: 30rem;
    margin-bottom: 20rem;
  `}
`;

export const FirstRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: ${marginSm};

  ${respondTo.smallTablet`
    flex-direction: column;
    align-items: flex-start;
  `}
`;

export const InputWrapper = styled.div`
  width: 300rem;

  ${respondTo.bigPhone`
    width: 100%;
  `}
`;

export const RightSide = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30rem;

  ${respondTo.smallTablet`
    width: 100%;
    justify-content: space-between;
  `}

  ${respondTo.bigPhone`
    flex-direction: column;
    align-items: flex-start;
    gap: 20rem;
  `}
`;

export const ViewAllModelsLabel = styled.label`
  font-size: 24px;
  font-weight: 300;
  color: ${COLORS.BLACK};
  margin-right: 10rem;
  cursor: pointer;

  ${respondTo.bigTablet`
    font-size: 20rem;
  `}

  ${respondTo.smallTablet`
    font-size: 18rem;
  `}

  ${respondTo.bigTablet`
    font-size: 16rem;
  `}
`;

export const ViewAllModelsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    ${ViewAllModelsLabel} {
      text-decoration: underline;
      text-decoration-thickness: 1rem;
    }
  }
`;

export const LoaderWrapper = styled.div`
  display: flex;
  margin-top: 30rem;
`;

export const NoContentLabel = styled.label`
  width: 100%;
  text-align: center;
  font-weight: 300;
  font-size: 21rem;
  line-height: 30rem;
  margin-top: 50rem;
  color: ${COLORS.BLACK};
  padding: 10rem 0 20rem;
  align-self: center;
  display: flex;
`;
