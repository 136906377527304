import { ISelectEntitiesDTO } from 'pages/Auth/CompanySelectPage/hooks';
import apiClient from 'services/api/apiService';

const BASE_URL = '/v1/users';

export const editUserData = async (
  firstName?: string,
  lastName?: string,
  currentPassword?: string,
  newPassword?: string
) => {
  const url = BASE_URL + `/me`;
  const body = {
    name: firstName,
    last_name: lastName,
    current_password: currentPassword,
    new_password: newPassword,
  };
  !firstName && delete body.name;
  !lastName && delete body.last_name;
  !currentPassword && delete body.current_password;
  !newPassword && delete body.new_password;

  const { data } = await apiClient.patch(url, body);

  return data.data;
};

export const selectEntities = async (dto: ISelectEntitiesDTO): Promise<any> => {
  const url = `/v1/select`;
  const payload = {
    supplier_id: dto.supplierId,
    company_id: dto.companyId,
  };
  const { data } = await apiClient.post<any>(url, payload);
  return data.data;
};
