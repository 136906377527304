import { IPostCartOrderDTO } from 'pages/ShoppingCartPage/hooks';
import apiClient from 'services/api/apiService';

const BASE_URL = '/v1/carts';

export const getShoppingCart = async (): Promise<any> => {
  const url = `${BASE_URL}/default`;
  const { data } = await apiClient.get<any>(url);

  return data.data;
};

// Cart id is also field that is optional, we use default cart for forms
export const addToShoppingCart = async (
  model_id: number,
  company_id: number,
  lang: string,
  queryPayload: any
): Promise<any> => {
  const url = `${BASE_URL}`;
  const { data } = await apiClient.post<any>(url, {
    model_id,
    company_id,
    questions: queryPayload.questions,
    language_code: lang,
  });

  return data.data;
};

// Cart id is also field that is optional, we use default cart for forms
export const editShoppingCartItem = async (
  cartLineId: number,
  modelId: number,
  companyId: number,
  lang: string,
  queryPayload: any
): Promise<any> => {
  const url = `${BASE_URL}/default/${cartLineId}`;
  const { data } = await apiClient.put<any>(url, {
    model_id: modelId,
    company_id: companyId,
    language_code: lang,
    questions: queryPayload.questions,
  });
  return data.data;
};

export const postCartOrder = async (
  postCardOrderDTO: IPostCartOrderDTO
): Promise<any> => {
  const url = `${BASE_URL}/order`;
  const { data } = await apiClient.post<any>(url, postCardOrderDTO);
  return data.data;
};

export const deleteShoppingCart = async (): Promise<any> => {
  const url = `${BASE_URL}/default`;
  const { data } = await apiClient.delete<any>(url, {});
  return data.data;
};

export const deleteShoppingCartItem = async (
  cartLineId: number
): Promise<any> => {
  const url = `${BASE_URL}/default/${cartLineId}`;

  const { data } = await apiClient.delete<any>(url, {});

  return data.data;
};

export const duplicateCartLine = async (cartLineId: number): Promise<any> => {
  const url = `${BASE_URL}/default/${cartLineId}/duplicate`;

  const { data } = await apiClient.post<any>(url);

  return data.data;
};
