import { Outlet, useLocation } from 'react-router-dom';
import Header from '../Header/Header';
import {
  LayoutColumn,
  LayoutRow,
  MarginTopHeaderHeight,
} from './DashboardLayout.styled';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { RoutesConfig } from 'navigation/routes';
import { AppMode } from 'store/Common/reducers/common';

export function DashboardLayout() {
  const location = useLocation();
  const appMode: AppMode = useSelector(
    (state: IRootReducerState) => state.commonInfo.appMode
  );
  return (
    <LayoutRow>
      <LayoutColumn>
        <Header />
        <MarginTopHeaderHeight
          isHeaderExpanded={
            appMode === AppMode.BLUEPRINT
              ? true
              : location.pathname === RoutesConfig.Configure.fullPath
          }
        />
        <Outlet />
      </LayoutColumn>
    </LayoutRow>
  );
}

export default DashboardLayout;
