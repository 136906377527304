import { useDrag, useDrop } from 'react-dnd';
import { ICustomModel } from 'types/Builder.types';
import { Container } from './DraggableField.styled';
import { useEffect, useRef, useState } from 'react';

interface IProps {
  children: (refs: {
    dragRef: React.Ref<any>;
    onDragStart: () => void;
  }) => React.ReactNode;
  index: number;
  setModel: React.Dispatch<React.SetStateAction<ICustomModel>>;
  draggedId: number | null;
  setDraggedId: (id: number | null) => void;
  isDragDisabled?: boolean;
  setSelectedFieldId: React.Dispatch<React.SetStateAction<string>>;
  fieldId: string;
}

const DraggableField = ({
  children,
  index,
  setModel,
  draggedId,
  setDraggedId,
  isDragDisabled = false,
  setSelectedFieldId,
  fieldId,
}: IProps) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [isDragEnabled, setIsDragEnabled] = useState(false);

  const [{ isDragging }, dragRef] = useDrag({
    type: 'field-item',
    item: { index },
    canDrag: !isDragDisabled && isDragEnabled,
    end: () => {
      setDraggedId(null);
      setIsDragEnabled(false);
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  useEffect(() => {
    if (isDragging) {
      setDraggedId(index);
    }
  }, [isDragging]);

  const [, dropRef] = useDrop({
    accept: 'field-item',
    hover: (draggedItem: { index: number }, monitor) => {
      const dragIndex = draggedItem.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) return;

      const hoverBoundingRect = (ref.current as any)?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const hoverActualY =
        (monitor.getClientOffset() as any).y - hoverBoundingRect.top;

      // if dragging down, continue only when hover is smaller than middle Y
      if (dragIndex < hoverIndex && hoverActualY < hoverMiddleY) return;
      // if dragging up, continue only when hover is bigger than middle Y
      if (dragIndex > hoverIndex && hoverActualY > hoverMiddleY) return;

      setModel((prevModel) => {
        const updatedFields = [...prevModel.input_fields];
        const [movedField] = updatedFields.splice(dragIndex, 1);
        updatedFields.splice(hoverIndex, 0, movedField);

        return { ...prevModel, input_fields: updatedFields };
      });

      draggedItem.index = hoverIndex;
    },
  });

  // Set field id for Field Configuration
  const handleClick = () => {
    if (!isDragEnabled) {
      setSelectedFieldId(fieldId);
    }
  };

  const dragDropRef = dropRef(ref);

  return (
    <Container
      ref={dragDropRef}
      isDragging={draggedId === index}
      onClick={handleClick}
    >
      {children({ dragRef, onDragStart: () => setIsDragEnabled(true) })}
    </Container>
  );
};

export default DraggableField;
