import { COLORS, borderRadiusSm } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';
import { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  background-color: ${COLORS.WHITE};
  border-radius: ${borderRadiusSm};
  padding: 30rem;

  ${respondTo.midTablet`
    padding: 20rem;
  `}
`;

export const CompaniesLabel = styled.label`
  color: ${COLORS.BLACK};
  font-size: 24rem;
  font-weight: 400;
  flex: 1;

  ${respondTo.bigLaptop`
    flex: 1 0 30%;
  `}

  ${respondTo.smallLaptop`
    font-size: 22rem;
  `}

  ${respondTo.bigTablet`
    flex: 1 0 10%;
  `}

  ${respondTo.midTablet`
    order: 1;
    width: 50%;
    flex: 1 0 50%;
  `}
`;

export const FirstRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 30rem;
`;

export const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 20rem;

  ${respondTo.bigLaptop`
    flex-wrap: wrap;
    justify-content: flex-end;
  `}

  ${respondTo.smallLaptop`
    gap: 20rem;
  `}
`;

export const SearchCategoryGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 20rem;
  justify-content: flex-end;

  ${respondTo.bigLaptop`
    flex: 1 0 65%;
  `};

  ${respondTo.midTablet`
    order: 3;
    width: 100%;
    flex: 1 0 100%;
  `};

  ${respondTo.bigPhone`
    flex-direction: column;
  `};
`;

export const SelectWrapper = styled.div`
  width: 200rem;

  ${respondTo.bigLaptop`
    display: flex;
    justify-content: flex-end;
  `}

  ${respondTo.midTablet`
    order: auto;
  `}

  ${respondTo.smallTablet`
    width: 100%;
  `}
`;

export const InputWrapper = styled.div`
  width: 300rem;

  ${respondTo.bigLaptop`
    display: flex;
    justify-content: flex-end;
  `}

  ${respondTo.midTablet`
    order: 3;
  `}

  ${respondTo.smallTablet`
    width: 100%;
  `}
`;

export const NoContentLabel = styled.label`
  width: 100%;
  text-align: center;
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
  color: ${COLORS.BLACK};

  ${respondTo.midTablet`
    font-size: 16rem;
  `}
`;

export const AddLabel = styled.label`
  font-size: 18rem;
  font-weight: 400;
  color: ${COLORS.GREEN};
  cursor: pointer;

  ${respondTo.midTablet`
    font-size: 16rem;
  `};
`;

export const AddWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  cursor: pointer;

  ${respondTo.midTablet`
    order: 2;
  `};

  &:hover {
    ${AddLabel} {
      text-decoration: underline;
    }
  }
`;

export const enableButtonWrapperStyling = css`
  height: 40rem;
  ${respondTo.midTablet`
    order: 4;
  `}
  ${respondTo.smallTablet`
    width: 100%;
  `};
`;

export const enableButtonStyling = css`
  font-size: 18rem;
  ${respondTo.midTablet`
    font-size: 16rem;
  `};
  ${respondTo.smallTablet`
    width: 100%;
  `};
  font-weight: 400;
`;
