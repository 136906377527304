import { COLORS, borderRadiusSm } from 'assets/styled';
import styled from 'styled-components';
import { css } from 'styled-components';

interface StyledProps {
  imageLoaded: boolean;
}

export const imageStyle = css`
  width: 100%;
  max-width: 400rem;
  height: auto;
  border-radius: ${borderRadiusSm};
  width: 100%;
  ${(props: StyledProps) =>
    props.imageLoaded
      ? css`
          min-height: 170rem;
          object-fit: contain;
          border: 1px solid ${COLORS.BORDER};
        `
      : css`
          width: 0;
          height: 0;
          opacity: 0;
        `};
`;

export const AdaptiveImageWrapper = styled.div`
  height: fit-content;
`;
