import { ITableConfig, ITableRowConfig } from 'components/Table/types';

const rowConfigs: ITableRowConfig[] = [
  { header: 'ID', flexValue: 1, isSortable: false },
  { header: 'First name', flexValue: 1.5, isSortable: false },
  { header: 'Last name', flexValue: 1.5, isSortable: false },
  { header: 'Email', flexValue: 1.5, isSortable: false },
  { header: 'Is active', flexValue: 1, isSortable: false },
];

export const tableConfig: ITableConfig = {
  rowConfigs: rowConfigs,
};

export const SUPPLIER_USERS_PER_PAGE = 10;
export const COMPANY_USERS_PER_PAGE = 10;
