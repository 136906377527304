import { COLORS, marginLg } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import Input from 'components/Input/Input';
import styled from 'styled-components';

export const Container = styled.div`
  background: ${COLORS.BACKGROUND};
  padding: ${marginLg};
  height: 100%;
  width: 100%;
  max-width: 1900rem;
  align-self: center;

  ${respondTo.bigPhone`
    padding: 15rem;
  `};
`;

export const Title = styled.label`
  font-weight: 600;
  font-size: 50rem;
  line-height: 68rem;
  font-weight: 300;
  color: ${COLORS.BLACK};

  ${respondTo.bigPhone`
    font-size: 40rem;
  `}
`;

export const SuppliersContainer = styled.div`
  display: grid;
  grid-gap: 15rem;
  justify-content: space-between;
  grid-template-columns: repeat(
    auto-fill,
    300rem
  ); // MODEL_CARD_CONTAINER_WIDTH === 300rem

  ${respondTo.smallLaptop`
    grid-template-columns: repeat(
      auto-fill,
      30%
    );
  `}

  ${respondTo.bigPhone`
    grid-template-columns: repeat(
      auto-fill,
      48%
    );
  `}

  ${respondTo.smallPhone`
    grid-template-columns: repeat(
      auto-fill,
      100%
    );
  `}
`;

export const TitleInputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 30rem;

  ${respondTo.bigPhone`
    flex-direction: column;
    gap: 20rem;
  `}
`;

export const NoContentLabel = styled.label`
  font-weight: 400;
  font-size: 24rem;
  line-height: 32rem;
  width: 100%;
  text-align: center;
  color: ${COLORS.BLACK};
`;

export const StyledInput = styled(Input)`
  width: 300rem;
  ${respondTo.bigPhone`
    width: 100%;
  `}
`;

export const InputWrapper = styled.div`
  display: flex;
  align-self: flex-end;
  width: 300rem;

  ${respondTo.bigPhone`
    width: 100%;
  `}
`;

export const NoContentLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
