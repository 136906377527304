import {
  Container,
  CurrentSelectionLabel,
  FullNameLabel,
  ButtonsWrapper,
  OtherOptionsLabel,
  RowCenterSpaceBetween,
  chevronRotateStyle,
  OtherOptionsWrapper,
  OtherOptionsRow,
  OtherOptionLabel,
  SelectLabel,
  NoContentLabel,
  NoContentLabelWrapper,
  OtherOptionsWrapperScrollable,
  inputWrapperStyling,
  ToggleDarkModeWrapperAbsolute,
} from './ProfileDropdown.styled';
import { useDispatch, useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { useTranslation } from 'react-i18next';
import { useLogout } from './hooks';
import { setCompany, setSupplier } from 'store/User/actions/user';
import Button from 'components/Button/Button';
import { useNavigate } from 'react-router-dom';
import { Dispatch, SetStateAction, useRef, useState } from 'react';
import { RoutesConfig } from 'navigation/routes';
import { useGetSuppliersInfinite } from 'pages/ModelsPage/hooks';
import { COMPANIES_PER_PAGE, SUPPLIERS_PER_PAGE } from './constants';
import { CaretDown, MagnifyingGlass as Search } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';
import Icon from 'components/Icon/Icon';
import { ISupplier } from 'types/Supplier.types';
import { ICompany } from 'types/Company.types';
import { toast } from 'react-toastify';
import { useLoadNextPage } from 'utils/hooks/useLoadNextPage';
import Input from 'components/Input/Input';
import { useDebounce } from 'utils/hooks/useDebounce';
import { AppMode } from 'store/Common/reducers/common';
import {
  useGetCompaniesInfinite,
  useSelectEntities,
} from 'pages/Auth/CompanySelectPage/hooks';
import { getDataFromPages } from 'utils/getDataFromPages';
import { Skeleton } from '@mui/material';
import { queryClient } from 'index';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import ToggleDarkMode from 'components/ToggleDarkMode/ToggleDarkMode';
import { handleSelectEntities } from 'pages/Auth/CompanySelectPage/helpers';

interface IProfileDropdownProps {
  setShowProfileDropdown: Dispatch<SetStateAction<boolean>>;
}

const ProfileDropdown = ({ setShowProfileDropdown }: IProfileDropdownProps) => {
  const loadMoreRefCompanies = useRef<HTMLDivElement>(null);
  const loadMoreRefSuppliers = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [searchByCompany, setSearchByCompany] = useState<string>('');
  const debouncedSearchByCompany = useDebounce(searchByCompany);
  const [searchBySupplier, setSearchBySupplier] = useState<string>('');
  const debouncedSearchBySupplier = useDebounce(searchBySupplier);
  const { first_name, last_name, company, supplier } = useSelector(
    (state: IRootReducerState) => state.userInfo
  );
  const [isCompaniesDropdownExpanded, setIsCompaniesDropdownExpanded] =
    useState<boolean>(false);
  const [isSuppliersDropdownExpanded, setIsSuppliersDropdownExpanded] =
    useState<boolean>(false);

  const appMode: AppMode = useSelector(
    (state: IRootReducerState) => state.commonInfo.appMode
  );

  // onSettled clears local storage
  const { mutate: logout, isLoading } = useLogout();

  const {
    data: companiesData,
    hasNextPage: hasNextPageCompanies,
    fetchNextPage: fetchNextPageCompanies,
    isFetching: isFetchingCompanies,
    isLoading: isLoadingCompanies,
  } = useGetCompaniesInfinite(COMPANIES_PER_PAGE, debouncedSearchByCompany);
  const companies: ICompany[] = getDataFromPages(
    companiesData?.pages,
    'companies'
  ).filter(
    (alternativeCompany: ICompany) => alternativeCompany.id !== company?.id
  );
  useLoadNextPage(
    loadMoreRefCompanies,
    hasNextPageCompanies,
    fetchNextPageCompanies,
    isFetchingCompanies
  );

  const {
    data: suppliersData,
    isFetching: isFetchingSuppliers,
    hasNextPage: hasNextPageSuppliers,
    fetchNextPage: fetchNextPageSuppliers,
    isLoading: isLoadingSuppliers,
  } = useGetSuppliersInfinite(SUPPLIERS_PER_PAGE, debouncedSearchBySupplier);
  const suppliers: ISupplier[] = getDataFromPages(
    suppliersData?.pages,
    'suppliers'
  ).filter(
    (alternativeSupplier: ISupplier) => alternativeSupplier.id !== supplier?.id
  );
  useLoadNextPage(
    loadMoreRefSuppliers,
    hasNextPageSuppliers,
    fetchNextPageSuppliers,
    isFetchingSuppliers
  );

  const { mutate: selectEntities, isLoading: isLoadingSelectEntities } =
    useSelectEntities();

  return (
    <Container>
      <ToggleDarkModeWrapperAbsolute>
        <ToggleDarkMode />
      </ToggleDarkModeWrapperAbsolute>
      <FullNameLabel>
        {first_name} {last_name}
      </FullNameLabel>
      {appMode === AppMode.COMPANY ? (
        <>
          <CurrentSelectionLabel>
            {company ? company.name : t('No company selected')}
          </CurrentSelectionLabel>
          <RowCenterSpaceBetween
            onClick={() =>
              setIsCompaniesDropdownExpanded(!isCompaniesDropdownExpanded)
            }
          >
            <OtherOptionsLabel>{t('Other company options')}</OtherOptionsLabel>
            <Icon
              svg={CaretDown}
              size={20}
              wrapperStyle={
                isCompaniesDropdownExpanded ? chevronRotateStyle : null
              }
              color={COLORS.BLACK}
            />
          </RowCenterSpaceBetween>
          <OtherOptionsWrapper isExpanded={isCompaniesDropdownExpanded}>
            <Input
              placeholder={t('Search companies')}
              width={'100%'}
              onChange={(e: any) => setSearchByCompany(e.target.value)}
              wrapperStyles={inputWrapperStyling}
              icon={Search}
            />
            <OtherOptionsWrapperScrollable>
              {companies.length ? (
                companies?.map((company: ICompany, index: number) => (
                  <OtherOptionsRow
                    isLast={index === companies.length}
                    key={`company-option-${index}`}
                    onClick={() => {
                      dispatch(setCompany(company));
                      queryClient.invalidateQueries([
                        ReactQueryKeys.GET_COMPANY,
                      ]);
                      toast.success(t('Successfully changed company'));
                      handleSelectEntities(
                        dispatch,
                        selectEntities,
                        company.id,
                        supplier?.id
                      );
                    }}
                  >
                    <OtherOptionLabel>{company?.name}</OtherOptionLabel>
                    <SelectLabel>{t('Select')}</SelectLabel>
                  </OtherOptionsRow>
                ))
              ) : isLoadingCompanies ? (
                <>
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                </>
              ) : (
                <NoContentLabelWrapper>
                  <NoContentLabel>{t('No companies found')}</NoContentLabel>
                </NoContentLabelWrapper>
              )}
              <div
                ref={loadMoreRefCompanies}
                style={{
                  height: '1rem',
                }}
              />
            </OtherOptionsWrapperScrollable>
          </OtherOptionsWrapper>
        </>
      ) : null}
      {appMode === AppMode.SUPPLIER ? (
        <>
          <CurrentSelectionLabel>
            {supplier ? supplier.name : t('No supplier selected')}
          </CurrentSelectionLabel>
          <RowCenterSpaceBetween
            onClick={() =>
              setIsSuppliersDropdownExpanded(!isSuppliersDropdownExpanded)
            }
          >
            <OtherOptionsLabel>{t('Other supplier options')}</OtherOptionsLabel>
            <Icon
              svg={CaretDown}
              size={20}
              wrapperStyle={
                isSuppliersDropdownExpanded ? chevronRotateStyle : null
              }
              color={COLORS.BLACK}
            />
          </RowCenterSpaceBetween>
          <OtherOptionsWrapper isExpanded={isSuppliersDropdownExpanded}>
            <Input
              placeholder={t('Search suppliers')}
              width={'100%'}
              onChange={(e: any) => setSearchBySupplier(e.target.value)}
              wrapperStyles={inputWrapperStyling}
              icon={Search}
            />
            <OtherOptionsWrapperScrollable>
              {suppliers.length ? (
                suppliers.map((supplier: ISupplier, index: number) => (
                  <OtherOptionsRow
                    isLast={index === suppliers.length}
                    key={`supplier-option-${index}`}
                    onClick={() => {
                      dispatch(setSupplier(supplier));
                      queryClient.invalidateQueries([
                        ReactQueryKeys.GET_SUPPLIER,
                      ]);
                      toast.success(t('Successfully changed supplier'));
                      handleSelectEntities(
                        dispatch,
                        selectEntities,
                        company?.id,
                        supplier.id
                      );
                    }}
                  >
                    <OtherOptionLabel>{supplier.name}</OtherOptionLabel>
                    <SelectLabel>{t('Select')}</SelectLabel>
                  </OtherOptionsRow>
                ))
              ) : isLoadingSuppliers ? (
                <>
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                </>
              ) : (
                <NoContentLabelWrapper>
                  <NoContentLabel>{t('No suppliers found')}</NoContentLabel>
                </NoContentLabelWrapper>
              )}
              <div
                ref={loadMoreRefSuppliers}
                style={{
                  height: '1rem',
                }}
              />
            </OtherOptionsWrapperScrollable>
          </OtherOptionsWrapper>
        </>
      ) : null}
      <ButtonsWrapper>
        <Button
          primary
          width="100%"
          onClick={() => {
            navigate(RoutesConfig.Profile.fullPath);
            setShowProfileDropdown(false);
          }}
          label={t('Manage profile')}
        />
        <Button
          disabled={isLoading}
          red
          onClick={() => logout()}
          label={t('Log out')}
          width={'100%'}
        />
      </ButtonsWrapper>
    </Container>
  );
};

export default ProfileDropdown;
