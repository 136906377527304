import { useTranslation } from 'react-i18next';
import { usePageTitle } from 'utils/hooks/usePageTitle';
import { Container, HeadingWrapper, Title } from './SettingsPage.styled';
import {
  SettingsRow,
  SettingsRowType,
  SettingsEntityType,
} from './SettingsRow/SettingsRow';
import { useGetCompany, useGetSupplier } from './hooks';
import { SettingsRowSkeleton } from './SettingsRow/SettingsRowSkeleton/SettingsRowSkeleton';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { AppMode } from 'store/Common/reducers/common';

export const SettingsPage = () => {
  const { t } = useTranslation();
  const appMode: AppMode = useSelector(
    (state: IRootReducerState) => state.commonInfo.appMode
  );
  const { company, supplier } = useSelector(
    (state: IRootReducerState) => state.userInfo
  );
  const { data: companyData, isLoading: isLoadingCompany } = useGetCompany(
    company?.id,
    appMode === AppMode.COMPANY
  );
  const { data: supplierData, isLoading: isLoadingSupplier } = useGetSupplier(
    supplier?.id,
    appMode === AppMode.SUPPLIER
  );

  usePageTitle(
    appMode === AppMode.COMPANY ? 'Company Settings' : 'Supplier Settings'
  );

  const entityType =
    appMode === AppMode.COMPANY
      ? SettingsEntityType.COMPANY
      : SettingsEntityType.SUPPLIER;

  const entityId =
    appMode === AppMode.COMPANY ? companyData?.id : supplierData?.id;

  // Use appMode as part of the key to force re-render when it changes
  const containerKey = `settings-container-${appMode}`;
  return (
    <Container key={containerKey}>
      <HeadingWrapper>
        <Title>
          {appMode === AppMode.COMPANY
            ? t('Company Settings')
            : t('Supplier Settings')}
        </Title>
      </HeadingWrapper>
      {isLoadingCompany || isLoadingSupplier ? (
        <>
          <SettingsRowSkeleton />
          <SettingsRowSkeleton />
          <SettingsRowSkeleton />
          <SettingsRowSkeleton />
          <SettingsRowSkeleton withoutLine />
        </>
      ) : (
        <>
          <SettingsRow
            entityId={entityId}
            entityType={entityType}
            type={SettingsRowType.NAME}
            initialData={
              appMode === AppMode.COMPANY
                ? companyData?.name
                : supplierData?.name
            }
          />
          <SettingsRow
            entityId={entityId}
            entityType={entityType}
            type={SettingsRowType.EMAIL}
            initialData={
              appMode === AppMode.COMPANY
                ? companyData?.email
                : supplierData?.email
            }
          />
          <SettingsRow
            entityId={entityId}
            entityType={entityType}
            type={SettingsRowType.PHONE_NUMBER}
            initialData={
              appMode === AppMode.COMPANY
                ? companyData?.phone_number
                : supplierData?.phone_number
            }
          />
          <SettingsRow
            entityId={entityId}
            entityType={entityType}
            type={SettingsRowType.WEBSITE}
            initialData={
              appMode === AppMode.COMPANY
                ? companyData?.website
                : supplierData?.website
            }
          />
          <SettingsRow
            entityId={entityId}
            entityType={entityType}
            type={SettingsRowType.LOGO}
            initialData={
              appMode === AppMode.COMPANY
                ? companyData?.logo_url
                : supplierData?.logo_url
            }
            withoutLine
          />
        </>
      )}
    </Container>
  );
};
