import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { logout } from 'services/AuthService';
import LocalStorageService from 'services/LocalStorageService';
import {
  LOCAL_STORAGE_ACCESS_TOKEN,
  LOCAL_STORAGE_REFRESH_TOKEN,
} from 'services/api/constants';
import { LogyxError, getToastErrorMessage } from 'services/api/errors';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { logout as logoutAction } from 'store/User/actions/user';

export const useLogout = () => {
  const dispatch = useDispatch();
  return useMutation(() => logout(), {
    onSuccess: (data) => {
      // onSuccess
    },
    onError: (error: LogyxError) => {
      toast.error(getToastErrorMessage(error));
    },
    onSettled: () => {
      LocalStorageService.removeItem(LOCAL_STORAGE_ACCESS_TOKEN);
      LocalStorageService.removeItem(LOCAL_STORAGE_REFRESH_TOKEN);
      dispatch(logoutAction());
    },
    mutationKey: ReactMutationKeys.LOGOUT,
  });
};
