import { queryClient } from 'index';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { deleteSupplierCompany } from 'services/SupplierCompanyService';
import { LogyxError, getToastErrorMessage } from 'services/api/errors';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';

export const useDeleteSupplierCompany = () => {
  const { t } = useTranslation();
  return useMutation(
    (supplierCompanyId: number) => {
      return deleteSupplierCompany(supplierCompanyId);
    },
    {
      onError: (error: LogyxError) => {
        toast.error(getToastErrorMessage(error));
      },
      onSuccess: () => {
        toast.success(t('Revoked company access'));
        queryClient.removeQueries(ReactQueryKeys.GET_SUPPLIER_COMPANIES);
        queryClient.removeQueries(
          ReactQueryKeys.GET_ELIGIBLE_COMPANIES_FOR_CREATING_SUPPLIER_COMPANY
        );
        queryClient.removeQueries(ReactQueryKeys.GET_SUPPLIER_COMPANY_MODELS);
      },
      mutationKey: ReactMutationKeys.DELETE_SUPPLIER_COMPANY,
    }
  );
};
