import { useNavigate, useParams } from 'react-router-dom';
import {
  Container,
  FirstRow,
  ModelDetailsWrapper,
  RowGap,
  Back,
  ModelName,
  InfoText,
  ContentWrapper,
  imageStyle,
  imageContainerStyle,
} from './SupplierModelCompaniesPage.styled';
import { useTranslation } from 'react-i18next';
import Icon from 'components/Icon/Icon';
import SupplierModelCompanies from './SupplierModelCompanies/SupplierModelCompanies';
import { COLORS } from 'assets/styled';
import { RoutesConfig } from 'navigation/routes';
import { usePageTitle } from 'utils/hooks/usePageTitle';
import { useGetSingleModel } from './hooks';
import {
  AdaptiveImage,
  AdaptiveImageType,
} from 'components/AdaptiveImage/AdaptiveImage';
import { CaretLeft } from '@phosphor-icons/react';

const SupplierModelCompaniesPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { id } = useParams();
  const { data: modelData, isSuccess: isSuccessGetSingleModel } =
    useGetSingleModel(Number(id));

  usePageTitle(modelData?.general?.name, false);

  return (
    <Container>
      <FirstRow onClick={() => navigate(RoutesConfig.MyModels.fullPath)}>
        <Icon
          svg={CaretLeft}
          color={COLORS.GREEN}
          weight="bold"
          size={20}
          onClick={() => null}
        />
        <Back>{t('Back')}</Back>
      </FirstRow>
      <ContentWrapper>
        <AdaptiveImage
          imageUrl={modelData?.general?.images?.[0]}
          imageStyle={imageStyle}
          containerStyle={imageContainerStyle}
          type={AdaptiveImageType.MODEL}
          isSuccess={isSuccessGetSingleModel}
        />
        <ModelDetailsWrapper>
          <ModelName>{modelData?.general?.name}</ModelName>
          <RowGap gap="30rem">
            <InfoText>
              {t('Model ID')}: {modelData?.id}
            </InfoText>
            <InfoText>
              {t('Version ID')}: {modelData?.version_number}
            </InfoText>
          </RowGap>
          <RowGap gap="30rem">
            <InfoText>
              {t('Price list')}: {modelData?.general?.pricelist}
            </InfoText>
            <InfoText>
              {t('Price list version')}: {modelData?.general?.pricelist_version}
            </InfoText>
          </RowGap>
          <InfoText>
            {modelData?.general?.descriptions &&
              modelData?.general?.descriptions.join(' ')}
          </InfoText>
        </ModelDetailsWrapper>
      </ContentWrapper>
      <SupplierModelCompanies modelId={Number(id)} />
    </Container>
  );
};

export default SupplierModelCompaniesPage;
