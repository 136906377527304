import { queryClient } from 'index';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import {
  createConfiguration,
  updateConfiguration,
} from 'services/ModelConfigurationService';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import {
  ICreateConfigurationData,
  IUpdateConfigurationData,
} from 'types/Configuration.types';
import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction, useEffect } from 'react';

export const useCreateConfiguration = () => {
  const { t } = useTranslation();
  return useMutation(
    (createConfigurationData: ICreateConfigurationData) => {
      return createConfiguration(createConfigurationData);
    },
    {
      onSuccess: (data) => {
        toast.success(t('Configuration applied'));
        queryClient.invalidateQueries([
          ReactQueryKeys.GET_MODEL_CONFIGURATIONS,
        ]);
      },
      onError: (error) => {
        toast.error(t('Error while applying configuration'));
        queryClient.invalidateQueries([
          ReactQueryKeys.GET_MODEL_CONFIGURATIONS,
        ]);
      },
      onSettled: () => {
        // Finally
      },
      mutationKey: ReactMutationKeys.CREATE_CONFIGURATION,
    }
  );
};

export const useUpdateConfiguration = (configurationId: number) => {
  const { t } = useTranslation();
  return useMutation(
    (updateConfigurationData: IUpdateConfigurationData) => {
      return updateConfiguration(configurationId, updateConfigurationData);
    },
    {
      onSuccess: (data) => {
        toast.success(t('Configuration applied'));
        queryClient.invalidateQueries([
          ReactQueryKeys.GET_MODEL_CONFIGURATIONS,
        ]);
        // on Success
      },
      onError: (error) => {
        toast.error(t('Error while applying configuration'));
        queryClient.invalidateQueries([
          ReactQueryKeys.GET_MODEL_CONFIGURATIONS,
        ]);
      },
      onSettled: () => {
        // Finally
      },
      mutationKey: ReactMutationKeys.UPDATE_CONFIGURATION,
    }
  );
};

export const useHideErrorsAfterDelay = (
  isBaseMarkupErrorVisible: boolean,
  setIsBaseMarkupErrorVisible: Dispatch<SetStateAction<boolean>>,
  isBasePurchaseDiscountErrorVisible: boolean,
  setIsBasePurchaseDiscountErrorVisible: Dispatch<SetStateAction<boolean>>,
  isFixedPriceErrorVisible: boolean,
  setIsFixedPriceErrorVisible: Dispatch<SetStateAction<boolean>>,
  isSurchargeMarkupErrorVisible: boolean,
  setIsSurchargeMarkupErrorVisible: Dispatch<SetStateAction<boolean>>,
  isSurchargePurchaseDiscountErrorVisible: boolean,
  setIsSurchargePurchaseDiscountErrorVisible: Dispatch<SetStateAction<boolean>>
) => {
  useEffect(() => {
    if (isBaseMarkupErrorVisible) {
      const timeoutId = setTimeout(
        () => setIsBaseMarkupErrorVisible(false),
        3000
      );
      return () => clearTimeout(timeoutId);
    }
  }, [isBaseMarkupErrorVisible]);

  useEffect(() => {
    if (isBasePurchaseDiscountErrorVisible) {
      const timeoutId = setTimeout(
        () => setIsBasePurchaseDiscountErrorVisible(false),
        3000
      );
      return () => clearTimeout(timeoutId);
    }
  }, [isBasePurchaseDiscountErrorVisible]);

  useEffect(() => {
    if (isFixedPriceErrorVisible) {
      const timeoutId = setTimeout(
        () => setIsFixedPriceErrorVisible(false),
        3000
      );
      return () => clearTimeout(timeoutId);
    }
  }, [isFixedPriceErrorVisible]);

  useEffect(() => {
    if (isSurchargeMarkupErrorVisible) {
      const timeoutId = setTimeout(
        () => setIsSurchargeMarkupErrorVisible(false),
        3000
      );
      return () => clearTimeout(timeoutId);
    }
  }, [isSurchargeMarkupErrorVisible]);

  useEffect(() => {
    if (isSurchargePurchaseDiscountErrorVisible) {
      const timeoutId = setTimeout(
        () => setIsSurchargePurchaseDiscountErrorVisible(false),
        3000
      );
      return () => clearTimeout(timeoutId);
    }
  }, [isSurchargePurchaseDiscountErrorVisible]);
};
