import { COLORS } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

export const Container = styled.div``;

export const SupplierName = styled.label`
  font-size: 30rem;
  font-weight: 300;
  color: ${COLORS.BLACK};

  ${respondTo.bigTablet`
    font-size: 26rem;
  `}

  ${respondTo.midTablet`
    font-size: 24rem;
  `}

  ${respondTo.smallTablet`
    font-size: 20rem;
  `}

  ${respondTo.bigPhone`
    font-size: 18rem;
    margin-bottom: 20rem;
  `}
`;

export const FirstRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20rem;

  ${respondTo.bigPhone`
    flex-direction: column;
    align-items: flex-start;
  `}
`;

export const InputWrapper = styled.div`
  width: 300rem;

  ${respondTo.bigPhone`
    width: 100%;
  `}
`;

export const CategoriesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20rem;
`;

export const NoContentLabel = styled.label`
  width: 100%;
  text-align: center;
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
  color: ${COLORS.BLACK};

  ${respondTo.bigPhone`
    font-size: 16rem;
  `}
`;

export const ViewMoreLabel = styled.label`
  font-size: 24rem;
  font-weight: 300;
  text-decoration: underline;
  color: ${COLORS.GREEN};
  cursor: pointer;

  ${respondTo.midTablet`
    font-size: 20rem;
  `}

  ${respondTo.bigPhone`
    font-size: 18rem;
  `}
`;

export const ViewMoreLabelWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10rem;
`;

export const NoContentLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
