import { COLORS } from 'assets/styled';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 30rem;
  display: flex;
  gap: 30rem;
  justify-content: center;
  width: 100%;
  max-width: 100%;
  align-items: flex-start;
  align-self: center;
`;

export const SideWrapper = styled.div`
  min-height: 85vh;
  height: 100%;
  border: 1px solid ${COLORS.BORDER};
  border-radius: 10rem;
  padding: 20rem;
  max-width: 340rem;
  width: 100%;
  background-color: ${COLORS.WHITE};
`;
export const MiddleWrapper = styled.div`
  min-width: 685rem;
`;
