import {
  FieldTypes,
  IInputField,
  IListInputField,
  InputField,
  IRadioInputField,
  OptionMatrixEntry,
} from 'types/Builder.types';
import { allowedTypesMatrixAxis } from './constants';

export const exportMatrixTemplate = () => {
  const link = document.createElement('a');
  link.href = '/matrix_template.xlsx';
  link.download = 'matrix_template.xlsx';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const checkFieldTypes = (inputFields: InputField[]): boolean => {
  const matchingFields = inputFields.filter((field) =>
    allowedTypesMatrixAxis.includes(field.field_type)
  );

  return matchingFields.length >= 2;
};

interface IValidateMatrixOptions {
  axisFieldId: string;
  matrix: OptionMatrixEntry[];
  inputFields: IInputField[];
  matrixIndex: number;
}

export interface IValidationResult {
  optionsFromMatrix: string[];
  missingOptions: string[];
  extraOptions: string[];
}

// Validates and extracts options for a field used as an axis in the matrix
export const validateMatrixOptions = ({
  axisFieldId,
  matrix,
  inputFields,
  matrixIndex,
}: IValidateMatrixOptions): IValidationResult => {
  const selectedField = inputFields.find((field) => field.id === axisFieldId);

  if (
    !selectedField ||
    (selectedField.field_type !== FieldTypes.List &&
      selectedField.field_type !== FieldTypes.Radio)
  ) {
    return { optionsFromMatrix: [], missingOptions: [], extraOptions: [] };
  }

  // Extract unique options from the matrix for the specified axis
  const optionsFromMatrix = Array.from(
    new Set(
      matrix.map((entry) => entry[matrixIndex]?.toString()).filter(Boolean)
    )
  );

  // Get the current options in the field
  const currentOptions = (
    selectedField as IListInputField | IRadioInputField
  ).options.map((option) => option.name);

  // Identify options required by the matrix but missing in the field
  const missingOptions = optionsFromMatrix.filter(
    (option) => !currentOptions.includes(option)
  );

  // Identify options present in the field but not required by the matrix
  const extraOptions = currentOptions.filter(
    (option) => !optionsFromMatrix.includes(option)
  );

  return { optionsFromMatrix, missingOptions, extraOptions };
};

// Extracts the options from a field by ID if the field is of type List or Radio
export const extractOptionsFromField = (
  axisFieldId: string,
  inputFields: IInputField[]
): string[] => {
  const field = inputFields.find((f) => f.id === axisFieldId);
  if (
    !field ||
    (field.field_type !== FieldTypes.List &&
      field.field_type !== FieldTypes.Radio)
  ) {
    return [];
  }
  return (field as IListInputField | IRadioInputField).options.map(
    (option) => option.name
  );
};
