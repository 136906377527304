import { COLORS, borderRadiusSm, marginXs } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import { MODEL_CARD_CONTAINER_WIDTH } from 'components/ModelCard/ModelCard.styled';
import styled, { css } from 'styled-components';

interface StyledProps {
  imageLoaded: boolean;
}

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: ${MODEL_CARD_CONTAINER_WIDTH}rem;
  transition: transform ease-in-out 50ms;
  margin-bottom: 20rem;

  &:hover {
    transform: translateY(-2rem);
  }

  ${respondTo.smallLaptop`
    max-width: 100%;
    width: 100%;
  `}

  ${respondTo.smallPhone`
    height: auto;
    margin-bottom: 20rem;
  `}
`;

export const AbsoluteCircleWrapper = styled.label`
  z-index: 1;
  position: absolute;
  display: flex;
  left: 0rem;
  top: 0rem;
  background-color: ${COLORS.GREEN_DISABLED};
  border-bottom-right-radius: ${borderRadiusSm};
  border-top-left-radius: ${borderRadiusSm};
  padding-left: 5rem;
  padding-right: 5rem;
`;

export const ReferenceLabel = styled.label`
  margin-left: 3rem;
  margin-right: 3rem;
`;

export const imageStyle = css`
  width: 100%;
  object-fit: contain;
  border-radius: ${borderRadiusSm};
  cursor: pointer;
  border: 1px solid ${COLORS.BORDER};
  ${(props: StyledProps) =>
    props.imageLoaded
      ? css`
          max-width: 100%;
          object-fit: contain;
          border-radius: ${borderRadiusSm};
          cursor: pointer;
          border: 1px solid ${COLORS.BORDER};
        `
      : css`
          width: 0;
          height: 0;
          opacity: 0;
        `};
`;

export const NameLabel = styled.label`
  margin-top: ${marginXs};
  font-weight: 400;
  font-size: 24rem;
  line-height: 32rem;
  cursor: pointer;
  color: ${COLORS.BLACK};

  ${respondTo.smallLaptop`
    font-size: 20rem;
    line-height: 28rem;
  `}

  ${respondTo.bigTablet`
    font-size: 18rem;
  `}
`;

export const RowCenter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
`;

export const IconWrapper = styled.div`
  position: absolute;
  top: 10rem;
  right: 10rem;
  &:hover {
    transform: scale(1.1);
    transition: transform ease-in-out 0.1s;
  }
`;
