import i18n, { FALLBACK_LOCALE } from 'providers/i18n/i18n';
import { useInfiniteQuery } from 'react-query';
import { getModels } from 'services/ModelService';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';

export const useGetModelsInfinite = (
  perPage: number,
  searchBy: string,
  supplierId: number | null,
  categoryId: number | null,
  recentlyViewed: boolean,
  favorites: boolean,
  isEnabled = true
) =>
  useInfiniteQuery({
    queryKey: [
      ReactQueryKeys.GET_MODELS_INFINITE,
      perPage,
      searchBy,
      supplierId,
      categoryId,
      recentlyViewed,
      favorites,
      i18n.resolvedLanguage || FALLBACK_LOCALE,
    ],
    queryFn: ({ pageParam = 1 }) =>
      getModels(
        pageParam,
        perPage,
        searchBy,
        supplierId,
        categoryId,
        recentlyViewed,
        favorites
      ),
    getNextPageParam: (lastPage: any) => {
      if (!lastPage?.pagination) {
        return undefined;
      }
      if (
        lastPage.pagination.per_page * lastPage.pagination.page >=
        lastPage.pagination.total
      ) {
        // Return undefined here so hasNextPage equals to false
        return undefined;
      }
      return lastPage.pagination.page + 1;
    },
    onSuccess: (data) => {
      return data;
    },
    enabled: isEnabled,
  });
