import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { ICompany } from 'types/Company.types';
import { useState } from 'react';
import SupplierCompanyRow from './SupplierCompanyRow/SupplierCompanyRow';
import {
  Container,
  NoContentLabel,
  NoContentLabelWrapper,
  PaginationWrapper,
} from './SupplierCompaniesList.styled';
import Pagination from 'components/Pagination/Pagination';
import { useTranslation } from 'react-i18next';
import { SUPPLIER_COMPANIES_PER_PAGE } from './constants';
import { useGetSupplierCompanies } from './hooks';
import SupplierCompanyRowSkeleton from './SupplierCompanyRow/SupplierCompanyRowSkeleton/SupplierCompanyRowSkeleton';

interface ISupplierCompaniesListProps {
  debouncedSearchBy: string;
  onDelete: (supplierCompanyId: number) => void;
}

const SupplierCompaniesList = ({
  debouncedSearchBy,
  onDelete,
}: ISupplierCompaniesListProps) => {
  const { t } = useTranslation();
  const [page, setPage] = useState<number>(1);
  const supplier = useSelector(
    (state: IRootReducerState) => state.userInfo.supplier
  );
  const { data: supplierCompaniesData, isLoading: isLoadingSupplierCompanies } =
    useGetSupplierCompanies(
      supplier.id,
      page,
      SUPPLIER_COMPANIES_PER_PAGE,
      debouncedSearchBy
    );

  const companies: ICompany[] = supplierCompaniesData?.companies || [];

  const showPagination =
    supplierCompaniesData?.pagination?.total &&
    supplierCompaniesData?.pagination?.total >= SUPPLIER_COMPANIES_PER_PAGE;

  return (
    <Container isSkeleton={isLoadingSupplierCompanies}>
      {companies.length ? (
        companies.map((company: ICompany, index: number) => (
          <SupplierCompanyRow
            company={company}
            key={`suppliercompanyrow-${index}`}
            withoutLine={companies.length - 1 === index}
            onDelete={onDelete}
          />
        ))
      ) : isLoadingSupplierCompanies ? (
        Array.from({ length: SUPPLIER_COMPANIES_PER_PAGE }, (_, index) => (
          <SupplierCompanyRowSkeleton
            key={index}
            index={index}
            withoutLine={SUPPLIER_COMPANIES_PER_PAGE - 1 === index}
            opacityCoeficient={0.1}
          />
        ))
      ) : (
        <NoContentLabelWrapper>
          <NoContentLabel>
            {t('No companies available for this supplier')}
          </NoContentLabel>
        </NoContentLabelWrapper>
      )}
      {showPagination ? (
        <PaginationWrapper>
          <Pagination
            page={page}
            perPage={SUPPLIER_COMPANIES_PER_PAGE}
            total={supplierCompaniesData?.pagination?.total}
            setPage={setPage}
          />
        </PaginationWrapper>
      ) : null}
    </Container>
  );
};

export default SupplierCompaniesList;
