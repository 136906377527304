import { getModelConfigurations } from 'services/ModelConfigurationService';
import { useLocation } from 'react-router-dom';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { useQuery } from 'react-query';
import i18n from 'providers/i18n/i18n';

export const useGetModelConfigurations = (
  supplierId: number | null,
  searchBy: string
) =>
  useQuery({
    queryKey: [
      ReactQueryKeys.GET_MODEL_CONFIGURATIONS,
      supplierId,
      searchBy,
      i18n.resolvedLanguage,
    ],
    queryFn: () => {
      return getModelConfigurations(supplierId, searchBy);
    },
    enabled: !!supplierId, // Temporarily removed; #739 Dealer panel model list
  });

export interface IDealerPanelSupplierDataFromNavigationState {
  supplierId: number;
  supplierName: string;
  supplierHasSystemIntegration: boolean;
}

export const useGetSupplierDataFromNavigationState =
  (): IDealerPanelSupplierDataFromNavigationState => {
    const { state }: { state: IDealerPanelSupplierDataFromNavigationState } =
      useLocation();
    if (state) {
      return {
        supplierId: state.supplierId,
        supplierName: state.supplierName,
        supplierHasSystemIntegration: state.supplierHasSystemIntegration,
      };
    } else {
      return {
        supplierId: -1,
        supplierName: 'Unknown supplier',
        supplierHasSystemIntegration: false,
      };
    }
  };
