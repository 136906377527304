import {
  IImageLayer,
  IImageSection,
} from 'pages/ConfigurePage/FormImages/hooks';
import {
  ContainerRelative,
  SmallFormImageLayerImage,
} from './SmallFormImage.styled';
import { useEffect, useRef, useState } from 'react';
import ErrorLoadingMediaImage from 'assets/images/errorLoadingMedia.png';
import ErrorLoadingMediaImageDark from 'assets/images/errorLoadingMediaDark.png';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { SmallFormImageLayer } from './SmallFormImageLayer';

interface ILayeredImageProps {
  image: IImageSection;
  onClick: () => void;
  singleImage?: boolean;
}

const SmallFormImage = ({
  image,
  onClick,
  singleImage,
}: ILayeredImageProps) => {
  const [error, setError] = useState<boolean>(false);
  useEffect(() => {
    setError(false);
  }, [image]);
  const { darkMode } = useSelector(
    (state: IRootReducerState) => state.commonInfo
  );

  const containerRef = useRef<HTMLDivElement>(null);
  // Make the ContainerRelative component have the height of its absolutely positioned child element by dynamically adjusting the height
  useEffect(() => {
    // Function to update the height of the container
    const updateContainerHeight = () => {
      if (containerRef.current) {
        let maxHeight = 0;
        Array.from(containerRef.current.children).forEach((child) => {
          const childHeight = child.getBoundingClientRect().height;
          if (childHeight > maxHeight) {
            maxHeight = childHeight;
          }
        });
        containerRef.current.style.height = `${maxHeight}rem`;
      }
    };
    updateContainerHeight();
    // Update the height if the window is resized
    window.addEventListener('resize', updateContainerHeight);
    // Cleanup the event listener
    return () => window.removeEventListener('resize', updateContainerHeight);
  }, [image]);

  const imageLayerKeys = image?.layers ? Object.keys(image.layers) : [];

  return (
    <ContainerRelative
      onClick={onClick}
      ref={containerRef}
      singleImage={singleImage}
    >
      {!error ? (
        imageLayerKeys.map((layerKey: string, index: number) => {
          const layer: IImageLayer = image.layers[layerKey];
          return (
            <SmallFormImageLayer
              key={`${index}-${layerKey}`}
              layerKey={`${index}-${layerKey}`}
              singleImage={singleImage}
              layer={layer}
              setError={setError}
            />
          );
        })
      ) : (
        <SmallFormImageLayerImage
          src={darkMode ? ErrorLoadingMediaImageDark : ErrorLoadingMediaImage}
          borderRadius={'20rem'}
          singleImage={singleImage}
        />
      )}
    </ContainerRelative>
  );
};

export default SmallFormImage;
