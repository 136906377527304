import { Dispatch, SetStateAction, useState } from 'react';
import { useGetSupplierUsers } from './hooks';
import {
  SUPPLIER_USERS_PER_PAGE,
  tableConfig,
} from 'pages/UsersPage/constants';
import { IUser } from 'types/User.types';
import { MagnifyingGlass as Search } from '@phosphor-icons/react';
import {
  Card,
  FirstRow,
  InputWrapper,
  RightSide,
  Title,
} from 'pages/UsersPage/UsersPage.styled';
import { formatUsersTableData } from 'pages/UsersPage/helpers';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import Input from 'components/Input/Input';
import Button from 'components/Button/Button';
import Table from 'components/Table/Table';

interface ISupplierUsersListViewProps {
  searchBy: string;
  setSearchBy: (str: string) => void;
  debouncedSearchBy: string;
  onDelete: (userId: number) => void;
  setIsEmailInputModalOpen: Dispatch<SetStateAction<boolean>>;
}

export const SupplierUsersListView = ({
  searchBy,
  setSearchBy,
  debouncedSearchBy,
  onDelete,
  setIsEmailInputModalOpen,
}: ISupplierUsersListViewProps) => {
  const supplier = useSelector(
    (state: IRootReducerState) => state.userInfo.supplier
  );
  const { t } = useTranslation();
  const [page, setPage] = useState<number>(1);
  const { data: usersData, isLoading } = useGetSupplierUsers(
    supplier.id,
    page,
    SUPPLIER_USERS_PER_PAGE,
    debouncedSearchBy
  );

  const users: IUser[] = usersData?.users || [];

  return (
    <Card>
      <FirstRow>
        <Title>
          {t('Users for supplier {{SUPPLIER}}').replace(
            '{{SUPPLIER}}',
            supplier.name
          )}
        </Title>
        <RightSide>
          <InputWrapper>
            <Input
              placeholder={t('Search users')}
              width={'100%'}
              wrapperStyles={{ width: '100%' }}
              value={searchBy}
              onChange={(e: any) => setSearchBy(e.target.value)}
              icon={Search}
            />
          </InputWrapper>
          <Button
            primary
            label={t('Add user to supplier')}
            onClick={() => setIsEmailInputModalOpen(true)}
          />
        </RightSide>
      </FirstRow>
      <Table
        page={page}
        setPage={setPage}
        perPage={10}
        total={usersData?.pagination?.total}
        isLoading={isLoading}
        tableData={formatUsersTableData(users) || []}
        tableConfig={tableConfig}
        onDelete={onDelete}
      />
    </Card>
  );
};
