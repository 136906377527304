import i18n from 'providers/i18n/i18n';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const usePageTitle = (title: string, shouldTranslate = true) => {
  const { t } = useTranslation();
  useEffect(() => {
    document.title = `Logyx - ${shouldTranslate ? t(title) : title} `;
  }, [title, i18n.resolvedLanguage]);
};
