import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Container, Title } from './AddModelToCompanyModal.styled';
import { Modal } from '../Modal/Modal';
import { useTranslation } from 'react-i18next';
import ScrollableList from 'components/ScrollableList/ScrollableList';
import { useDebounce } from 'utils/hooks/useDebounce';
import { useGetEligibleModelsForCreatingSupplierCompanyModel } from './hooks';
import { IRootReducerState } from 'store/store';
import { useSelector } from 'react-redux';
import { ELIGIBLE_MODELS_FOR_CREATING_SUPPLIER_COMPANY_MODEL_PER_PAGE } from './constants';
import {
  ICreateSupplierCompanyModelDTO,
  useCreateSupplierCompanyModel,
} from '../AddCompanyToModelModal/hooks';
import { IModel } from 'types/Model.types';
import { getDataFromPages } from 'utils/getDataFromPages';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';

export interface IAddModelToCompanyModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  companyId: number;
}

export function AddModelToCompanyModal({
  isOpen,
  setIsOpen,
  companyId,
}: IAddModelToCompanyModalProps) {
  const { t } = useTranslation();
  const [searchBy, setSearchBy] = useState<string>('');
  const debouncedSearchByCompany = useDebounce(searchBy);
  const { supplier } = useSelector(
    (state: IRootReducerState) => state.userInfo
  );
  const { isPhone } = useBreakpointFlag();

  const {
    data: modelsData,
    isLoading: isLoadingGetModels,
    isFetching: isFetchingGetModels,
    hasNextPage,
    fetchNextPage,
  } = useGetEligibleModelsForCreatingSupplierCompanyModel(
    ELIGIBLE_MODELS_FOR_CREATING_SUPPLIER_COMPANY_MODEL_PER_PAGE,
    companyId,
    supplier.id,
    debouncedSearchByCompany,
    isOpen
  );

  const models: IModel[] = getDataFromPages(modelsData?.pages, 'models');

  const {
    mutate: createSupplierCompanyModel,
    isLoading: isLoadingCreateSupplierCompanyModel,
  } = useCreateSupplierCompanyModel();

  const handleSelect = (model: IModel) => {
    const dto: ICreateSupplierCompanyModelDTO = {
      companyId: companyId,
      supplierId: supplier.id,
      modelId: model.id,
    };
    createSupplierCompanyModel(dto, { onSuccess: () => setIsOpen(false) });
  };

  useEffect(() => {
    if (!isOpen) {
      setSearchBy('');
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      modalStyle={{
        margin: 'auto',
        width: isPhone ? '90%' : '600rem',
        minWidth: isPhone ? '90%' : 'auto',
        position: 'fixed',
      }}
    >
      <Container>
        <Title>{t('Select model for granting access')}</Title>
        <ScrollableList
          items={models || []}
          isLoading={isLoadingGetModels || isLoadingCreateSupplierCompanyModel}
          noContentLabel={t('No models found')}
          onClick={(model: IModel) => handleSelect(model)}
          onSearch={(str: string) => setSearchBy(str)}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          isFetching={isFetchingGetModels}
        />
      </Container>
    </Modal>
  );
}
