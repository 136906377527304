import { COLORS } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled, { css } from 'styled-components';

interface StyledCheckboxProps {
  isSelected: boolean;
}

export const Container = styled.div`
  width: 100%;
  max-width: 100%;
  position: relative;
  padding-bottom: 10rem;
`;
export const List = styled.div``;
export const OptionWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
export const Option = styled.div`
  display: flex;
  max-width: 50%;
  width: 100%;
  flex-wrap: wrap;
  border: 1px solid ${COLORS.BORDER};
  padding: 10rem;

  ${(props: StyledCheckboxProps) =>
    props.isSelected &&
    css`
      background-color: ${COLORS.BACKGROUND};
    `};

  ${respondTo.bigPhone`
    max-width: 100%;
  `}
`;
export const OptionHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 10rem;
`;
export const OptionHeader = styled.span`
  display: inline-block;
  font-size: 16rem;
  color: ${COLORS.BLACK};
  font-weight: bold;
  min-height: 45rem;
`;
export const OptionValueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 10rem;
`;
export const OptionValue = styled.span`
  display: inline-block;
  font-size: 16rem;
  color: ${COLORS.BLACK};
  min-height: 45rem;
`;

export const CheckboxWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10rem;
`;

export const CheckboxLabel = styled.span`
  display: inline-block;
  font-size: 16rem;
  color: ${COLORS.BLACK};
`;

export const Checkbox = styled.div`
  cursor: pointer;
  width: 24rem;
  height: 24rem;
  border-radius: 2rem;
  align-self: center;
  border: 1px solid ${COLORS.BORDER};
  background-color: ${COLORS.WHITE};
  ${(props: StyledCheckboxProps) =>
    props.isSelected &&
    css`
      border: 1px solid ${COLORS.GREEN};
      background-color: ${COLORS.GREEN} !important;
    `};
`;

export const ButtonsWrapper = styled.div`
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  gap: 30rem;
  justify-content: center;
  background-color: ${COLORS.WHITE};
  padding: 10rem;
  border-top: 1px solid ${COLORS.BORDER};

  ${respondTo.smallPhone`
    gap: 10rem;
  `}
`;
