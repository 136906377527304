import { ChildWrapper } from './Tooltip.styled';
import { Tooltip as MuiTooltip, Zoom } from '@mui/material';
import { COLORS } from 'assets/styled';
import { ReactNode } from 'react';
import { getSanitizedHtmlMsg } from './helpers';

interface ITooltipProps {
  children: ReactNode;
  content: string | React.ReactNode;
  placement?: 'top' | 'bottom' | 'left' | 'right';
}

export const Tooltip = ({
  children,
  content,
  placement = 'top',
}: ITooltipProps) => {
  return (
    <MuiTooltip
      placement={placement}
      enterTouchDelay={0}
      title={
        typeof content === 'string' ? (
          <div
            dangerouslySetInnerHTML={{ __html: getSanitizedHtmlMsg(content) }}
          />
        ) : (
          content
        )
      }
      TransitionComponent={Zoom}
      arrow={true}
      slotProps={{
        popper: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 0],
              },
            },
          ],
        },
      }}
      componentsProps={{
        tooltip: {
          sx: {
            fontSize: '16rem',
            bgcolor: COLORS.WHITE,
            border: `1rem solid ${COLORS.GREEN}`,
            color: COLORS.BLACK,
            borderRadius: '20rem',
            textAlign: 'center',
          },
        },
        arrow: {
          sx: {
            '&::before': {
              backgroundColor: COLORS.WHITE,
              border: `1rem solid ${COLORS.GREEN}`,
            },
          },
        },
      }}
    >
      <ChildWrapper>{children}</ChildWrapper>
    </MuiTooltip>
  );
};

export default Tooltip;
