import { useTranslation } from 'react-i18next';
import { forwardRef } from 'react';
import { Input } from 'components/Input/InputFormik';
import Icon from 'components/Icon/Icon';
import { Circle } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';
import {
  Container,
  Headline,
  Option,
  OptionLabel,
  Options,
} from './Price.styled';
import { IValues } from '../../ConfigForms/RadioForm/RadioForm';

interface PriceProps {
  name: string;
  onBlur: (e: any) => void;
  onFocus: () => void;
  priceType: string;
  amount: number | null;
  setFieldValue: (field: string, value: any) => void;
  optionIndex: number;
  values: IValues;
}

const Price = forwardRef<HTMLDivElement, PriceProps>(
  (
    {
      name,
      onBlur,
      onFocus,
      priceType = 'fixed',
      amount,
      setFieldValue,
      optionIndex,
      values,
    },
    ref
  ) => {
    const { t } = useTranslation();

    const handlePriceTypeChange = (type: string) => {
      const updatedOption = {
        ...values.options[optionIndex],
        price: {
          ...values.options[optionIndex].price,
          price_type: type,
        },
      };
      setFieldValue(`options.${optionIndex}`, updatedOption);
      onBlur({
        target: {
          name: `options.${optionIndex}.price_type`,
          value: type,
        },
      });
    };

    const handleAmountChange = (e: any) => {
      const newAmount = e;
      const updatedOption = {
        ...values.options[optionIndex],
        price: {
          ...values.options[optionIndex].price,
          amount: newAmount,
        },
      };
      setFieldValue(`options.${optionIndex}`, updatedOption);
      onBlur({
        target: {
          name: `options.${optionIndex}.amount`,
          value: e,
        },
      });
    };

    return (
      <Container ref={ref}>
        <Headline>{t('Price')}</Headline>
        <Options>
          <Option onClick={() => handlePriceTypeChange('fixed')}>
            <Icon
              svg={Circle}
              color={COLORS.GREEN}
              weight={priceType === 'fixed' ? 'fill' : 'light'}
              size={25}
            />
            <OptionLabel>€</OptionLabel>
          </Option>
          <Option onClick={() => handlePriceTypeChange('percentage')}>
            <Icon
              svg={Circle}
              color={COLORS.GREEN}
              weight={priceType === 'percentage' ? 'fill' : 'light'}
              size={25}
            />
            <OptionLabel>%</OptionLabel>
          </Option>
        </Options>
        <Input
          name={`${name}.amount`}
          value={amount ?? ''}
          type="number"
          hideNumberArrows
          placeholder={t('Amount')}
          height={'40rem'}
          wrapperStyles={{ width: '100%' }}
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={handleAmountChange}
        />
      </Container>
    );
  }
);

Price.displayName = 'Price';

export default Price;
