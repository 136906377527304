import { useWindowDimensions } from 'utils/hooks/useWindowDimensions';

export const HAS_IMAGES_BREAKPOINT = 1174;

export const useHasImages = (hasImages: boolean) => {
  const { width } = useWindowDimensions();

  if (width <= HAS_IMAGES_BREAKPOINT) {
    return false;
  } else {
    return hasImages;
  }
};
