import { Circle, Container, Line } from './Toggle.styled';

interface IToggleProps {
  isChecked: boolean;
  onClick: () => void;
  pwId: string;
}

const Toggle = ({ isChecked, onClick, pwId }: IToggleProps) => {
  return (
    <Container onClick={onClick} data-testid={pwId}>
      <Line isChecked={isChecked}>
        <Circle isChecked={isChecked} />
      </Line>
    </Container>
  );
};

export default Toggle;
