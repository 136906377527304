import axios, { AxiosError } from 'axios';
import LocalStorageService from 'services/LocalStorageService';
import {
  LOCAL_STORAGE_ACCESS_TOKEN,
  LOCAL_STORAGE_REFRESH_TOKEN,
} from './constants';
import { IRootReducerState, store } from '../../store/store';
import { config } from 'config';
import { LOGOUT } from 'store/constants';
import i18n, { FALLBACK_LOCALE } from 'providers/i18n/i18n';
import { queryClient } from 'index';
import { ISupplier } from 'types/Supplier.types';
import { ICompany } from 'types/Company.types';

const API_URL = config.api.baseUrl;
const AUTH_BASE_URL = API_URL;

const apiClient = axios.create({
  baseURL: API_URL,
});

const apiAuthClient = axios.create({
  baseURL: AUTH_BASE_URL,
});

apiAuthClient.interceptors.request.use(
  async (config) => {
    // Include locale header
    config.headers = {
      'X-Locale': i18n.resolvedLanguage || FALLBACK_LOCALE,
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

apiClient.interceptors.request.use(
  async (config) => {
    const state: IRootReducerState = store.getState();
    const intentUUID = state.submitConfigurationInfo.intentUUID;
    const token = LocalStorageService.getItem(LOCAL_STORAGE_ACCESS_TOKEN);
    if (!token) {
      store.dispatch({ type: LOGOUT });
    }
    // Include authorization and locale headers
    config.headers = {
      Authorization: intentUUID ? `Bearer ${intentUUID}` : `Bearer ${token}`,
      'X-Locale': i18n.resolvedLanguage || FALLBACK_LOCALE,
      // 'ngrok-skip-browser-warning': '69420',
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    try {
      const originalRequest = error.config;
      const currentRefreshToken = LocalStorageService.getItem(
        LOCAL_STORAGE_REFRESH_TOKEN
      );

      const lastRefreshRequest = parseInt(
        LocalStorageService.getItem('LAST_REFRESH_REQUEST_TIMESTAMP') || '0'
      );
      const currentTime = new Date().getTime();
      const hasMinutePassed = currentTime - lastRefreshRequest > 5000;

      if (
        currentRefreshToken &&
        error.response.status === 401 &&
        !originalRequest._retry &&
        hasMinutePassed
      ) {
        originalRequest._retry = true;
        LocalStorageService.setItem(
          'LAST_REFRESH_REQUEST_TIMESTAMP',
          currentTime.toString()
        );
        const {
          supplier,
          company,
        }: {
          supplier: ISupplier | undefined;
          company: ICompany | undefined;
        } = store.getState().userInfo;
        const refreshResponse = await apiAuthClient.post('/v1/refresh', {
          refresh_token: currentRefreshToken,
          supplier_id: supplier?.id,
          company_id: company?.id,
        });
        const { access_token, refresh_token } = refreshResponse.data.data;

        await LocalStorageService.setItem(
          LOCAL_STORAGE_ACCESS_TOKEN,
          access_token
        );
        await LocalStorageService.setItem(
          LOCAL_STORAGE_REFRESH_TOKEN,
          refresh_token
        );

        // Potentially multiple requests failed at the moment of refresh token expiration so refetch everything
        queryClient.invalidateQueries();

        originalRequest.headers['Authorization'] = `Bearer ${access_token}`;
        return axios(originalRequest);
      }
      return Promise.reject(error);
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.response?.status === 401 || err.response?.status === 400) {
          await LocalStorageService.removeItem(LOCAL_STORAGE_ACCESS_TOKEN);
          await LocalStorageService.removeItem(LOCAL_STORAGE_REFRESH_TOKEN);
          store.dispatch({ type: LOGOUT });
        }
        return Promise.reject(err);
      }
    }
  }
);
export { apiAuthClient };
export default apiClient;
