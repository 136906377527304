import { useTranslation } from 'react-i18next';
import {
  BorderWrapper,
  Container,
  Description,
  FieldWrapper,
  FieldWrapperAlt,
  Headline,
  listIconStyle,
  ListInput,
  LongInput,
  NumberInput,
  RadioButton,
  RadioButtons,
  RadioText,
  ShortInput,
  ToggleText,
} from './InputFieldsOptions.styled';
import Icon from 'components/Icon/Icon';
import { COLORS } from 'assets/styled';
import { CaretDown } from '@phosphor-icons/react';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import { useFieldDrag } from './helper';
import { FieldTypes } from '../../../types/Builder.types';

const InputFieldsOptions = () => {
  const { t } = useTranslation();

  const { drag: dragShortText } = useFieldDrag(
    'Short text',
    FieldTypes.ShortText
  );
  const { drag: dragLongText } = useFieldDrag('Long text', FieldTypes.LongText);
  const { drag: dragList } = useFieldDrag('List', FieldTypes.List);
  const { drag: dragNumber } = useFieldDrag('Number', FieldTypes.Number);
  const { drag: dragToggle } = useFieldDrag('Toggle', FieldTypes.Toggle);
  const { drag: dragRadio } = useFieldDrag('Radio', FieldTypes.Radio);

  return (
    <Container>
      <Headline>{t('Input fields')}</Headline>
      <Description>
        {t('Drag and drop these input fields in the product builder')}
      </Description>
      <BorderWrapper topBorder>
        <FieldWrapper ref={dragShortText}>
          <ShortInput>{t('Short text')}</ShortInput>
        </FieldWrapper>
      </BorderWrapper>

      <BorderWrapper>
        <FieldWrapper ref={dragLongText}>
          <LongInput>{t('Long text')}</LongInput>
        </FieldWrapper>
      </BorderWrapper>

      <BorderWrapper>
        <FieldWrapperAlt ref={dragList}>
          <ListInput>{t('List')}</ListInput>
          <Icon
            svg={CaretDown}
            color={COLORS.BLACK}
            size={20}
            weight="regular"
            wrapperStyle={listIconStyle}
          />
        </FieldWrapperAlt>
      </BorderWrapper>

      <BorderWrapper>
        <FieldWrapper ref={dragNumber}>
          <NumberInput>{t('Number')}</NumberInput>
        </FieldWrapper>
      </BorderWrapper>

      <BorderWrapper>
        <FieldWrapper ref={dragToggle}>
          <ToggleText>{t('Toggle')}</ToggleText>
          <ToggleSwitch
            isChecked={false}
            disabled={true}
            size="large"
            onClick={() => null}
          />
        </FieldWrapper>
      </BorderWrapper>

      <BorderWrapper>
        <FieldWrapper ref={dragRadio}>
          <RadioText>{t('Radio')}</RadioText>
          <RadioButtons>
            <RadioButton green />
            <RadioButton />
            <RadioButton />
          </RadioButtons>
        </FieldWrapper>
      </BorderWrapper>
    </Container>
  );
};

export default InputFieldsOptions;
