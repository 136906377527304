import { COLORS } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import { marginLg, marginSm } from 'assets/styled/variables';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 15rem;
`;

export const Logo = styled.img`
  margin-top: 100rem;
  width: 179rem;
  height: 261rem;
  margin-bottom: ${marginLg};

  ${respondTo.smallTablet`
    width: auto;
    height: 200rem;
    margin-bottom: 20rem;
    margin-top: 20rem;
  `};

  ${respondTo.smallPhone`
    height: 120rem;
  `}
`;

export const SelectCompanyLabel = styled.label`
  text-align: center;
  font-size: 50rem;
  line-height: 75rem;
  font-weight: 500rem;
  margin-bottom: ${marginSm};
  color: ${COLORS.BLACK};

  ${respondTo.smallTablet`
    font-size: 40rem;
    margin-bottom: 20rem;
  `}

  ${respondTo.bigPhone`
    font-size: 30rem;
    line-height: 1.2;
  `}
`;

export const RelativeWrapper = styled.div`
  position: relative;
  width: 580rem;
  display: -webkit-flex;
  flex-direction: row;
  justify-content: flex-end;

  ${respondTo.bigPhone`
    width: 100%;
    max-width: 100%;
  `}
`;

export const AbsoluteWrapper = styled.div`
  position: absolute;
  right: 0rem;
  top: -436rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${respondTo.smallTablet`
    top: -320rem;
  `}

  ${respondTo.bigPhone`
    top: -280rem;
  `}

  ${respondTo.smallPhone`
    top: -200rem;
  `}
`;

export const AbsoluteItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50rem;
`;
