import { useTranslation } from 'react-i18next';
import {
  Container,
  ButtonsWrapper,
  FieldName,
  FieldNameWrapper,
  Headline,
  Row,
} from './ProductBuilder.styled';
import { BuilderTabs, ICustomModel } from '../../../types/Builder.types';
import General from './General/General';
import Button from 'components/Button/Button';
import Icon from 'components/Icon/Icon';
import { CaretDown, CaretUp } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';
import InputFields from './InputFields/InputFields';
import Pricing from './Pricing/Pricing';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useCheckForSaveBeforeUserLeave, useSaveDraft } from './hooks';
import { useNavigateWithQuery } from 'utils/hooks/useNavigateWithQuery';
import { RoutesConfig } from 'navigation/routes';
import { useUpdateVersion } from 'pages/SingleCustomModel/hooks';
import { useNavigate } from 'react-router-dom';
import Tooltip from 'components/Tooltip/Tooltip';
import { LeavePageModal } from 'components/Modals/LeavePageModal/LeavePageModal';

interface IProps {
  activeTab: BuilderTabs;
  model: ICustomModel;
  setModel: Dispatch<SetStateAction<ICustomModel>>;
  setSelectedFieldId: Dispatch<SetStateAction<string>>;
  versionId: number;
  modelId: number;
  isModelChanged: boolean;
  resetModelChanged: () => void;
}

const ProductBuilder = ({
  activeTab,
  model,
  setModel,
  setSelectedFieldId,
  versionId,
  modelId,
  isModelChanged,
  resetModelChanged,
}: IProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isAccordionOpen, setIsAccordionOpen] = useState<boolean>(true);
  const [isSaved, setIsSaved] = useState<boolean>(false);
  const prevLocationRef = useRef(location);

  const { mutate: saveDraft } = useSaveDraft(
    model,
    versionId,
    () => {
      confirmNavigation();
      setIsSaved(true);
      resetModelChanged();
    },
    () => {
      cancelNavigation();
    }
  );

  const { mutate: updateVersion, isLoading: isLoadingUpdateVersion } =
    useUpdateVersion(() => {
      navigate(
        `${RoutesConfig.SingleCustomModel.fullPath.replace(
          ':id',
          modelId.toString()
        )}`
      );
    });

  useEffect(() => {
    setIsSaved(false);
  }, [model]);

  useEffect(() => {
    setIsAccordionOpen(true);
  }, [activeTab]);

  const navigateWithQuery = useNavigateWithQuery();

  const handleTest = (versionId: number) => {
    if (!versionId) {
      return;
    }
    const queryParamsObj = {
      editVersionId: versionId?.toString(),
      modelId: modelId.toString(),
    };
    navigateWithQuery(RoutesConfig.Configure.fullPath, {}, queryParamsObj);
  };

  const {
    showPrompt,
    confirmNavigation,
    cancelNavigation,
    setCheckNavigation,
  } = useCheckForSaveBeforeUserLeave(isModelChanged);

  useEffect(() => {
    return () => {
      if (!prevLocationRef.current.pathname.includes('builder')) {
        // Unlock version when leave builder
        updateVersion({
          id: versionId,
          locked: false,
          redirectAfterSuccess: false,
        });
      }
    };
  }, []);

  useEffect(() => {
    prevLocationRef.current = location;
  }, [location]);

  return (
    <Container>
      <Row>
        <Headline>{t('Product Builder')}</Headline>
        <ButtonsWrapper>
          <Tooltip
            content={
              !model.general.name || !model.input_fields.length
                ? t('Version name and at least one input field is required')
                : undefined
            }
          >
            <Button
              secondary
              width="150rem"
              onClick={() => {
                saveDraft();
              }}
              disabled={!model.general.name || !model.input_fields.length}
              label={t('Save Draft')}
            />
          </Tooltip>
          <Tooltip content={!isSaved ? t('Save before publish') : undefined}>
            <Button
              primary
              width="150rem"
              onClick={() => {
                if (!isLoadingUpdateVersion)
                  updateVersion({
                    id: versionId,
                    publish: true,
                    redirectAfterSuccess: true,
                  });
              }}
              label={t('Publish Version')}
              disabled={!isSaved}
            />
          </Tooltip>
        </ButtonsWrapper>
      </Row>
      <Row>
        <FieldNameWrapper onClick={() => setIsAccordionOpen((prev) => !prev)}>
          <FieldName>{t(activeTab)}</FieldName>
          <Icon
            svg={isAccordionOpen ? CaretUp : CaretDown}
            color={COLORS.BLACK}
            size={20}
            weight="regular"
          />
        </FieldNameWrapper>
        <Tooltip content={!isSaved ? t('Save before test') : undefined}>
          <Button
            secondary
            width="150rem"
            onClick={() => {
              if (isSaved) {
                handleTest(versionId);
              }
            }}
            label={t('Test model')}
            disabled={!isSaved}
          />
        </Tooltip>
      </Row>

      {(() => {
        switch (activeTab) {
          case BuilderTabs.General:
            return (
              <General
                isAccordionOpen={isAccordionOpen}
                model={model}
                setModel={setModel}
              />
            );
          case BuilderTabs.InputFields:
            return (
              <InputFields
                isAccordionOpen={isAccordionOpen}
                model={model}
                setModel={setModel}
                setSelectedFieldId={setSelectedFieldId}
              />
            );
          case BuilderTabs.Pricing:
            return (
              <Pricing
                isAccordionOpen={isAccordionOpen}
                model={model}
                setModel={setModel}
              />
            );
          default:
            return null;
        }
      })()}

      <LeavePageModal
        isOpen={showPrompt}
        setIsOpen={setCheckNavigation}
        onX={() => cancelNavigation()}
        onYes={() => saveDraft()}
        onNo={() => confirmNavigation()}
        title={t('Save your work')}
        description={t('Do you want to save version before you leave?')}
      />
    </Container>
  );
};

export default ProductBuilder;
