import { FormikContextType, useFormikContext } from 'formik';
import { CSSProperties, HTMLProps, MouseEventHandler, useRef } from 'react';
import {
  Container,
  ErrorContainer,
  ErrorMessage,
  InputLabel,
  StyledInput,
  StyledTextArea,
} from './InputFormik.styled';
import NumberInputArrows from './NumberInputArrows/NumberInputArrows';
import { IconWrap, StyledIcon } from './Input.styled';
import { COLORS } from 'assets/styled';

interface IInput extends HTMLProps<HTMLInputElement> {
  label?: string;
  styles?: CSSProperties;
  errorMessage?: string;
  wrapperStyles?: CSSProperties;
  validate?: (value: string, fieldName: string) => void;
  isTextArea?: boolean;
  icon?: any;
  handleOnChange?: (value: string) => void;
  placeholder: string;
  onIncrease?: (value: string) => void; // Used when type "number" is provided
  onDecrease?: (value: string) => void; // Used when type "number" is provided
  onMouseEnter?: MouseEventHandler<HTMLInputElement>; // Used when type "number" is provided
  onMouseLeave?: MouseEventHandler<HTMLInputElement>; // Used when type "number" is provided
  hideNumberArrows?: boolean;
}

export const Input = ({
  errorMessage,
  type,
  styles,
  wrapperStyles,
  label,
  validate,
  isTextArea,
  handleOnChange,
  onIncrease,
  onDecrease,
  onMouseEnter,
  onMouseLeave,
  hideNumberArrows = false,
  ...props
}: IInput) => {
  const context: FormikContextType<any> = useFormikContext();
  const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null);

  return (
    <Container
      wrapperStyles={wrapperStyles}
      onClick={() => {
        inputRef?.current?.focus();
      }}
    >
      {label && (
        <InputLabel type={props} htmlFor={props.name}>
          {label}
        </InputLabel>
      )}

      {isTextArea ? (
        <StyledTextArea
          {...props}
          component={'textarea'}
          innerRef={inputRef}
          validate={validate}
          placeholder={props.placeholder}
          type={type || 'text'}
          styles={styles}
          errormessage={errorMessage}
          onChange={(e: any) => {
            context.setFieldValue(props.name!, e.target.value);
            props.onChange && props.onChange(e.target.value);
            handleOnChange && handleOnChange(e.target.value);
          }}
          onBlur={(e: any) => {
            props.onBlur && props.onBlur(e);
          }}
        />
      ) : (
        <>
          <StyledInput
            {...props}
            innerRef={inputRef}
            validate={validate}
            type={type || 'text'}
            styles={styles}
            placeholder={props.placeholder}
            // Prevent user to type 'e'
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (type === 'number' && (e.key === 'e' || e.key === 'E')) {
                e.preventDefault();
              }
              props.onKeyDown && props.onKeyDown(e);
            }}
            onChange={(e: any) => {
              context.setFieldValue(props.name!, e.target.value);
              props.onChange && props.onChange(e.target.value);
              handleOnChange && handleOnChange(e.target.value);
            }}
            errormessage={errorMessage}
            onBlur={(e: any) => {
              props.onBlur && props.onBlur(e);
            }}
          />
          {props.icon && (
            <IconWrap>
              <StyledIcon
                svg={props.icon}
                size={20}
                fill={COLORS.BLACK}
                pointer={false}
                weight="regular"
              />
            </IconWrap>
          )}
          {type === 'number' &&
            onIncrease &&
            onDecrease &&
            !hideNumberArrows && (
              <NumberInputArrows
                onIncrease={onIncrease}
                onDecrease={onDecrease}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
              />
            )}
        </>
      )}
      <ErrorContainer>
        <ErrorMessage>{errorMessage}</ErrorMessage>
      </ErrorContainer>
    </Container>
  );
};
