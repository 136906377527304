import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { removeGrossAndNetSuffix } from './helpers';
import { useIsMutating } from 'react-query';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { useLocation } from 'react-router-dom';
import { ITestDealerPanelConfigurationNavigationState } from 'pages/DealerPanel/DealerPanelModelsPage/SelectVATAmountModal/SelectVATAmountModal';

export interface IPricingItem {
  description: string;
  document_link_code: string;
  question: string;
  type: string;
  value: number;
}

export interface IFormattedPricingItem {
  key: string;
  grossItem: IPricingItem;
  netItem: IPricingItem;
  name: string;
}

export const useCalculateAndFormatPrices = (
  pricing: Record<string, any> | null
): [number, number, number, number, IFormattedPricingItem[], boolean] => {
  let isPricingValid = true;
  if (!pricing) {
    return [0, 0, 0, 0, [], true];
  }

  const formattedPrices: IFormattedPricingItem[] = [];
  let totalBaseNetPrice = 0;
  let totalBaseGrossPrice = 0;
  let totalNetPrice = 0;
  let totalGrossPrice = 0;

  for (const pricingKey of Object.keys(pricing)) {
    const pricingItem = pricing[pricingKey];
    if (pricingItem.value === 'Invalid') {
      isPricingValid = false;
      continue;
    }

    if (pricingItem.type.includes('net')) {
      totalNetPrice += pricingItem.value;
      if (pricingItem.type === 'net_base') {
        totalBaseNetPrice += pricingItem.value;
      }
    }

    if (pricingItem.type.includes('gross')) {
      totalGrossPrice += pricingItem.value;
      if (pricingItem.type === 'gross_base') {
        totalBaseGrossPrice += pricingItem.value;
      }
    }

    const formattedPricingItem = formattedPrices.find(
      (formattedPrice) =>
        formattedPrice.key === removeGrossAndNetSuffix(pricingKey)
    );

    if (formattedPricingItem) {
      if (pricingItem.type.includes('net')) {
        formattedPricingItem.netItem = pricingItem;
      } else if (pricingItem.type.includes('gross')) {
        formattedPricingItem.grossItem = pricingItem;
      }
    } else {
      const newFormattedPricingItem: IFormattedPricingItem = {
        key: removeGrossAndNetSuffix(pricingKey),
        grossItem: pricingItem.type.includes('gross') ? pricingItem : 0,
        netItem: pricingItem.type.includes('net') ? pricingItem : 0,
        name: pricingItem.name,
      };
      formattedPrices.push(newFormattedPricingItem);
    }
  }

  return [
    totalBaseNetPrice,
    totalBaseGrossPrice,
    totalNetPrice,
    totalGrossPrice,
    formattedPrices,
    isPricingValid,
  ];
};

export const useShouldRoundNumbers = () => {
  const shouldRoundNumbers = useSelector(
    (state: IRootReducerState) => state.modelInfo.roundPricing
  );
  return shouldRoundNumbers;
};

export const useIsEditOrAddToShoppingCartRequestInProgress = () => {
  const isAddingToShoppingCart = useIsMutating({
    mutationKey: ReactMutationKeys.ADD_TO_SHOPPING_CART,
  });
  const isEditingShoppingCartItem = useIsMutating({
    mutationKey: ReactMutationKeys.EDIT_SHOPPING_CART_ITEM,
  });

  return isAddingToShoppingCart || isEditingShoppingCartItem;
};
