import { OptionProps } from 'react-select';
import { components } from 'react-select';
import { ImageSmall, OptionContainer } from './ImageSmallOption.styled';
import { adjustImageSourceToUseS3ImageTransformator } from 'utils/adjustImageSourceToUseS3ImageTransformator';

export const ImageSmallOption = (props: OptionProps<any>) => {
  return (
    <components.Option {...props}>
      <OptionContainer data-testid={props.data.pwId}>
        <div data-testid={props.data.pwIdLabel}>{props.data.label}</div>
        <ImageSmall
          src={adjustImageSourceToUseS3ImageTransformator(
            props.data.small_image,
            174
          )}
          alt={'img'}
        />
      </OptionContainer>
    </components.Option>
  );
};
