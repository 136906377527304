import { queryClient } from 'index';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import {
  deleteSupplierCompanyModel,
  enableModelOrderingForAllSupplierCompanies,
  enableModelQueryingForAllSupplierCompanies,
} from 'services/SupplierCompanyModelsService';
import { LogyxError, getToastErrorMessage } from 'services/api/errors';

import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';

export interface IEnableOrderingOrQueryingForAllSupplierCompanies {
  modelId: number;
  supplierId: number;
}

export const useEnableModelOrderingForAllSupplierCompanies = () => {
  const { t } = useTranslation();
  return useMutation(
    (params: IEnableOrderingOrQueryingForAllSupplierCompanies) => {
      return enableModelOrderingForAllSupplierCompanies(
        params.modelId,
        params.supplierId
      );
    },
    {
      onSuccess: () => {
        toast.success(t('Enabled ordering'));
      },
      onError: (error: LogyxError) => {
        toast.error(getToastErrorMessage(error));
      },
      mutationKey:
        ReactMutationKeys.ENABLE_MODEL_ORDERING_FOR_ALL_SUPPLIER_COMPANIES,
    }
  );
};

export const useEnableModelQueryingForAllSupplierCompanies = () => {
  const { t } = useTranslation();
  return useMutation(
    (params: IEnableOrderingOrQueryingForAllSupplierCompanies) => {
      return enableModelQueryingForAllSupplierCompanies(
        params.modelId,
        params.supplierId
      );
    },
    {
      onSuccess: () => {
        toast.success(t('Enabled querying'));
      },
      onError: (error: LogyxError) => {
        toast.error(getToastErrorMessage(error));
      },
      mutationKey:
        ReactMutationKeys.ENABLE_MODEL_QUERYING_FOR_ALL_SUPPLIER_COMPANIES,
    }
  );
};

export const useDeleteSupplierCompanyModel = () => {
  const { t } = useTranslation();
  return useMutation(
    (supplierCompanyModelId: number) => {
      return deleteSupplierCompanyModel(supplierCompanyModelId);
    },
    {
      onSuccess: () => {
        toast.success(t('Company access revoked'));
        queryClient.invalidateQueries([
          ReactQueryKeys.GET_SUPPLIER_COMPANY_MODELS,
        ]);
        queryClient.invalidateQueries([
          ReactQueryKeys.GET_ELIGIBLE_COMPANIES_FOR_CREATING_SUPPLIER_COMPANY_MODEL,
        ]);
        queryClient.invalidateQueries([
          ReactQueryKeys.GET_ELIGIBLE_MODELS_FOR_CREATING_SUPPLIER_COMPANY_MODEL,
        ]);
      },
      onError: (error: LogyxError) => {
        toast.error(getToastErrorMessage(error));
      },
      mutationKey: ReactMutationKeys.DELETE_SUPPLIER_COMPANY_MODEL,
    }
  );
};
