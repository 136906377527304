import { COLORS } from 'assets/styled';
import styled, { css } from 'styled-components';
import { MODEL_ROW_CONTAINER_HEIGHT } from './ModelRow/ModelRow.styled';
import { PAGINATION_HEIGHT } from 'components/Pagination/Pagination.styled';
import { Skeleton } from '@mui/material';
import Icon from 'components/Icon/Icon';
import { respondTo } from 'assets/styled/breakpoints';

interface StyledProps {
  isExpanded: boolean;
  numberOfModelsInCategory: number;
  withoutLine: boolean;
  hasPagination: boolean;
  opacityIndex: number;
  opacityCoeficient: number;
}

export const Container = styled.div`
  background-color: ${COLORS.WHITE};
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  ${(props: StyledProps) =>
    !props.withoutLine &&
    css`
      border-bottom: 1rem solid ${COLORS.BORDER};
    `}
  ${(props: StyledProps) =>
    props.opacityIndex !== undefined &&
    css`
      background-color: ${COLORS.WHITE};
      opacity: ${1 - (props.opacityIndex * props.opacityCoeficient + 0.2)};
    `}
`;

export const CategoryNameLabel = styled.label`
  font-weight: 400;
  font-size: 18rem;
  line-height: 24.5rem;
  color: ${COLORS.BLACK};

  ${respondTo.bigPhone`
    font-size: 16rem;
  `}
`;

export const CategoryNameAndChevronWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 84rem;
  cursor: pointer;
  user-select: none;
`;

export const StyledIcon = styled(Icon)`
  width: 30rem;
  height: 30rem;
  cursor: pointer;
  ${(props: StyledProps) =>
    props.isExpanded &&
    css`
      transform: rotate(90deg);
    `}
`;

export const ModelsWrapper = styled.div<{ isExpanded: boolean }>`
  max-height: ${(props: StyledProps) => {
    let totalConfigurationsHeightInt =
      props.numberOfModelsInCategory * MODEL_ROW_CONTAINER_HEIGHT;
    if (props.hasPagination) {
      totalConfigurationsHeightInt =
        totalConfigurationsHeightInt +
        PAGINATION_HEIGHT +
        PAGINATION_MARGIN_BOTTOM;
    }
    return props.isExpanded
      ? css`
          ${totalConfigurationsHeightInt}rem
        `
      : '0';
  }};
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
`;

const PAGINATION_MARGIN_BOTTOM = 20;
export const PaginationWrapper = styled.div`
  margin-bottom: ${PAGINATION_MARGIN_BOTTOM}rem;
`;

export const NoContentLabel = styled.label`
  width: 100%;
  text-align: center;
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
  height: ${MODEL_ROW_CONTAINER_HEIGHT}rem;
  color: ${COLORS.BLACK};

  ${respondTo.bigPhone`
    font-size: 16rem;
  `}
`;

export const CircleSkeleton = styled(Skeleton)`
  border-radius: 13rem;
`;

export const RowGap = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10rem;
  align-items: center;
`;
