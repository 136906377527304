import { Dispatch, SetStateAction } from 'react';
import { IColumnSort, SortOrder } from './types';
import {
  ArrowsDownUp,
  SortAscending,
  SortDescending,
} from '@phosphor-icons/react';

export const getSortedTableRows = (
  tableRows: any[],
  columnSort: IColumnSort
) => {
  const headerToPropertyMap: { [key: number]: string } = {
    0: 'id',
    1: 'name',
    2: 'length',
    3: 'quantity_per',
    4: 'routing_link_code',
  };

  const compare = (a: any, b: any): number => {
    if (columnSort.columnIndex !== null) {
      const property = headerToPropertyMap[columnSort.columnIndex];
      let aValue = a[property];
      let bValue = b[property];

      // Attempt to parse as numbers
      if (!isNaN(parseFloat(aValue)) && isFinite(aValue)) {
        aValue = parseFloat(aValue);
      }
      if (!isNaN(parseFloat(bValue)) && isFinite(bValue)) {
        bValue = parseFloat(bValue);
      }

      // Compare
      if (typeof aValue === 'number' && typeof bValue === 'number') {
        return aValue - bValue;
      } else {
        return String(aValue).localeCompare(String(bValue));
      }
    } else {
      return -1;
    }
  };

  switch (columnSort.sortOrder) {
    case SortOrder.NONE:
      return tableRows;
    case SortOrder.ASC:
      return tableRows ? tableRows.sort(compare) : tableRows;
    case SortOrder.DESC:
      return tableRows ? tableRows.sort(compare).reverse() : tableRows;
  }
};

export const handleSortPress = (
  columnIndex: number,
  columnSort: IColumnSort,
  setColumnSort: Dispatch<SetStateAction<IColumnSort>>
) => {
  const newColumnSort: IColumnSort = {
    columnIndex: columnIndex,
    sortOrder: SortOrder.NONE,
  };
  switch (columnSort.sortOrder) {
    case SortOrder.NONE:
      newColumnSort.sortOrder = SortOrder.DESC;
      break;
    case SortOrder.DESC:
      newColumnSort.sortOrder = SortOrder.ASC;
      break;
    case SortOrder.ASC:
      newColumnSort.columnIndex = null;
      newColumnSort.sortOrder = SortOrder.NONE;
      break;
  }
  setColumnSort(newColumnSort);
};

export const getArrowImageFromColumnSortOption = (
  columnIndex: number,
  columnSort: IColumnSort
) => {
  if (columnIndex === columnSort.columnIndex) {
    switch (columnSort.sortOrder) {
      case SortOrder.NONE:
        return ArrowsDownUp;
      case SortOrder.ASC:
        return SortAscending;
      case SortOrder.DESC:
        return SortDescending;
    }
  } else {
    return ArrowsDownUp;
  }
};
