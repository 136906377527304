import Button from 'components/Button/Button';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ButtonsWrapper,
  Container,
  DropContainer,
  IconWrap,
  ImgInnerWrap,
  ImgPreview,
  ImgWrapper,
  Text,
  UploadFromComputer,
  ButtonContainer,
} from './FileUploadModal.styled';
import { useDropzone } from 'react-dropzone';
import { allowedTypes } from './constants';
import FileIcon from 'assets/images/file-icon.png';
import CSVIcon from 'assets/images/csv-icon.png';
import XLSIcon from 'assets/images/xls-icon.png';
import XLSXIcon from 'assets/images/xlsx-icon.png';
import PDFIcon from 'assets/images/pdf-icon.png';
import Icon from 'components/Icon/Icon';
import { compressImage, fileRejectHandler } from '../../FileUploader/helpers';
import { ReactComponent as DragAndDropIcon } from 'assets/icons/draganddrop.svg';
import { COLORS } from 'assets/styled';
import { Modal } from '../Modal/Modal';
import { toast } from 'react-toastify';
import { Trash } from '@phosphor-icons/react';
import { Level } from '../Modal/type';

const maxSize = 32000000; // 32MB

export interface IFileUploadModalProps {
  onSubmit: any;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isUploadProfilePicture?: boolean;
  multiple?: boolean;
  customAllowedTypes?: any;
  level?: Level;
}

export function FileUploadModal({
  onSubmit,
  isOpen,
  isUploadProfilePicture = false,
  setIsOpen,
  multiple = false,
  customAllowedTypes,
  level = 'FIRST',
}: IFileUploadModalProps) {
  const { t } = useTranslation();
  const [files, setFiles] = useState<any>([]);
  const [isFileRejected, setIsFileRejected] = useState<boolean>(false);
  const [isCompressing, setIsCompressing] = useState<boolean>(false);

  const onDrop = useCallback(
    async (acceptedFiles: any, fileRejections: any) => {
      if (fileRejections.length) {
        setIsFileRejected(true);
        fileRejectHandler(fileRejections, t);
      }

      setIsFileRejected(false);
      setIsCompressing(true);

      try {
        const compressedFiles = await Promise.all(
          acceptedFiles.map(compressImage)
        );

        if (isUploadProfilePicture) {
          const profilePicUrl = URL.createObjectURL(compressedFiles[0]);
          setFiles([profilePicUrl]);
        } else if (!multiple) {
          setFiles([compressedFiles[0]]);
        } else {
          setFiles((prevFiles: any) => [...prevFiles, ...compressedFiles]);
        }
      } catch (error) {
        toast.error(t('Something went wrong with File'));
      } finally {
        setIsCompressing(false);
      }
    },
    [isUploadProfilePicture]
  );

  const removeFile = (fileToRemove: any) => {
    setFiles((currentFiles: any[]) =>
      currentFiles.filter((file) => file !== fileToRemove)
    );
  };

  const onDropRejected = useCallback(() => {
    setIsFileRejected(true);
  }, []);

  const handleCancel = () => {
    setFiles([]);
    setIsOpen(false);
  };

  const handleSubmit = () => {
    if (files.length) {
      onSubmit(files);
      setFiles([]);
      setIsOpen(false);
    }
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive: dropzoneDragActive,
  } = useDropzone({
    onDrop,
    onDropRejected,
    accept: customAllowedTypes || allowedTypes,
    maxSize,
    multiple: multiple,
  });

  return (
    <Modal
      isOpen={isOpen}
      showCloseIcon={false}
      onClose={() => {
        setFiles([]);
        setIsOpen(false);
      }}
      level={level}
      modalStyle={{ position: 'fixed', margin: 'auto' }}
    >
      <Container>
        <DropContainer
          {...getRootProps()}
          isDragActive={dropzoneDragActive}
          isFileRejected={isFileRejected}
        >
          <input {...getInputProps()} />
          {dropzoneDragActive ? (
            <>
              {!files.length && (
                <Icon svg={DragAndDropIcon} size={150} color={COLORS.GREEN} />
              )}
              <Text>{t('Drop the file here ...')}</Text>
            </>
          ) : (
            <>
              {!files.length && (
                <Icon svg={DragAndDropIcon} size={150} color={COLORS.GREEN} />
              )}
              <Text>{t('Drag and drop the file or')}</Text>
              <UploadFromComputer>
                {t('Upload from your computer')}
              </UploadFromComputer>
              {files.length > 0 && (
                <ImgWrapper>
                  {files.map((file: any, index: number) => {
                    let src;

                    if (isUploadProfilePicture) {
                      src = file;
                    } else {
                      if (file?.type?.startsWith('image/')) {
                        src = URL.createObjectURL(file);
                      } else if (file?.type === 'application/pdf') {
                        src = PDFIcon;
                      } else if (file?.type === 'text/csv') {
                        src = CSVIcon;
                      } else if (file?.type === 'application/vnd.ms-excel') {
                        src = XLSIcon;
                      } else if (
                        file?.type ===
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                      ) {
                        src = XLSXIcon;
                      } else {
                        src = FileIcon;
                      }
                    }

                    return (
                      <ImgInnerWrap key={file?.lastModified + index}>
                        <ImgPreview src={src} />
                        <IconWrap
                          onClick={(e: any) => {
                            e.stopPropagation();
                            removeFile(file);
                          }}
                        >
                          <Icon svg={Trash} size={25} color={COLORS.RED} />
                        </IconWrap>
                      </ImgInnerWrap>
                    );
                  })}
                </ImgWrapper>
              )}
            </>
          )}
        </DropContainer>
        <ButtonsWrapper>
          <ButtonContainer>
            <Button
              label={t('Cancel')}
              secondary
              disabled={false}
              width="200rem"
              onClick={() => handleCancel()}
            />
          </ButtonContainer>
          <ButtonContainer>
            <Button
              label={t('Continue')}
              primary
              disabled={files.length === 0 || isCompressing}
              width="200rem"
              onClick={() => {
                handleSubmit();
              }}
              isLoading={isCompressing} // true
            />
          </ButtonContainer>
        </ButtonsWrapper>
      </Container>
    </Modal>
  );
}
