import { COLORS } from 'assets/styled';
import styled from 'styled-components';

export const Container = styled.div``;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${COLORS.BORDER};
  padding-bottom: 20rem;

  &:not(:first-of-type) {
    padding-top: 20rem;
  }

  &:last-of-type {
    border-bottom: none;
    padding-bottom: 0;
  }
`;

export const FieldName = styled.label`
  font-size: 18rem;
  color: ${COLORS.BLACK};
  font-weight: 400;
`;

export const TextAreaWrapper = styled.div`
  max-width: 440rem;
  width: 100%;
`;

export const SelectWrapper = styled.div`
  width: 250rem;
`;
