import {
  AbsoluteCircleWrapper,
  Container,
  IconWrapper,
  NameLabel,
  ReferenceLabel,
  RowCenter,
  imageStyle,
} from './CompanyCard.styled';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { formatCompanyName } from './helpers';
import { ICompany } from 'types/Company.types';
import { RoutesConfig } from 'navigation/routes';
import { ISupplierCompany } from 'types/SupplierCompany';
import Icon from 'components/Icon/Icon';
import {
  AdaptiveImage,
  AdaptiveImageType,
} from 'components/AdaptiveImage/AdaptiveImage';
import { Trash } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';

interface ICompanyCardProps {
  company: ICompany;
  onDelete: (supplierCompanyId: number) => void;
}

const CompanyCard = ({ company, onDelete }: ICompanyCardProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const supplierCompany: ISupplierCompany | undefined = company
    .supplier_companies?.length
    ? company.supplier_companies[0]
    : undefined;

  return (
    <Container data-testid={`company-card-${company.id}`}>
      {supplierCompany?.supplier_company_reference ? (
        <AbsoluteCircleWrapper>
          <ReferenceLabel>
            {supplierCompany?.supplier_company_reference}
          </ReferenceLabel>
        </AbsoluteCircleWrapper>
      ) : null}
      <AdaptiveImage
        imageUrl={company.logo_url}
        type={AdaptiveImageType.COMPANY}
        imageStyle={imageStyle}
        absoluteComponent={
          <IconWrapper>
            <Icon
              onClick={() =>
                supplierCompany && onDelete(Number(supplierCompany.id))
              }
              svg={Trash}
              color={COLORS.RED_100}
            />
          </IconWrapper>
        }
        onClick={() =>
          navigate(
            RoutesConfig.SupplierSingleCompany.fullPath.replace(
              ':id',
              String(supplierCompany?.id)
            )
          )
        }
      />
      <RowCenter>
        <NameLabel>
          {company.name
            ? formatCompanyName(company.name)
            : t('Unnamed company')}
        </NameLabel>
      </RowCenter>
    </Container>
  );
};
export default CompanyCard;
