import { useTranslation } from 'react-i18next';
import { useAddCompanyUser, useDeleteCompanyUser } from './hooks';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { useState } from 'react';
import { useDebounce } from 'utils/hooks/useDebounce';
import { EmailInputModal } from 'components/Modals/EmailInputModal/EmailInputModal';
import { DangerModal } from 'components/Modals/DangerModal/DangerModal';
import { ViewType } from 'components/ViewTypePicker/ViewTypePicker';
import { CompanyUsersListView } from './CompanyUsersListView/CompanyUsersListView';
import { CompanyUsersGridView } from './CompanyUsersGridView/CompanyUsersGridView';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';
import { useSelectedViewType } from '../hooks';

const CompanyUsers = () => {
  const { isMidTablet } = useBreakpointFlag();
  const { selectedViewType } = useSelectedViewType(isMidTablet);
  const { t } = useTranslation();
  const company = useSelector(
    (state: IRootReducerState) => state.userInfo.company
  );

  const [searchBy, setSearchBy] = useState<string>('');
  const debouncedSearchBy = useDebounce(searchBy);
  const [isEmailInputModalOpen, setIsEmailInputModalOpen] =
    useState<boolean>(false);
  const [isDeleteUserModalOpen, setIsDeleteUserModalOpen] =
    useState<boolean>(false);
  const [userIdForDeletion, setUserIdForDeletion] = useState<number | null>(
    null
  );

  const { mutate: addCompanyUser } = useAddCompanyUser(company.id);
  const { mutate: deleteCompanyUser, isLoading: isLoadingDeleteCompanyUser } =
    useDeleteCompanyUser(company.id);

  return (
    <>
      {selectedViewType === ViewType.LIST ? (
        <CompanyUsersListView
          searchBy={searchBy}
          setSearchBy={setSearchBy}
          debouncedSearchBy={debouncedSearchBy}
          onDelete={(userId: number) => {
            setUserIdForDeletion(userId);
            setIsDeleteUserModalOpen(true);
          }}
          setIsEmailInputModalOpen={setIsEmailInputModalOpen}
        />
      ) : (
        <CompanyUsersGridView
          searchBy={searchBy}
          setSearchBy={setSearchBy}
          debouncedSearchBy={debouncedSearchBy}
          onDelete={(userId: number) => {
            setUserIdForDeletion(userId);
            setIsDeleteUserModalOpen(true);
          }}
          setIsEmailInputModalOpen={setIsEmailInputModalOpen}
        />
      )}
      <EmailInputModal
        isOpen={isEmailInputModalOpen}
        setIsOpen={setIsEmailInputModalOpen}
        onConfirm={(email: string) => addCompanyUser(email)}
        title={t(
          'Enter E-mail of the user that you wish to add to {{ENTITY}}'
        ).replace('{{ENTITY}}', company.name)}
      />
      <DangerModal
        isOpen={isDeleteUserModalOpen}
        setIsOpen={setIsDeleteUserModalOpen}
        title={t('Confirm removal')}
        submit={{
          onClick: () => {
            deleteCompanyUser(userIdForDeletion!);
            setIsDeleteUserModalOpen(false);
            setUserIdForDeletion(null);
          },
          text: t('Remove'),
          disabled: isLoadingDeleteCompanyUser,
        }}
        cancel={{
          onClick: () => {
            setIsDeleteUserModalOpen(false);
            setUserIdForDeletion(null);
          },
        }}
        description={t(
          'Are you sure you want to remove this User from this company?'
        )}
      />
    </>
  );
};

export default CompanyUsers;
