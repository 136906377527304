export interface LogyxError {
  error: {
    message: string;
  };
  response: LogyxErrorResponse;
}

interface LogyxErrorResponse {
  data: {
    code: number;
    message: string;
    data: Record<string, unknown>;
    errors: string[];
  };
  status: number;
}

export const DEFAULT_ERROR_MESSAGE =
  'Oops! Something went wrong. Please refresh the page and try again.';

export const getToastErrorMessage = (error: LogyxError) => {
  if (error.response?.data?.message === 'Unprocessable entity') {
    return handleUnprocessableEntityErrorMessage(error);
  } else {
    return handleGeneralErrorMessage(error);
  }
};

// TODO: revisit
// This handles errors of type flask marshmallow 422 ValidationErrors
// Pure UnprocessableEntity error responses will need to be handled differently
const handleUnprocessableEntityErrorMessage = (error: any) => {
  if (
    !error.response ||
    !error.response.data ||
    !error.response.data.errors ||
    error.response.data.errors.length === 0
  ) {
    return DEFAULT_ERROR_MESSAGE;
  }

  const errorMessages: string[] = [];

  error.response.data.errors.forEach((errorString: string) => {
    try {
      // Convert single-quoted JSON to valid JSON by replacing single quotes and parsing it
      const correctedJsonString = errorString.replace(/'/g, '"');
      const errorsJson = JSON.parse(correctedJsonString);

      // Extract messages from each key in the parsed object
      Object.keys(errorsJson).forEach((errorsKey: string) => {
        const messages = errorsJson[errorsKey];
        messages.forEach((message: string) => {
          errorMessages.push(`${errorsKey}: ${message}; \n`);
        });
      });
    } catch (e) {
      // Error parsing JSON
      return DEFAULT_ERROR_MESSAGE;
    }
  });

  const errorMessage = errorMessages.join(', ');

  return errorMessage || DEFAULT_ERROR_MESSAGE;
};

const handleGeneralErrorMessage = (error: any) => {
  try {
    if (
      !error.response?.data &&
      !error.response.data.message &&
      !error.response.data.errors &&
      !error.response.data.errors?.length
    ) {
      return DEFAULT_ERROR_MESSAGE;
    }

    return (
      error.response.data.errors[0]?.message ||
      error.response.data.errors[0] ||
      error.response.data.message
    );
  } catch (e) {
    console.log('handleGeneralErrorMessage error: ', e);
  }
};
