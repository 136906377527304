import { usePageTitle } from 'utils/hooks/usePageTitle';
import {
  Container,
  InputWrapper,
  ListItem,
  ListOptions,
  ModelRowsContainer,
  NoContentLabel,
  NoContentLabelWrapper,
  RightSide,
  Title,
  TopSection,
} from './CustomModels.styled';
import { useTranslation } from 'react-i18next';
import Input from 'components/Input/Input';
import { useState } from 'react';
import { useDebounce } from 'utils/hooks/useDebounce';
import { MagnifyingGlass as Search } from '@phosphor-icons/react';
import Button from 'components/Button/Button';
import { MODELS_PER_PAGE } from './constants';
import { useGetModels } from 'pages/AllModelsPage/AllModelsListView/hooks';
import { IModel } from 'types/Model.types';
import Pagination from 'components/Pagination/Pagination';
import ModelRow from './ModelRow/ModelRow';
import ModelRowSkeleton from './ModelRow/ModelRowSkeleton/ModelRowSkeleton';
import { DangerModal } from 'components/Modals/DangerModal/DangerModal';
import { useNavigate } from 'react-router-dom';
import { useCreateCustomModel } from './hooks';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import {
  useArchiveCustomModel,
  useRestoreCustomModel,
} from 'pages/SingleCustomModel/hooks';
import { CTAModal } from 'components/Modals/CTAModal/CTAModal';

const CustomModels = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchBy, setSearchBy] = useState<string>('');
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState<boolean>(false);
  const [archivedModelOption, setArchivedModelOption] =
    useState<boolean>(false);
  const debouncedSearchBy = useDebounce(searchBy);
  const [page, setPage] = useState<number>(1);
  const [archivedModelId, setArchivedModelId] = useState<number | null>(null);
  const [archiveModelName, setArchiveModelName] = useState<string>('');
  const [restoreModelId, setRestoreModelId] = useState<number | null>(null);
  const [restoreModelName, setRestoreModelName] = useState<string>('');
  const [isCTAModalOpen, setIsCTAModalOpen] = useState<boolean>(false);
  const { data: modelsData, isLoading: isLoadingModels } = useGetModels(
    page,
    MODELS_PER_PAGE,
    debouncedSearchBy,
    null,
    null,
    false,
    false,
    archivedModelOption,
    true
  );

  const { supplier } = useSelector(
    (state: IRootReducerState) => state.userInfo
  );

  const { mutate: createCustomModel } = useCreateCustomModel(supplier?.id);
  const { mutate: archiveCustomModel } = useArchiveCustomModel();
  const { mutate: restoreCustomModel } = useRestoreCustomModel();

  usePageTitle('Custom models');
  const models: IModel[] = modelsData?.models || [];

  const onArchiveClick = (id: number, name: string) => {
    setIsArchiveModalOpen(true);
    setArchiveModelName(name);
    setArchivedModelId(id);
  };
  const onViewClick = (id: number) => {
    navigate(`${id}`);
  };

  const onRestoreClick = (id: number, name: string) => {
    setIsCTAModalOpen(true);
    setRestoreModelName(name);
    setRestoreModelId(id);
  };

  return (
    <Container>
      <TopSection>
        <Title>{t('Custom models')}</Title>
        <RightSide>
          <InputWrapper>
            <Input
              placeholder={t('Search models')}
              width={'100%'}
              onChange={(e: any) => setSearchBy(e.target.value)}
              icon={Search}
              wrapperStyles={{ width: '100%' }}
            />
          </InputWrapper>
          <Button
            label={t('Create new model')}
            primary
            width="150rem"
            onClick={() => createCustomModel()}
          />
        </RightSide>
      </TopSection>

      <ListOptions>
        <ListItem
          selectedOption={!archivedModelOption}
          onClick={() => {
            setArchivedModelOption(false);
            setPage(1);
          }}
        >
          {t('Available')}
        </ListItem>
        <ListItem
          selectedOption={archivedModelOption}
          onClick={() => {
            setArchivedModelOption(true);
            setPage(1);
          }}
        >
          {t('Archived')}
        </ListItem>
      </ListOptions>

      {models.length ? (
        <ModelRowsContainer>
          {models?.map((model: IModel, index: number) => (
            <ModelRow
              model={model}
              withoutLine={models.length - 1 === index}
              key={`model-row-${index}`}
              onArchiveClick={onArchiveClick}
              onViewClick={onViewClick}
              onRestoreClick={onRestoreClick}
            />
          ))}
          {modelsData?.pagination?.total > MODELS_PER_PAGE && (
            <Pagination
              page={page}
              perPage={MODELS_PER_PAGE}
              total={modelsData?.pagination?.total || '0'}
              setPage={setPage}
            />
          )}
        </ModelRowsContainer>
      ) : isLoadingModels ? (
        <ModelRowsContainer isSkeleton>
          {Array.from({ length: MODELS_PER_PAGE }, (_, index) => (
            <ModelRowSkeleton
              key={index}
              index={index}
              opacityCoeficient={0.1}
              withoutLine={MODELS_PER_PAGE - 1 === index}
            />
          ))}
        </ModelRowsContainer>
      ) : (
        <NoContentLabelWrapper>
          <NoContentLabel>{t('No models found')}</NoContentLabel>
        </NoContentLabelWrapper>
      )}

      <DangerModal
        isOpen={isArchiveModalOpen}
        setIsOpen={setIsArchiveModalOpen}
        title={t('Archive model')}
        submit={{
          onClick: () => {
            if (archivedModelId) archiveCustomModel({ id: archivedModelId });
            setIsArchiveModalOpen(false);
          },
          text: t('Archive'),
          disabled: false,
        }}
        cancel={{
          onClick: () => {
            setIsArchiveModalOpen(false);
          },
        }}
        description={`
          ${t('Are you sure you want to archive')}
        ${archiveModelName}?`}
      />

      <CTAModal
        isOpen={isCTAModalOpen}
        setIsOpen={setIsCTAModalOpen}
        firstButtonClick={() => {
          setIsCTAModalOpen(false);
        }}
        firstButtonText={t('Cancel')}
        secondButtonClick={() => {
          if (restoreModelId) restoreCustomModel({ id: restoreModelId });
          setIsCTAModalOpen(false);
        }}
        secondButtonText={t('Confirm')}
        title={t('Restore model')}
        description={`
          ${t('Are you sure you want to restore')}
        ${restoreModelName}?`}
      />
    </Container>
  );
};

export default CustomModels;
