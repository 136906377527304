import { getIn } from 'formik';
import {
  ChosenOptionLabel,
  ChosenOptionsWrapper,
  Container,
} from './TableInput.styled';
import { IQuestion } from 'types/Question.types';
import Button from 'components/Button/Button';
import { setTableModalData } from 'store/TableModal/actions/tableModal';
import Tooltip from 'components/Tooltip/Tooltip';
import { getTooltipText } from 'pages/ConfigurePage/ConfigureForm/FormItem/helpers';
import { useDispatch } from 'react-redux';
import { setTouchedQuestionName } from 'store/Form/actions/form';
import { useTranslation } from 'react-i18next';
import { useValidateTableInput } from './hooks';

interface ITableInputProps {
  pwId: string;
  question: IQuestion;
  context: any;
  isTouched: boolean;
}

const TableInput = ({
  pwId,
  question,
  context,
  isTouched,
}: ITableInputProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const chosenOptions: any[] = getIn(context.values, question.initialKey);
  useValidateTableInput(question, context);

  return (
    <Container>
      <Button
        label={t('Choose')}
        primary
        width="150rem"
        onClick={() => {
          dispatch(setTableModalData(question));
          if (!isTouched) {
            dispatch(setTouchedQuestionName(question.initialKey));
          }
        }}
      />
      <ChosenOptionsWrapper data-testid={pwId}>
        {chosenOptions
          ? chosenOptions.map(
              (chosenOption: any[], chosenOptionIndex: number) => (
                <Tooltip
                  content={getTooltipText(
                    Object.keys(question.outputs),
                    chosenOption,
                    t
                  )}
                  key={chosenOptionIndex}
                >
                  <ChosenOptionLabel>
                    {t('Option')} {chosenOptionIndex + 1}
                    {chosenOptionIndex < chosenOptions.length - 1 && ', '}
                  </ChosenOptionLabel>
                </Tooltip>
              )
            )
          : null}
      </ChosenOptionsWrapper>
    </Container>
  );
};

export default TableInput;
