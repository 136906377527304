import {
  BackToLoginLabel,
  ButtonContainer,
  EmailInputWrapper,
  Label,
} from './RequestPasswordResetPage.styled';
import { useEffect, useState } from 'react';
import Input from 'components/Input/Input';
import Button from 'components/Button/Button';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AuthWrapper from '../AuthWrapper/AuthWrapper';
import { useRequestPasswordReset } from './hooks';
import { RoutesConfig } from 'navigation/routes';
import { usePageTitle } from 'utils/hooks/usePageTitle';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';

const RequestPasswordResetPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>('');

  const { isPhone } = useBreakpointFlag();

  const {
    mutate: requestResetPassword,
    isSuccess: isSuccessRequestResetPassword,
    isLoading: isLoadingRequestResetPassword,
  } = useRequestPasswordReset();

  useEffect(() => {
    if (isSuccessRequestResetPassword) navigate(RoutesConfig.Login.fullPath);
  }, [isSuccessRequestResetPassword]);

  usePageTitle('Forgot your password?');

  return (
    <AuthWrapper title={t('Forgot your password?')}>
      <Label>
        {t(
          'Please enter your email address. You will receive a link to create a new password via email.'
        )}
      </Label>
      <EmailInputWrapper>
        <Input
          height={'55rem'}
          placeholder={t('E-mail')}
          value={email}
          onChange={(e: any) => setEmail(e.target.value)}
          wrapperStyles={{ width: '100%' }}
          width={'100%'}
        />
      </EmailInputWrapper>
      <ButtonContainer>
        <Button
          label={t('Reset password')}
          primary
          disabled={isLoadingRequestResetPassword}
          width={isPhone ? '100%' : '580rem'}
          height={'55rem'}
          onClick={() => requestResetPassword(email)}
        />
      </ButtonContainer>
      <BackToLoginLabel onClick={() => navigate(RoutesConfig.Login.fullPath)}>
        {t('Back to Login')}
      </BackToLoginLabel>
    </AuthWrapper>
  );
};

export default RequestPasswordResetPage;
