import { AnyAction } from '@reduxjs/toolkit';
import {
  SET_CURRENTLY_EDITING_SHOPPING_CART_LINE_ID,
  TRIGGER_ADD_TO_SHOPPING_CART,
  SET_TRIGGER_SHOW_ADDED_TO_CART_MODAL,
  SET_TRIGGER_SHOW_CART_ITEM_EDITED_MODAL,
  TRIGGER_EDIT_CART_ITEM,
} from '../../constants';

export interface IShoppingCartReducerState {
  triggerAddToShoppingCart: boolean;
  triggerShowAddedToCartModal: boolean;
  currentlyEditingShoppingCartLineId: number | null;
  triggerShowCartItemEditedModal: boolean;
  triggerEditCartItem: boolean;
}

const INIT_STATE: IShoppingCartReducerState = {
  triggerAddToShoppingCart: false,
  triggerShowAddedToCartModal: false,
  currentlyEditingShoppingCartLineId: null,
  triggerShowCartItemEditedModal: false,
  triggerEditCartItem: false,
};

const shoppingCartReducer = (state = INIT_STATE, action: AnyAction) => {
  switch (action.type) {
    case TRIGGER_ADD_TO_SHOPPING_CART:
      return {
        ...state,
        triggerAddToShoppingCart: action.payload,
      };
    case SET_CURRENTLY_EDITING_SHOPPING_CART_LINE_ID:
      return {
        ...state,
        currentlyEditingShoppingCartLineId: action.payload,
      };
    case SET_TRIGGER_SHOW_ADDED_TO_CART_MODAL:
      return {
        ...state,
        triggerShowAddedToCartModal: action.payload,
      };
    case SET_TRIGGER_SHOW_CART_ITEM_EDITED_MODAL:
      return {
        ...state,
        triggerShowCartItemEditedModal: action.payload,
      };
    case TRIGGER_EDIT_CART_ITEM:
      return {
        ...state,
        triggerEditCartItem: action.payload,
      };
    default:
      return state;
  }
};

export default shoppingCartReducer;
