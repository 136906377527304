import { COLORS } from 'assets/styled';
import styled from 'styled-components';

export const Container = styled.div`
  position: sticky;
  top: 100rem;
  height: fit-content;
`;

export const Headline = styled.span`
  display: block;
  font-size: 26rem;
  color: ${COLORS.BLACK};
`;

export const FieldTypeText = styled.span`
  display: block;
  font-size: 18rem;
  color: ${COLORS.BLACK};
  padding-bottom: 20rem;
  border-bottom: 1px solid ${COLORS.BORDER};
`;

export const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 15rem;
`;
