import { CSSProperties } from 'react';
import ReactDOM from 'react-dom';
import { BackDrop, CloseIcon, StyledModal } from './Modal.styled';
import { Level } from './type';
import { X } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';

interface IModalProps {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  onX?: () => void;
  level?: Level;
  modalStyle?: CSSProperties;
  pwId?: string;
  showCloseIcon?: boolean;
}

export const Modal = ({
  children,
  isOpen,
  onClose,
  onX,
  level = 'FIRST',
  modalStyle,
  pwId,
  showCloseIcon = true,
}: IModalProps) => {
  if (!isOpen) return <></>;

  return ReactDOM.createPortal(
    <>
      <BackDrop
        level={level}
        isOpen={isOpen}
        onClick={() => (onX ? onX() : onClose())}
      />
      <StyledModal style={modalStyle} level={level} data-testid={pwId}>
        {children}
        {showCloseIcon && (
          <CloseIcon
            svg={X}
            color={COLORS.GREEN}
            onClick={() => (onX ? onX() : onClose())}
          />
        )}
      </StyledModal>
    </>,
    document.body
  );
};
