import { COLORS } from 'assets/styled';
import styled, { css } from 'styled-components';

interface StyledProps {
  isOff: boolean;
}

export const Container = styled.div`
  padding: 20rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${COLORS.BORDER};
  gap: 20rem;
  transition: 0.3s ease;
  cursor: pointer;

  &:hover {
    transform: scale(1.03);
  }
`;

export const LeftSide = styled.div`
  display: flex;
  align-items: center;
  gap: 20rem;
  width: 170rem;
`;

export const IconWrapper = styled.div``;

export const Middle = styled.div`
  width: 300rem;
  display: flex;
  justify-content: flex-end;
`;
export const RightSide = styled.div`
  display: flex;
  justify-content: flex-end;
  z-index: 0;
`;

export const Name = styled.div`
  display: flex;
  gap: 10rem;
  align-items: center;
  font-size: 18rem;
  color: ${COLORS.BLACK};
  max-height: 90rem;
  overflow-y: auto;
`;

export const RadioButton = styled.div`
  display: flex;
  gap: 5rem;
  align-items: center;
  z-index: 0;
`;

export const RadioText = styled.span`
  display: block;
  font-size: 16rem;
  color: ${COLORS.BLACK};
`;

export const MiddleInputWrapper = styled.div`
  position: relative;
`;

export const listIconStyle = css`
  position: absolute;
  right: 15rem;
  top: 50%;
  transform: translateY(-50%) !important;
  z-index: 0;
`;

export const SwitchWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 100%;
  justify-content: flex-end;
  gap: 20rem;
`;

export const ToggleText = styled.span`
  font-size: 18rem;
  font-weight: 400;
  color: ${COLORS.BLACK};
  transition: 0.3s ease;

  ${(props: StyledProps) =>
    !props.isOff &&
    css`
      color: ${COLORS.BORDER};
    `}
`;
