import { IOption } from './type';
import { SelectWrapper, StyledReactSelect } from './Select.styled';
import { SelectComponents } from 'react-select/dist/declarations/src/components';
import { GroupBase } from 'react-select';
import { useTranslation } from 'react-i18next';

interface ISelectProps {
  width?: string;
  height?: string;
  options: IOption[];
  placeholder: string;
  name: string;
  onChange: (selectedValue: IOption) => void;
  onInputChange?: (value: string) => void;
  onMenuClose?: () => void;
  onMenuOpen?: () => void;
  menuIsOpen?: boolean;
  isMulti?: boolean;
  isDisabled?: boolean;
  isSearchable?: boolean;
  defaultValue?: IOption;
  controlledValue?: IOption | null;
  components?:
    | Partial<SelectComponents<unknown, boolean, GroupBase<unknown>>>
    | undefined;
  styles?: any;
  onMenuScrollToBottom?: () => void;
  translate?: boolean;
}

export const Select = ({
  width = '200rem',
  height = '40rem',
  name,
  placeholder,
  isMulti,
  isDisabled,
  isSearchable,
  onChange,
  onInputChange,
  onMenuClose,
  onMenuOpen,
  menuIsOpen,
  options,
  defaultValue,
  controlledValue,
  components,
  styles,
  onMenuScrollToBottom,
  translate = true,
}: ISelectProps) => {
  const { t } = useTranslation();
  const formatOptionLabel = ({ label }: any) => {
    return (
      <div style={{ display: 'flex' }}>{translate ? t(label) : label}</div>
    );
  };

  const combinedStyles = {
    ...styles,
    menuPortal: (base: any) => ({
      ...base,
      zIndex: 9999,
      ...(styles?.menuPortal || {}),
    }),
  };

  return (
    <SelectWrapper styles={styles}>
      <StyledReactSelect
        width={width}
        height={height}
        formatOptionLabel={formatOptionLabel}
        options={options}
        placeholder={placeholder}
        aria-label={`select-${name}`}
        onChange={(e: any) => {
          onChange && onChange(e);
        }}
        menuIsOpen={menuIsOpen}
        value={controlledValue ?? undefined}
        classNamePrefix="select"
        isSearchable={isSearchable}
        isMulti={isMulti}
        isDisabled={isDisabled}
        onInputChange={(value: any) => {
          onInputChange && onInputChange(value);
        }}
        defaultValue={defaultValue}
        components={components}
        styles={combinedStyles}
        menuPortalTarget={document.body}
        onMenuClose={() => {
          onMenuClose && onMenuClose();
        }}
        onMenuOpen={() => {
          onMenuOpen && onMenuOpen();
        }}
        onMenuScrollToBottom={onMenuScrollToBottom}
      />
    </SelectWrapper>
  );
};
