import LanguageSwitcher from 'components/LanguageSwitcher/LanguageSwitcher';
import {
  AbsoluteItem,
  AbsoluteWrapper,
  Container,
  Content,
  Label,
  Logo,
} from './AuthWrapper.styled';
import LogyxLogoSVG from 'assets/icons/logyxLogomark.svg';
import ToggleDarkMode from 'components/ToggleDarkMode/ToggleDarkMode';

interface IAuthWrapperProps {
  title: string;
  children: React.ReactNode;
}

const AuthWrapper = ({ title, children }: IAuthWrapperProps) => {
  return (
    <Container>
      <Content>
        <Logo src={LogyxLogoSVG} alt="" />
        <Label>{title}</Label>
        <AbsoluteWrapper>
          <AbsoluteItem>
            <ToggleDarkMode />
          </AbsoluteItem>
          <AbsoluteItem>
            <LanguageSwitcher />
          </AbsoluteItem>
        </AbsoluteWrapper>
        <>{children}</>
      </Content>
    </Container>
  );
};

export default AuthWrapper;
