import * as Yup from 'yup';

export const testConfigurationPreviewFormValidationSchema = Yup.object().shape({
  tax_rate: Yup.number()
    .typeError('VAT amount must be a number')
    .min(0, 'VAT amount must be between 0 and 100')
    .max(100, 'VAT amount must be between 0 and 100')
    .required('Required field'),
  model_id: Yup.number().required('Required').integer('Must be an integer'),
  base_markup: Yup.number()
    .required('Required')
    .min(-100, 'Base markup must be between -100 and +1000')
    .max(1000, 'Base markup must be between -100 and +1000')
    .test(
      'decimal-places',
      'Must have at most 2 decimal places',
      (value) =>
        value === undefined ||
        value === null ||
        /^\d+(\.\d{1,2})?$/.test(value.toString())
    ),
  base_purchase_discount: Yup.number()
    .required('Required')
    .min(0, 'Base purchase discount must be between 0 and 100')
    .max(100, 'Base purchase discount must be between 0 and 100')
    .test(
      'decimal-places',
      'Must have at most 2 decimal places',
      (value) =>
        value === undefined ||
        value === null ||
        /^\d+(\.\d{1,2})?$/.test(value.toString())
    ),
  surcharge_markup: Yup.number()
    .required('Required')
    .min(-100, 'Surcharge markup must be between -100 and +1000')
    .max(1000, 'Surcharge markup must be between -100 and +1000')
    .test(
      'decimal-places',
      'Must have at most 2 decimal places',
      (value) =>
        value === undefined ||
        value === null ||
        /^\d+(\.\d{1,2})?$/.test(value.toString())
    ),
  surcharge_purchase_discount: Yup.number()
    .required('Required')
    .min(0, 'Surcharge purchase discount must be between 0 and 100')
    .max(100, 'Surcharge purchase discount must be between 0 and 100')
    .test(
      'decimal-places',
      'Must have at most 2 decimal places',
      (value) =>
        value === undefined ||
        value === null ||
        /^\d+(\.\d{1,2})?$/.test(value.toString())
    ),
  fixed_amount: Yup.number()
    .required('Required')
    .min(-10000, 'Fixed amount must be between -10,000 and 10,000')
    .max(10000, 'Fixed amount must be between -10,000 and 10,000')
    .test(
      'decimal-places',
      'Must have at most 2 decimal places',
      (value) =>
        value === undefined ||
        value === null ||
        /^\d+(\.\d{1,2})?$/.test(value.toString())
    ),
  exclude_surcharge: Yup.boolean().required('Required'),
  round_prices: Yup.boolean().required('Required'),
  is_active: Yup.boolean(),
  is_using_supplier_system_discounts: Yup.boolean(),
});
