import { useEffect, useState } from 'react';
import { FileUploadModal } from 'components/Modals/FileUploadModal/FileUploadModal';
import { useUploadCompanyLogo, useUploadSupplierLogo } from './hooks';
import {
  Container,
  ButtonWrapper,
  ChangeLabel,
} from '../SettingsRowForm/SettingsRowForm.styled';
import { useTranslation } from 'react-i18next';
import SmallLoader from 'components/SmallLoader/SmallLoader';
import { AdaptiveImageWrapper, imageStyle } from './SettingsRowLogoForm.styled';
import {
  AdaptiveImage,
  AdaptiveImageType,
} from 'components/AdaptiveImage/AdaptiveImage';
import { SettingsEntityType } from '../SettingsRow';

export interface ISettingsRowLogoFormProps {
  entityId: number;
  entityType: SettingsEntityType;
  initialData: string;
  isLoading: boolean;
}

export function SettingsRowLogoForm({
  entityId,
  entityType,
  initialData,
  isLoading,
}: ISettingsRowLogoFormProps) {
  const { t } = useTranslation();
  const [isUploadModalOpen, setIsUploadModalOpen] = useState<boolean>(false);

  const {
    mutate: uploadCompanyLogo,
    isSuccess: isSuccessUploadCompanyLogo,
    reset: resetUploadCompanyLogo,
  } = useUploadCompanyLogo(entityId);

  const {
    mutate: uploadSupplierLogo,
    isSuccess: isSuccessUploadSupplierLogo,
    reset: resetUploadSupplierLogo,
  } = useUploadSupplierLogo(entityId);

  const handleSubmitUploadLogo = async (files: any) => {
    const blob: Blob = await fetch(files).then((r) => r.blob());
    switch (entityType) {
      case SettingsEntityType.COMPANY: {
        uploadCompanyLogo(blob);
        break;
      }
      case SettingsEntityType.SUPPLIER: {
        uploadSupplierLogo(blob);
        break;
      }
    }
  };

  useEffect(() => {
    if (isSuccessUploadCompanyLogo || isSuccessUploadSupplierLogo) {
      setIsUploadModalOpen(false);
      resetUploadCompanyLogo();
      resetUploadSupplierLogo();
    }
  }, [isSuccessUploadCompanyLogo]);

  return (
    <Container>
      <FileUploadModal
        isUploadProfilePicture={true}
        isOpen={isUploadModalOpen}
        setIsOpen={setIsUploadModalOpen}
        onSubmit={(files: any) => handleSubmitUploadLogo(files)}
        multiple={false}
      />
      <AdaptiveImageWrapper>
        <AdaptiveImage
          imageUrl={initialData}
          type={
            entityType === SettingsEntityType.COMPANY
              ? AdaptiveImageType.COMPANY
              : AdaptiveImageType.SUPPLIER
          }
          imageStyle={imageStyle}
        />
      </AdaptiveImageWrapper>
      <ButtonWrapper>
        {isLoading ? (
          <SmallLoader />
        ) : (
          <ChangeLabel onClick={() => setIsUploadModalOpen(true)}>
            {t('Change')}
          </ChangeLabel>
        )}
      </ButtonWrapper>
    </Container>
  );
}
