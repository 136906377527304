export const formatPrice = (price: string, t: (str: string) => string) => {
  if (!price) return null;
  // Check if the price starts with a negative sign
  const isNegative = price.startsWith('-');
  // Remove the negative sign if present for clean formatting
  const cleanPrice = isNegative ? price.slice(1) : price;
  // Format the price with the Euro symbol
  const formattedPrice = `€${cleanPrice}`;
  // Re-add the negative sign in the correct position if necessary
  return isNegative
    ? `${t('Additional price')}: -${formattedPrice}`
    : `${t('Additional price')}: ${formattedPrice}`;
};
