export const getSmallImageIndices = (
  activeIndex: number,
  totalImages: number
) => {
  const indices = [];
  const iterationCount = Math.min(totalImages, 4);
  for (let i = 0; i < iterationCount; i++) {
    indices.push((activeIndex + i) % totalImages);
  }

  return indices;
};
