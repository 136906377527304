import { COLORS, marginSm } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import { marginLg, marginMd, marginXs } from 'assets/styled/variables';
import styled from 'styled-components';

export const Label = styled.label`
  font-size: 20rem;
  font-weight: 300rem;
  margin-bottom: ${marginLg};
  color: ${COLORS.BLACK};
  text-align: center;

  ${respondTo.smallTablet`
    margin-bottom: 20rem;
    font-size: 18rem;
  `}

  ${respondTo.bigPhone`
    font-size: 16rem;
  `}
`;

export const EmailInputWrapper = styled.div`
  margin-bottom: ${marginSm};
  position: relative;

  width: 580rem;

  ${respondTo.bigPhone`
    width: 100%;
    max-width: 100%;
  `}
`;

export const ButtonContainer = styled.div`
  width: 100%;
  margin-top: ${marginXs};
  margin-bottom: ${marginMd};
`;

export const BackToLoginLabel = styled.label`
  font-size: 14rem;
  font-weight: 200;
  cursor: pointer;
  color: ${COLORS.BLACK};
  &:hover {
    text-decoration: underline;
  }
  margin-bottom: 20rem;
`;
