import { borderRadiusSm, COLORS } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled, { css } from 'styled-components';

interface StyledProps {
  selectedOption: boolean;
  isSkeleton: boolean;
}

export const Container = styled.div`
  padding: 50rem 50rem 20rem;
  display: flex;
  flex-direction: column;

  ${respondTo.smallLaptop`
    padding: 50rem 30rem 30rem;
  `}

  ${respondTo.smallTablet`
    padding: 30rem 15rem 20rem;
  `}
`;

export const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30rem;
`;

export const Title = styled.label`
  font-weight: 600;
  font-size: 50rem;
  line-height: 68rem;
  font-weight: 300;
  color: ${COLORS.BLACK};

  ${respondTo.smallLaptop`
    font-size: 40rem;
    line-height: 50rem;
  `}

  ${respondTo.smallTablet`
    font-size: 30rem;
  `}
`;

export const RightSide = styled.div`
  display: flex;
  gap: 20rem;
`;

export const InputWrapper = styled.div`
  width: 300rem;

  ${respondTo.bigPhone`
    width: 100%;
  `}
`;

export const ListOptions = styled.div`
  display: flex;
  align-items: center;
  gap: 20rem;
  margin-bottom: 20rem;
`;

export const ListItem = styled.span`
  display: block;
  color: ${COLORS.GREEN};
  cursor: pointer;
  position: relative;

  &::before {
    content: '';
    width: 0;
    height: 1rem;
    background-color: ${COLORS.GREEN};
    position: absolute;
    bottom: 0;
    left: 0;
    transition: 0.3s ease;
  }

  ${(props: StyledProps) =>
    props.selectedOption &&
    css`
      &::before {
        width: 100%;
      }
    `}
`;

export const ModelRowsContainer = styled.div`
  padding: 10rem 30rem;
  background-color: ${COLORS.WHITE};
  border: 1rem solid ${COLORS.BORDER};
  border-radius: ${borderRadiusSm};
  ${(props: StyledProps) =>
    props.isSkeleton &&
    css`
      background-color: ${COLORS.WHITE};
    `}
`;

export const NoContentLabel = styled.label`
  width: 100%;
  text-align: center;
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
  margin-top: 20rem;
  color: ${COLORS.BLACK};

  ${respondTo.bigPhone`
    font-size: 16rem;
  `}
`;

export const NoContentLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
