import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'components/Modals/Modal/Modal';
import {
  ButtonsWrapper,
  ButtonWrapper,
  Container,
  FieldsButtonWrapper,
  FieldsWrapper,
  InputWrapper,
  Row,
  RowTitle,
  RowTitleWrapper,
  Title,
} from './EditPriceModal.styled';
import Tooltip from 'components/Tooltip/Tooltip';
import Icon from 'components/Icon/Icon';
import { Info } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';
import Button from 'components/Button/Button';
import Input from 'components/Input/Input';
import {
  FieldTypes,
  ICustomModel,
  IImportMatrixOptions,
  IListInputField,
  IPriceMatrix,
  IRadioInputField,
} from 'types/Builder.types';
import { Select } from 'components/Select/Select';
import { IOption } from 'components/Select/type';
import {
  allowedTypesMatrixAxis,
  numericalInputFields,
  priceMatrixFileType,
} from '../../constants';
import { FileUploadModal } from 'components/Modals/FileUploadModal/FileUploadModal';
import { useExportPriceMatrix, useImportPriceMatrix } from '../hooks';
import { downloadFile } from './helpers';
import { extractOptionsFromField, validateMatrixOptions } from '../../helpers';
import { CTAModal } from 'components/Modals/CTAModal/CTAModal';
import { MatrixModalText, MatrixModalTextBold } from '../../Pricing.styled';

export interface IEditPriceModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  model: ICustomModel;
  setModel: Dispatch<SetStateAction<ICustomModel>>;
}

const EditPriceModal = ({
  isOpen,
  setIsOpen,
  model,
  setModel,
}: IEditPriceModalProps) => {
  const { t } = useTranslation();
  const [isCTAModalOpen, setIsCTAModalOpen] = useState<boolean>(false);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState<boolean>(false);

  const [missingOptions, setMissingOptions] = useState<IImportMatrixOptions>({
    xAxis: [],
    yAxis: [],
  });
  const [extraOptions, setExtraOptions] = useState<IImportMatrixOptions>({
    xAxis: [],
    yAxis: [],
  });

  const [localMatrix, setLocalMatrix] = useState<IPriceMatrix>(
    model.price_matrices[0]
  );

  const {
    data: importPriceMatrixData,
    isSuccess: isSuccessImportPriceMatrix,
    mutate: importPriceMatrix,
    isLoading: isLoadingImportPriceMatrix,
  } = useImportPriceMatrix();

  const { mutate: exportPriceMatrix, isLoading: isLoadingExportPriceMatrix } =
    useExportPriceMatrix((data) => {
      if (data) {
        downloadFile(data, `${model.price_matrices[0].title}`);
      }
    });

  useEffect(() => {
    if (importPriceMatrixData) {
      setLocalMatrix((prevMatrix: IPriceMatrix) => ({
        ...prevMatrix,
        matrix: importPriceMatrixData.matrix,
        filetype: importPriceMatrixData.filetype,
      }));
    }
  }, [isSuccessImportPriceMatrix]);

  const isMatrixXString = typeof localMatrix.matrix?.[0]?.[0] === 'string';
  const isMatrixYString = typeof localMatrix.matrix?.[0]?.[1] === 'string';

  const xAxisOptions = model.input_fields
    .filter((field) => allowedTypesMatrixAxis.includes(field.field_type))
    .filter((field) => {
      if (isMatrixXString && field.field_type === 'number') {
        return false;
      }
      return true;
    })
    .map((field) => ({
      label: field.field_label,
      value: field.id,
      key: field.field_type,
    }));

  const yAxisOptions = model.input_fields
    .filter((field) => allowedTypesMatrixAxis.includes(field.field_type))
    .filter((field) => {
      if (isMatrixYString && field.field_type === 'number') {
        return false;
      }
      return true;
    })
    .map((field) => ({
      label: field.field_label,
      value: field.id,
      key: field.field_type,
    }));

  const isXAxisNumerical = numericalInputFields.includes(
    xAxisOptions.find((option) => option.value === localMatrix.x_axis_id)
      ?.key || ''
  );

  const isYAxisNumerical = numericalInputFields.includes(
    yAxisOptions.find((option) => option.value === localMatrix.y_axis_id)
      ?.key || ''
  );

  const handleEditPriceMatrix = (
    ignoreChanges = false,
    isModalConfirmed = false
  ) => {
    const {
      optionsFromMatrix: xAxisOptions,
      missingOptions: xAxisMissing,
      extraOptions: xAxisExtra,
    } = validateMatrixOptions({
      axisFieldId: localMatrix.x_axis_id,
      matrix: localMatrix.matrix || [],
      inputFields: model.input_fields,
      matrixIndex: 0,
    });

    const {
      optionsFromMatrix: yAxisOptions,
      missingOptions: yAxisMissing,
      extraOptions: yAxisExtra,
    } = validateMatrixOptions({
      axisFieldId: localMatrix.y_axis_id,
      matrix: localMatrix.matrix || [],
      inputFields: model.input_fields,
      matrixIndex: 1,
    });

    const hasDifferences =
      xAxisMissing.length > 0 ||
      xAxisExtra.length > 0 ||
      yAxisMissing.length > 0 ||
      yAxisExtra.length > 0;

    if (hasDifferences && !isModalConfirmed) {
      setMissingOptions({ xAxis: xAxisMissing, yAxis: yAxisMissing });
      setExtraOptions({ xAxis: xAxisExtra, yAxis: yAxisExtra });
      setIsCTAModalOpen(true);
      return;
    }

    // If user choose to ignore adding options by us
    if (ignoreChanges) {
      const currentXAxisOptions = extractOptionsFromField(
        localMatrix.x_axis_id,
        model.input_fields
      );
      const currentYAxisOptions = extractOptionsFromField(
        localMatrix.y_axis_id,
        model.input_fields
      );
      updateFieldsAndModel(currentXAxisOptions, currentYAxisOptions);
    } else {
      // If user choose to add options from matrix
      updateFieldsAndModel(xAxisOptions, yAxisOptions);
    }

    setIsCTAModalOpen(false);
  };

  const updateFieldsAndModel = (
    xAxisOptions: string[],
    yAxisOptions: string[]
  ) => {
    const updatedInputFields = model.input_fields.map((field) => {
      // Check if the field is used for X or Y axis in the current matrix
      if (
        field.id === localMatrix.x_axis_id ||
        field.id === localMatrix.y_axis_id
      ) {
        const updatedField = { ...field };

        // Multiple is false when user choose List for matrix
        if (field.field_type === FieldTypes.List) {
          (updatedField as IListInputField).multiple = false;
        }

        // Update field options with values from the matrix
        (updatedField as IListInputField | IRadioInputField).options = (
          field.id === localMatrix.x_axis_id ? xAxisOptions : yAxisOptions
        ).map((option) => ({
          name: option,
          price: { price_type: 'fixed', amount: null },
        }));

        return updatedField;
      }

      return field;
    });

    setModel((prevModel) => ({
      ...prevModel,
      input_fields: updatedInputFields,
      price_matrices: [
        {
          ...prevModel.price_matrices[0],
          title: localMatrix.title,
          x_axis_id: localMatrix.x_axis_id,
          x_axis_min: localMatrix.x_axis_min,
          y_axis_id: localMatrix.y_axis_id,
          y_axis_min: localMatrix.y_axis_min,
          matrix: localMatrix.matrix ?? prevModel.price_matrices[0].matrix,
          filetype: localMatrix.filetype,
        },
      ],
    }));

    setIsOpen(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      level="FIRST"
      modalStyle={{
        margin: 'auto',
        height: 'fit-content',
        width: '685rem',
        position: 'fixed',
      }}
    >
      <Container>
        <Title>{t('Edit Price Matrix')}</Title>

        <Row>
          <RowTitle>{t('Matrix')}</RowTitle>
          <FieldsButtonWrapper>
            <Button
              secondary
              isLoading={isLoadingExportPriceMatrix}
              label={t('Export')}
              width="150rem"
              onClick={() => {
                const matrixEntry = model.price_matrices[0];
                if (matrixEntry.matrix && matrixEntry.filetype) {
                  exportPriceMatrix({
                    name: matrixEntry.title,
                    filetype: matrixEntry.filetype,
                    matrix: matrixEntry.matrix,
                  });
                }
              }}
            />
            <ButtonWrapper>
              <Button
                primary
                isLoading={isLoadingImportPriceMatrix}
                label={t('Import')}
                width="150rem"
                onClick={() => {
                  setIsUploadModalOpen(true);
                }}
              />
            </ButtonWrapper>
          </FieldsButtonWrapper>
        </Row>

        <Row>
          <RowTitleWrapper>
            <RowTitle>{t('Matrix Title')}</RowTitle>
          </RowTitleWrapper>
          <Input
            placeholder={t('Title')}
            width={'250rem'}
            value={localMatrix.title}
            onChange={(e) => {
              const value = e.target.value;
              setLocalMatrix((prevMatrix: IPriceMatrix) => ({
                ...prevMatrix,
                title: value,
              }));
            }}
          />
        </Row>

        <Row>
          <RowTitleWrapper>
            <RowTitle>{t('Add X-Axis')}</RowTitle>
            <Tooltip
              content={t(
                'Please select the input field representing the X-axis (horizontal) in the price matrix.'
              )}
            >
              <Icon
                svg={Info}
                color={COLORS.GREEN}
                size={20}
                weight="regular"
              />
            </Tooltip>
          </RowTitleWrapper>
          <FieldsWrapper>
            <Select
              width="250rem"
              defaultValue={xAxisOptions.find(
                (option) => option.value === localMatrix.x_axis_id
              )}
              name="x-axis-select"
              placeholder={t('Input field')}
              options={xAxisOptions}
              onChange={(selectedOption: IOption) => {
                setLocalMatrix((prevMatrix: IPriceMatrix) => ({
                  ...prevMatrix,
                  x_axis_id: selectedOption.value,
                }));
              }}
            />
            {isXAxisNumerical && (
              <InputWrapper>
                <Tooltip
                  content={t(
                    'Please enter the minimum value for the input field when this price matrix is active.'
                  )}
                >
                  <Icon
                    svg={Info}
                    color={COLORS.GREEN}
                    size={20}
                    weight="regular"
                  />
                </Tooltip>
                <Input
                  placeholder={t('Input field minimum')}
                  width={'250rem'}
                  type="number"
                  hideNumberArrows
                  value={localMatrix.x_axis_min ?? ''}
                  onChange={(e) => {
                    const value: number | null =
                      e.target.value === '' ? null : Number(e.target.value);

                    setLocalMatrix((prevMatrix: IPriceMatrix) => ({
                      ...prevMatrix,
                      x_axis_min: value,
                    }));
                  }}
                />
              </InputWrapper>
            )}
          </FieldsWrapper>
        </Row>

        <Row>
          <RowTitleWrapper>
            <RowTitle>{t('Add Y-Axis')}</RowTitle>
            <Tooltip
              content={t(
                'Please select the input field representing the Y-axis (vertical) in the price matrix.'
              )}
            >
              <Icon
                svg={Info}
                color={COLORS.GREEN}
                size={20}
                weight="regular"
              />
            </Tooltip>
          </RowTitleWrapper>
          <FieldsWrapper>
            <Select
              width="250rem"
              defaultValue={yAxisOptions.find(
                (option) => option.value === localMatrix.y_axis_id
              )}
              name="y-axis-select"
              placeholder={t('Input field')}
              options={yAxisOptions}
              onChange={(selectedOption: IOption) => {
                setLocalMatrix((prevMatrix: IPriceMatrix) => ({
                  ...prevMatrix,
                  y_axis_id: selectedOption.value,
                }));
              }}
            />
            {isYAxisNumerical && (
              <InputWrapper>
                <Tooltip
                  content={t(
                    'Please enter the minimum value for the input field when this price matrix is active.'
                  )}
                >
                  <Icon
                    svg={Info}
                    color={COLORS.GREEN}
                    size={20}
                    weight="regular"
                  />
                </Tooltip>
                <Input
                  placeholder={t('Input field minimum')}
                  width={'250rem'}
                  type="number"
                  hideNumberArrows
                  value={localMatrix.y_axis_min ?? ''}
                  onChange={(e) => {
                    const value: number | null =
                      e.target.value === '' ? null : Number(e.target.value);

                    setLocalMatrix((prevMatrix: IPriceMatrix) => ({
                      ...prevMatrix,
                      y_axis_min: value,
                    }));
                  }}
                />
              </InputWrapper>
            )}
          </FieldsWrapper>
        </Row>

        <ButtonsWrapper>
          <Button
            secondary
            label={t('Cancel')}
            width="150rem"
            onClick={() => {
              setIsOpen(false);
            }}
          />
          <Button
            primary
            label={t('Edit')}
            width="150rem"
            onClick={() => handleEditPriceMatrix()}
          />
        </ButtonsWrapper>
      </Container>

      <CTAModal
        level="THIRD"
        isOpen={isCTAModalOpen}
        setIsOpen={setIsCTAModalOpen}
        firstButtonClick={() => {
          handleEditPriceMatrix(true, true); // Ignore changes
          setIsCTAModalOpen(false);
        }}
        firstButtonText={t('Cancel')}
        secondButtonClick={() => {
          handleEditPriceMatrix(false, true); // Update options
          setIsCTAModalOpen(false);
        }}
        secondButtonText={t('Confirm')}
        title={t('Matrix options')}
        description={
          <>
            <MatrixModalText>
              {t(
                'Some options in your matrix do not match the existing input field options. Do you want to replace them?'
              )}
            </MatrixModalText>
            {missingOptions.xAxis.length > 0 && (
              <MatrixModalTextBold>
                {`${t('X-Axis Missing Options')}: ${missingOptions.xAxis.join(
                  ', '
                )}`}
              </MatrixModalTextBold>
            )}
            {extraOptions.xAxis.length > 0 && (
              <MatrixModalTextBold>
                {`${t('X-Axis Extra Options')}: ${extraOptions.xAxis.join(
                  ', '
                )}`}
              </MatrixModalTextBold>
            )}
            {missingOptions.yAxis.length > 0 && (
              <MatrixModalTextBold>
                {`${t('Y-Axis Missing Options')}: ${missingOptions.yAxis.join(
                  ', '
                )}`}
              </MatrixModalTextBold>
            )}
            {extraOptions.yAxis.length > 0 && (
              <MatrixModalTextBold>
                {`${t('Y-Axis Extra Options')}: ${extraOptions.yAxis.join(
                  ', '
                )}`}
              </MatrixModalTextBold>
            )}
          </>
        }
      />

      <FileUploadModal
        level={'SECOND'}
        isOpen={isUploadModalOpen}
        setIsOpen={setIsUploadModalOpen}
        onSubmit={async (files: any) => {
          const file = files[0];
          const blob = new Blob([file], { type: file.type });
          importPriceMatrix(blob);
        }}
        multiple={false}
        customAllowedTypes={priceMatrixFileType}
      />
    </Modal>
  );
};

export default EditPriceModal;
