import { RoutesConfig } from 'navigation/routes';
import { AppMode } from 'store/Common/reducers/common';

export const getLogoNavigationDestinationBasedOnAppMode = (
  appMode: AppMode
) => {
  switch (appMode) {
    case AppMode.BLUEPRINT:
      return RoutesConfig.Configure.fullPath;
    case AppMode.DEALER_PANEL:
      return RoutesConfig.DealerPanelSuppliers.fullPath;
    case AppMode.COMPANY:
      return RoutesConfig.Models.fullPath;
    case AppMode.SUPPLIER:
      return RoutesConfig.MyModels.fullPath;
  }
};
