import { Container } from './PriceInput.styled';
import Input from '../Input';
import { COLORS } from 'assets/styled';
import { useEffect, useState } from 'react';
import Icon from 'components/Icon/Icon';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { Minus, Plus, CurrencyEur, Percent } from '@phosphor-icons/react';

export enum SymbolPlacementEnum {
  BEFORE = 'BEFORE',
  AFTER = 'AFTER',
}

export enum SymbolTypeEnum {
  EURO = 'EURO',
  PERCENTAGE = 'PERCENTAGE',
}

interface IPriceInputProps {
  value: number;
  setValue: (value: number) => void;
  symbolPlacement: SymbolPlacementEnum;
  hasError?: boolean;
  symbol: SymbolTypeEnum;
  validate: (value: number) => void;
  width?: string;
}

// DealerPanel
export const PriceInput = ({
  value,
  setValue,
  symbol,
  symbolPlacement,
  hasError,
  validate,
  width = '100rem',
}: IPriceInputProps) => {
  const [numberValue, setNumberValue] = useState<number | string>(value);

  const handleInputChange = (e: any) => {
    const newValue = e.target.value.replace(',', '.');
    setNumberValue(newValue);
  };

  useEffect(() => {
    setNumberValue(value);
  }, [value]);

  useEffect(() => {
    validate(Number(numberValue));
  }, [numberValue]);

  const { darkMode } = useSelector(
    (state: IRootReducerState) => state.commonInfo
  );

  return (
    <Container>
      <Icon
        svg={Minus}
        color={COLORS.BLACK}
        onClick={() => setValue(value - 1)}
      />
      <Input
        placeholder=""
        value={numberValue}
        width={width}
        style={{
          color: COLORS.RED,
          backgroundColor: darkMode ? COLORS.BACKGROUND : COLORS.WHITE,
        }}
        onChange={handleInputChange}
        onBlur={(e) => {
          if (!hasError) {
            setValue(Number(numberValue));
          } else {
            setNumberValue(value);
          }
        }}
        error={hasError}
        errorMessage={hasError ? ' ' : ''}
        icon={symbol === SymbolTypeEnum.EURO ? CurrencyEur : Percent}
        iconPlacement={symbolPlacement}
      />
      <Icon
        svg={Plus}
        color={COLORS.BLACK}
        onClick={() => setValue(value + 1)}
      />
    </Container>
  );
};
