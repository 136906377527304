import { COLORS, borderRadiusSm } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';
import { css } from 'styled-components';

interface StyledProps {
  isSkeleton: boolean;
}

export const ModelRowsContainer = styled.div`
  padding-left: 30rem;
  padding-right: 30rem;
  padding-top: 10rem;
  padding-bottom: 10rem;
  background-color: ${COLORS.WHITE};
  border: 1rem solid ${COLORS.BORDER};
  border-radius: ${borderRadiusSm};
  margin-bottom: 30rem;
  ${(props: StyledProps) =>
    props.isSkeleton &&
    css`
      background-color: ${COLORS.WHITE};
    `}
`;

export const NoContentLabel = styled.label`
  text-align: center;
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
  color: ${COLORS.BLACK};

  ${respondTo.bigPhone`
    font-size: 16rem;
  `}
`;

export const NoContentLabelWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
