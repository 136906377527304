import styled from 'styled-components';
import { COLORS } from 'assets/styled/colors';
import { borderRadiusSm, marginSm, paddingMd, paddingSm } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';

export const Container = styled.div`
  max-width: 772rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: ${paddingMd};
  border-bottom: 1rem solid ${COLORS.BORDER};
  border-radius: ${borderRadiusSm};
  background-color: ${COLORS.WHITE};

  ${respondTo.smallTablet`
    padding: 40rem 20rem 20rem;
  `}
`;

export const Title = styled.label`
  font-weight: 400;
  font-size: 24rem;
  line-height: 32rem;
  text-align: center;
  max-width: 500rem;
  width: 100%;
  color: ${COLORS.BLACK};

  ${respondTo.smallTablet`
    font-size: 20rem;
    line-height: 1.3;
  `}

  ${respondTo.bigPhone`
    font-size: 18rem;
  `}

  ${respondTo.smallPhone`
    font-size: 16rem;
  `}
`;

export const SubTitle = styled.label`
  font-weight: 400;
  font-size: 20rem;
  line-height: 27rem;
  padding-bottom: ${paddingSm};
  color: ${COLORS.BLACK};

  ${respondTo.smallTablet`
    padding-bottom: 10rem;
  `}
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${marginSm};
  justify-content: center;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: ${paddingMd};

  ${respondTo.smallTablet`
    padding-bottom: 20rem;
  `}
`;

export const QuestionKeyLabel = styled.label`
  font-weight: 400;
  font-size: 18rem;
  line-height: 24rem;
  padding-bottom: ${paddingSm};
  color: ${COLORS.BLACK};

  ${respondTo.bigPhone`
    font-size: 16rem;
  `}
`;

export const HorizontalLine = styled.div`
  height: 0;
  width: 100%;
  border-bottom: 1px solid ${COLORS.BORDER};
  margin-bottom: ${marginSm};
`;
