import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { useGetSupplierCompaniesInfinite } from './hooks';
import { SUPPLIER_COMPANIES_PER_PAGE_GRID } from './constants';
import { ICompany } from 'types/Company.types';
import { useRef } from 'react';
import { useLoadNextPage } from 'utils/hooks/useLoadNextPage';
import {
  CompaniesWrapper,
  Container,
  NoContentLabel,
  NoContentLabelWrapper,
} from './SupplierCompaniesGrid.styled';
import CompanyCard from './CompanyCard/CompanyCard';
import { useTranslation } from 'react-i18next';
import ModelCardSkeleton from 'components/ModelCard/ModelCardSkeleton/ModelCardSkeleton';
import { getDataFromPages } from 'utils/getDataFromPages';

interface ISupplierCompaniesGridProps {
  debouncedSearchBy: string;
  onDelete: (supplierCompanyId: number) => void;
}

const SupplierCompaniesGrid = ({
  debouncedSearchBy,
  onDelete,
}: ISupplierCompaniesGridProps) => {
  const { t } = useTranslation();

  const loadMoreRef = useRef<HTMLDivElement>(null);
  const supplier = useSelector(
    (state: IRootReducerState) => state.userInfo.supplier
  );
  const {
    data: supplierCompaniesData,
    isFetching: isLoadingSupplierCompanies,
    hasNextPage,
    fetchNextPage,
  } = useGetSupplierCompaniesInfinite(
    supplier.id,
    SUPPLIER_COMPANIES_PER_PAGE_GRID,
    debouncedSearchBy
  );

  const companies: ICompany[] = getDataFromPages(
    supplierCompaniesData?.pages,
    'companies'
  );

  useLoadNextPage(
    loadMoreRef,
    hasNextPage,
    fetchNextPage,
    isLoadingSupplierCompanies
  );

  return (
    <Container>
      {companies.length ? (
        <CompaniesWrapper>
          {companies.map((company: ICompany, index: number) => (
            <CompanyCard
              key={`company-card-${index}`}
              company={company}
              onDelete={onDelete}
            />
          ))}
          {isLoadingSupplierCompanies
            ? Array.from(
                { length: SUPPLIER_COMPANIES_PER_PAGE_GRID },
                (_, index) => (
                  <ModelCardSkeleton
                    key={index}
                    index={index}
                    opacityCoeficient={0.1}
                  />
                )
              )
            : null}
        </CompaniesWrapper>
      ) : isLoadingSupplierCompanies ? (
        <CompaniesWrapper>
          {Array.from(
            { length: SUPPLIER_COMPANIES_PER_PAGE_GRID },
            (_, index) => (
              <ModelCardSkeleton
                key={index}
                index={index}
                opacityCoeficient={0.1}
              />
            )
          )}
        </CompaniesWrapper>
      ) : (
        <NoContentLabelWrapper>
          <NoContentLabel>
            {t('No companies available for this supplier')}
          </NoContentLabel>
        </NoContentLabelWrapper>
      )}
      <div ref={loadMoreRef} style={{ height: '1px' }} />
    </Container>
  );
};

export default SupplierCompaniesGrid;
