import { COLORS } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  max-width: 70%;
  width: 100%;
  gap: 5rem;

  ${respondTo.smallTablet`
    width: 100%;
    max-width: 100%;
  `}
`;

export const ErrorContainer = styled.div``;

export const ADDITIONAL_WRAPPER_MIN_HEIGHT = 15;
export const AdditionalWrapper = styled.div`
  min-height: ${ADDITIONAL_WRAPPER_MIN_HEIGHT}rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ErrorMessage = styled.div`
  font-weight: 400;
  font-size: 12rem;
  line-height: 10rem;
  color: ${COLORS.RED_100};
`;

export const PriceContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5rem;
`;

export const PriceLabel = styled.label`
  color: ${COLORS.BLACK};
  font-weight: 400;
  font-size: 13rem;
  line-height: 10rem;
`;

export const PriceValue = styled(PriceLabel)`
  color: ${COLORS.GREEN};
`;
