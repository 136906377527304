export const removeGrossAndNetSuffix = (str: string) => {
  if (!str) {
    return '';
  } else {
    return str
      .replace('_Gross', '')
      .replace('_gross', '')
      .replace('_Net', '')
      .replace('_net', '');
  }
};

export const shouldShowRedBorder = (
  isTestMode: boolean,
  areAllFieldsTouched: boolean,
  queryRequestAttempt: boolean | undefined,
  isFormValid: boolean
) => {
  return (
    !isTestMode && areAllFieldsTouched && (queryRequestAttempt || !isFormValid)
  );
};
