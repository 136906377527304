import {
  CategoryNameLabel,
  Container,
  ModelConfigurationsWrapper,
  CategoryNameAndChevronWrapper,
  CATEGORY_EXPAND_TRANSITION_TIME,
} from './DealerPanelCategory.styled';
import ModelConfiguration from './ModelConfiguration/ModelConfiguration';
import { Dispatch, SetStateAction, useState } from 'react';
import { IDealerPanelCategory } from 'types/Category.types';
import { IDealerPanelModel } from 'types/Model.types';
import Icon from 'components/Icon/Icon';
import { COLORS } from 'assets/styled/colors';
import { CaretUp, CaretDown } from '@phosphor-icons/react';
import {
  IBreakpointFlags,
  useBreakpointFlag,
} from 'utils/hooks/useBreakpointFlag';

interface IDealerPanelCategoryProps {
  category: IDealerPanelCategory;
  withoutLine?: boolean;
  setManageVisibilityModelId: Dispatch<SetStateAction<number | null>>;
  onTestClick: (
    modelIdForTest: number,
    existingConfigurationId: number | null
  ) => void;
  supplierHasSystemIntegration: boolean;
}

const DealerPanelCategory = ({
  category,
  withoutLine = false,
  setManageVisibilityModelId,
  onTestClick,
  supplierHasSystemIntegration,
}: IDealerPanelCategoryProps) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  // If all hidden categories are rendered the responsiveness is very laggy, this is here to prevent non expanded categories
  const [shouldRender, setShouldRender] = useState<boolean>(false);

  // Sort models by id in ascending order
  const sortedModels = [...category.models].sort(
    (a: IDealerPanelModel, b: IDealerPanelModel) => a.id - b.id
  );

  const handleOnClick = () => {
    const newIsExpandedValue = !isExpanded;
    setIsExpanded(!isExpanded);
    if (newIsExpandedValue === true) {
      setShouldRender(true);
    } else {
      setTimeout(() => setShouldRender(false), CATEGORY_EXPAND_TRANSITION_TIME);
    }
  };

  const breakpointFlags: IBreakpointFlags = useBreakpointFlag();

  return (
    <Container withoutLine={withoutLine}>
      <CategoryNameAndChevronWrapper onClick={handleOnClick}>
        <CategoryNameLabel>{category.name}</CategoryNameLabel>
        <Icon
          svg={isExpanded ? CaretUp : CaretDown}
          color={COLORS.BLACK}
          onClick={handleOnClick}
        />
      </CategoryNameAndChevronWrapper>

      <ModelConfigurationsWrapper
        breakpointFlags={breakpointFlags}
        isExpanded={isExpanded}
        numberOfModelsInCategory={category.models.length} // Used for transition speed
      >
        {shouldRender &&
          sortedModels.map((model: IDealerPanelModel, index: number) => (
            <ModelConfiguration
              model={model}
              key={index}
              index={index}
              withoutLine={category.models.length - 1 === index}
              setManageVisibilityModelId={setManageVisibilityModelId}
              onTestClick={onTestClick}
              supplierHasSystemIntegration={supplierHasSystemIntegration}
            />
          ))}
      </ModelConfigurationsWrapper>
    </Container>
  );
};

export default DealerPanelCategory;
