import { IColor } from '../ColorModal';
import { ColorNameLabel, Container } from './PopularColorCard.styled';
import { getContrastingLabelColor } from '../helpers';
import Tooltip from 'components/Tooltip/Tooltip';
import { formatPrice } from '../ColorCard/helpers';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { Subtitle } from '../ColorCard/ColorCard.styled';

interface IPopularColorCardProps {
  isSelected: boolean;
  color: IColor;
  handleChooseColor: (color: IColor) => void;
}

const PopularColorCard = ({
  isSelected,
  color,
  handleChooseColor,
}: IPopularColorCardProps) => {
  const { t } = useTranslation();
  const { darkMode } = useSelector(
    (state: IRootReducerState) => state.commonInfo
  );
  const bgColor = color.hex_code
    ? color.hex_code
    : darkMode
    ? '#222737' // --color-background
    : '#f6fcf7';

  return (
    <Tooltip content={formatPrice(color.additional_price, t)}>
      <Container
        isSelected={isSelected}
        bgColor={color.hex_code}
        onClick={() => handleChooseColor(color)}
      >
        <ColorNameLabel color={getContrastingLabelColor(bgColor)}>
          {color.name}
        </ColorNameLabel>
        {color.code ? <Subtitle>({color.code})</Subtitle> : null}
      </Container>
    </Tooltip>
  );
};

export default PopularColorCard;
