import { Dispatch, SetStateAction } from 'react';
import {
  Container,
  Title,
  SubTitle,
  ButtonWrapper,
} from './AddedToCartModal.styled';
import { Modal } from '../Modal/Modal';
import Button from 'components/Button/Button';
import { useNavigateWithQuery } from 'utils/hooks/useNavigateWithQuery';
import { useTranslation } from 'react-i18next';
import { RoutesConfig } from 'navigation/routes';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';

export interface IAddedToCartModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export function AddedToCartModal({
  isOpen,
  setIsOpen,
}: IAddedToCartModalProps) {
  const navigateWithQuery = useNavigateWithQuery();
  const { t } = useTranslation();
  const { isPhone } = useBreakpointFlag();

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      modalStyle={{
        margin: 'auto',
        height: 'fit-content',
        width: isPhone ? '90%' : '600rem',
        minWidth: isPhone ? '90%' : 'auto',
        position: 'fixed',
      }}
      pwId="added-to-cart-modal"
    >
      <Container>
        <Title>{t('The product was added to cart')}</Title>
        <SubTitle>
          {t(
            'Do you want to configure another product or continue to the cart'
          )}
          ?
        </SubTitle>
        <ButtonWrapper>
          <Button
            pwId="added-to-card-modal-configure-new-product"
            secondary
            label={t('Configure new product')}
            width="200rem"
            onClick={() => {
              navigateWithQuery(RoutesConfig.Models.fullPath);
              setIsOpen(false);
            }}
          />
          <Button
            pwId="added-to-card-modal-proceed-to-cart"
            primary
            label={t('Proceed to cart')}
            width="200rem"
            onClick={() => {
              navigateWithQuery(RoutesConfig.ShoppingCart.fullPath);
              setIsOpen(false);
            }}
          />
        </ButtonWrapper>
      </Container>
    </Modal>
  );
}
