export const initModel = {
  general: {
    name: '',
    category: '',
    description: '',
  },
  input_fields: [],
  price_matrices: [
    {
      title: '',
      x_axis_id: '',
      x_axis_min: null,
      y_axis_id: '',
      y_axis_min: null,
      matrix: null,
      filetype: null,
    },
  ],
};
