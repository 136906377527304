import { respondTo } from 'assets/styled/breakpoints';
import { Field } from 'formik';
import styled from 'styled-components';
import { css } from 'styled-components';

interface StyledProps {
  hasImages: boolean;
}

export const BooleanQuestionWrapper = styled.div`
  ${respondTo.smallTablet`
      width: 100%;
    `};

  ${(props: StyledProps) =>
    props.hasImages &&
    css`
      align-self: flex-end;
    `};
`;

export const StyledField = styled(Field)`
  align-self: flex-end;
`;

export const ColorInputWrapper = styled.div`
  ${(props: StyledProps) =>
    props.hasImages &&
    css`
      align-self: flex-end;
    `};
`;
