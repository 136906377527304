import { COLORS } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled, { css } from 'styled-components';

interface StyledProps {
  isRowFlexWrap: boolean;
  hasImages: boolean;
}

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10rem;

  ${(props: StyledProps) =>
    props.isRowFlexWrap &&
    css`
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-end;
    `};

  ${respondTo.smallTablet`
    width: 100%;
    gap: 10rem;
    justify-content: flex-start;
  `}
`;

export const Option = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 500rem;
`;

export const OptionLabel = styled.label`
  margin-left: 5rem;
  font-weight: 400;
  color: ${COLORS.BLACK};
`;

export const iconStyling = css`
  border: 1px solid ${COLORS.GREEN};
  border-radius: 50%;
`;
