import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RoutesConfig } from 'navigation/routes';
import { AppMode } from 'store/Common/reducers/common';
import { IRootReducerState } from 'store/store';
import { useNavigateWithQuery } from 'utils/hooks/useNavigateWithQuery';

const NavigateWithQuery = ({ to }: { to: string }) => {
  const navigateWithQuery = useNavigateWithQuery();

  useEffect(() => {
    navigateWithQuery(to, { replace: true });
  }, [to, navigateWithQuery]);

  return null;
};

export const RootRedirect = () => {
  const appMode = useSelector(
    (state: IRootReducerState) => state.commonInfo.appMode
  );

  switch (appMode) {
    case AppMode.BLUEPRINT:
      return <NavigateWithQuery to={RoutesConfig.Configure.fullPath} />;
    case AppMode.DEALER_PANEL:
      return (
        <NavigateWithQuery to={RoutesConfig.DealerPanelSuppliers.fullPath} />
      );
    case AppMode.COMPANY:
      return <NavigateWithQuery to={RoutesConfig.Models.fullPath} />;
    case AppMode.SUPPLIER:
      return <NavigateWithQuery to={RoutesConfig.MyModels.fullPath} />;
    default:
      return null;
  }
};
