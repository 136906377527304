import { IQuestion, QuestionType } from 'types/Question.types';

export const getIndexesOfInputsInOutputs = (
  inputs: string[],
  outputs: object
): number[] => {
  const indexesOfInputsInOutputs: number[] = [];
  const outputKeys = Object.keys(outputs);
  // Finds the index of each input in the outputs object keys array
  for (const inputValue of inputs) {
    const indexOfInputInOutput = outputKeys.indexOf(inputValue);
    if (indexOfInputInOutput !== -1) {
      indexesOfInputsInOutputs.push(indexOfInputInOutput);
    }
  }
  return indexesOfInputsInOutputs;
};

export const getValuesForMultipleListQuestion = (question: IQuestion) => {
  const indexesOfInputsInOutputs: number[] = getIndexesOfInputsInOutputs(
    question.inputs,
    question.outputs
  );
  const selectedValues: any[] = [];
  for (const option of question.options) {
    for (const indexOfInputInOutputs of indexesOfInputsInOutputs) {
      let outputsArray =
        question.outputs[Object.keys(question.outputs)[indexOfInputInOutputs]];
      if (!Array.isArray(outputsArray)) {
        outputsArray = [outputsArray];
      }
      if (outputsArray.includes(option[indexOfInputInOutputs])) {
        selectedValues.push(option[0]);
      }
    }
  }
  return selectedValues;
};

export const getValuesForSingleListQuestion = (question: IQuestion) => {
  const indexesOfInputsInOutputs: number[] = getIndexesOfInputsInOutputs(
    question.inputs,
    question.outputs
  );
  for (const option of question.options) {
    let matchingOption = true;
    for (const index of indexesOfInputsInOutputs) {
      if (
        option[index] !== question.outputs[Object.keys(question.outputs)[index]]
      ) {
        matchingOption = false;
      }
    }
    if (matchingOption) {
      return option[0];
    }
  }
};

export const getValuesForSingleTableQuestion = (question: IQuestion) => {
  const selectedOptions: any[] = [];
  for (const option of question.options) {
    let i = 0;
    let matchingOption = true;
    for (const outputKey of Object.keys(question.outputs)) {
      if (question.outputs[outputKey] !== option[i]) {
        matchingOption = false;
      }
      i++;
    }
    if (matchingOption) {
      selectedOptions.push(option);
    }
  }
  return selectedOptions;
};

export const getValuesForMultipleTableQuestion = (question: IQuestion) => {
  const selectedOptions: any[] = [];
  for (const option of question.options) {
    let i = 0;
    let matchingOption = true;
    for (const outputKey of Object.keys(question.outputs)) {
      if (
        question.outputs[outputKey] &&
        Array.isArray(question.outputs[outputKey])
      ) {
        if (!question.outputs[outputKey].includes(option[i])) {
          matchingOption = false;
        }
      } else {
        if (question.outputs[outputKey] !== option[i]) {
          matchingOption = false;
        }
      }
      i++;
    }
    if (matchingOption) {
      selectedOptions.push(option);
    }
  }

  return selectedOptions;
};

export const getValuesForRadioQuestion = (question: IQuestion) => {
  const indexesOfInputsInOutputs: number[] = getIndexesOfInputsInOutputs(
    question.inputs,
    question.outputs
  );
  for (const option of question.options) {
    let matchingOption = true;
    for (const index of indexesOfInputsInOutputs) {
      if (
        option[index] !== question.outputs[Object.keys(question.outputs)[index]]
      ) {
        matchingOption = false;
      }
    }
    if (matchingOption) {
      return option[0];
    }
  }
};

export const getValuesForColorQuestion = (question: IQuestion) => {
  const indexesOfInputsInOutputs: number[] = getIndexesOfInputsInOutputs(
    question.inputs,
    question.outputs
  );

  for (const option of question.options) {
    let matchingOption = true;
    for (const index of indexesOfInputsInOutputs) {
      if (
        option[index] !== question.outputs[Object.keys(question.outputs)[index]]
      ) {
        matchingOption = false;
      }
    }

    if (matchingOption) {
      return option[indexesOfInputsInOutputs[0]];
    }
  }
};

export const questionHasValue = (values: any, question: IQuestion) => {
  return Array.isArray(values[question.initialKey])
    ? !!values[question.initialKey].length
    : hasValue(values[question.initialKey], question);
};

const hasValue = (value: any, question: IQuestion) => {
  if ([undefined, '0', ''].includes(value)) {
    if (
      question.type === QuestionType.NUMBER &&
      question.default?.[0] == 0 &&
      value === '0'
    ) {
      // initial valuesFromOutputs obtained from useGetValuesFromQuestionOutputs for number questions that are initially empty strings are converted into zero (0) values (because Number('') === 0, conversion happens in OnBlurInputFormikNumber's useEffect) and set into formik state,
      // These values are set as initial values for numberValue state variables inside OnBlurInputFormikNumber components. The initial useState numberValue doesnt always match the formik state values. The initial values of those state variables are derived from
      // the initial outputs obtained from the first query request and they are calculated by logyx core using the question.default field (The initial output will be 0 and not null if a default is set in the model).
      // So if this '0' value is indeed the value of the OnBlurInputFormikNumber numberValue caused by this default field, we can hide the RedAsterisk by returning true from hasValue().
      return true;
    }
    return false;
  } else {
    return true;
  }
};

// Questions param is used here to modify the keys of valuesFromOutputs object from question.initialKey to question.name, because erp expects that type of object structure
export const formatValuesFromOutputs = (
  valuesFromOutputs: object,
  questions: IQuestion[]
) => {
  const formattedValuesFromOutputs = {};
  for (const valuesFromOutputsInitialKey of Object.keys(valuesFromOutputs)) {
    const questionName =
      questions.find(
        (question: IQuestion) =>
          question.initialKey === valuesFromOutputsInitialKey
      )?.name || 'Unknown question';

    formattedValuesFromOutputs[questionName] =
      valuesFromOutputs[valuesFromOutputsInitialKey];
  }

  return formattedValuesFromOutputs;
};

export const extractQuestionPrice = (
  pricing: object,
  questionInitialKey: string,
  showNetPrices: boolean
): number | undefined => {
  if (!pricing) {
    return undefined;
  }
  let price = 0;
  Object.keys(pricing).forEach((pricingKey) => {
    const pricingItem = pricing[pricingKey];
    if (pricingItem.question === questionInitialKey) {
      if (showNetPrices && pricingItem.type.includes('net')) {
        price += pricing[pricingKey].value;
      } else if (!showNetPrices && pricingItem.type.includes('gross')) {
        price += pricing[pricingKey].value;
      }
    }
  });

  return price || undefined;
};
