import { OptionProps } from 'react-select';
import { components } from 'react-select';
import { ColorImage, OptionContainer } from './ColorOption.styled';

export const ColorOption = (props: OptionProps<any>) => {
  return (
    <components.Option {...props}>
      <OptionContainer data-testid={props.data.pwId}>
        <div data-testid={props.data.pwIdLabel}>{props.data.label}</div>
        <ColorImage src={props.data.color_small_image} alt="color" />
      </OptionContainer>
    </components.Option>
  );
};
