import {
  LeftPart,
  RightPart,
  Container,
  CircleSkeleton,
} from '../SupplierModelCompaniesRow.styled';

import { Skeleton } from '@mui/material';

interface ISupplierModelCompaniesRowSkeletonProps {
  index: number;
  opacityCoeficient?: number;
  withoutLine: boolean;
}

const SupplierModelCompaniesRowSkeleton = ({
  index,
  opacityCoeficient = 0.2,
  withoutLine,
}: ISupplierModelCompaniesRowSkeletonProps) => {
  return (
    <Container
      withoutLine={withoutLine}
      opacityIndex={index}
      opacityCoeficient={opacityCoeficient}
    >
      <LeftPart>
        <Skeleton width={'150rem'} animation="wave" />
        <Skeleton width={'100rem'} animation="wave" />
      </LeftPart>
      <RightPart>
        <Skeleton width="130rem" animation="wave" />
        <CircleSkeleton
          animation="wave"
          variant="rounded"
          height={'30rem'}
          width={'30rem'}
        />
        {/* Apply gap to div */}
        <div />
        <Skeleton width="130rem" animation="wave" />
        <CircleSkeleton
          animation="wave"
          variant="rounded"
          height={'30rem'}
          width={'30rem'}
        />
      </RightPart>
    </Container>
  );
};

export default SupplierModelCompaniesRowSkeleton;
