import { config } from 'config';
import { Base64 } from 'js-base64';

// https://aws.amazon.com/blogs/networking-and-content-delivery/image-optimization-using-amazon-cloudfront-and-aws-lambda/ 🧠

/**
 * Removes 'app.logyx.it/' and everything to its left from the provided URL.
 * @param url - The URL from which the substring and any preceding content should be removed.
 * @returns The modified URL with the specified substring and preceding content removed.
 */
function removePrefixAndSubdomain(url: string): string {
  const keySubstring = 'app.logyx.it/';
  const index = url.indexOf(keySubstring);

  // Check if the substring exists in the URL
  if (index !== -1) {
    // Remove everything up to and including the specified substring
    return url.substring(index + keySubstring.length);
  }

  // Return the original URL if the substring is not found
  return url;
}

export const adjustImageSourceToUseS3ImageTransformator = (
  initialSource: string,
  width?: number,
  height?: number
) => {
  if (!initialSource || (!width && !height)) {
    return '';
  }

  const transformatorUrlPrefix: string | undefined =
    config.aws.cloudFrontImageTransformatorUrl;
  if (!transformatorUrlPrefix) {
    return initialSource;
  }

  // Remove the prefix
  const urlWithoutPrefix = removePrefixAndSubdomain(initialSource);

  // Construct the new URL parts
  let queryParams;
  queryParams = `?format=webp&width=${width}`;
  if (!width) {
    queryParams = `?format=webp&height=${height?.toFixed()}`;
  }
  const newUrl = `${transformatorUrlPrefix}${
    '/' + Base64.encodeURL(decodeURIComponent(urlWithoutPrefix))
  }${queryParams}`;

  return newUrl;
};
