import apiClient from 'services/api/apiService';
import { getUrlWithQueryParams } from './api/getUrlWithQueryParams';
import { IVisibilityConfigurationDTO } from 'pages/DealerPanel/DealerPanelModelsPage/ManageModelQuestionDocumentVisibilityModal/hooks';

const BASE_URL = '/v1/external/';

export const submitVisibilityConfiguration = async (
  visiblityConfigurationDTO: IVisibilityConfigurationDTO
): Promise<any> => {
  const url = `${BASE_URL}submit-model-visibility`;
  const { data } = await apiClient.post<any>(url, {
    data: visiblityConfigurationDTO.values,
    logyx_model_id: visiblityConfigurationDTO.modelId,
    base64_metadata: visiblityConfigurationDTO.base64Metadata,
  });

  return data.data;
};

export const getModelDocumentVisibility = async (
  modelId: number,
  base64Metadata?: string
): Promise<any> => {
  const url = `${BASE_URL}get-model-visibility`;
  const queryParamsObj = {
    logyx_model_id: modelId,
    base64_metadata: base64Metadata,
  };
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);

  const { data } = await apiClient.get<any>(fullUrl);

  return data.data;
};
