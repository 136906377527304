import { useTranslation } from 'react-i18next';
import {
  ActionButton,
  Back,
  ButtonText,
  Container,
  ContentWrapper,
  FirstRow,
  ModelNameWrapper,
  ModelName,
  Description,
  DescriptionText,
  VersionsRowsContainer,
  NoContentLabelWrapper,
  NoContentLabel,
  HeadlineRow,
  Headline,
  ModelID,
  SortWrapper,
  SortOptions,
  SortOption,
} from './SingleCustomModel.styled';
import { useNavigate, useParams } from 'react-router-dom';
import { RoutesConfig } from 'navigation/routes';
import Icon from 'components/Icon/Icon';
import { Archive, CaretLeft } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';
import VersionRowSkeleton from './VersionRow/VersionRowSkeleton/VersionRowSkeleton';
import Pagination from 'components/Pagination/Pagination';
import VersionRow from './VersionRow/VersionRow';
import { VERSIONS_PER_PAGE } from './constants';
import { useRef, useState } from 'react';
import {
  useArchiveCustomModel,
  useCopyVersion,
  useGetVersions,
  useUpdateVersion,
} from './hooks';
import { SortOrder } from 'components/OptionsTable/types';
import Button from 'components/Button/Button';
import { DangerModal } from 'components/Modals/DangerModal/DangerModal';
import { IVersion } from 'types/Builder.types';
import { usePageTitle } from 'utils/hooks/usePageTitle';
import Loader from 'components/Loader/Loader';
import { sortOptions } from './helpers';
import { useOutsideAlerter } from 'utils/hooks/useOutsideAlerter';

const SingleCustomModel = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [page, setPage] = useState<number>(1);
  const [sortBy, setSortBy] = useState<string>('number');
  const [sortDirection, setSortDirection] = useState<SortOrder>(SortOrder.DESC);
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState<boolean>(false);
  const [sortOptionsOpen, setSortOptionsOpen] = useState<boolean>(false);

  const sortOptionsRef = useRef(null);
  useOutsideAlerter(sortOptionsRef, () => setSortOptionsOpen(false));

  const { data: versionsData, isLoading: isLoadingVersions } = useGetVersions(
    id!,
    page,
    VERSIONS_PER_PAGE,
    sortBy,
    sortDirection
  );

  const versions: IVersion[] = versionsData?.versions || [];

  const { mutate: updateVersion, isLoading: isLoadingUpdateVersion } =
    useUpdateVersion();

  const { mutate: copyVersion, isLoading: isLoadingCopyVersion } =
    useCopyVersion();

  const { mutate: archiveCustomModel } = useArchiveCustomModel();

  usePageTitle((versionsData && versionsData?.model_info?.name) ?? '');

  const onLockClick = (id: number, isLocked: boolean) => {
    if (!isLoadingUpdateVersion) updateVersion({ id, locked: isLocked });
  };

  const onActiveClick = (id: number, isActive: boolean) => {
    if (!isLoadingUpdateVersion) updateVersion({ id, is_active: isActive });
  };

  const onCopyClick = (id: number, toNewModel: boolean) => {
    if (!isLoadingCopyVersion) copyVersion({ id, toNewModel });
  };

  const onEditClick = (id: number) => {
    navigate(`${RoutesConfig.Builder.fullPath.replace(':id', id.toString())}`);
  };

  return (
    <>
      {isLoadingVersions && <Loader />}
      <Container>
        <FirstRow onClick={() => navigate(RoutesConfig.CustomModels.fullPath)}>
          <Icon
            svg={CaretLeft}
            color={COLORS.GREEN}
            weight="bold"
            size={20}
            onClick={() => null}
          />
          <Back>{t('Back')}</Back>
        </FirstRow>
        <ContentWrapper>
          <ModelNameWrapper>
            <ModelName>{versionsData?.model_info?.name}</ModelName>
            <ActionButton onClick={() => setIsArchiveModalOpen(true)}>
              <Icon
                svg={Archive}
                color={COLORS.RED_100}
                weight="regular"
                size={30}
              />
              <ButtonText>{t('Archive')}</ButtonText>
            </ActionButton>
          </ModelNameWrapper>
          <ModelID>{`${t('Model ID')}: ${
            versions && versions[0]?.model_id
          }`}</ModelID>
          <Description>
            <DescriptionText>
              {versionsData?.model_info?.description}
            </DescriptionText>
          </Description>
        </ContentWrapper>
        {versions?.length ? (
          <VersionsRowsContainer>
            <HeadlineRow>
              <Headline>{t('Model versions')}</Headline>
              <SortWrapper ref={sortOptionsRef}>
                <Button
                  onClick={() => setSortOptionsOpen(true)}
                  primary
                  label={t('Sort by')}
                  width="150rem"
                />
                {sortOptionsOpen && (
                  <SortOptions>
                    {sortOptions.map((option, index) => (
                      <SortOption
                        key={index}
                        isSortOptionActive={
                          option.value.field === sortBy &&
                          option.value.order === sortDirection
                        }
                        onClick={() => {
                          setSortBy(option.value.field);
                          setSortDirection(option.value.order);
                        }}
                      >
                        {t(option.label)}
                      </SortOption>
                    ))}
                  </SortOptions>
                )}
              </SortWrapper>
            </HeadlineRow>
            {versions?.map((version: IVersion, index: number) => (
              <VersionRow
                version={version}
                withoutLine={versions.length - 1 === index}
                key={`version-row-${index}`}
                onLock={onLockClick}
                onActive={onActiveClick}
                onEdit={onEditClick}
                onCopy={onCopyClick}
              />
            ))}
            {versionsData?.pagination?.total > VERSIONS_PER_PAGE && (
              <Pagination
                page={page}
                perPage={VERSIONS_PER_PAGE}
                total={versionsData?.pagination?.total || '0'}
                setPage={setPage}
              />
            )}
          </VersionsRowsContainer>
        ) : isLoadingVersions ? (
          <VersionsRowsContainer isSkeleton>
            {Array.from({ length: VERSIONS_PER_PAGE }, (_, index) => (
              <VersionRowSkeleton
                key={index}
                index={index}
                opacityCoeficient={0.1}
                withoutLine={VERSIONS_PER_PAGE - 1 === index}
              />
            ))}
          </VersionsRowsContainer>
        ) : (
          <NoContentLabelWrapper>
            <NoContentLabel>{t('No versions found')}</NoContentLabel>
          </NoContentLabelWrapper>
        )}

        <DangerModal
          isOpen={isArchiveModalOpen}
          setIsOpen={setIsArchiveModalOpen}
          title={t('Archive model')}
          submit={{
            onClick: () => {
              archiveCustomModel({ id: Number(id) });
              setIsArchiveModalOpen(false);
            },
            text: t('Archive'),
            disabled: false,
          }}
          cancel={{
            onClick: () => {
              setIsArchiveModalOpen(false);
            },
          }}
          description={`
          ${t('Are you sure you want to archive')}
        ${versionsData?.model_info?.name}?`}
        />
      </Container>
    </>
  );
};

export default SingleCustomModel;
