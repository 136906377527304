import { MutableRefObject, useEffect } from 'react';
import { FetchNextPageOptions, InfiniteQueryObserverResult } from 'react-query';

type IFetchNextPage = (
  options?: FetchNextPageOptions | undefined
) => Promise<InfiniteQueryObserverResult<any, unknown>>;

export const useLoadNextPage = (
  loadMoreRef: MutableRefObject<any>,
  hasNextPage: boolean | undefined,
  fetchNextPage: IFetchNextPage | undefined,
  isFetching: boolean
) => {
  const loadMoreEntities = () => {
    if (hasNextPage && fetchNextPage) {
      fetchNextPage();
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          loadMoreEntities();
        }
      },
      { threshold: 0.5 }
    );

    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }

    return () => {
      if (loadMoreRef.current) {
        observer.unobserve(loadMoreRef.current);
      }
    };
  }, [loadMoreRef, hasNextPage, isFetching]);
};
