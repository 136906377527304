import styled from 'styled-components';
import {
  COLORS,
  borderRadiusSm,
  paddingMd,
  paddingSm,
  paddingXs,
} from 'assets/styled';
import { css } from 'styled-components';
import { HAS_IMAGES_BREAKPOINT } from './hooks';
import { respondTo } from 'assets/styled/breakpoints';

interface StyledProps {
  hasImages: boolean;
}

export const Wrapper = styled.div`
  min-height: 700rem;
  max-width: 1400rem;
  width: 100%;
  margin: 50rem 0;
  padding: 0 15rem;
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 20rem;

  ${respondTo.smallTablet`
    margin-bottom: 100rem;
  `}
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: ${paddingSm};
  justify-content: space-between;
  background-color: ${COLORS.WHITE};
  border-radius: ${borderRadiusSm};
  width: 100%;

  ${respondTo.bigPhone`
    padding: 10rem;
  `};
  margin-bottom: 0rem;
  ${respondTo.smallTablet`
    margin-bottom: 15rem;
  `};
`;

export const ByCodeflexWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: ${paddingMd};
  padding-bottom: ${paddingXs};
`;

export const ByCodeflexText = styled.label`
  position: absolute;
  top: 40rem;
  font-weight: 400;
  font-size: 16rem;
  color: ${COLORS.BLACK};
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // Display form images above the form questions when screen is small
  @media screen and (max-width: ${HAS_IMAGES_BREAKPOINT}px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const NoModelSelectedWrapper = styled.div`
  gap: 30rem;
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const NoModelSelectedLabel = styled.label`
  text-align: center;
  font-weight: 300;
  font-size: 31rem;
  line-height: 44rem;
  color: ${COLORS.BLACK};
`;

export const BackLabel = styled.label`
  margin-left: 5rem;
  font-size: 18rem;
  color: ${COLORS.BLACK};

  ${respondTo.bigPhone`
    font-size: 16rem;
  `}
`;

export const AbsoluteBackWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  top: -30rem;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10rem;
  &:hover {
    ${BackLabel} {
      cursor: pointer;
      text-decoration: underline;
      text-decoration-thickness: 1rem;
    }
  }
`;

export const BackWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  &:hover {
    ${BackLabel} {
      cursor: pointer;
      text-decoration: underline;
      text-decoration-thickness: 1rem;
    }
  }
`;
