import { COLORS } from 'assets/styled';
import styled, { css } from 'styled-components';

interface StyledProps {
  green: boolean;
  topBorder: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 100rem;
  height: fit-content;
`;
export const Headline = styled.span`
  display: block;
  font-size: 26rem;
  color: ${COLORS.BLACK};
  margin-bottom: 20rem;
`;

export const Description = styled.span`
  display: block;
  font-size: 16rem;
  color: ${COLORS.BLACK};
  margin-bottom: 30rem;
  line-height: 1.5;
`;

export const FieldWrapper = styled.div`
  padding: 20rem 5rem;
  cursor: grab;
  position: relative;
  transition: 0.3s ease;

  &:hover > div {
    transition: 0.3s ease;
    transform: scale(1.1);
  }
`;

export const BorderWrapper = styled.div`
  border-bottom: 1px solid ${COLORS.BORDER};

  ${(props: StyledProps) =>
    props.topBorder &&
    css`
      border-top: 1px solid ${COLORS.BORDER};
    `}
`;

export const FieldWrapperAlt = styled(FieldWrapper)`
  &:hover > div {
    transition: 0.3s ease;
    transform: scale(1.1);
  }
`;

export const FieldText = styled.div`
  border: 1px solid ${COLORS.BORDER};
  border-radius: 20rem;
  display: flex;
  padding: 10rem 15rem;
  font-size: 16rem;
  color: ${COLORS.BLACK};
  transition: 0.3s ease;
`;

export const ShortInput = styled.div`
  border: 1px solid ${COLORS.BORDER};
  border-radius: 20rem;
  display: flex;
  padding: 10rem 15rem;
  font-size: 16rem;
  color: ${COLORS.BLACK};
  transition: 0.3s ease;
`;

export const LongInput = styled.div`
  border: 1px solid ${COLORS.BORDER};
  border-radius: 20rem;
  display: flex;
  padding: 10rem 15rem;
  height: 100rem;
  font-size: 16rem;
  color: ${COLORS.BLACK};
  transition: 0.3s ease;
`;

export const ListInput = styled.div`
  border: 1px solid ${COLORS.BORDER};
  border-radius: 20rem;
  display: flex;
  padding: 10rem 15rem;
  font-size: 16rem;
  color: ${COLORS.BLACK};
  transition: 0.3s ease;
`;

export const listIconStyle = css`
  position: absolute;
  right: 15rem;
  top: 50%;
  transform: translateY(-50%) !important;
`;

export const NumberInput = styled.div`
  border: 1px solid ${COLORS.BORDER};
  border-radius: 20rem;
  display: flex;
  padding: 10rem 15rem;
  font-size: 16rem;
  color: ${COLORS.BLACK};
  transition: 0.3s ease;
`;

export const ToggleText = styled.div`
  font-size: 16rem;
  color: ${COLORS.BLACK};
  margin-bottom: 20rem;
  transition: 0.3s ease;
`;

export const RadioText = styled.div`
  font-size: 16rem;
  color: ${COLORS.BLACK};
  margin-bottom: 10rem;
  transition: 0.3s ease;
`;

export const RadioButtons = styled.div`
  display: flex;
  gap: 20rem;
  align-items: center;
`;

export const RadioButton = styled.div`
  width: 25rem;
  height: 25rem;
  border-radius: 50%;
  border: 1px solid ${COLORS.GREEN};
  background-color: ${COLORS.WHITE};

  ${(props: StyledProps) =>
    props.green &&
    css`
      background-color: ${COLORS.GREEN};
    `}
`;
