import { CSSProperties, useState } from 'react';
import { ClearIndicatorProps } from 'react-select';
import { COLORS } from 'assets/styled';
import {
  ClearIndicatorContainer,
  CustomIcon,
} from './CustomClearIndicator.styled';
import { X } from '@phosphor-icons/react';

const CustomClearIcon = ({ isHovered }: { isHovered: boolean }) => {
  const fillColor = isHovered ? COLORS.RED_100 : COLORS.BLACK;

  return (
    <div>
      <CustomIcon svg={X} fill={fillColor} size={12} onClick={() => true} />
    </div>
  );
};

const ClearIndicator = (props: ClearIndicatorProps<any, true>) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);
  const {
    children = <CustomClearIcon isHovered={isHovered} />,
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;

  const selectedOptionCount = props?.getValue()?.length;

  if (selectedOptionCount < 2) {
    return <></>;
  }
  return (
    <ClearIndicatorContainer
      {...restInnerProps}
      ref={ref}
      style={getStyles('clearIndicator', props) as CSSProperties}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
    </ClearIndicatorContainer>
  );
};

export default ClearIndicator;
