import { useQuery } from 'react-query';
import { getSupplierCompanies } from 'services/SupplierService';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';

export const useGetSupplierCompanies = (
  supplierId: number,
  page: number,
  perPage: number,
  searchBy: string
) =>
  useQuery({
    queryKey: [
      ReactQueryKeys.GET_SUPPLIER_COMPANIES,
      supplierId,
      page,
      perPage,
      searchBy,
    ],
    queryFn: () => {
      return getSupplierCompanies(supplierId, page, perPage, searchBy);
    },
  });
