import styled, { css } from 'styled-components';

interface StyledProps {
  imageLoaded: boolean;
}

export const SingleValueContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ImageLarge = styled.img`
  width: 0;
  height: 0;
  opacity: 0;
  ${(props: StyledProps) =>
    props.imageLoaded &&
    css`
      opacity: 1;
      height: 24rem;
      width: 24rem;
    `};
`;

export const SkeletonWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
`;
