import { useTranslation } from 'react-i18next';
import { Container, Label } from './DealerPanelFormulaTooltipContent.styled';

export const DealerPanelFormulaTooltipContent = () => {
  const { t } = useTranslation();

  const tooltipText0 = t('All price variable settings are exc. VAT');

  const tooltipText1 = `${t('Net purchase price')} = (${t(
    'Gross base price'
  )} * (1 - ${t('Base purchase discount')})) + (${t(
    'Gross surcharge price'
  )} * (1 - ${t('Surcharge purchase discount')}))`;

  const tooltipText2 = `${t('Sales price exc. VAT')} = (${t(
    'Gross base price'
  )} * (1 - ${t('Base purchase discount')}) * (1 + ${t('Base markup')})) + (${t(
    'Gross surcharge price'
  )} * (1 - ${t('Surcharge purchase discount')}) * (1 + ${t(
    'Surcharge markup'
  )}))`;

  const tooltipText3 = `${t('Sales price incl. VAT')} = ${t(
    'Sales price exc. VAT'
  )} * (1 + ${t('VAT')}%)`;

  return (
    <Container>
      <Label>{tooltipText0}</Label>
      <Label>{tooltipText1}</Label>
      <Label>{tooltipText2}</Label>
      <Label>{tooltipText3}</Label>
    </Container>
  );
};
