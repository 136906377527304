export const downloadFile = (data: Blob, filename: string) => {
  const url = URL.createObjectURL(data);

  const link = document.createElement('a');
  link.href = url;
  link.download = filename;
  document.body.appendChild(link);
  link.click();

  URL.revokeObjectURL(url);
  document.body.removeChild(link);
};
