import { ICompany } from 'types/Company.types';
import {
  Label,
  LeftPart,
  CompanyNameLabel,
  RightPart,
  Container,
  OrderPerModelLabel,
  ToggleWrapper,
  IconWrapper,
} from './SupplierModelCompaniesRow.styled';
import { useTranslation } from 'react-i18next';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import { useEffect } from 'react';
import {
  useDisableOrderSupplierCompanyModel,
  useDisableQuerySupplierCompanyModel,
  useEnableOrderSupplierCompanyModel,
  useEnableQuerySupplierCompanyModel,
} from 'pages/Supplier/SupplierSingleCompanyPage/SupplierCompanyModels/SupplierCompanyModelsRow/hooks';
import { ISupplierCompanyModel } from 'types/SupplierCompanyModel';
import { queryClient } from 'index';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import Icon from 'components/Icon/Icon';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';
import { Trash } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';

interface ISupplierModelCompaniesRowProps {
  supplierCompanyModel: ISupplierCompanyModel;
  withoutLine: boolean;
  isLoadingParent: boolean;
  onDelete: (id: number) => void;
}

const SupplierModelCompaniesRow = ({
  supplierCompanyModel,
  withoutLine,
  isLoadingParent,
  onDelete,
}: ISupplierModelCompaniesRowProps) => {
  const { t } = useTranslation();
  const { isMidTablet, isSmallTablet } = useBreakpointFlag();

  const company: ICompany = supplierCompanyModel.supplier_company.company;

  const {
    mutate: enableOrder,
    isLoading: isLoadingEnableOrder,
    isSuccess: isSuccessEnableOrder,
    reset: resetEnableOrder,
  } = useEnableOrderSupplierCompanyModel();
  const {
    mutate: disableOrder,
    isLoading: isLoadingDisableOrder,
    isSuccess: isSuccessDisableOrder,
    reset: resetDisableOrder,
  } = useDisableOrderSupplierCompanyModel();

  const {
    mutate: enableQuery,
    isLoading: isLoadingEnableQuery,
    isSuccess: isSuccessEnableQuery,
    reset: resetEnableQuery,
  } = useEnableQuerySupplierCompanyModel();
  const {
    mutate: disableQuery,
    isLoading: isLoadingDisableQuery,
    isSuccess: isSuccessDisableQuery,
    reset: resetDisableQuery,
  } = useDisableQuerySupplierCompanyModel();

  const isLoading =
    isLoadingEnableOrder ||
    isLoadingDisableOrder ||
    isLoadingEnableQuery ||
    isLoadingDisableQuery ||
    isLoadingParent;

  useEffect(() => {
    if (
      isSuccessEnableOrder ||
      isSuccessDisableOrder ||
      isSuccessEnableQuery ||
      isSuccessDisableQuery
    ) {
      queryClient.invalidateQueries([
        ReactQueryKeys.GET_SUPPLIER_COMPANY_MODELS,
      ]);
      resetEnableOrder();
      resetDisableOrder();
      resetEnableQuery();
      resetDisableQuery();
    }
  }, [
    isSuccessEnableOrder,
    isSuccessDisableOrder,
    isSuccessEnableQuery,
    isSuccessDisableQuery,
  ]);

  return (
    <Container withoutLine={withoutLine}>
      <LeftPart>
        <CompanyNameLabel>{company.name}</CompanyNameLabel>
        <Label>
          {supplierCompanyModel.supplier_company.supplier_company_reference}
        </Label>
        {isSmallTablet && (
          <IconWrapper>
            <Icon
              onClick={() => onDelete(supplierCompanyModel.id)}
              svg={Trash}
              color={COLORS.RED_100}
            />
          </IconWrapper>
        )}
      </LeftPart>
      <RightPart>
        <ToggleWrapper>
          <OrderPerModelLabel>{t('Querying enabled')}</OrderPerModelLabel>
          <ToggleSwitch
            disabled={isLoading}
            isChecked={supplierCompanyModel.can_query}
            onClick={() => {
              if (isLoading) {
                return;
              }
              if (supplierCompanyModel.can_query) {
                disableQuery(supplierCompanyModel.id);
              } else {
                enableQuery(supplierCompanyModel.id);
              }
            }}
            size={isMidTablet ? 'small' : 'large'}
          />
        </ToggleWrapper>
        <ToggleWrapper>
          <OrderPerModelLabel>{t('Ordering enabled')}</OrderPerModelLabel>
          <ToggleSwitch
            disabled={isLoading}
            isChecked={supplierCompanyModel.can_order}
            onClick={() => {
              if (isLoading) {
                return;
              }
              if (supplierCompanyModel.can_order) {
                disableOrder(supplierCompanyModel.id);
              } else {
                enableOrder(supplierCompanyModel.id);
              }
            }}
            size={isMidTablet ? 'small' : 'large'}
          />
        </ToggleWrapper>
        {!isSmallTablet && (
          <Icon
            onClick={() => onDelete(supplierCompanyModel.id)}
            svg={Trash}
            color={COLORS.RED_100}
          />
        )}
      </RightPart>
    </Container>
  );
};

export default SupplierModelCompaniesRow;
