import ModelCard from 'components/ModelCard/ModelCard';
import {
  ModelCardsWrapper,
  NoContentLabel,
} from './RecentlyViewedSectionGrid.styled';
import { IModel } from 'types/Model.types';
import { useGetMaxDisplayedModelCardAmountBasedOnWindowWidth } from 'pages/ModelsPage/hooks';
import { RECENTLY_VIEWED_MODELS_PER_PAGE } from '../constants';
import { useGetModels } from 'pages/AllModelsPage/AllModelsListView/hooks';
import { queryClient } from 'index';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { useTranslation } from 'react-i18next';
import useIsFocused from 'utils/hooks/useIsFocused';
import { useEffect } from 'react';
import ModelCardSkeleton from 'components/ModelCard/ModelCardSkeleton/ModelCardSkeleton';

const RecentlyViewedSectionGrid = () => {
  const { t } = useTranslation();
  const { data: modelsData, isLoading } = useGetModels(
    1,
    RECENTLY_VIEWED_MODELS_PER_PAGE,
    '',
    null,
    null,
    true,
    false
  );

  const models: IModel[] = modelsData?.models || [];

  const maxDisplayedModelCardAmount =
    useGetMaxDisplayedModelCardAmountBasedOnWindowWidth();

  // Slice the models array to not exceed the max displayed model card amount
  const displayedModels = models.slice(0, maxDisplayedModelCardAmount);

  const isFocused = useIsFocused();
  useEffect(() => {
    if (isFocused) {
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey[0] === ReactQueryKeys.GET_MODELS &&
          query.queryKey[2] === RECENTLY_VIEWED_MODELS_PER_PAGE &&
          query.queryKey[3] === '' &&
          query.queryKey[4] === null &&
          query.queryKey[5] === null &&
          query.queryKey[6] === true &&
          query.queryKey[7] === false,
      });
    }
  }, [isFocused]);

  return (
    <ModelCardsWrapper>
      {displayedModels.length ? (
        displayedModels.map((model: IModel, index: number) => (
          <ModelCard
            model={model}
            key={`recently-viewed-${index}`}
            onToggleFavoriteSuccess={() => {
              queryClient.invalidateQueries([ReactQueryKeys.GET_MODELS]);
              queryClient.invalidateQueries([
                ReactQueryKeys.GET_MODELS_INFINITE,
              ]);
            }}
          />
        ))
      ) : isLoading && !displayedModels.length ? (
        Array.from({ length: maxDisplayedModelCardAmount }, (_, index) => (
          <ModelCardSkeleton
            key={index}
            index={index}
            opacityCoeficient={0.15}
          />
        ))
      ) : (
        <NoContentLabel>{t('No recently viewed models found')}</NoContentLabel>
      )}
    </ModelCardsWrapper>
  );
};

export default RecentlyViewedSectionGrid;
