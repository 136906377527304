import { useDrag } from 'react-dnd';

export interface IFieldDrag {
  fieldLabel: string;
  fieldType: string;
}

export const useFieldDrag = (fieldLabel: string, fieldType: string) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'input-option',
    item: { fieldLabel, fieldType },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  return { isDragging, drag };
};
