import Button from 'components/Button/Button';
import { capitalizeFirstLetter } from 'utils/stringUtils';
import {
  getArrowImageFromColumnSortOption,
  getSortedTableRows,
  handleSortPress,
  handleCheckboxClick,
  includesByValue,
  getButtonLabel,
} from './helpers';
import {
  ButtonsWrapper,
  Checkbox,
  Container,
  CustomTable,
  HCell,
  TD,
  TableWrapper,
  TdLabel,
} from './OptionsTable.styled';
import { useState } from 'react';
import { IColumnSort, SortOrder } from './types';
import { useTranslation } from 'react-i18next';
import Icon from 'components/Icon/Icon';

interface IOptionsTableProps {
  isMultiple: boolean;
  initialSelectedOptions: any[];
  tableRows: any[];
  headerData: string[];
  onCancel: () => void;
  onSubmit: (selectedOptions: any[]) => void;
}

export const OptionsTable = ({
  isMultiple,
  initialSelectedOptions = [],
  headerData,
  tableRows,
  onSubmit,
  onCancel,
}: IOptionsTableProps) => {
  const { t } = useTranslation();
  const [columnSort, setColumnSort] = useState<IColumnSort>({
    columnIndex: null,
    sortOrder: SortOrder.NONE,
  });
  const [selectedOptions, setSelectedOptions] = useState<any[]>([
    ...initialSelectedOptions,
  ]);

  const renderTableHeaderData = () => {
    return (
      <tr>
        <th></th>
        <th></th>
        {headerData.map((thString, index) => (
          <th
            key={index}
            onClick={() => handleSortPress(index, columnSort, setColumnSort)}
          >
            <HCell>
              {t(capitalizeFirstLetter(thString).replace('_', ' '))}
              <Icon
                svg={getArrowImageFromColumnSortOption(index, columnSort)}
                onClick={() => null}
              />
            </HCell>
          </th>
        ))}
        <th></th>
      </tr>
    );
  };

  const renderTableBodyData = () => {
    let sortedTableRows;
    if (columnSort.columnIndex !== null) {
      sortedTableRows = getSortedTableRows([...tableRows], columnSort);
    } else {
      sortedTableRows = tableRows;
    }
    return sortedTableRows.map((row, index) => {
      const isSelected = includesByValue(selectedOptions, row);
      return (
        <tr
          key={index}
          onClick={() =>
            handleCheckboxClick(
              isMultiple,
              row,
              selectedOptions,
              setSelectedOptions
            )
          }
        >
          <td></td>
          <td>
            <Checkbox isSelected={isSelected} />
          </td>
          {row.map((cell: string, cellIndex: number) => {
            return (
              <TD key={cellIndex}>
                <TdLabel>{cell.toString()}</TdLabel>
              </TD>
            );
          })}
          <td></td>
        </tr>
      );
    });
  };

  return (
    <Container>
      <TableWrapper optionCount={tableRows.length}>
        <CustomTable>
          <thead>{renderTableHeaderData()}</thead>
          <tbody>{renderTableBodyData()}</tbody>
        </CustomTable>
      </TableWrapper>
      <ButtonsWrapper>
        <Button
          label={t('Cancel')}
          width="200rem"
          secondary
          onClick={() => onCancel()}
        />
        <Button
          disabled={!initialSelectedOptions.length && !selectedOptions.length}
          label={getButtonLabel(selectedOptions, initialSelectedOptions, t)}
          width="200rem"
          primary
          onClick={() => onSubmit(selectedOptions)}
        />
      </ButtonsWrapper>
    </Container>
  );
};

export default OptionsTable;
