import { COLORS, marginSm } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

export const Container = styled.div``;

export const Title = styled.label`
  color: ${COLORS.BLACK};
  font-size: 30rem;
  font-weight: 300;
  margin-bottom: ${marginSm};
  display: inline-block;

  ${respondTo.bigTablet`
    font-size: 26rem;
  `}

  ${respondTo.midTablet`
    font-size: 24rem;
    margin-bottom: 10rem;
  `}

  ${respondTo.smallTablet`
    font-size: 20rem;
    font-weight: bold;
  `}
`;
