import apiClient from 'services/api/apiService';
import { getUrlWithQueryParams } from './api/getUrlWithQueryParams';
import { IUpdateCompanySettingsDTO } from 'pages/SettingsPage/SettingsRow/hooks';

const BASE_URL = '/v1/companies';

export const getCompanies = async (
  page: number,
  perPage: number,
  searchBy: string
): Promise<any> => {
  const url = `${BASE_URL}`;
  const queryParamsObj = {
    page,
    per_page: perPage,
    search_term: searchBy,
  };
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);
  const { data } = await apiClient.get<any>(fullUrl);

  return data.data;
};

export const getCompanyUsers = async (
  companyId: number,
  page: number,
  perPage: number,
  searchBy: string
): Promise<any> => {
  const queryParamsObj = {
    page,
    per_page: perPage,
    search_term: searchBy,
  };
  const url = `${BASE_URL}/${companyId}/users`;
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);
  const { data } = await apiClient.get<any>(fullUrl);

  return data.data;
};

export const addCompanyUser = async (
  email: string,
  companyId: number
): Promise<any> => {
  const url = `${BASE_URL}/${companyId}/users`;
  const { data } = await apiClient.post<any>(url, {
    email,
  });
  // Need the response message to show alternative toast if registration link was sent
  return data;
};

export const deleteCompanyUser = async (
  userId: number,
  supplierId: number
): Promise<any> => {
  const url = `${BASE_URL}/${supplierId}/users/${userId}`;
  const { data } = await apiClient.delete<any>(url);

  return data.data;
};

export const getCompany = async (companyId: number): Promise<any> => {
  const url = `${BASE_URL}/${companyId}`;
  const { data } = await apiClient.get<any>(url);
  return data.data;
};

export const updateCompanySettings = async (
  companyId: number,
  dto: IUpdateCompanySettingsDTO
): Promise<any> => {
  const url = `${BASE_URL}/${companyId}`;
  const { data } = await apiClient.patch<any>(url, dto);

  return data.data;
};

export const uploadCompanyLogo = async (
  companyId: number,
  formData: FormData
) => {
  const headers = { 'Content-Type': 'multipart/form-data' };
  const url = `${BASE_URL}/${companyId}/logo`;
  const { data } = await apiClient.post(url, formData, { headers });

  return data.data;
};
