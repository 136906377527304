import { Dispatch, SetStateAction } from 'react';
import { IColumnSort, SortOrder } from './types';
import {
  ArrowsDownUp,
  SortAscending,
  SortDescending,
} from '@phosphor-icons/react';

export const handleCheckboxClick = (
  isMultiple: boolean,
  row: any,
  selectedOptions: any[],
  setSelectedOptions: Dispatch<SetStateAction<any[]>>
) => {
  const isAlreadySelected = includesByValue(selectedOptions, row);
  if (isMultiple) {
    if (!isAlreadySelected) {
      setSelectedOptions([...selectedOptions, row]);
    } else {
      const optionsWithoutSelectedRow = selectedOptions.filter(
        (selectedRow) => !areArraysEqual(selectedRow, row)
      );
      setSelectedOptions(optionsWithoutSelectedRow);
    }
  } else {
    if (!isAlreadySelected) {
      setSelectedOptions([row]);
    } else {
      setSelectedOptions([]);
    }
  }
};

export const handleSortPress = (
  columnIndex: number,
  columnSort: IColumnSort,
  setColumnSort: Dispatch<SetStateAction<IColumnSort>>
) => {
  const newColumnSort: IColumnSort = {
    columnIndex: columnIndex,
    sortOrder: SortOrder.NONE, // Temp
  };
  switch (columnSort.sortOrder) {
    case SortOrder.NONE:
      newColumnSort.sortOrder = SortOrder.DESC;
      break;
    case SortOrder.DESC:
      newColumnSort.sortOrder = SortOrder.ASC;
      break;
    case SortOrder.ASC:
      newColumnSort.columnIndex = null;
      newColumnSort.sortOrder = SortOrder.NONE;
      break;
  }
  setColumnSort(newColumnSort);
};

export const getArrowImageFromColumnSortOption = (
  columnIndex: number,
  columnSort: IColumnSort
) => {
  if (columnIndex === columnSort.columnIndex) {
    switch (columnSort.sortOrder) {
      case SortOrder.NONE:
        return ArrowsDownUp;
      case SortOrder.ASC:
        return SortAscending;
      case SortOrder.DESC:
        return SortDescending;
    }
  } else {
    return ArrowsDownUp;
  }
};

export const getSortedTableRows = (
  tableRows: any[],
  columnSort: IColumnSort
) => {
  switch (columnSort.sortOrder) {
    case SortOrder.NONE:
      return tableRows;
    case SortOrder.ASC:
      return tableRows.sort((a: any[], b: any[]) => {
        if (columnSort.columnIndex !== null) {
          if (
            typeof a[columnSort.columnIndex] === 'number' &&
            typeof b[columnSort.columnIndex] === 'number'
          ) {
            return a[columnSort.columnIndex] - b[columnSort.columnIndex];
          } else {
            return (
              Number(a[columnSort.columnIndex]) -
              Number(b[columnSort.columnIndex])
            );
          }
        } else {
          // This wont be returned, "Array.prototype.sort() expects a value to be returned at the end of arrow function."
          return -1;
        }
      });
    case SortOrder.DESC:
      return tableRows
        .sort((a: any[], b: any[]) => {
          if (columnSort.columnIndex !== null) {
            if (
              typeof a[columnSort.columnIndex] === 'number' &&
              typeof b[columnSort.columnIndex] === 'number'
            ) {
              return a[columnSort.columnIndex] - b[columnSort.columnIndex];
            } else {
              return (
                Number(a[columnSort.columnIndex]) -
                Number(b[columnSort.columnIndex])
              );
            }
          } else {
            // This wont be returned, "Array.prototype.sort() expects a value to be returned at the end of arrow function."
            return -1;
          }
        })
        .reverse();
  }
};

// Arrays consisting of primitive types can use the includes() method for inclusion checking, but more complex arrays require inclusion checking by value because they might have a different reference while still having the same values in their elements
export const includesByValue = (selectedOptions: any[], row: any[]) => {
  return selectedOptions.some((selectedOption) => {
    return (
      selectedOption.length === row.length &&
      selectedOption.every((value: any, index: number) => {
        return value === row[index];
      })
    );
  });
};

// Arrays consisting of primitive types can be compared with the "===" operator but arrays consisting of complex elements require a different solution
export const areArraysEqual = (arr1: any[], arr2: any[]) => {
  return JSON.stringify(arr1) === JSON.stringify(arr2);
};

export const getButtonLabel = (
  selectedOptions: string[],
  initialSelectedOptions: string[],
  t: (str: string) => string
) => {
  if (selectedOptions.length) {
    return selectedOptions.length === 1
      ? t('Choose 1 option')
      : t('Choose {OPTION_COUNT} options').replace(
          '{OPTION_COUNT}',
          selectedOptions.length.toString()
        );
  } else {
    if (initialSelectedOptions.length) {
      return t('Clear options');
    } else {
      return t('Select an option');
    }
  }
};
