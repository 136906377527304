import {
  Container,
  Label,
  LeftPart,
  RightPart,
} from '../SupplierCompanyRow.styled';
import { Skeleton } from '@mui/material';
import { CircleSkeleton, StyledSkeleton } from '../SupplierCompanyRow.styled';

interface ISupplierCompanyRowSkeletonProps {
  index: number;
  opacityCoeficient?: number;
  withoutLine: boolean;
}

const SupplierCompanyRowSkeleton = ({
  index,
  opacityCoeficient = 0.2,
  withoutLine,
}: ISupplierCompanyRowSkeletonProps) => {
  return (
    <Container
      withoutLine={withoutLine}
      opacityIndex={index}
      opacityCoeficient={opacityCoeficient}
    >
      <LeftPart>
        <Skeleton width={'100rem'} animation="wave" />
        <CircleSkeleton
          width={'26rem'}
          height={'26rem'}
          variant="rounded"
          animation="wave"
        />
      </LeftPart>
      <RightPart>
        <Label>
          <Skeleton width={'100rem'} animation="wave" />
        </Label>
        <StyledSkeleton width={'100rem'} height={'35rem'} animation="wave" />
      </RightPart>
    </Container>
  );
};

export default SupplierCompanyRowSkeleton;
