import { COLORS, marginSm, paddingMd } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

export const Container = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30rem;
  padding: ${paddingMd};

  ${respondTo.bigPhone`
    padding: 15rem;
    gap: 20rem;
  `}
`;

export const Title = styled.label`
  padding-left: 30rem;
  padding-right: 30rem;
  font-weight: 400;
  font-size: 24rem;
  line-height: 32rem;
  color: ${COLORS.BLACK};
  text-align: center;

  ${respondTo.bigPhone`
    font-size: 20rem;
  `}
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${marginSm};
`;

export const ErrorMessage = styled.div`
  position: absolute;
  font-weight: 400;
  font-size: 12rem;
  color: ${COLORS.RED_100};
  right: 0rem;
`;

export const RelativeWrapper = styled.div`
  position: relative;
`;
