import { IOption } from 'components/Select/type';
import { IQuestion } from 'types/Question.types';
import { IColor } from './ColorModal';

export const extractColorsFromQuestion = (question: IQuestion): IColor[] => {
  const outputKeys = Object.keys(question.outputs);

  return question.options.map((option: any[]) => {
    const color: any = {};
    outputKeys.forEach((key, index) => {
      // Set each property of the IColor object from the corresponding index in the options array
      // Uses the key from outputs as the field name
      color[key] = option[index] ? option[index].toString() : '';
    });
    return color;
  });
};

export const extractColorCollectionOptions = (colors: IColor[]): IOption[] => {
  // Create a set to ensure all collections are unique
  const collectionsSet = new Set<string>();

  // Iterate over each color and add its collection to the set if it's not an empty string
  colors.forEach((color) => {
    if (color.collection) {
      // Check if collection is not empty
      collectionsSet.add(color.collection);
    }
  });

  // Convert the set back to an array and return
  const colorCollections: IOption[] = Array.from(collectionsSet).map(
    (collectionString: string) => {
      return { label: collectionString, value: collectionString };
    }
  );
  colorCollections.push({ label: 'All collections', value: '' });
  return colorCollections;
};

export const filterColorsByCollectionAndSearchTerm = (
  colors: IColor[],
  selectedCollection: string,
  colorSearchBy: string
) => {
  return colors.filter((color: IColor) => {
    let matches = false;
    const colorKeys = Object.keys(color);
    if (selectedCollection && color.collection !== selectedCollection) {
      return false;
    }
    if (!colorSearchBy) {
      return true;
    }
    for (const colorKey of colorKeys) {
      if (['popular', 'image'].includes(colorKey)) {
        continue;
      }
      if (color[colorKey].toLowerCase().includes(colorSearchBy.toLowerCase())) {
        matches = true;
      }
    }

    return matches;
  });
};

// Function that determines whether a black or white label should be used based on the luminance of a hex color
export const getContrastingLabelColor = (hexColor: string): string => {
  // Normalize hex color to full 6 characters
  if (hexColor.length === 4) {
    hexColor =
      '#' +
      hexColor[1] +
      hexColor[1] +
      hexColor[2] +
      hexColor[2] +
      hexColor[3] +
      hexColor[3];
  }
  // Convert hex to RGB
  const r = parseInt(hexColor.slice(1, 3), 16);
  const g = parseInt(hexColor.slice(3, 5), 16);
  const b = parseInt(hexColor.slice(5, 7), 16);

  // Calculate luminance
  const luminance =
    0.2126 * (r / 255) ** 2.2 +
    0.7152 * (g / 255) ** 2.2 +
    0.0722 * (b / 255) ** 2.2;

  // Determine contrast color (simple threshold)
  return luminance > 0.179 ? '#000000' : '#FFFFFF';
};

export const getIsColorSelected = (
  color: IColor,
  chosenColor: IColor | undefined
) => {
  if (color?.code) {
    return color.code === chosenColor?.code;
  } else {
    return color.name === chosenColor?.name;
  }
};
