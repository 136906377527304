import styled from 'styled-components';

export const Overlay = styled.div`
  position: absolute;
  inset: 0;
  z-index: 10; // Above ImageMagnifier
  display: flex;
  align-items: center;
  justify-content: center;
`;
