import { AnyAction } from '@reduxjs/toolkit';
import { IQuestion } from 'types/Question.types';
import { CLOSE_TABLE_MODAL, SET_TABLE_MODAL_DATA } from '../tableModal';

export interface ITableModalReducerState {
  isOpen: boolean;
  question: IQuestion | null;
}

const INIT_STATE: ITableModalReducerState = {
  isOpen: true,
  question: null,
};

const tableModalReducer = (state = INIT_STATE, action: AnyAction) => {
  switch (action.type) {
    case CLOSE_TABLE_MODAL:
      return {
        ...state,
        isOpen: false,
        question: null,
      };
    case SET_TABLE_MODAL_DATA:
      return {
        ...state,
        isOpen: true,
        question: action.payload.question,
      };
    default:
      return state;
  }
};

export default tableModalReducer;
