import {
  CircleSkeleton,
  Container,
  Label,
  LeftPart,
  RightPart,
  StyledSkeleton,
} from '../ModelRow.styled';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { Skeleton } from '@mui/material';
import { AppMode } from 'store/Common/reducers/common';

interface IModelRowSkeletonProps {
  index: number;
  opacityCoeficient?: number;
  withoutLine: boolean;
  withoutCategory?: boolean;
}

const ModelRowSkeleton = ({
  index,
  opacityCoeficient = 0.2,
  withoutLine,
  withoutCategory = false,
}: IModelRowSkeletonProps) => {
  const appMode: AppMode = useSelector(
    (state: IRootReducerState) => state.commonInfo.appMode
  );

  return (
    <Container
      opacityIndex={index}
      opacityCoeficient={opacityCoeficient}
      withoutLine={withoutLine}
    >
      <LeftPart>
        <Skeleton width={'170rem'} animation="wave" />
        <CircleSkeleton
          animation="wave"
          variant="rounded"
          height={'20rem'}
          width={'20rem'}
        />
      </LeftPart>
      <RightPart>
        {withoutCategory ? null : (
          <Skeleton width={'100rem'} animation="wave" />
        )}
        <Label>
          <Skeleton width={'70rem'} animation="wave" />
        </Label>
        <Label>
          <Skeleton width={'70rem'} animation="wave" />
        </Label>
        {appMode === AppMode.COMPANY ? (
          <StyledSkeleton width={'100rem'} height={'35rem'} animation="wave" />
        ) : null}
        {appMode === AppMode.SUPPLIER ? (
          <CircleSkeleton
            animation="wave"
            variant="rounded"
            height={'26rem'}
            width={'26rem'}
          />
        ) : null}
        <CircleSkeleton
          animation="wave"
          variant="rounded"
          height={'26rem'}
          width={'26rem'}
        />
      </RightPart>
    </Container>
  );
};

export default ModelRowSkeleton;
