import { useTranslation } from 'react-i18next';
import {
  Container,
  ErrorMessage,
  ErrorWrapper,
  FieldName,
  Row,
  RowName,
  SwitchWrapper,
  ToggleText,
} from '../ConfigForms.styled';
import { Form, Formik, FormikProps } from 'formik';
import { useRef, useState } from 'react';
import { Input } from 'components/Input/InputFormik';
import Icon from 'components/Icon/Icon';
import { Asterisk } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import { ICustomModel, INumberInputField } from 'types/Builder.types';
import {
  NumberValidationSchema,
  validateDefaultFieldNumber,
} from '../validations';
import Tooltip from 'components/Tooltip/Tooltip';

interface IProps {
  currentSelectedField: INumberInputField;
  updateModel: (values: any) => void;
  model: ICustomModel;
}

const NumberForm = ({ currentSelectedField, updateModel, model }: IProps) => {
  const { t } = useTranslation();
  const formikRef = useRef<FormikProps<any> | any>();
  const [activeField, setActiveField] = useState<string | null>(null);

  const initialValues = {
    field_label: currentSelectedField.field_label,
    description: currentSelectedField.description,
    default: currentSelectedField.default,
    min: currentSelectedField.min,
    max: currentSelectedField.max,
    required: currentSelectedField.required,
  };

  return (
    <Container>
      <Formik
        innerRef={formikRef}
        enableReinitialize
        initialValues={initialValues}
        validationSchema={NumberValidationSchema}
        validateOnChange={true}
        validateOnMount={true}
        validateOnBlur={true}
        onSubmit={() => {
          //
        }}
      >
        {({ handleBlur, setFieldValue, isValid, values, errors, touched }) => {
          if (
            isValid &&
            activeField === null &&
            JSON.stringify(values) !== JSON.stringify(initialValues)
          ) {
            updateModel(values);
          }

          const handleBlurFunction = (e: any, name: string, type?: string) => {
            let value = e.target.value.trim();

            if (
              (name === 'min' || name === 'max' || name === 'default') &&
              value === ''
            ) {
              value = null;
            } else if (type === 'number') {
              value = Number(value);
            }

            setFieldValue(name, value);
            handleBlur(e);
            setActiveField(null);
          };

          // Block setModal while user focus field
          const handleFocus = (name: string) => {
            setActiveField(name);
          };

          return (
            <Form>
              <Row>
                <RowName>
                  {t('Field Label')}
                  <Icon svg={Asterisk} size={10} color={COLORS.RED_100} />
                </RowName>
                <Input
                  name="field_label"
                  placeholder={t('Field Label')}
                  height={'40rem'}
                  wrapperStyles={{ width: '100%' }}
                  onBlur={(e) => {
                    handleBlurFunction(e, 'field_label');
                  }}
                  onFocus={() => handleFocus('field_label')}
                />
                <ErrorWrapper>
                  <ErrorMessage>
                    {touched['field_label'] ? errors['field_label'] : null}
                  </ErrorMessage>
                </ErrorWrapper>
              </Row>
              <Row>
                <RowName>{t('Description')}</RowName>
                <Input
                  name="description"
                  isTextArea
                  placeholder={t('Description')}
                  height={'100rem'}
                  wrapperStyles={{ width: '100%' }}
                  onBlur={(e) => {
                    handleBlurFunction(e, 'description');
                  }}
                  onFocus={() => handleFocus('description')}
                />
                <ErrorWrapper>
                  <ErrorMessage>
                    {touched['description'] ? errors['description'] : null}
                  </ErrorMessage>
                </ErrorWrapper>
              </Row>
              <Row>
                <RowName>{t('Default value')}</RowName>
                <Input
                  name="default"
                  type="number"
                  hideNumberArrows
                  placeholder={t('Default value')}
                  height={'40rem'}
                  wrapperStyles={{ width: '100%' }}
                  onBlur={(e) => {
                    handleBlurFunction(e, 'default', 'number');
                  }}
                  onFocus={() => handleFocus('default')}
                  validate={(value: string) => {
                    if (
                      currentSelectedField.id !==
                        model.price_matrices[0].x_axis_id ||
                      currentSelectedField.id !==
                        model.price_matrices[0].y_axis_id
                    ) {
                      return validateDefaultFieldNumber(
                        values.min,
                        values.max,
                        Number(value)
                      );
                    }
                  }}
                />
                <ErrorWrapper>
                  <ErrorMessage>
                    {errors['default'] ? errors['default'] : null}
                  </ErrorMessage>
                </ErrorWrapper>
              </Row>
              <Row>
                <RowName>{t('Min/Max Characters')}</RowName>
                <FieldName>{t('From')}:</FieldName>
                <Tooltip
                  content={
                    currentSelectedField.id ===
                      model.price_matrices[0].x_axis_id ||
                    currentSelectedField.id ===
                      model.price_matrices[0].y_axis_id
                      ? t('Min is blocked when matrix price is connected')
                      : undefined
                  }
                >
                  <Input
                    name="min"
                    placeholder={t('Min')}
                    type="number"
                    hideNumberArrows
                    height={'40rem'}
                    disabled={
                      currentSelectedField.id ===
                        model.price_matrices[0].x_axis_id ||
                      currentSelectedField.id ===
                        model.price_matrices[0].y_axis_id
                    }
                    wrapperStyles={{ width: '100%' }}
                    onBlur={(e) => {
                      handleBlurFunction(e, 'min', 'number');
                      setTimeout(() => {
                        formikRef.current?.validateField('default');
                      }, 0);
                    }}
                    onFocus={() => handleFocus('min')}
                  />
                </Tooltip>
                <ErrorWrapper>
                  <ErrorMessage>
                    {touched['min'] ? errors['min'] : null}
                  </ErrorMessage>
                </ErrorWrapper>
                <FieldName>{t('To')}:</FieldName>
                <Tooltip
                  content={
                    currentSelectedField.id ===
                      model.price_matrices[0].x_axis_id ||
                    currentSelectedField.id ===
                      model.price_matrices[0].y_axis_id
                      ? t('Max is blocked when matrix price is connected')
                      : undefined
                  }
                >
                  <Input
                    name="max"
                    placeholder={t('Max')}
                    height={'40rem'}
                    type="number"
                    hideNumberArrows
                    disabled={
                      currentSelectedField.id ===
                        model.price_matrices[0].x_axis_id ||
                      currentSelectedField.id ===
                        model.price_matrices[0].y_axis_id
                    }
                    wrapperStyles={{ width: '100%' }}
                    onBlur={(e) => {
                      handleBlurFunction(e, 'max', 'number');
                      setTimeout(() => {
                        formikRef.current?.validateField('default');
                      }, 0);
                    }}
                    onFocus={() => handleFocus('max')}
                  />
                </Tooltip>
                <ErrorWrapper>
                  <ErrorMessage>
                    {touched['max'] ? errors['max'] : null}
                  </ErrorMessage>
                </ErrorWrapper>
              </Row>
              <Row>
                <RowName>{t('Required')}</RowName>
                <SwitchWrapper>
                  <ToggleText isOff={values.required}>
                    {values.required ? t('On') : t('Off')}
                  </ToggleText>
                  <ToggleSwitch
                    isChecked={values.required}
                    size="large"
                    onClick={() => setFieldValue('required', !values.required)}
                  />
                </SwitchWrapper>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
};

export default NumberForm;
