import { Dispatch, SetStateAction } from 'react';
import { Modal } from '../Modal/Modal';
import {
  ButtonContainer,
  Title,
  TitleContainer,
  TopContainer,
  Margin,
  Description,
  ContentContainer,
} from './DangerModal.styled';

import Button from 'components/Button/Button';
import { useTranslation } from 'react-i18next';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';
import { Level } from '../Modal/type';

interface IDangerProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  submit: { onClick: () => void; text: string; disabled: boolean };
  cancel?: { onClick: () => void; text?: string };
  description?: string;
  title: string;
  level?: Level;
}

export const DangerModal = ({
  isOpen,
  setIsOpen,
  submit,
  cancel,
  description,
  title,
  level = 'FIRST',
}: IDangerProps) => {
  const { t } = useTranslation();
  const { isPhone, isSmallPhone } = useBreakpointFlag();
  return (
    <Modal
      isOpen={isOpen}
      level={level}
      onClose={() => {
        cancel?.onClick();
        setIsOpen(false);
      }}
      modalStyle={{
        position: 'fixed',
        margin: 'auto',
        width: isSmallPhone ? '90%' : '500rem',
        minWidth: isSmallPhone ? '90%' : 'auto',
      }}
    >
      <ContentContainer>
        <TopContainer>
          <TitleContainer>
            <Title>{title}</Title>
          </TitleContainer>
          <TitleContainer>
            <Description>{description}</Description>
          </TitleContainer>
        </TopContainer>
        <ButtonContainer>
          <Margin>
            <Button
              onClick={cancel?.onClick}
              secondary
              width={isPhone ? '100rem' : '200rem'}
              label={cancel?.text ? cancel.text : t('Cancel')}
            />
          </Margin>
          <Button
            red
            width={isPhone ? '100rem' : '200rem'}
            label={submit?.text ? submit.text : t('Delete')}
            onClick={() => submit.onClick()}
            disabled={submit.disabled}
          />
        </ButtonContainer>
      </ContentContainer>
    </Modal>
  );
};
