import { Route, Routes } from 'react-router-dom';
import { RoutesConfig } from './routes';
import DashboardLayout from 'components/Layout/DashboardLayout/DashboardLayout';
import ConfigurePage from 'pages/ConfigurePage/ConfigurePage';
import PageNotFound from 'pages/PageNotFound/PageNotFound';
import ModelsPage from 'pages/ModelsPage/ModelsPage';
import ShoppingCartPage from 'pages/ShoppingCartPage/ShoppingCartPage';
import LoadingScreenOverlayProvider from 'providers/LoadingScreenOverlayProvider/LoadingScreenOverlayProvider';
import LoginPage from 'pages/Auth/LoginPage/LoginPage';
import PrivateRoute from './Routes/PrivateRoute/PrivateRoute';
import PublicRoute from './Routes/PublicRoute/PublicRoute';
import CompanySelectPage from 'pages/Auth/CompanySelectPage/CompanySelectPage';
import AllModelsPage from 'pages/AllModelsPage/AllModelsPage';
import SupplierSelectPage from 'pages/Auth/SupplierSelectPage/SupplierSelectPage';
import SupplierModelCompaniesPage from 'pages/Supplier/SupplierModelCompaniesPage/SupplierModelCompaniesPage';
import SupplierCompaniesPage from 'pages/Supplier/SupplierCompaniesPage/SupplierCompaniesPage';
import SupplierSingleCompanyPage from 'pages/Supplier/SupplierSingleCompanyPage/SupplierSingleCompanyPage';
import MyModelsPage from 'pages/MyModelsPage/MyModelsPage';
import UsersPage from 'pages/UsersPage/UsersPage';
import ProfilePage from 'pages/ProfilePage/ProfilePage';
import RequestPasswordResetPage from 'pages/Auth/RequestPasswordResetPage/RequestPasswordResetPage';
import ResetPasswordPage from 'pages/Auth/ResetPasswordPage/ResetPasswordPage';
import RegisterPage from 'pages/Auth/RegisterPage/RegisterPage';
import SuccessfulRegistrationPage from 'pages/Auth/SuccessfulRegistrationPage/SuccessfulRegistrationPage';
import { AppMode } from 'store/Common/reducers/common';
import SharedRoute from './Routes/SharedRoute/SharedRoute';
import { DealerPanelSuppliersPage } from 'pages/DealerPanel/DealerPanelSuppliersPage/DealerPanelSuppliersPage';
import { DealerPanelModelsPage } from 'pages/DealerPanel/DealerPanelModelsPage/DealerPanelModelsPage';
import { SettingsPage } from 'pages/SettingsPage/SettingsPage';
import { RootRedirect } from './Routes/RootRedirect/RootRedirect';
import CustomModels from 'pages/CustomModels/CustomModels';
import Builder from 'pages/Builder/Builder';
import SingleCustomModel from 'pages/SingleCustomModel/SingleCustomModel';

export default function AppNavigationRouter() {
  return (
    <Routes>
      <Route path="*" element={<PageNotFound />} />
      <Route element={<DashboardLayout />}>
        <Route path={'/'} element={<PrivateRoute />}>
          <Route path={'/'} element={<LoadingScreenOverlayProvider />}>
            <Route path={'/'} element={<RootRedirect />} />
            <Route
              path={'/'}
              element={
                <SharedRoute
                  appModes={[AppMode.DEALER_PANEL]}
                  redirectTo={'/'}
                />
              }
            >
              <Route
                path={RoutesConfig.DealerPanelSuppliers.fullPath}
                element={<DealerPanelSuppliersPage />}
              />
              <Route
                path={RoutesConfig.DealerPanelModels.fullPath}
                element={<DealerPanelModelsPage />}
              />
            </Route>
            <Route
              path={'/'}
              element={
                <SharedRoute
                  appModes={[
                    AppMode.COMPANY,
                    AppMode.BLUEPRINT,
                    AppMode.DEALER_PANEL,
                    AppMode.SUPPLIER,
                  ]}
                  redirectTo={'/'}
                />
              }
            >
              <Route
                path={RoutesConfig.Configure.fullPath}
                element={<ConfigurePage />}
              />
            </Route>
            <Route
              path={'/'}
              element={
                <SharedRoute
                  appModes={[AppMode.SUPPLIER, AppMode.DEALER_PANEL]}
                  redirectTo={'/'}
                />
              }
            >
              <Route
                path={RoutesConfig.CustomModels.fullPath}
                element={<CustomModels />}
              />
              <Route
                path={RoutesConfig.SingleCustomModel.fullPath}
                element={<SingleCustomModel />}
              />
              <Route
                path={RoutesConfig.Builder.fullPath}
                element={<Builder />}
              />
            </Route>
            <Route
              path={'/'}
              element={
                <SharedRoute
                  appModes={[AppMode.COMPANY, AppMode.SUPPLIER]}
                  redirectTo={'/'}
                />
              }
            >
              <Route
                path={RoutesConfig.Profile.fullPath}
                element={<ProfilePage />}
              />
              <Route
                path={RoutesConfig.Users.fullPath}
                element={<UsersPage />}
              />
              <Route
                path={RoutesConfig.Settings.fullPath}
                element={<SettingsPage />}
              />
            </Route>
            <Route
              path={'/'}
              element={
                <SharedRoute appModes={[AppMode.COMPANY]} redirectTo={'/'} />
              }
            >
              <Route
                path={RoutesConfig.Models.fullPath}
                element={<ModelsPage />}
              />
              <Route
                path={RoutesConfig.AllModels.fullPath}
                element={<AllModelsPage />}
              />
              <Route
                path={RoutesConfig.ShoppingCart.fullPath}
                element={<ShoppingCartPage />}
              />
            </Route>
            <Route
              path={'/'}
              element={
                <SharedRoute appModes={[AppMode.SUPPLIER]} redirectTo={'/'} />
              }
            >
              <Route
                path={RoutesConfig.MyModels.fullPath}
                element={<MyModelsPage />}
              />
              <Route
                path={RoutesConfig.SupplierModelCompanies.fullPath}
                element={<SupplierModelCompaniesPage />}
              />
              <Route
                path={RoutesConfig.SupplierCompanies.fullPath}
                element={<SupplierCompaniesPage />}
              />
              <Route
                path={RoutesConfig.SupplierSingleCompany.fullPath}
                element={<SupplierSingleCompanyPage />}
              />
            </Route>
          </Route>
        </Route>
      </Route>
      <Route path={'/auth'} element={<PublicRoute />}>
        <Route path={'/auth'} element={<LoadingScreenOverlayProvider />}>
          <Route path={RoutesConfig.Login.fullPath} element={<LoginPage />} />
          <Route
            path={RoutesConfig.Register.fullPath}
            element={<RegisterPage />}
          />
          <Route
            path={RoutesConfig.SuccessfulRegistration.fullPath}
            element={<SuccessfulRegistrationPage />}
          />
          <Route
            path={RoutesConfig.RequestPasswordResetPage.fullPath}
            element={<RequestPasswordResetPage />}
          />
          <Route
            path={RoutesConfig.ResetPassword.fullPath}
            element={<ResetPasswordPage />}
          />
          <Route
            path={RoutesConfig.CompanySelect.fullPath}
            element={<CompanySelectPage />}
          />
          <Route
            path={RoutesConfig.SupplierSelect.fullPath}
            element={<SupplierSelectPage />}
          />
        </Route>
      </Route>
    </Routes>
  );
}
