import { useMutation } from 'react-query';
import { getToastErrorMessage, LogyxError } from 'services/api/errors';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { toast } from 'react-toastify';
import { exportPriceMatrix, importPriceMatrix } from 'services/BuilderService';
import { OptionMatrixEntry } from 'types/Builder.types';

export const useImportPriceMatrix = () =>
  useMutation(
    (blob: Blob) => {
      const formData = new FormData();
      formData.append('file', blob);
      return importPriceMatrix(formData);
    },
    {
      onError: (error: LogyxError) => {
        toast.error(getToastErrorMessage(error));
      },
      mutationKey: ReactMutationKeys.IMPORT_PRICE_MATRIX,
    }
  );

export const useExportPriceMatrix = (onSuccess?: (data: any) => void) => {
  return useMutation(
    (params: {
      name: string;
      filetype: string;
      matrix: OptionMatrixEntry[];
    }) => {
      return exportPriceMatrix(params.name, params.filetype, params.matrix);
    },
    {
      onSuccess: (data) => {
        if (onSuccess) onSuccess(data);
      },
      onError: (error: LogyxError) => {
        toast.error(getToastErrorMessage(error));
      },
      mutationKey: ReactMutationKeys.SAVE_DRAFT,
    }
  );
};
