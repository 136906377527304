import { ButtonBase } from '@mui/material';
import { COLORS } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled, { css } from 'styled-components';

interface StyledProps {
  active: boolean;
  isDisabled: boolean;
  theme: any;
}

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${COLORS.GREEN};
  border-radius: 25rem;
`;

export const Text = styled.span`
  font-size: 16rem;
  display: inline-block;
  color: ${COLORS.GREEN};
  white-space: nowrap;
`;

export const Mode = styled(ButtonBase)`
  display: flex;
  justify-content: center;
  min-width: 150rem;
  border-radius: 21rem;
  background-color: transparent;
  padding: 10rem 20rem;
  cursor: pointer;

  ${(props: StyledProps) =>
    props.active &&
    css`
      background-color: ${COLORS.GREEN};
      ${Text} {
        color: ${COLORS.STRICT_WHITE};
      }
    `};

  ${(props: StyledProps) =>
    props.isDisabled &&
    css`
      ${Text} {
        color: ${props.theme.dark ? COLORS.BORDER : COLORS.BACKDROP};
      }
    `}

  ${respondTo.bigTablet`
    padding: 10rem 15rem;
    min-width: 140rem;
  `}
`;
