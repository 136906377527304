import { Container, LogyxLogoBigImg } from './LoadingScreenOverlay.styled';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import LogyxLogoGif from 'assets/images/LogyxLogoGif.gif';
import LogyxLogoGifWhite from 'assets/images/LogyxLogoGifWhite.gif';

interface IProps {
  isDarkMode?: boolean;
}

const LoadingScreenOverlay = ({ isDarkMode }: IProps) => {
  const { isMobileApp } = useSelector(
    (state: IRootReducerState) => state.commonInfo
  );

  // Generate a unique key each time the component renders
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [uniqueGifKey, _] = useState<number>(Date.now());

  return (
    <Container isDarkMode={isDarkMode}>
      <LogyxLogoBigImg
        src={`${isDarkMode ? LogyxLogoGifWhite : LogyxLogoGif}?${uniqueGifKey}`}
        alt=""
        isMobileApp={isMobileApp}
      />
    </Container>
  );
};

export default LoadingScreenOverlay;
