import { COLORS } from 'assets/styled';
import {
  StyledSkeleton,
  SkeletonRow,
  SkeletonTd,
} from './TableSkeleton.styled';

interface ITableSkeleton {
  numRows: number;
  numColumns: number;
}

const TableSkeleton = ({ numRows, numColumns }: ITableSkeleton) => {
  return (
    <>
      {Array.from(new Array(numRows)).map((_, index) => (
        <tr key={`table-skeleton-row-${index}`}>
          <SkeletonTd colSpan={numColumns}>
            <SkeletonRow style={index === 0 ? { marginTop: '10rem' } : {}}>
              <StyledSkeleton
                animation="wave"
                variant="rounded"
                height={68}
                sx={{
                  backgroundColor: `${COLORS.BACKGROUND}`,
                }}
              />
            </SkeletonRow>
          </SkeletonTd>
        </tr>
      ))}
    </>
  );
};

export default TableSkeleton;
