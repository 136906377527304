import { IUser } from 'types/User.types';
import {
  AbsoluteTrash,
  Grid,
  HorizontalLine,
  LabelKey,
  LabelValue,
} from './UserCard.styled';
import { useTranslation } from 'react-i18next';
import { handleTrueFalseLabel } from 'pages/ShoppingCartPage/ShoppingCartCard/helpers';
import Icon from 'components/Icon/Icon';
import { Trash } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';

interface IUserCardProps {
  user: IUser;
  onDelete: (userId: number) => void;
  isLast: boolean;
}
export const UserCard = ({ user, onDelete, isLast }: IUserCardProps) => {
  const { t } = useTranslation();
  return (
    <>
      <Grid>
        <AbsoluteTrash>
          <Icon
            onClick={() => onDelete(user.id)}
            svg={Trash}
            color={COLORS.RED_100}
          />
        </AbsoluteTrash>
        <LabelKey>{t('ID')}:</LabelKey>
        <LabelValue>{user.id}</LabelValue>
        <LabelKey>{t('First name')}:</LabelKey>
        <LabelValue>{user.first_name}</LabelValue>
        <LabelKey>{t('Last name')}:</LabelKey>
        <LabelValue>{user.last_name}</LabelValue>
        <LabelKey>{t('Email')}:</LabelKey>
        <LabelValue>{user.email}</LabelValue>
        <LabelKey>{t('Is active')}:</LabelKey>
        <LabelValue>
          {handleTrueFalseLabel(user.is_active.toString(), t)}
        </LabelValue>
      </Grid>
      {isLast ? null : <HorizontalLine />}
    </>
  );
};
