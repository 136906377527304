import { SUPPLIER_USERS_PER_PAGE } from 'pages/UsersPage/constants';
import { Dispatch, SetStateAction, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { useGetSupplierUsersInfinite } from './hooks';
import { getDataFromPages } from 'utils/getDataFromPages';
import { IUser } from 'types/User.types';
import {
  Card,
  FirstRow,
  RightSide,
  Title,
} from 'pages/UsersPage/UsersPage.styled';
import { MagnifyingGlass as Search } from '@phosphor-icons/react';
import Input from 'components/Input/Input';
import Button from 'components/Button/Button';
import { UserCard } from 'pages/UsersPage/UserCard/UserCard';
import { useLoadNextPage } from 'utils/hooks/useLoadNextPage';
import { UserCardSkeleton } from 'pages/UsersPage/UserCard/UserCardSkeleton';
import { InputWrapper } from 'pages/ModelsPage/ModelsPage.styled';

interface ISupplierUsersGridViewProps {
  searchBy: string;
  setSearchBy: (str: string) => void;
  debouncedSearchBy: string;
  onDelete: (userId: number) => void;
  setIsEmailInputModalOpen: Dispatch<SetStateAction<boolean>>;
}

export const SupplierUsersGridView = ({
  searchBy,
  setSearchBy,
  debouncedSearchBy,
  onDelete,
  setIsEmailInputModalOpen,
}: ISupplierUsersGridViewProps) => {
  const loadMoreRef = useRef<HTMLDivElement>(null);
  const supplier = useSelector(
    (state: IRootReducerState) => state.userInfo.supplier
  );
  const { t } = useTranslation();
  const {
    data: usersData,
    hasNextPage: hasNextPageUsers,
    fetchNextPage: fetchNextPageUsers,
    isFetching: isFetchingUsers,
  } = useGetSupplierUsersInfinite(
    supplier.id,
    SUPPLIER_USERS_PER_PAGE,
    debouncedSearchBy
  );

  const users: IUser[] = getDataFromPages(usersData?.pages, 'users');

  useLoadNextPage(
    loadMoreRef,
    hasNextPageUsers,
    fetchNextPageUsers,
    isFetchingUsers
  );

  return (
    <div>
      <FirstRow>
        <Title>
          {t('Users for supplier {{SUPPLIER}}').replace(
            '{{SUPPLIER}}',
            supplier.name
          )}
        </Title>
        <RightSide>
          <InputWrapper>
            <Input
              placeholder={t('Search users')}
              width={'100%'}
              wrapperStyles={{ width: '100%' }}
              value={searchBy}
              onChange={(e: any) => setSearchBy(e.target.value)}
              icon={Search}
            />
          </InputWrapper>
          <Button
            primary
            label={t('Add user to supplier')}
            onClick={() => setIsEmailInputModalOpen(true)}
          />
        </RightSide>
      </FirstRow>
      <Card>
        {users.length ? (
          <>
            {users.map((user: IUser, index: number) => (
              <UserCard
                key={`supplier-user-card-${user.id}`}
                user={user}
                onDelete={onDelete}
                isLast={index === users.length - 1 && !isFetchingUsers}
              />
            ))}
            <div
              ref={loadMoreRef}
              style={{
                height: '1px',
              }}
            />
          </>
        ) : null}
        {isFetchingUsers ? (
          <>
            <UserCardSkeleton />
            <UserCardSkeleton />
            <UserCardSkeleton isLast />
          </>
        ) : null}
      </Card>
    </div>
  );
};
