import { ChangeEvent, useState } from 'react';
import {
  Container,
  NoContentLabel,
  TitleInputWrapper,
  SuppliersContainer,
  Title,
  StyledInput,
  InputWrapper,
  NoContentLabelWrapper,
} from './DealerPanelSuppliersPage.styled';
import { useGetSuppliers } from './hooks';
import { SUPPLIERS_PER_PAGE } from './constants';
import { ISupplier } from 'types/Supplier.types';
import SupplierCard from './SupplierCard/SupplierCard';
import debounce from 'lodash.debounce';
import Pagination from 'components/Pagination/Pagination';
import { useTranslation } from 'react-i18next';
import { MagnifyingGlass } from '@phosphor-icons/react';
import SupplierCardSkeleton from './SupplierCard/SupplierCardSkeleton/SupplierCardSkeleton';

export const DealerPanelSuppliersPage = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState<number>(1);
  const [searchBy, setSearchBy] = useState<string>('');
  const { data: suppliersData, isLoading } = useGetSuppliers(
    page,
    SUPPLIERS_PER_PAGE,
    searchBy
  );

  const debouncedSearchBy = debounce(
    (searchBy: string) => setSearchBy(searchBy),
    500
  );

  const suppliers: ISupplier[] = suppliersData?.suppliers || [];

  return (
    <Container>
      <TitleInputWrapper>
        <Title>{t('Suppliers')}</Title>
        <InputWrapper>
          <StyledInput
            icon={MagnifyingGlass}
            placeholder={t('Search')}
            width={'300rem'}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              debouncedSearchBy(e.target.value);
            }}
            wrapperStyles={{ width: '100%' }}
          />
        </InputWrapper>
      </TitleInputWrapper>
      {suppliers?.length ? (
        <>
          <SuppliersContainer>
            {suppliers
              ?.sort((a: ISupplier, b: ISupplier) => a.id - b.id)
              .map((supplier: ISupplier, index: number) => (
                <SupplierCard supplier={supplier} key={index} />
              ))}
          </SuppliersContainer>
          <Pagination
            page={page}
            setPage={setPage}
            total={suppliersData?.pagination?.total}
            perPage={SUPPLIERS_PER_PAGE}
          />
        </>
      ) : isLoading ? (
        <SuppliersContainer>
          {Array.from({ length: 4 }, (_, index) => (
            <SupplierCardSkeleton
              key={index}
              index={index}
              opacityCoeficient={0.22}
            />
          ))}
        </SuppliersContainer>
      ) : (
        <NoContentLabelWrapper>
          <NoContentLabel>{t('No suppliers found')}</NoContentLabel>
        </NoContentLabelWrapper>
      )}
    </Container>
  );
};
