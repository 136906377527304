import { COLORS } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

export const MODEL_CARDS_GAP = 15;

export const ModelCardsWrapper = styled.div`
  display: flex;
  gap: ${MODEL_CARDS_GAP}rem;
  flex-direction: row;
  justify-content: flex-start;

  ${respondTo.bigPhone`
    justify-content: center;
  `}
`;

export const NoContentLabel = styled.label`
  width: 100%;
  text-align: center;
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
  color: ${COLORS.BLACK};
  padding: 10rem 0 20rem;

  ${respondTo.bigPhone`
    font-size: 16rem;
  `}
`;

export const NoContentLabelWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
