import styled from 'styled-components';
import { COLORS } from 'assets/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 785rem;
  width: 100%;
  align-self: center;
  padding: 50rem 15rem;
`;
export const ProfileCard = styled.div`
  max-width: 785rem;
  width: 100%;
  background-color: ${COLORS.WHITE};
  border-radius: 10rem;
  display: flex;
  flex-direction: column;
`;

export const H3 = styled.div`
  font-size: 24rem;
  line-height: 36rem;
  color: ${COLORS.BLACK};
  margin-bottom: 20rem;
`;

export const HeadingWrapper = styled.div`
  max-width: 785rem;
  width: 100%;
  display: flex;
  align-self: left;
`;
