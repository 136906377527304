import { ISupplier } from 'types/Supplier.types';
import {
  CategoriesWrapper,
  Container,
  FirstRow,
  InputWrapper,
  NoContentLabel,
  NoContentLabelWrapper,
  SupplierName,
  ViewMoreLabel,
  ViewMoreLabelWrapper,
} from './SupplierCategoriesGrid.styled';
import { useTranslation } from 'react-i18next';
import { ICategory } from 'types/Category.types';
import { useState } from 'react';
import { CATEGORIES_PER_PAGE } from './constants';
import { useGetCategoriesInfinite } from './hooks';
import Input from 'components/Input/Input';
import { useDebounce } from 'utils/hooks/useDebounce';
import CategoryGrid from './CategoryGrid/CategoryGrid';
import CategoryGridSkeleton from './CategoryGrid/CategoryGridSkeleton/CategoryGridSkeleton';
import { MagnifyingGlass as Search } from '@phosphor-icons/react';
import { getDataFromPages } from 'utils/getDataFromPages';

interface ISupplierCategoriesGrid {
  supplier: ISupplier;
}

const SupplierCategoriesGrid = ({ supplier }: ISupplierCategoriesGrid) => {
  const { t } = useTranslation();

  const [searchBy, setSearchBy] = useState<string>('');
  const debouncedSearchBy = useDebounce(searchBy);

  const {
    data: categoriesData,
    isFetching: isFetchingCategories,
    fetchNextPage,
    hasNextPage,
  } = useGetCategoriesInfinite(
    supplier.id,
    CATEGORIES_PER_PAGE,
    debouncedSearchBy
  );

  const categories: ICategory[] = getDataFromPages(
    categoriesData?.pages,
    'categories'
  );

  return (
    <Container>
      <FirstRow>
        <SupplierName>{supplier.name}</SupplierName>
        <InputWrapper>
          <Input
            placeholder={t('Search supplier categories')}
            width={'100%'}
            onChange={(e: any) => setSearchBy(e.target.value)}
            icon={Search}
            wrapperStyles={{ width: '100%' }}
          />
        </InputWrapper>
      </FirstRow>
      <CategoriesWrapper>
        {categories.map((category: ICategory, index: number) => (
          <CategoryGrid
            supplierId={supplier.id}
            category={category}
            key={`categorygrid-${index}`}
          />
        ))}
        {isFetchingCategories
          ? Array.from({ length: 3 }, (_, index) => (
              <CategoryGridSkeleton
                key={index}
                index={index}
                withoutLine={2 === index}
              />
            ))
          : null}
      </CategoriesWrapper>
      {!isFetchingCategories && categories.length === 0 ? (
        <NoContentLabelWrapper>
          <NoContentLabel>
            {t('No categories found for this supplier')}
          </NoContentLabel>
        </NoContentLabelWrapper>
      ) : null}
      {hasNextPage && !isFetchingCategories ? (
        <ViewMoreLabelWrapper>
          <ViewMoreLabel onClick={() => fetchNextPage()}>
            {t('View more')}
          </ViewMoreLabel>
        </ViewMoreLabelWrapper>
      ) : null}
    </Container>
  );
};

export default SupplierCategoriesGrid;
