import { useQuery } from 'react-query';
import { getCompany } from 'services/CompanyService';
import { getSupplier } from 'services/SupplierService';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';

export const useGetCompany = (companyId: number, isEnabled = true) =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_COMPANY, companyId],
    queryFn: () => {
      return getCompany(companyId);
    },
    enabled: !!companyId && isEnabled,
  });

export const useGetSupplier = (supplierId: number, isEnabled = true) =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_SUPPLIER, supplierId],
    queryFn: () => {
      return getSupplier(supplierId);
    },
    enabled: !!supplierId && isEnabled,
  });
