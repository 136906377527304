import { IEditSupplierCompanyReferenceDTO } from 'pages/Supplier/SupplierSingleCompanyPage/hooks';
import apiClient from 'services/api/apiService';
import { getUrlWithQueryParams } from './api/getUrlWithQueryParams';

const BASE_URL = '/v1/supplier-companies';

export const getSupplierCompanyById = async (id: number): Promise<any> => {
  const url = `${BASE_URL}/${id}`;
  const { data } = await apiClient.get<any>(url);
  return data.data;
};

export const editSupplierCompanyReference = async (
  dto: IEditSupplierCompanyReferenceDTO
): Promise<any> => {
  const url = `${BASE_URL}/${dto.supplier_company_id}/reference`;
  const { data } = await apiClient.patch<any>(url, {
    reference: dto.reference,
  });
  return data.data;
};

export const deleteSupplierCompany = async (
  supplierCompanyId: number
): Promise<any> => {
  const url = `${BASE_URL}/${supplierCompanyId}`;
  const { data } = await apiClient.delete<any>(url);
  return data.data;
};

export const createSupplierCompany = async (
  companyId: number,
  supplierId: number,
  reference: string
): Promise<any> => {
  const url = `${BASE_URL}`;
  const payload = {
    company_id: companyId,
    supplier_id: supplierId,
    supplier_company_reference: reference,
  };
  const { data } = await apiClient.post<any>(url, payload);
  return data.data;
};

export const getEligibleCompaniesForCreatingSupplierCompany = async (
  page: number,
  perPage: number,
  supplierId: number,
  searchBy: string
): Promise<any> => {
  const url = `${BASE_URL}/eligible-companies`;
  const queryParamsObj = {
    page: page,
    per_page: perPage,
    supplier_id: supplierId,
    search_term: searchBy,
  };
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);
  const { data } = await apiClient.get<any>(fullUrl);

  return data.data;
};
