import {
  Container,
  CategoryNameAndChevronWrapper,
  RowGap,
} from '../CategoryList.styled';
import { Skeleton } from '@mui/material';

interface ICategoryListSkeletonProps {
  index: number;
  opacityCoeficient?: number;
  withoutLine: boolean;
}

const CategoryListSkeleton = ({
  index,
  opacityCoeficient = 0.2,
  withoutLine,
}: ICategoryListSkeletonProps) => {
  return (
    <Container
      withoutLine={withoutLine}
      opacityCoeficient={opacityCoeficient}
      opacityIndex={index}
    >
      <CategoryNameAndChevronWrapper>
        <RowGap>
          <Skeleton width="200rem" height={'30rem'} animation="wave" />
        </RowGap>
      </CategoryNameAndChevronWrapper>
    </Container>
  );
};

export default CategoryListSkeleton;
