import {
  Container,
  CustomTable,
  StyledIcon,
  TD,
  TH,
  THead,
  TR,
  TableWrapper,
  TdLabel,
  ThLabel,
  ThWrapper,
} from './Table.styled';
import { useTranslation } from 'react-i18next';
import Icon from 'components/Icon/Icon';
import TableSkeleton from './TableSkeleton/TableSkeleton';
import { COLORS } from 'assets/styled';
import {
  getArrowImageFromColumnSortOption,
  getSortedTableRows,
  handleSortPress,
} from './helpers';
import { useState } from 'react';
import { IColumnSort, ITableConfig, ITableRowConfig, SortOrder } from './types';
import Pagination from 'components/Pagination/Pagination';
import { Trash } from '@phosphor-icons/react';

interface ITableProps {
  page?: number;
  perPage: number;
  total?: number;
  setPage?: (page: number) => void;
  tableData: any[];
  tableConfig: ITableConfig;
  isLoading: boolean;
  onDelete?: (userId: number) => void;
  tableFooter?: ({ tableData }: any) => JSX.Element;
}

export const Table = ({
  page,
  perPage,
  total,
  setPage,
  tableData,
  tableConfig,
  isLoading,
  onDelete,
  tableFooter,
}: ITableProps) => {
  const { t } = useTranslation();
  // Local sorting
  const [columnSort, setColumnSort] = useState<IColumnSort>({
    columnIndex: null,
    sortOrder: SortOrder.NONE,
  });

  const renderTableHeaderData = () => {
    return (
      <TR>
        <th></th>
        <th></th>
        {tableConfig?.rowConfigs.map((rowConfig: ITableRowConfig, index) => {
          return (
            <TH key={index} flexValue={rowConfig.flexValue}>
              <ThWrapper>
                <ThLabel>{t(rowConfig.header)}</ThLabel>
                {rowConfig.isSortable && (
                  <Icon
                    svg={getArrowImageFromColumnSortOption(index, columnSort)}
                    marginLeft="5rem"
                    fill={COLORS.WHITE}
                    size={20}
                    onClick={() =>
                      handleSortPress(index, columnSort, setColumnSort)
                    }
                  />
                )}
              </ThWrapper>
            </TH>
          );
        })}
        <th></th>
        {onDelete ? <th></th> : null}
      </TR>
    );
  };

  const renderTableBodyData = () => {
    let sortedTableRows;
    if (columnSort.columnIndex !== null) {
      sortedTableRows = getSortedTableRows([...tableData], columnSort);
    } else {
      sortedTableRows = tableData;
    }
    return sortedTableRows.map((row, index) => {
      const withoutBorderBottom = tableData.length - 1 === index;
      return (
        <TR key={index}>
          <TD withoutBorderBottom={withoutBorderBottom} />
          <TD withoutBorderBottom={withoutBorderBottom} />
          {Object.keys(row).map((cellKey: string, cellIndex: number) => {
            const rowConfig: ITableRowConfig =
              tableConfig.rowConfigs?.[cellIndex];
            return (
              <TD
                withoutBorderBottom={withoutBorderBottom}
                key={cellIndex}
                flexValue={rowConfig.flexValue}
              >
                <TdLabel>
                  {['true', 'false'].includes(row[cellKey]?.toString())
                    ? t(row[cellKey]?.toString())
                    : row[cellKey]?.toString()}
                </TdLabel>
              </TD>
            );
          })}
          {onDelete ? (
            <TD withoutBorderBottom={withoutBorderBottom}>
              <StyledIcon
                onClick={() => onDelete(row.id)}
                svg={Trash}
                color={COLORS.RED_100}
              />
            </TD>
          ) : null}
          <TD withoutBorderBottom={withoutBorderBottom} />
        </TR>
      );
    });
  };

  const showPagination =
    total && perPage && total >= perPage && page && setPage;

  return (
    <Container>
      <TableWrapper>
        <CustomTable>
          <THead>{renderTableHeaderData()}</THead>
          <tbody>
            {isLoading && (
              <TableSkeleton
                numRows={perPage}
                numColumns={tableConfig.rowConfigs.length + (onDelete ? 4 : 3)}
              />
            )}
            {renderTableBodyData()}
            {tableFooter ? tableFooter({ tableData, tableConfig }) : null}
          </tbody>
        </CustomTable>
      </TableWrapper>
      {showPagination ? (
        <Pagination
          page={page}
          perPage={perPage}
          total={total}
          setPage={setPage}
        />
      ) : null}
    </Container>
  );
};

export default Table;
