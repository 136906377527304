import { COLORS, borderRadiusSm } from 'assets/styled';
import styled from 'styled-components';
import Icon from 'components/Icon/Icon';
import { css } from 'styled-components';

interface StyledProps {
  isSelected: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10rem;
`;

export const Option = styled.div`
  width: 60rem;
  height: 42rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1rem solid ${COLORS.BORDER};
  background-color: ${COLORS.WHITE};
  border-radius: ${borderRadiusSm};
  cursor: pointer;
`;

export const StyledIcon = styled(Icon)`
  &:hover {
    transform: scale(1.1);
    transition: transform ease-in-out 0.1s;
  }
  ${(props: StyledProps) =>
    props.isSelected &&
    css`
      transform: scale(1.1);
      transition: transform ease-in-out 0.1s;
    `}
`;
