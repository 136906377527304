import Button from 'components/Button/Button';
import { Dispatch, ReactNode, SetStateAction } from 'react';
import { Modal } from '../Modal/Modal';
import { Level } from '../Modal/type';
import {
  ButtonContainer,
  Container,
  Description,
  Title,
  TitleContainer,
  TopContainer,
} from './CTAModal.styled';

interface ICTAModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  firstButtonClick: () => void;
  firstButtonText: string;
  secondButtonClick: () => void;
  secondButtonText: string;
  description?: string | ReactNode;
  title: string;
  level?: Level;
  disabled?: boolean;
}

export const CTAModal = ({
  isOpen,
  setIsOpen,
  firstButtonClick,
  firstButtonText,
  secondButtonClick,
  secondButtonText,
  description,
  title,
  level = 'FIRST',
  disabled,
}: ICTAModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      modalStyle={{
        margin: 'auto',
        width: '600rem',
        minWidth: 'auto',
        position: 'fixed',
      }}
      level={level}
    >
      <Container>
        <TopContainer>
          <TitleContainer>
            <Title>{title}</Title>
          </TitleContainer>
          <TitleContainer>
            <Description>
              {typeof description === 'string' ? (
                <p>{description}</p>
              ) : (
                description
              )}
            </Description>
          </TitleContainer>
        </TopContainer>
        <ButtonContainer>
          <Button
            disabled={disabled}
            onClick={firstButtonClick}
            secondary
            width="200rem"
            label={firstButtonText}
          />
          <Button
            disabled={disabled}
            primary
            width="200rem"
            label={secondButtonText}
            onClick={secondButtonClick}
          />
        </ButtonContainer>
      </Container>
    </Modal>
  );
};
