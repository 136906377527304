import {
  ContainerGrayBottomBorder,
  FormItemContainer,
  QuestionName,
  QuestionNameAsteriskInfoRow,
} from '../../ConfigureForm.styled';
import { Skeleton } from '@mui/material';

interface IQuestionSkeletonsProps {
  hasImages: boolean;
  index: number;
  showBorder?: boolean;
  opacityCoeficient?: number;
}

const QuestionSkeleton = ({
  hasImages,
  index,
  showBorder = true,
  opacityCoeficient = 0.12,
}: IQuestionSkeletonsProps) => {
  return (
    <ContainerGrayBottomBorder
      key={`question-skeleton-${index}`}
      showBorder={showBorder}
      opacityIndex={index}
      opacityCoeficient={opacityCoeficient}
      isSkeleton
    >
      <FormItemContainer hasImages={hasImages}>
        <QuestionNameAsteriskInfoRow>
          <QuestionName>
            <Skeleton
              variant="rectangular"
              height={'20rem'}
              width={'100rem'}
              animation="wave"
              style={{
                borderRadius: '5rem',
                marginRight: '5rem',
                marginTop: '9rem',
              }}
            />
          </QuestionName>
          <Skeleton
            variant="rounded"
            height={'20rem'}
            width={'20rem'}
            animation="wave"
            style={{ borderRadius: '10rem', marginTop: '9rem' }}
          />
        </QuestionNameAsteriskInfoRow>
        <Skeleton
          variant="rectangular"
          height={'40rem'}
          width={'300rem'}
          animation="wave"
          style={{ borderRadius: '20rem' }}
        />
      </FormItemContainer>
    </ContainerGrayBottomBorder>
  );
};

export default QuestionSkeleton;
