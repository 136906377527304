import ShoppingCartCardSkeleton from './ShoppingCartCardSkeleton.tsx/ShoppingCartCardSkeleton';

const ShoppingCartCardSkeletons = () => {
  return (
    <>
      <ShoppingCartCardSkeleton index={1} />
      <ShoppingCartCardSkeleton index={2} />
      <ShoppingCartCardSkeleton index={3} />
    </>
  );
};

export default ShoppingCartCardSkeletons;
