import {
  SET_MODEL,
  SET_QUESTIONS,
  RESET_CONFIGURE_FORM,
  SET_IMAGES,
  SET_PRICING,
  SET_LANG,
  SET_QUERY_PAYLOAD,
  SET_IS_USING_SUPPLIER_SYSTEM_DISCOUNTS,
} from '../../constants';

export const setModel = (modelData: object) => ({
  type: SET_MODEL,
  payload: modelData,
});

// Setting questions from query response require some formatting in the reducer
export const setQuestions = (questionData: object) => ({
  type: SET_QUESTIONS,
  payload: questionData,
});

// Query payload is saved because it is also sent along when submitting the configuration
export const setQueryPayload = (queryPayload: object) => ({
  type: SET_QUERY_PAYLOAD,
  payload: queryPayload,
});

export const setImages = (imagesData: object) => ({
  type: SET_IMAGES,
  payload: imagesData,
});

export const setPricing = (pricingData: object) => ({
  type: SET_PRICING,
  payload: pricingData,
});

// The language that was selected when the query was initiated
export const setLang = (lang: string) => ({
  type: SET_LANG,
  payload: lang,
});

export const resetConfigureForm = () => ({
  type: RESET_CONFIGURE_FORM,
});

export const setIsUsingSupplierSystemDiscounts = (bool: boolean) => ({
  type: SET_IS_USING_SUPPLIER_SYSTEM_DISCOUNTS,
  payload: bool,
});
