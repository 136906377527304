import styled, { css } from 'styled-components';

interface StyledProps {
  color: string;
}

export const OptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ImageSmall = styled.img`
  height: 24rem;
  width: 24rem;
  object-fit: contain;
  border-radius: 5rem;
  ${(props: StyledProps) =>
    props.color &&
    css`
      background: ${(props: StyledProps) => props.color};
    `};
`;
