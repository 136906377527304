import { borderRadiusSm, COLORS } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled, { css } from 'styled-components';

interface StyledProps {
  isSkeleton: boolean;
  isSortOptionActive: boolean;
}

export const Container = styled.div`
  padding: 50rem;

  ${respondTo.smallLaptop`
    padding: 30rem;
  `}

  ${respondTo.smallTablet`
    padding: 30rem 15rem;
  `}
`;

export const Back = styled.label`
  margin-left: 5rem;
  font-size: 18rem;
  color: ${COLORS.BLACK};

  ${respondTo.bigPhone`
    font-size: 16rem;
  `}
`;

export const FirstRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5rem;
  margin-bottom: 20rem;
  width: fit-content;
  cursor: pointer;
  &:hover {
    ${Back} {
      cursor: pointer;
      text-decoration: underline;
      text-decoration-thickness: 1rem;
    }
  }
`;

export const ContentWrapper = styled.div`
  margin-bottom: 50rem;
  display: flex;
  flex-direction: column;

  ${respondTo.smallTablet`
    margin-bottom: 20rem;
  `}
`;

export const ModelNameWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 50rem;
  margin-bottom: 10rem;
`;

export const ModelName = styled.h2`
  font-weight: 300;
  font-size: 50rem;
  color: ${COLORS.BLACK};

  ${respondTo.bigTablet`
    font-size: 40rem;
  `}

  ${respondTo.smallTablet`
    font-size: 30rem;
  `}

  ${respondTo.smallTablet`
    text-align: center;
  `}
`;

export const ButtonText = styled.span`
  display: block;
  font-size: 18rem;
  color: ${COLORS.RED_100};
  position: relative;

  &::before {
    content: '';
    width: 100%;
    height: 1rem;
    background-color: ${COLORS.RED_100};
    position: absolute;
    bottom: 0;
    left: 0;
    transition: 0.3s ease;
  }
`;

export const ActionButton = styled.div`
  display: flex;
  align-items: center;
  gap: 5rem;
  cursor: pointer;

  &:hover {
    ${ButtonText} {
      &::before {
        width: 0;
      }
    }
  }
`;

export const Description = styled.div``;
export const DescriptionText = styled.p`
  font-size: 14rem;
  color: ${COLORS.BLACK};
`;

export const ModelID = styled.span`
  display: block;
  font-size: 18rem;
  color: ${COLORS.BLACK};
  margin-bottom: 10rem;
`;

export const VersionsRowsContainer = styled.div`
  padding: 30rem;
  background-color: ${COLORS.WHITE};
  border: 1rem solid ${COLORS.BORDER};
  border-radius: ${borderRadiusSm};
  ${(props: StyledProps) =>
    props.isSkeleton &&
    css`
      background-color: ${COLORS.WHITE};
    `}
`;

export const NoContentLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NoContentLabel = styled.label`
  width: 100%;
  text-align: center;
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
  margin-top: 20rem;
  color: ${COLORS.BLACK};

  ${respondTo.bigPhone`
    font-size: 16rem;
  `}
`;

export const HeadlineRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30rem;
`;

export const Headline = styled.span`
  font-size: 24rem;
  color: ${COLORS.BLACK};
`;

export const SortWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const SortOptions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid ${COLORS.BORDER};
  border-radius: 10rem;
  background-color: ${COLORS.BACKGROUND};
  overflow-y: auto;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 200rem;
  height: 200rem;
  transform: translateY(calc(100% + 10rem));
  z-index: 2;
`;

export const SortOption = styled.div`
  display: flex;
  justify-content: flex-start;
  font-size: 15rem;
  padding: 10rem;
  border-bottom: 1px solid ${COLORS.BORDER};
  color: ${COLORS.BLACK};
  width: 100%;
  max-width: 100%;
  cursor: pointer;
  transition: 0.3s ease;

  ${(props: StyledProps) =>
    props.isSortOptionActive &&
    css`
      background-color: ${COLORS.BORDER};
    `}

  &:hover {
    background-color: ${COLORS.BORDER};
  }

  &:last-of-type {
    border-bottom: none;
  }
`;
