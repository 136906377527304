import { COLORS } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 50rem;

  ${respondTo.smallLaptop`
    padding: 30rem;
  `}

  ${respondTo.smallTablet`
    padding: 15rem;
  `}
`;

export const Title = styled.label`
  font-weight: 600;
  font-size: 50rem;
  line-height: 68rem;
  font-weight: 300;
  color: ${COLORS.BLACK};

  ${respondTo.smallLaptop`
    font-size: 40rem;
    line-height: 50rem;
  `}

  ${respondTo.smallTablet`
    font-size: 30rem;
  `}

  ${respondTo.bigPhone`
    margin-bottom: 10rem;
  `}
`;

export const FirstRow = styled.div`
  margin-bottom: 20rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  ${respondTo.bigPhone`
    flex-direction: column;
    align-items: flex-start;
  `}
`;

export const LeftSide = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 27rem;
`;

export const RightSide = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30rem;

  ${respondTo.smallTablet`
    gap: 15rem;
  `}

  ${respondTo.bigPhone`
    width: 100%;
  `}
`;

export const InputWrapper = styled.div`
  width: 300rem;

  ${respondTo.bigPhone`
    width: 100%;
  `}
`;

export const AddLabel = styled.label`
  font-size: 18rem;
  font-weight: 400;
  color: ${COLORS.GREEN};
  cursor: pointer;

  ${respondTo.bigPhone`
    font-size: 16rem;
  `}
`;

export const AddWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  cursor: pointer;
  &:hover {
    ${AddLabel} {
      text-decoration: underline;
    }
  }
`;
