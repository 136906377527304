import { Skeleton } from '@mui/material';
import { COLORS } from 'assets/styled';
import styled from 'styled-components';

export const SkeletonRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10rem;
`;

export const SkeletonTd = styled.td`
  max-width: 100%;
  width: 100% !important;
  padding: 0 !important;
  background-color: transparent !important;

  span:after {
    background-color: ${COLORS.GREEN};
    opacity: 0.1;
  }
`;

export const StyledSkeleton = styled(Skeleton)`
  flex-grow: 1;
  margin: 0 4px;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
`;
