import { useTranslation } from 'react-i18next';
import {
  FormWrapper,
  HorizontalLine,
  RowContainer,
  TypeLabel,
} from './SettingsRow.styled';
import { SettingsRowForm } from './SettingsRowForm/SettingsRowForm';
import {
  settingsEmailValSchema,
  settingsNameValSchema,
  settingsPhoneValSchema,
  settingsWebsiteValSchema,
} from './validation';
import {
  IUpdateCompanySettingsDTO,
  IUpdateSupplierSettingsDTO,
  useUpdateCompanySettings,
  useUpdateSupplierSettings,
} from './hooks';
import { SettingsRowLogoForm } from './SettingsRowLogoForm/SettingsRowLogoForm';
import { getFieldnameBasedOnType } from './SettingsRowForm/helpers';

export enum SettingsRowType {
  NAME = 'Name',
  EMAIL = 'Email',
  PHONE_NUMBER = 'Phone number',
  WEBSITE = 'Website',
  LOGO = 'Logo',
}

export enum SettingsEntityType {
  COMPANY = 'Company',
  SUPPLIER = 'Supplier',
}

interface ISettingsRowProps {
  entityId: number;
  type: SettingsRowType;
  entityType: SettingsEntityType;
  initialData: string;
  withoutLine?: boolean;
}

export const SettingsRow = ({
  entityId,
  type,
  entityType,
  initialData,
  withoutLine = false,
}: ISettingsRowProps) => {
  const { t } = useTranslation();

  const {
    mutate: editCompanySettings,
    isLoading: isLoadingEditCompanySettings,
  } = useUpdateCompanySettings();

  const {
    mutate: editSupplierSettings,
    isLoading: isLoadingEditSupplierSettings,
  } = useUpdateSupplierSettings();

  const handleOnConfirm = (fieldName: string, value: string) => {
    switch (entityType) {
      case SettingsEntityType.COMPANY:
        {
          const dto: IUpdateCompanySettingsDTO = {
            [fieldName]: value,
          };
          editCompanySettings({ companyId: entityId, dto });
        }
        break;
      case SettingsEntityType.SUPPLIER: {
        const dto: IUpdateSupplierSettingsDTO = {
          [fieldName]: value,
        };
        editSupplierSettings({ supplierId: entityId, dto });
      }
    }
  };

  const renderSettingsRowForm = () => {
    switch (type) {
      case SettingsRowType.NAME:
        return (
          <SettingsRowForm
            type={type}
            initialData={initialData}
            validationSchema={settingsNameValSchema}
            onConfirm={(value: string) =>
              handleOnConfirm(getFieldnameBasedOnType(type), value)
            }
            isLoading={
              isLoadingEditCompanySettings || isLoadingEditSupplierSettings
            }
          />
        );
      case SettingsRowType.EMAIL:
        return (
          <SettingsRowForm
            type={type}
            initialData={initialData}
            validationSchema={settingsEmailValSchema}
            onConfirm={(value: string) =>
              handleOnConfirm(getFieldnameBasedOnType(type), value)
            }
            isLoading={
              isLoadingEditCompanySettings || isLoadingEditSupplierSettings
            }
          />
        );
      case SettingsRowType.PHONE_NUMBER:
        return (
          <SettingsRowForm
            type={type}
            initialData={initialData}
            validationSchema={settingsPhoneValSchema}
            onConfirm={(value: string) =>
              handleOnConfirm(getFieldnameBasedOnType(type), value)
            }
            isLoading={
              isLoadingEditCompanySettings || isLoadingEditSupplierSettings
            }
          />
        );
      case SettingsRowType.WEBSITE:
        return (
          <SettingsRowForm
            type={type}
            initialData={initialData}
            validationSchema={settingsWebsiteValSchema}
            onConfirm={(value: string) =>
              handleOnConfirm(getFieldnameBasedOnType(type), value)
            }
            isLoading={
              isLoadingEditCompanySettings || isLoadingEditSupplierSettings
            }
          />
        );
      case SettingsRowType.LOGO:
        return (
          <SettingsRowLogoForm
            entityId={entityId}
            entityType={entityType}
            initialData={initialData}
            isLoading={
              isLoadingEditCompanySettings || isLoadingEditSupplierSettings
            }
          />
        );
    }
  };
  return (
    <>
      <RowContainer>
        <TypeLabel>{t(type)}</TypeLabel>
        <FormWrapper>{renderSettingsRowForm()}</FormWrapper>
      </RowContainer>
      {withoutLine ? null : <HorizontalLine />}
    </>
  );
};
