export const config = {
  environment: process.env.REACT_APP_ENVIRONMENT,
  api: {
    baseUrl: process.env.REACT_APP_API_URL,
  },
  sentry: {
    dsnUrl: process.env.REACT_APP_SENTRY_DSN_URL,
  },
  aws: {
    cloudFrontImageTransformatorUrl:
      process.env.REACT_APP_CLOUDFRONT_IMAGE_TRANSFORMATOR_URL,
  },
  get testTargetUrl() {
    const url = process.env.REACT_APP_TEST_TARGET_URL;
    if (!url) throw new Error('Test target URL is not defined!');
    return url;
  },
};

export enum EnvironmentType {
  LOCAL = 'local',
  DEV = 'dev',
  STAGING = 'staging',
  PROD = 'prod',
}
