import { COLORS } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

export const RowContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20rem;
  margin-bottom: 20rem;
  width: 100%;

  ${respondTo.midTablet`    
    flex-direction: column;
  `}
`;

export const TypeLabel = styled.label`
  font-size: 18rem;
  font-weight: 600;
  flex: 1;
  color: ${COLORS.BLACK};
`;

export const HorizontalLine = styled.div`
  height: 0;
  width: 100%;
  border-bottom: 1px solid ${COLORS.BORDER};
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 5;
  ${respondTo.midTablet`    
    margin-top: 20rem;
  `};
  ${respondTo.smallPhone`    
    margin-top: 60rem;
  `};
  justify-content: space-between;
`;
