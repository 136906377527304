import { COLORS, paddingMd } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

export const Title = styled.label`
  font-weight: 400;
  font-size: 24rem;
  line-height: 32rem;
  color: ${COLORS.BLACK};
  margin-bottom: 20rem;
  display: inline-block;

  ${respondTo.bigTablet`
    font-size: 20rem;
  `}

  ${respondTo.smallTablet`
    font-size: 18rem;
    margin-bottom: 10rem;
  `}
`;

export const InputWrapper = styled.div`
  width: 435rem;

  ${respondTo.bigTablet`
    width: 250rem;
  `}

  ${respondTo.smallTablet`
    width: 100%;
  `}
`;

export const Container = styled.div`
  padding: ${paddingMd};

  ${respondTo.bigPhone`
    padding-top: 40rem;
  `}
`;

export const FirstRow = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid ${COLORS.BORDER};
  padding-bottom: 10rem;
  margin-bottom: 10rem;

  ${respondTo.smallTablet`
    flex-direction: column;
  `}
`;

export const Divider = styled.div`
  height: 110rem;
  width: 1rem;
  background-color: ${COLORS.BORDER};
  margin-left: 30rem;
  margin-right: 30rem;

  ${respondTo.smallTablet`
    display: none;
  `}
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  ${respondTo.smallTablet`
    margin-bottom: 10rem;

    &:first-of-type {
      padding-bottom: 20rem;
      border-bottom: 1px solid ${COLORS.BORDER};
    }
  `}
`;

export const PopularSection = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${COLORS.BORDER};
`;

export const ResultsSection = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FlexWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 30rem;
  justify-content: center;
`;

export const PopularFlexWrap = styled(FlexWrap)`
  padding-bottom: 20rem;
`;

export const SelectWrapper = styled.div`
  width: 435rem;

  ${respondTo.bigTablet`
    width: 250rem;
  `}

  ${respondTo.smallTablet`
    width: 100%;
  `}
`;
