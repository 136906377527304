import { useNavigate, useParams } from 'react-router-dom';
import {
  Container,
  FirstRow,
  CompanyDetailsWrapper,
  RowGap,
  Back,
  IconWrapper,
  editIconStyling,
  ContentWrapper,
  CompanyName,
  InfoText,
  imageStyle,
  imageContainerStyle,
} from './SupplierSingleCompanyPage.styled';
import { useTranslation } from 'react-i18next';
import Icon from 'components/Icon/Icon';
import {
  IEditSupplierCompanyReferenceDTO,
  useEditSupplierCompanyReference,
  useGetSingleSupplierCompany,
} from './hooks';
import { COLORS } from 'assets/styled';
import SupplierCompanyModels from './SupplierCompanyModels/SupplierCompanyModels';
import { RoutesConfig } from 'navigation/routes';
import { usePageTitle } from 'utils/hooks/usePageTitle';
import { useState } from 'react';
import { EditSupplierCompanyReferenceModal } from 'components/Modals/EditSupplierCompanyReferenceModal/EditSupplierCompanyReferenceModal';
import {
  AdaptiveImage,
  AdaptiveImageType,
} from 'components/AdaptiveImage/AdaptiveImage';
import { CaretLeft, PencilSimple } from '@phosphor-icons/react';

const SupplierSingleCompanyPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    data: supplierCompanyData,
    isLoading: isLoadingGetSupplierCompany,
    isSuccess: isSuccessGetSupplierCompany,
  } = useGetSingleSupplierCompany(Number(id!));

  const [isEditReferenceModalOpen, setIsEditReferenceModalOpen] =
    useState<boolean>(false);

  usePageTitle(supplierCompanyData?.company?.name, false);

  const {
    mutate: editSupplierCompanyReference,
    isLoading: isLoadingEditSupplierCompanyReference,
  } = useEditSupplierCompanyReference();

  return (
    <Container>
      <FirstRow
        onClick={() => navigate(RoutesConfig.SupplierCompanies.fullPath)}
      >
        <Icon
          svg={CaretLeft}
          weight="bold"
          size={20}
          color={COLORS.GREEN}
          onClick={() => null}
        />
        <Back>{t('Back')}</Back>
      </FirstRow>
      <ContentWrapper>
        <AdaptiveImage
          imageStyle={imageStyle}
          containerStyle={imageContainerStyle}
          imageUrl={supplierCompanyData?.company?.logo_url}
          type={AdaptiveImageType.COMPANY}
          isSuccess={isSuccessGetSupplierCompany}
        />
        <CompanyDetailsWrapper>
          <CompanyName>{supplierCompanyData?.company?.name}</CompanyName>
          <RowGap>
            <InfoText>{t('Reference number')}:</InfoText>
            <InfoText>
              {supplierCompanyData?.supplier_company_reference}
            </InfoText>
            {supplierCompanyData?.supplier_company_reference && (
              <IconWrapper>
                <Icon
                  svg={PencilSimple}
                  color={COLORS.GREEN}
                  wrapperStyle={editIconStyling}
                  onClick={() => setIsEditReferenceModalOpen(true)}
                />
              </IconWrapper>
            )}
          </RowGap>
        </CompanyDetailsWrapper>
      </ContentWrapper>
      <SupplierCompanyModels
        isLoadingGetSupplierCompany={isLoadingGetSupplierCompany}
        supplierCompanyId={supplierCompanyData?.id}
        companyId={supplierCompanyData?.company?.id}
      />
      <EditSupplierCompanyReferenceModal
        isOpen={isEditReferenceModalOpen}
        setIsOpen={setIsEditReferenceModalOpen}
        onConfirm={(reference: string) => {
          const dto: IEditSupplierCompanyReferenceDTO = {
            supplier_company_id: supplierCompanyData?.id,
            reference,
          };
          editSupplierCompanyReference(dto, {
            onSuccess: () => {
              setIsEditReferenceModalOpen(false);
            },
          });
        }}
        title={t('Edit reference for {{ENTITY}}').replace(
          '{{ENTITY}}',
          supplierCompanyData?.company?.name
        )}
        initialValue={supplierCompanyData?.supplier_company_reference}
        isLoading={isLoadingEditSupplierCompanyReference}
      />
    </Container>
  );
};

export default SupplierSingleCompanyPage;
