import { IFieldDrag } from 'pages/Builder/InputFieldsOptions/helper';
import {
  FieldTypes,
  IShortTextInputField,
  ILongTextInputField,
  INumberInputField,
  IRadioInputField,
  IListInputField,
  IToggleInputField,
  IInputField,
  ICustomModel,
} from 'types/Builder.types';

export const createNewField = (
  item: IFieldDrag,
  model: ICustomModel
): IInputField | null => {
  const count = model.input_fields.filter(
    (field) => field.field_type === item.fieldType
  ).length;

  // Avoid inputs to have same field label
  const labelWithNumber =
    count === 0
      ? item.fieldLabel || 'Field'
      : `${item.fieldLabel || 'Field'} ${count + 1}`;

  switch (item.fieldType) {
    case FieldTypes.ShortText:
      return {
        id: generateUniqueId(),
        default_field_name: 'Short text',
        field_type: FieldTypes.ShortText,
        field_label: labelWithNumber,
        description: '',
        default: '',
        min: null,
        max: 50,
        required: false,
      } as IShortTextInputField;

    case FieldTypes.LongText:
      return {
        id: generateUniqueId(),
        default_field_name: 'Long text',
        field_type: FieldTypes.LongText,
        field_label: labelWithNumber,
        description: '',
        default: '',
        min: null,
        max: null,
        required: false,
      } as ILongTextInputField;

    case FieldTypes.Number:
      return {
        id: generateUniqueId(),
        default_field_name: 'Number',
        field_type: FieldTypes.Number,
        field_label: labelWithNumber,
        description: '',
        default: null,
        min: null,
        max: null,
        required: false,
      } as INumberInputField;

    case FieldTypes.Radio:
      return {
        id: generateUniqueId(),
        default_field_name: 'Radio',
        field_type: FieldTypes.Radio,
        field_label: labelWithNumber,
        description: '',
        options: [
          {
            name: 'Option 1',
            price: { price_type: 'fixed', amount: null },
          },
        ],
        default: 0,
      } as IRadioInputField;

    case FieldTypes.List:
      return {
        id: generateUniqueId(),
        default_field_name: 'List',
        field_type: FieldTypes.List,
        field_label: labelWithNumber,
        description: '',
        options: [
          {
            name: 'Option 1',
            price: { price_type: 'fixed', amount: null },
          },
        ],
        default: 0,
        required: false,
        multiple: false,
      } as IListInputField;

    case FieldTypes.Toggle:
      return {
        id: generateUniqueId(),
        default_field_name: 'Toggle',
        field_type: FieldTypes.Toggle,
        field_label: labelWithNumber,
        description: '',
        price: { price_type: 'fixed', amount: null },
        default: false,
      } as IToggleInputField;

    default:
      return null;
  }
};

export const generateUniqueId = () =>
  `${Date.now().toString(36)}-${Math.random().toString(36).substr(2, 9)}`;
