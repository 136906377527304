export interface IColumnSort {
  columnIndex: number | null;
  sortOrder: SortOrder;
}

export enum SortOrder {
  ASC = 'asc',
  DESC = 'desc',
  NONE = 'none',
}
