import { useSelector } from 'react-redux';
import CompanyUsers from './CompanyUsers/CompanyUsers';
import SupplierUsers from './SupplierUsers/SupplierUsers';
import { Container } from './UsersPage.styled';
import { IRootReducerState } from 'store/store';
import { AppMode } from 'store/Common/reducers/common';
import { usePageTitle } from 'utils/hooks/usePageTitle';

const UsersPage = () => {
  const appMode: AppMode = useSelector(
    (state: IRootReducerState) => state.commonInfo.appMode
  );

  usePageTitle('Users');

  return (
    <Container>
      {appMode === AppMode.COMPANY ? <CompanyUsers /> : <SupplierUsers />}
    </Container>
  );
};

export default UsersPage;
