import React, { useRef, useEffect } from 'react';
import { MenuListProps } from 'react-select';
import Button from 'components/Button/Button';
import { ButtonWrapper, Menu } from './CustomMenuList.styled.';

interface CustomMenuListProps extends MenuListProps<any> {
  onMenuScrollToBottom?: () => void;
  onClick?: () => void;
  label?: string;
  scrollPositionRef: React.MutableRefObject<number>;
}

const CustomMenuList: React.FC<CustomMenuListProps> = ({
  children,
  onMenuScrollToBottom,
  onClick,
  label,
  scrollPositionRef,
}) => {
  const menuListRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const menuList = menuListRef.current;

    if (menuList) {
      if (scrollPositionRef.current !== 0)
        menuList.scrollTop = scrollPositionRef.current;

      const handleScroll = () => {
        const { scrollTop, scrollHeight, clientHeight } = menuList;
        if (scrollTop !== 0) scrollPositionRef.current = scrollTop;

        if (scrollHeight - scrollTop === clientHeight) {
          onMenuScrollToBottom && onMenuScrollToBottom();
        }
      };

      menuList.addEventListener('scroll', handleScroll);

      return () => {
        menuList.removeEventListener('scroll', handleScroll);
      };
    }
  }, [onMenuScrollToBottom, children]);

  return (
    <Menu>
      <div
        ref={menuListRef}
        style={{
          overflowY: 'auto',
          maxHeight: '200rem',
        }}
      >
        {children}
      </div>
      <ButtonWrapper>
        <Button label={label || ''} link onClick={onClick} />
      </ButtonWrapper>
    </Menu>
  );
};

export default CustomMenuList;
