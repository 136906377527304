import { COLORS } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

export const Container = styled.div``;

export const CompaniesWrapper = styled.div`
  display: grid;
  grid-gap: 15rem;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fill, 300rem);

  ${respondTo.smallLaptop`
    grid-template-columns: repeat(
      auto-fill,
      30%
    );
  `}

  ${respondTo.bigPhone`
    grid-template-columns: repeat(
      auto-fill,
      48%
    );
  `}

  ${respondTo.smallPhone`
    grid-template-columns: repeat(
      auto-fill,
      100%
    );
  `}
`;

export const NoContentLabel = styled.label`
  width: 100%;
  text-align: center;
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
  color: ${COLORS.BLACK};

  ${respondTo.bigPhone`
    font-size: 16rem;
  `}
`;

export const NoContentLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
