import { Skeleton } from '@mui/material';
import {
  Label,
  LeftPart,
  RightPart,
  Container,
  CircleSkeleton,
} from '../SupplierCompanyModelsRow.styled';

interface ISupplierCompanyModelsRowSkeletonProps {
  index: number;
  withoutLine: boolean;
}

const SupplierCompanyModelsRowSkeleton = ({
  index,
  withoutLine,
}: ISupplierCompanyModelsRowSkeletonProps) => {
  return (
    <Container withoutLine={withoutLine} opacityIndex={index}>
      <LeftPart>
        <Skeleton width="150rem" animation="wave" />
        <Label>
          <Skeleton width="100rem" animation="wave" />
        </Label>
        <Label>
          <Skeleton width="100rem" animation="wave" />
        </Label>
      </LeftPart>
      <RightPart>
        <Skeleton width="130rem" animation="wave" />
        <CircleSkeleton
          animation="wave"
          variant="rounded"
          height={'30rem'}
          width={'30rem'}
        />
        {/* Apply gap to div */}
        <div />
        <Skeleton width="130rem" animation="wave" />
        <CircleSkeleton
          animation="wave"
          variant="rounded"
          height={'30rem'}
          width={'30rem'}
        />
      </RightPart>
    </Container>
  );
};

export default SupplierCompanyModelsRowSkeleton;
