import { RoutesConfig } from 'navigation/routes';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { IRootReducerState } from 'store/store';
import { useIsAuthenticated } from 'utils/hooks/useIsAuthenticated';

const PrivateRoute = () => {
  const isAuthenticated = useIsAuthenticated();

  const { queryParamsProcessed } = useSelector(
    (state: IRootReducerState) => state.commonInfo
  );

  // If the query params are not yet processed, don't make a navigation decision.
  if (!queryParamsProcessed) {
    return null;
  }

  return isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to={RoutesConfig.Login.fullPath} />
  );
};

export default PrivateRoute;
