import { SettingsRowType } from '../SettingsRow';

export const getFieldnameBasedOnType = (type: SettingsRowType) => {
  switch (type) {
    case SettingsRowType.NAME:
      return 'name';
    case SettingsRowType.EMAIL:
      return 'email';
    case SettingsRowType.PHONE_NUMBER:
      return 'phone_number';
    case SettingsRowType.WEBSITE:
      return 'website';
  }
  return 'unknown_field';
};
