import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import {
  enableAllModelOrderingForSupplierCompany,
  enableAllModelQueryingForSupplierCompany,
  getSupplierCompanyModels,
} from 'services/SupplierCompanyModelsService';
import { LogyxError, getToastErrorMessage } from 'services/api/errors';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';

// Fetch supplierCompanyModels based on supplierCompanyId or combination of modelId and supplierId
export const useGetSupplierCompanyModels = (
  page: number,
  perPage: number,
  searchBy: string,
  categoryId: number | null,
  supplierCompanyId: number | null,
  modelId: number | null,
  supplierId: number | null
) => {
  return useQuery({
    queryKey: [
      ReactQueryKeys.GET_SUPPLIER_COMPANY_MODELS,
      page,
      perPage,
      searchBy,
      categoryId,
      supplierCompanyId,
      modelId,
      supplierId,
    ],
    queryFn: () => {
      return getSupplierCompanyModels(
        page,
        perPage,
        searchBy,
        categoryId,
        supplierCompanyId,
        modelId,
        supplierId
      );
    },
    enabled: !!supplierCompanyId || (!!modelId && !!supplierId),
  });
};

export const useEnableAllModelOrderingForSupplierCompany = () => {
  const { t } = useTranslation();
  return useMutation(
    (supplierCompanyId: number) => {
      return enableAllModelOrderingForSupplierCompany(supplierCompanyId);
    },
    {
      onSuccess: () => {
        toast.success(t('Enabled ordering'));
      },
      onError: (error: LogyxError) => {
        toast.error(getToastErrorMessage(error));
      },
      mutationKey:
        ReactMutationKeys.ENABLE_ALL_MODEL_ORDERING_FOR_SUPPLIER_COMPANY,
    }
  );
};

export const useEnableAllModelQueryingForSupplierCompany = () => {
  const { t } = useTranslation();
  return useMutation(
    (supplierCompanyId: number) => {
      return enableAllModelQueryingForSupplierCompany(supplierCompanyId);
    },
    {
      onSuccess: () => {
        toast.success(t('Enabled querying'));
      },
      onError: (error: LogyxError) => {
        toast.error(getToastErrorMessage(error));
      },
      mutationKey:
        ReactMutationKeys.ENABLE_ALL_MODEL_QUERYING_FOR_SUPPLIER_COMPANY,
    }
  );
};
