import { COLORS, marginSm, marginXs } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: ${marginXs};
  position: relative;

  ${respondTo.smallTablet`
    margin-top: 0;
  `}
`;

export const ChosenOptionsWrapper = styled.div`
  margin-left: ${marginSm};
`;

export const ChosenOptionLabel = styled.label`
  color: ${COLORS.GREEN};
  font-weight: 600;
  font-size: 16rem;
  line-height: 21rem;
  margin-right: 5rem;
`;
