import { useTranslation } from 'react-i18next';
import {
  Container,
  ErrorMessage,
  ErrorWrapper,
  FieldName,
  Row,
  RowName,
  SwitchWrapper,
  ToggleText,
} from '../ConfigForms.styled';
import { Form, Formik, FormikProps } from 'formik';
import { useRef, useState } from 'react';
import { Input } from 'components/Input/InputFormik';
import Icon from 'components/Icon/Icon';
import { Asterisk } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import { IShortTextInputField } from 'types/Builder.types';
import {
  shortTextValidationSchema,
  validateDefaultField,
} from '../validations';

interface IProps {
  currentSelectedField: IShortTextInputField;
  updateModel: (values: any) => void;
}

const ShortTextForm = ({ currentSelectedField, updateModel }: IProps) => {
  const { t } = useTranslation();
  const formikRef = useRef<FormikProps<any> | any>();
  const [activeField, setActiveField] = useState<string | null>(null);

  const initialValues = {
    field_label: currentSelectedField.field_label,
    description: currentSelectedField.description,
    default: currentSelectedField.default,
    min: currentSelectedField.min,
    max: currentSelectedField.max,
    required: currentSelectedField.required,
  };

  return (
    <Container>
      <Formik
        innerRef={formikRef}
        enableReinitialize
        initialValues={initialValues}
        validationSchema={shortTextValidationSchema}
        validateOnChange={true}
        validateOnMount={true}
        validateOnBlur={true}
        onSubmit={() => {
          //
        }}
      >
        {({ handleBlur, setFieldValue, isValid, values, errors, touched }) => {
          if (
            isValid &&
            activeField === null &&
            JSON.stringify(values) !== JSON.stringify(initialValues)
          ) {
            updateModel(values);
          }

          const handleBlurFunction = (e: any, name: string, type?: string) => {
            let value = e.target.value.trim();

            if ((name === 'min' || name === 'max') && value === '') {
              value = null;
            } else if (type === 'number') {
              value = Number(value);
            }

            setFieldValue(name, value);
            handleBlur(e);
            setActiveField(null);
          };

          // Block setModal while user focus field
          const handleFocus = (name: string) => {
            setActiveField(name);
          };

          return (
            <Form>
              <Row>
                <RowName>
                  {t('Field Label')}
                  <Icon svg={Asterisk} size={10} color={COLORS.RED_100} />
                </RowName>
                <Input
                  name="field_label"
                  placeholder={t('Field Label')}
                  height={'40rem'}
                  wrapperStyles={{ width: '100%' }}
                  onBlur={(e) => {
                    handleBlurFunction(e, 'field_label');
                  }}
                  onFocus={() => handleFocus('field_label')}
                />
                <ErrorWrapper>
                  <ErrorMessage>
                    {touched['field_label'] ? errors['field_label'] : null}
                  </ErrorMessage>
                </ErrorWrapper>
              </Row>
              <Row>
                <RowName>{t('Description')}</RowName>
                <Input
                  name="description"
                  isTextArea
                  placeholder={t('Description')}
                  height={'100rem'}
                  wrapperStyles={{ width: '100%' }}
                  onBlur={(e) => {
                    handleBlurFunction(e, 'description');
                  }}
                  onFocus={() => handleFocus('description')}
                />
                <ErrorWrapper>
                  <ErrorMessage>
                    {touched['description'] ? errors['description'] : null}
                  </ErrorMessage>
                </ErrorWrapper>
              </Row>
              <Row>
                <RowName>{t('Default text')}</RowName>
                <Input
                  name="default"
                  placeholder={t('Default text')}
                  height={'40rem'}
                  wrapperStyles={{ width: '100%' }}
                  onBlur={(e) => {
                    handleBlurFunction(e, 'default');
                  }}
                  onFocus={() => handleFocus('default')}
                  validate={(value: string) =>
                    validateDefaultField(values.min, values.max, value)
                  }
                />
                <ErrorWrapper>
                  <ErrorMessage>
                    {errors['default'] ? errors['default'] : null}
                  </ErrorMessage>
                </ErrorWrapper>
              </Row>
              <Row>
                <RowName>{t('Min/Max Characters')}</RowName>
                <FieldName>{t('From')}:</FieldName>
                <Input
                  name="min"
                  type="number"
                  hideNumberArrows
                  placeholder={t('Min')}
                  height={'40rem'}
                  wrapperStyles={{ width: '100%' }}
                  onBlur={(e) => {
                    handleBlurFunction(e, 'min', 'number');
                    setTimeout(() => {
                      formikRef.current?.validateField('default');
                    }, 0);
                  }}
                  onFocus={() => handleFocus('min')}
                />
                <ErrorWrapper>
                  <ErrorMessage>
                    {touched['min'] ? errors['min'] : null}
                  </ErrorMessage>
                </ErrorWrapper>
                <FieldName>{t('To')}:</FieldName>
                <Input
                  name="max"
                  type="number"
                  hideNumberArrows
                  placeholder={t('Max')}
                  height={'40rem'}
                  wrapperStyles={{ width: '100%' }}
                  onBlur={(e) => {
                    handleBlurFunction(e, 'max', 'number');
                    setTimeout(() => {
                      formikRef.current?.validateField('default');
                    }, 0);
                  }}
                  onFocus={() => handleFocus('max')}
                />
                <ErrorWrapper>
                  <ErrorMessage>
                    {touched['max'] ? errors['max'] : null}
                  </ErrorMessage>
                </ErrorWrapper>
              </Row>
              <Row>
                <RowName>{t('Required')}</RowName>
                <SwitchWrapper>
                  <ToggleText isOff={values.required}>
                    {values.required ? t('On') : t('Off')}
                  </ToggleText>
                  <ToggleSwitch
                    isChecked={values.required}
                    size="large"
                    onClick={() => setFieldValue('required', !values.required)}
                  />
                </SwitchWrapper>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
};

export default ShortTextForm;
