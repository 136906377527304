import i18n from 'providers/i18n/i18n';
import { useQuery } from 'react-query';
import { getModelById } from 'services/ModelService';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';

export const useGetSingleModel = (modelId: number) =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_SINGLE_MODEL, i18n.resolvedLanguage, modelId],
    queryFn: () => {
      return getModelById(modelId);
    },
  });
