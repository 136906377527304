import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  Container,
  Title,
  SecondStepContainer,
} from './AddSupplierCompanyModal.styled';
import { Modal } from '../Modal/Modal';
import { useTranslation } from 'react-i18next';
import ScrollableList from 'components/ScrollableList/ScrollableList';
import { ICompany } from 'types/Company.types';
import { useDebounce } from 'utils/hooks/useDebounce';
import {
  ICreateSupplierCompanyDTO,
  useCreateSupplierCompany,
  useGetEligibleCompaniesForCreatingSupplierCompany,
} from './hooks';
import { IRootReducerState } from 'store/store';
import { useSelector } from 'react-redux';
import { ELIGIBLE_COMPANIES_FOR_CREATING_SUPPLIER_COMPANY_MODEL_PER_PAGE } from './constants';
import { getDataFromPages } from 'utils/getDataFromPages';
import Input from 'components/Input/Input';
import Button from 'components/Button/Button';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';

export interface IAddSupplierCompanyModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export function AddSupplierCompanyModal({
  isOpen,
  setIsOpen,
}: IAddSupplierCompanyModalProps) {
  const { t } = useTranslation();
  const [searchBy, setSearchBy] = useState<string>('');
  const [reference, setReference] = useState<string>('');
  const [selectedCompany, setSelectedCompany] = useState<ICompany | null>(null);
  const debouncedSearchByCompany = useDebounce(searchBy);
  const { supplier } = useSelector(
    (state: IRootReducerState) => state.userInfo
  );

  const { data: companiesData, isLoading: isLoadingGetCompanies } =
    useGetEligibleCompaniesForCreatingSupplierCompany(
      ELIGIBLE_COMPANIES_FOR_CREATING_SUPPLIER_COMPANY_MODEL_PER_PAGE,
      supplier.id,
      debouncedSearchByCompany,
      isOpen
    );

  const companies: ICompany[] = getDataFromPages(
    companiesData?.pages,
    'companies'
  );

  const {
    mutate: createSupplierCompany,
    isLoading: isLoadingCreateSupplierCompany,
  } = useCreateSupplierCompany();

  const handleSelect = (company: ICompany) => {
    setSelectedCompany(company);
  };

  useEffect(() => {
    if (!isOpen) {
      setSelectedCompany(null);
      setReference('');
      setSearchBy('');
    }
  }, [isOpen]);

  const { isSmallPhone } = useBreakpointFlag();

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      modalStyle={{
        margin: 'auto',
        width: isSmallPhone ? '90%' : '600rem',
        minWidth: isSmallPhone ? '90%' : 'auto',
        position: 'fixed',
      }}
    >
      <Container>
        <Title>
          {!selectedCompany
            ? t('Select company for granting access')
            : t('Enter custom reference for {{COMPANY_NAME}}').replace(
                '{{COMPANY_NAME}}',
                selectedCompany.name
              )}
        </Title>
        {!selectedCompany ? (
          <ScrollableList
            items={companies || []}
            isLoading={isLoadingGetCompanies || isLoadingCreateSupplierCompany}
            noContentLabel={t('No companies found')}
            onClick={(company: ICompany) => handleSelect(company)}
            onSearch={(str: string) => setSearchBy(str)}
          />
        ) : (
          <SecondStepContainer>
            <Input
              placeholder={t('Reference')}
              width={'300rem'}
              onChange={(e: any) => setReference(e.target.value)}
              disabled={isLoadingCreateSupplierCompany}
            />
            <Button
              primary
              label={t('Confirm')}
              disabled={
                !reference || !selectedCompany || isLoadingCreateSupplierCompany
              }
              width="200rem"
              wrapperStyle={{ marginTop: '20rem' }}
              onClick={() => {
                const dto: ICreateSupplierCompanyDTO = {
                  companyId: selectedCompany.id,
                  supplierId: supplier.id,
                  reference,
                };
                createSupplierCompany(dto, {
                  onSuccess: () => setIsOpen(false),
                });
              }}
            />
          </SecondStepContainer>
        )}
      </Container>
    </Modal>
  );
}
