import { TableModal } from 'components/Modals/TableModal/TableModal';
import { FieldArray, Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { IQuestion } from 'types/Question.types';
import { capitalizeFirstLetter } from 'utils/stringUtils';
import {
  ClearAllFieldsLabel,
  Container,
  FormContainer,
  FormItemContainer,
  ModelNotFoundLabel,
  QuestionName,
  RedAsterisk,
  QuestionNameAsteriskInfoRow,
  TooltipWrapper,
  ContainerGrayBottomBorder,
  LabelsWrapper,
  AdditionalMargin,
  Column,
  RedAsteriskWrapper,
} from './ConfigureForm.styled';
import QueryProvider from '../../../providers/QueryProvider/QueryProvider';
import FormItem from './FormItem/FormItem';
import {
  useGetSortedQuestions,
  useGetValuesFromQuestionOutputs,
  useListenForSubmitConfigurationTrigger,
} from './hooks';
import OverlayProvider from 'providers/OverlayProvider/OverlayProvider';
import { resetConfigureForm } from 'store/Model/actions/model';
import {
  resetTouchedQuestionNames,
  setAreAllFieldsTouched,
  setIsFormValid,
} from 'store/Form/actions/form';
import { extractQuestionPrice, questionHasValue } from './helpers';
import Tooltip from 'components/Tooltip/Tooltip';
import { useTranslation } from 'react-i18next';
import Icon from 'components/Icon/Icon';
import QuestionSkeletons from './QuestionSkeletons/QuestionSkeletons';
import { ColorModal } from 'components/Modals/ColorModal/ColorModal';
import { AppMode } from 'store/Common/reducers/common';
import { Info } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';

interface IConfigureFormProps {
  hasImages: boolean;
}

const ConfigureForm = ({ hasImages }: IConfigureFormProps) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const valuesFromOutputs = useGetValuesFromQuestionOutputs();
  const questions: IQuestion[] = useGetSortedQuestions();

  const { queryStatus, pricing } = useSelector(
    (state: IRootReducerState) => state.modelInfo
  );
  const { isFormValid, modelNotFound } = useSelector(
    (state: IRootReducerState) => state.formInfo
  );

  const appMode: AppMode = useSelector(
    (state: IRootReducerState) => state.commonInfo.appMode
  );

  useListenForSubmitConfigurationTrigger(valuesFromOutputs, dispatch);

  return (
    <Container data-testid={'form-container'} hasImages={hasImages}>
      <OverlayProvider>
        <Formik
          enableReinitialize
          initialValues={valuesFromOutputs}
          validateOnChange={true}
          validateOnBlur={true}
          onSubmit={() => {
            return;
          }}
          validateOnMount={true}
        >
          {({ isValid, values, setFormikState }) => {
            if (
              queryStatus.success &&
              JSON.stringify(values) !== JSON.stringify(valuesFromOutputs)
            ) {
              // enableReinitialize doesnt trigger every time when the formik context values and the values from outputs missmatch
              // These objects can missmatch after the query request is successful and new form data is calculated from the response
              // This will update the formik values to match the query response data and rerender the form
              setFormikState((state) => ({
                ...state,
                values: valuesFromOutputs,
              }));
            }

            if (isFormValid !== isValid) {
              dispatch(setIsFormValid(isValid));
            }

            let nonHiddenQuestionIndex = 0;
            return (
              <Form>
                <LabelsWrapper>
                  <ClearAllFieldsLabel
                    onClick={() => {
                      dispatch(resetConfigureForm());
                      dispatch(resetTouchedQuestionNames());
                      dispatch(setAreAllFieldsTouched(false));
                    }}
                  >
                    {t('Clear all fields')}
                  </ClearAllFieldsLabel>
                </LabelsWrapper>
                {modelNotFound && (
                  <ModelNotFoundLabel>
                    {t('Model not found')}
                  </ModelNotFoundLabel>
                )}
                <FieldArray
                  name="questions"
                  render={() => (
                    <QueryProvider>
                      <FormContainer>
                        {questions.length ? (
                          questions?.map(
                            (question: IQuestion, index: number) => {
                              const hasValue = questionHasValue(
                                values,
                                question
                              );
                              if (!question.hidden) {
                                nonHiddenQuestionIndex++;
                              }
                              const questionPrice = extractQuestionPrice(
                                pricing,
                                question.initialKey,
                                appMode !== AppMode.BLUEPRINT
                              );
                              return question.hidden ? null : (
                                <ContainerGrayBottomBorder
                                  key={index}
                                  showBorder={
                                    nonHiddenQuestionIndex <=
                                    questions.filter(
                                      (question: IQuestion) => !question.hidden
                                    ).length -
                                      1
                                  }
                                >
                                  <FormItemContainer hasValue={hasValue}>
                                    <Column>
                                      <QuestionNameAsteriskInfoRow>
                                        <QuestionName>
                                          {capitalizeFirstLetter(
                                            question.name
                                          ).replace('_', ' ')}
                                        </QuestionName>
                                        {question.required && !hasValue && (
                                          <RedAsteriskWrapper>
                                            <RedAsterisk>*</RedAsterisk>
                                          </RedAsteriskWrapper>
                                        )}
                                        {question.description && (
                                          <TooltipWrapper>
                                            <Tooltip
                                              content={question.description}
                                            >
                                              {question.description ? (
                                                <Icon
                                                  svg={Info}
                                                  size={20}
                                                  color={COLORS.GREEN}
                                                />
                                              ) : null}
                                            </Tooltip>
                                          </TooltipWrapper>
                                        )}
                                      </QuestionNameAsteriskInfoRow>
                                      <AdditionalMargin />
                                    </Column>

                                    <FormItem
                                      question={question}
                                      hasImages={hasImages}
                                      questionPrice={questionPrice}
                                    />
                                  </FormItemContainer>
                                </ContainerGrayBottomBorder>
                              );
                            }
                          )
                        ) : (
                          <QuestionSkeletons hasImages={hasImages} />
                        )}
                      </FormContainer>
                      <TableModal />
                      <ColorModal />
                    </QueryProvider>
                  )}
                />
              </Form>
            );
          }}
        </Formik>
      </OverlayProvider>
    </Container>
  );
};

export default ConfigureForm;
