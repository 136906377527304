export const capitalizeFirstLetter = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const uncapitalizeFirstLetter = (word: string) => {
  return word.charAt(0).toLowerCase() + word.slice(1);
};

export const cutLongWords = (value: string, size: number) => {
  return value.length > size ? `${value.slice(0, size)}...` : value;
};
