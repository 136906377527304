import { ICustomModel, OptionMatrixEntry } from 'types/Builder.types';
import apiClient from './api/apiService';

const BASE_URL = '/v1/editor';

export const importPriceMatrix = async (formData: FormData) => {
  const headers = { 'Content-Type': 'multipart/form-data' };
  const url = `${BASE_URL}/versions/price-matrix`;
  const { data } = await apiClient.post(url, formData, { headers });

  return data.data;
};

export const exportPriceMatrix = async (
  name: string,
  filetype: string,
  matrix: OptionMatrixEntry[]
): Promise<any> => {
  const payload = {
    name,
    filetype,
    matrix,
  };
  const url = `${BASE_URL}/versions/price-matrix/export`;
  const response = await apiClient.post(url, payload, { responseType: 'blob' });
  return response.data;
};

export const updateVersion = async (
  id: number,
  payload: { locked?: boolean; is_active?: boolean }
): Promise<any> => {
  const url = `${BASE_URL}/versions/${id}`;
  const { data } = await apiClient.patch<any>(url, payload);

  return data.data;
};

export const getCustomVersion = async (id: string): Promise<any> => {
  const url = `${BASE_URL}/versions/${id}`;
  const { data } = await apiClient.get<any>(url);

  return data.data;
};

export const saveDraft = async (
  model: ICustomModel,
  versionId: number
): Promise<any> => {
  const url = `${BASE_URL}/versions/${versionId}/save`;
  const { data } = await apiClient.patch<any>(url, model);
  return data.data;
};

export const copyVersion = async (
  id: number,
  toNewModel: boolean
): Promise<any> => {
  const url = `${BASE_URL}/versions/${id}?copy_to_new_model=${toNewModel}`;
  const { data } = await apiClient.post<any>(url);
  return data.data;
};

export const archiveCustomModel = async (modelId: number): Promise<any> => {
  const url = `${BASE_URL}/models/${modelId}`;
  const { data } = await apiClient.delete<any>(url);
  return data.data;
};

export const restoreCustomModel = async (modelId: number): Promise<any> => {
  const url = `${BASE_URL}/models/${modelId}`;
  const { data } = await apiClient.patch<any>(url);
  return data.data;
};
