import { useTranslation } from 'react-i18next';
import {
  FirstRow,
  InputWrapper,
  LeftSide,
  RightSide,
  SelectWrapper,
  Title,
} from 'pages/AllModelsPage/AllModelsPage.styled';
import Input from 'components/Input/Input';
import ViewTypePicker, {
  ViewType,
} from 'components/ViewTypePicker/ViewTypePicker';
import { useEffect, useState } from 'react';
import Icon from 'components/Icon/Icon';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'utils/hooks/useDebounce';
import { Select } from 'components/Select/Select';
import { useSelectedViewType } from 'pages/ModelsPage/hooks';
import { useGetCategoryOptions } from 'pages/AllModelsPage/hooks';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import AllModelsGridView from 'pages/AllModelsPage/AllModelsGridView/AllModelsGridView';
import AllModelsListView from 'pages/AllModelsPage/AllModelsListView/AllModelsListView';
import { RoutesConfig } from 'navigation/routes';
import { AppMode } from 'store/Common/reducers/common';
import { CaretLeft, MagnifyingGlass as Search } from '@phosphor-icons/react';
import { usePageTitle } from 'utils/hooks/usePageTitle';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';
import { COLORS } from 'assets/styled';

const MyModelsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const appMode: AppMode = useSelector(
    (state: IRootReducerState) => state.commonInfo.appMode
  );
  const { selectedViewType, setSelectedViewType } = useSelectedViewType();
  const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(
    null
  );

  const [searchBy, setSearchBy] = useState<string>('');
  const debouncedSearchBy = useDebounce(searchBy);
  const [categoriesSearchBy, setCategoriesSearchBy] = useState<string>('');
  const debouncedCategoriesSearchBy = useDebounce(categoriesSearchBy);

  const { categoryOptions, fetchNextPage, hasNextPage } = useGetCategoryOptions(
    debouncedCategoriesSearchBy
  );

  const supplier = useSelector(
    (state: IRootReducerState) => state.userInfo.supplier
  );

  const isCompanyMode = appMode === AppMode.COMPANY;

  usePageTitle('My Models');

  const { isMidTablet } = useBreakpointFlag();

  useEffect(() => {
    if (isMidTablet && selectedViewType === ViewType.LIST) {
      setSelectedViewType(ViewType.GRID);
    }
  }, [isMidTablet]);

  return (
    <>
      <FirstRow>
        <LeftSide
          isMyModels
          onClick={() =>
            isCompanyMode ? navigate(RoutesConfig.Models.fullPath) : null
          }
        >
          {isCompanyMode ? (
            <Icon
              svg={CaretLeft}
              color={COLORS.GREEN}
              weight="bold"
              size={27}
            />
          ) : null}
          <Title>{t('My Models')}</Title>
        </LeftSide>
        <RightSide>
          <InputWrapper>
            <Input
              placeholder={t('Search models')}
              width={'100%'}
              onChange={(e: any) => setSearchBy(e.target.value)}
              icon={Search}
              wrapperStyles={{ width: '100%' }}
            />
          </InputWrapper>
          <SelectWrapper>
            <Select
              width="100%"
              defaultValue={{ value: '', label: 'All categories' }}
              name="category-select"
              placeholder={t('Category')}
              options={categoryOptions}
              onChange={(selectedOption: any) =>
                setSelectedCategoryId(selectedOption.value)
              }
              onInputChange={(value: string) => setCategoriesSearchBy(value)}
              onMenuScrollToBottom={() => hasNextPage && fetchNextPage()}
            />
          </SelectWrapper>
          {!isMidTablet && (
            <ViewTypePicker
              selectedViewType={selectedViewType}
              setSelectedViewType={setSelectedViewType}
            />
          )}
        </RightSide>
      </FirstRow>

      {selectedViewType === ViewType.GRID ? (
        <AllModelsGridView
          debouncedSearchBy={debouncedSearchBy}
          selectedCategoryId={selectedCategoryId}
          supplierId={supplier.id}
        />
      ) : (
        <AllModelsListView
          debouncedSearchBy={debouncedSearchBy}
          selectedCategoryId={selectedCategoryId}
          supplierId={supplier.id}
        />
      )}
    </>
  );
};

export default MyModelsPage;
