import { ViewType } from 'components/ViewTypePicker/ViewTypePicker';
import { useEffect, useState } from 'react';

export const useSelectedViewType = (isMidTablet: boolean) => {
  const [selectedViewType, setSelectedViewType] = useState<ViewType>(
    isMidTablet ? ViewType.GRID : ViewType.LIST
  );
  useEffect(() => {
    if (isMidTablet) {
      setSelectedViewType(ViewType.GRID);
    } else {
      setSelectedViewType(ViewType.LIST);
    }
  }, [isMidTablet]);

  return { selectedViewType };
};
