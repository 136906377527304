import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import {
  Container,
  Title,
  ButtonWrapper,
  ErrorMessage,
  RelativeWrapper,
} from './EditSupplierCompanyReferenceModal.styled';
import { Modal } from '../Modal/Modal';
import Button from 'components/Button/Button';
import { useTranslation } from 'react-i18next';
import { Form, Formik, FormikProps } from 'formik';
import { editSupplierCompanyReferenceValidationSchema } from './validation';
import { Input } from 'components/Input/InputFormik';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';

export interface IEditSupplierCompanyReferenceModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onConfirm: (reference: string) => void;
  title: string;
  initialValue: string;
  isLoading: boolean;
}

export function EditSupplierCompanyReferenceModal({
  isOpen,
  setIsOpen,
  onConfirm,
  title,
  initialValue,
  isLoading,
}: IEditSupplierCompanyReferenceModalProps) {
  const { t } = useTranslation();
  const formikRef = useRef<FormikProps<any> | any>();
  const [areAllFieldsTouched, setAreAllFieldsTouched] =
    useState<boolean>(false);
  const { isPhone } = useBreakpointFlag();

  const handleSubmit = () => {
    if (formikRef.current.isValid) {
      onConfirm(formikRef.current.values.reference);
    } else if (!areAllFieldsTouched) {
      setAreAllFieldsTouched(true);
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setAreAllFieldsTouched(false);
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      modalStyle={{
        margin: 'auto',
        width: isPhone ? '90%' : '600rem',
        minWidth: isPhone ? '90%' : 'auto',
        position: 'fixed',
      }}
    >
      <Container>
        <Title>{title}</Title>
        <Formik
          innerRef={formikRef}
          initialValues={{
            reference: initialValue,
          }}
          validationSchema={editSupplierCompanyReferenceValidationSchema}
          validateOnChange={true}
          validateOnMount={true}
          validateOnBlur={true}
          onSubmit={() => {
            //
          }}
        >
          {({ errors }) => {
            return (
              <Form>
                <RelativeWrapper>
                  <Input
                    name="reference"
                    placeholder={t('Reference')}
                    height={'41rem'}
                    wrapperStyles={{ width: isPhone ? '100%' : '300rem' }}
                    disabled={isLoading}
                  />
                  <ErrorMessage>
                    {areAllFieldsTouched ? errors['reference'] : null}
                  </ErrorMessage>
                </RelativeWrapper>
              </Form>
            );
          }}
        </Formik>
        <ButtonWrapper>
          <Button
            secondary
            label={t('Cancel')}
            width={isPhone ? '100rem' : '200rem'}
            onClick={() => {
              setIsOpen(false);
            }}
            disabled={isLoading}
          />
          <Button
            primary
            label={t('Confirm')}
            width={isPhone ? '100rem' : '200rem'}
            onClick={() => handleSubmit()}
            disabled={isLoading}
          />
        </ButtonWrapper>
      </Container>
    </Modal>
  );
}
