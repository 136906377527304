import ModelRow from 'pages/ModelsPage/ModelsPageList/SupplierCategoriesList/CategoryList/ModelRow/ModelRow';
import {
  Container,
  ModelRowsContainer,
  NoContentLabel,
  NoContentLabelWrapper,
} from './AllModelsListView.styled';
import { MODELS_PER_PAGE } from '../constants';
import { useEffect, useState } from 'react';
import { IModel } from 'types/Model.types';
import { useGetModels } from './hooks';
import Pagination from 'components/Pagination/Pagination';
import { queryClient } from 'index';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import ModelRowSkeleton from 'pages/ModelsPage/ModelsPageList/SupplierCategoriesList/CategoryList/ModelRow/ModelRowSkeleton/ModelRowSkeleton';
import { useTranslation } from 'react-i18next';

interface IAllModelsGridViewProps {
  debouncedSearchBy: string;
  selectedCategoryId: number | null;
  supplierId?: number; // Used in my models page
}

const AllModelsListView = ({
  debouncedSearchBy,
  selectedCategoryId,
  supplierId,
}: IAllModelsGridViewProps) => {
  const { t } = useTranslation();
  const [page, setPage] = useState<number>(1);
  const { data: modelsData, isLoading: isLoadingModels } = useGetModels(
    page,
    MODELS_PER_PAGE,
    debouncedSearchBy,
    supplierId || null,
    selectedCategoryId,
    false,
    false
  );

  useEffect(() => {
    if (page !== 1) {
      setPage(1);
    }
  }, [selectedCategoryId]);

  const models: IModel[] = modelsData?.models || [];

  return (
    <Container>
      {models.length ? (
        <ModelRowsContainer>
          {models?.map((model: IModel, index: number) => (
            <ModelRow
              model={model}
              key={`model-row-${index}`}
              withoutLine={models.length - 1 === index}
              onToggleFavoriteSuccess={() => {
                queryClient.invalidateQueries([ReactQueryKeys.GET_MODELS]);
                queryClient.invalidateQueries([
                  ReactQueryKeys.GET_MODELS_INFINITE,
                ]);
              }}
            />
          ))}
          <Pagination
            page={page}
            perPage={MODELS_PER_PAGE}
            total={modelsData?.pagination?.total || '0'}
            setPage={setPage}
          />
        </ModelRowsContainer>
      ) : isLoadingModels ? (
        <ModelRowsContainer isSkeleton>
          {Array.from({ length: MODELS_PER_PAGE }, (_, index) => (
            <ModelRowSkeleton
              key={index}
              index={index}
              opacityCoeficient={0.1}
              withoutLine={MODELS_PER_PAGE - 1 === index}
            />
          ))}
        </ModelRowsContainer>
      ) : (
        <NoContentLabelWrapper>
          <NoContentLabel>{t('No models found')}</NoContentLabel>
        </NoContentLabelWrapper>
      )}
    </Container>
  );
};

export default AllModelsListView;
