import Icon from 'components/Icon/Icon';
import {
  Container,
  IconWrapper,
  LeftSide,
  listIconStyle,
  Middle,
  MiddleInputWrapper,
  Name,
  RightSide,
} from './FieldsComponents.styled';
import {
  CaretDown,
  CurrencyCircleDollar,
  DotsSixVertical,
  Info,
  Trash,
} from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';
import { ICustomModel, IListInputField } from 'types/Builder.types';
import { useTranslation } from 'react-i18next';
import Input from 'components/Input/Input';
import Tooltip from 'components/Tooltip/Tooltip';

interface IProps {
  field: IListInputField;
  model: ICustomModel;
  onDelete: () => void;
  drag?: React.Ref<any>;
  onDragStart: () => void;
}

const ListField = ({ field, model, onDelete, drag, onDragStart }: IProps) => {
  const { t } = useTranslation();

  const currentField = model.input_fields.find(
    (f) => f.id === field.id
  ) as IListInputField;

  return (
    <Container ref={drag}>
      <LeftSide>
        <IconWrapper
          ref={drag}
          onMouseDown={(e: any) => {
            e.stopPropagation();
            onDragStart();
          }}
        >
          <Icon
            svg={DotsSixVertical}
            color={COLORS.GREEN}
            size={30}
            weight="regular"
          />
        </IconWrapper>
        <Name>
          {field.field_label}
          {!!field.description.length && (
            <Tooltip content={field.description}>
              <Icon
                svg={Info}
                color={COLORS.GREEN}
                size={20}
                weight="regular"
              />
            </Tooltip>
          )}
          {(currentField.id === model.price_matrices[0].x_axis_id ||
            currentField.id === model.price_matrices[0].y_axis_id) && (
            <Tooltip content={t('Input field has impact on price')}>
              <Icon
                svg={CurrencyCircleDollar}
                color={COLORS.GREEN}
                size={20}
                weight="regular"
              />
            </Tooltip>
          )}
        </Name>
      </LeftSide>
      <Middle>
        <MiddleInputWrapper>
          {currentField.default !== null &&
            currentField.options[currentField.default]?.name && (
              <>
                <Input
                  value={currentField.options[currentField.default]?.name}
                  readOnly
                  placeholder="List"
                  wrapperStyles={{ width: '250rem' }}
                  style={{ paddingRight: '40rem' }}
                />
                <Icon
                  svg={CaretDown}
                  color={COLORS.BLACK}
                  size={20}
                  weight="regular"
                  disabled
                  wrapperStyle={listIconStyle}
                />
              </>
            )}
        </MiddleInputWrapper>
      </Middle>
      <RightSide>
        <Tooltip
          content={
            currentField.id === model.price_matrices[0].x_axis_id ||
            currentField.id === model.price_matrices[0].y_axis_id
              ? t('Input field is used in matrix')
              : undefined
          }
        >
          <Icon
            svg={Trash}
            color={COLORS.RED_100}
            size={30}
            weight="regular"
            onClick={onDelete}
            disabled={
              currentField.id === model.price_matrices[0].x_axis_id ||
              currentField.id === model.price_matrices[0].y_axis_id
            }
          />
        </Tooltip>
      </RightSide>
    </Container>
  );
};

export default ListField;
