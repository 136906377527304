import { CSSProperties, MouseEvent } from 'react';
import { StyledButton, Container, SpinnerWrapper } from './Button.styled';
import SmallLoader from 'components/SmallLoader/SmallLoader';

interface IButton {
  className?: string;
  label: string;
  onClick?: (event: MouseEvent) => void;
  uploadOnChange?: React.ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
  icon?: JSX.Element;
  type?: string;
  height?: string;
  width?: string;
  primary?: boolean;
  secondary?: boolean;
  table?: boolean;
  red?: boolean;
  redOutline?: boolean;
  link?: boolean;
  color?: string;
  rightIcon?: boolean;
  block?: boolean;
  pwId?: string;
  wrapperStyle?: CSSProperties;
  buttonStyle?: CSSProperties;
  isLoading?: boolean;
}

const Button = (props: IButton) => {
  return (
    <Container wrapperStyle={props.wrapperStyle} className={props.className}>
      <StyledButton {...props} onClick={props.onClick} data-testid={props.pwId}>
        {props.label}
      </StyledButton>
      {props.isLoading && (
        <SpinnerWrapper>
          <SmallLoader />
        </SpinnerWrapper>
      )}
    </Container>
  );
};

export default Button;
