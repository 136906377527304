import QuestionSkeleton from './QuestionSkeleton/QuestionSkeleton';

interface IQuestionSkeletonsProps {
  hasImages: boolean;
}

const QuestionSkeletons = ({ hasImages }: IQuestionSkeletonsProps) => {
  return (
    <>
      <QuestionSkeleton hasImages={hasImages} index={1} />
      <QuestionSkeleton hasImages={hasImages} index={2} />
      <QuestionSkeleton hasImages={hasImages} index={3} />
      <QuestionSkeleton hasImages={hasImages} index={4} />
      <QuestionSkeleton hasImages={hasImages} index={5} />
      <QuestionSkeleton hasImages={hasImages} index={6} showBorder={false} />
    </>
  );
};

export default QuestionSkeletons;
