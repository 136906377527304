import { Skeleton } from '@mui/material';
import {
  Container,
  EditIconLabelWrapper,
  Grid,
  HorizontalLine,
  RemoveIconLabelWrapper,
  RowSpaceBetween,
  FirstRow,
  RightPart,
  LeftPart,
  DuplicateIconLabelWrapper,
  ImagesConfigurationWrapper,
  GridPriceWrapper,
  PriceWrapper,
} from 'pages/ShoppingCartPage/ShoppingCartCard/ShoppingCartCard.styled';

import { useTranslation } from 'react-i18next';

interface IShoppingCartCardSkeletonProps {
  index: number;
  opacityCoeficient?: number;
}

const ShoppingCartCardSkeleton = ({
  index,
  opacityCoeficient = 0.22,
}: IShoppingCartCardSkeletonProps) => {
  const { t } = useTranslation();

  const hasImages = true;
  return (
    <Container opacityIndex={index} opacityCoeficient={opacityCoeficient}>
      <FirstRow>
        <LeftPart>
          <Skeleton
            variant="rectangular"
            height={'30rem'}
            width={'268rem'}
            animation="wave"
            style={{
              borderRadius: '5rem',
              marginRight: '30rem',
            }}
          />
        </LeftPart>
        <RightPart>
          <EditIconLabelWrapper>
            <Skeleton
              variant="rectangular"
              height={'20rem'}
              width={'50rem'}
              animation="wave"
              style={{
                borderRadius: '5rem',
              }}
            />
            <Skeleton
              variant="rectangular"
              height={'20rem'}
              width={'50rem'}
              animation="wave"
              style={{
                borderRadius: '5rem',
                marginLeft: '20rem',
              }}
            />
          </EditIconLabelWrapper>
          <RemoveIconLabelWrapper>
            <Skeleton
              variant="rectangular"
              height={'20rem'}
              width={'50rem'}
              animation="wave"
              style={{
                borderRadius: '5rem',
              }}
            />
            <Skeleton
              variant="rectangular"
              height={'20rem'}
              width={'75rem'}
              animation="wave"
              style={{
                borderRadius: '5rem',
                marginLeft: '20rem',
              }}
            />
          </RemoveIconLabelWrapper>
          <DuplicateIconLabelWrapper>
            <Skeleton
              variant="rectangular"
              height={'20rem'}
              width={'50rem'}
              animation="wave"
              style={{
                borderRadius: '5rem',
              }}
            />
            <Skeleton
              variant="rectangular"
              height={'20rem'}
              width={'100rem'}
              animation="wave"
              style={{
                borderRadius: '5rem',
                marginLeft: '20rem',
              }}
            />
          </DuplicateIconLabelWrapper>
        </RightPart>
      </FirstRow>
      <ImagesConfigurationWrapper>
        <Skeleton
          variant="rectangular"
          width={'268rem'}
          height={'180rem'}
          animation="wave"
          style={{
            borderRadius: '5rem',
          }}
        />
        <GridPriceWrapper>
          <Grid hasImages={hasImages}>
            {Array.from({ length: 6 }, (_, index) => (
              <RowSpaceBetween key={index}>
                <Skeleton
                  key={`grid-item-${index}`}
                  variant="rectangular"
                  width={'100rem'}
                  height={'20rem'}
                  animation="wave"
                  style={{
                    borderRadius: '5rem',
                  }}
                />
                <Skeleton
                  key={`grid-item-${index}`}
                  variant="rectangular"
                  width={'100rem'}
                  height={'20rem'}
                  animation="wave"
                  style={{
                    borderRadius: '5rem',
                  }}
                />
              </RowSpaceBetween>
            ))}
          </Grid>
          <PriceWrapper>
            <Skeleton
              variant="rectangular"
              height={'20rem'}
              width={'100rem'}
              animation="wave"
              style={{
                borderRadius: '5rem',
              }}
            />
            <Skeleton
              variant="rectangular"
              height={'20rem'}
              width={'100rem'}
              animation="wave"
              style={{
                marginLeft: '20rem',
                borderRadius: '5rem',
              }}
            />
          </PriceWrapper>
        </GridPriceWrapper>
      </ImagesConfigurationWrapper>
      <HorizontalLine hasImages={hasImages} />
    </Container>
  );
};

export default ShoppingCartCardSkeleton;
