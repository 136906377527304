import Tooltip from 'components/Tooltip/Tooltip';
import {
  CheckboxWrapper,
  Container,
  PriceInputWrapper,
  FixedAmountWrapper,
  Label,
  ModelNameLabel,
  ModelNameTooltipWrapper,
  RowTop,
  RowBottom,
  ToggleSwitchLabel,
  TooltipWrapper,
  RowTopRightPart,
  ToggleSwitchWrapper,
  ErrorOverlay,
  ManageQuestionVisibilityLabel,
  TestButton,
  ApplyButton,
  IsUsingSupplierSystemDiscountsWrapper,
} from './ModelConfiguration.styled';
import { Dispatch, SetStateAction, useState } from 'react';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import { useTranslation } from 'react-i18next';
import {
  PriceInput,
  SymbolPlacementEnum,
  SymbolTypeEnum,
} from 'components/Input/PriceInput/PriceInput';
import { IDealerPanelModel } from 'types/Model.types';
import {
  useCreateConfiguration,
  useHideErrorsAfterDelay,
  useUpdateConfiguration,
} from './hooks';
import {
  IConfiguration,
  ICreateConfigurationData,
  ITestCompanyModelConfiguration,
  IUpdateConfigurationData,
} from 'types/Configuration.types';
import {
  getModelConfigurationTooltipText,
  validatePercentageInput,
  validateFixedPriceInput,
} from './helpers';
import Icon from 'components/Icon/Icon';
import { COLORS } from 'assets/styled';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { Info } from '@phosphor-icons/react';
import { setTestCompanyModelConfiguration } from 'store/SubmitConfiguration/actions/submitConfiguration';
import { useDispatch } from 'react-redux';

interface IModelConfigurationProps {
  model: IDealerPanelModel;
  index: number;
  withoutLine: boolean;
  setManageVisibilityModelId: Dispatch<SetStateAction<number | null>>;
  onTestClick: (
    modelIdForTest: number,
    existingConfigurationId: number | null
  ) => void;
  supplierHasSystemIntegration: boolean;
}

const ModelConfiguration = ({
  model,
  index,
  withoutLine,
  setManageVisibilityModelId,
  onTestClick,
  supplierHasSystemIntegration,
}: IModelConfigurationProps) => {
  const dispatch = useDispatch();
  const MODEL_CONFIGURATION_INITIAL_VALUES = {
    baseMarkup: 0,
    basePurchaseDiscount: 0,
    surchargeMarkup: 0,
    surchargePurchaseDiscount: 0,
    fixedPrice: 0,
    excludeSurcharge: false,
    roundPrices: false,
    isActive: true,
    isUsingSupplierSystemDiscounts: false,
  };

  const configuration: IConfiguration = model.configurations?.[0];
  const { t } = useTranslation();
  const [baseMarkup, setBaseMarkup] = useState<number>(
    configuration
      ? Number(configuration.base_markup)
      : MODEL_CONFIGURATION_INITIAL_VALUES.baseMarkup
  );

  const [basePurchaseDiscount, setBasePurchaseDiscount] = useState<number>(
    configuration
      ? Number(configuration.base_purchase_discount)
      : MODEL_CONFIGURATION_INITIAL_VALUES.basePurchaseDiscount
  );

  const [surchargeMarkup, setSurchargeMarkup] = useState<number>(
    configuration
      ? Number(configuration.surcharge_markup)
      : MODEL_CONFIGURATION_INITIAL_VALUES.surchargeMarkup
  );

  const [surchargePurchaseDiscount, setSurchargePurchaseDiscount] =
    useState<number>(
      configuration
        ? Number(configuration.surcharge_purchase_discount)
        : MODEL_CONFIGURATION_INITIAL_VALUES.surchargePurchaseDiscount
    );

  const [fixedPrice, setFixedPrice] = useState<number>(
    configuration
      ? Number(configuration.fixed_amount)
      : MODEL_CONFIGURATION_INITIAL_VALUES.fixedPrice
  );
  const [excludeSurcharge, setExcludeSurcharge] = useState<boolean>(
    configuration
      ? configuration.exclude_surcharge
      : MODEL_CONFIGURATION_INITIAL_VALUES.excludeSurcharge
  );
  const [roundPrices, setRoundPrices] = useState<boolean>(
    configuration
      ? configuration.round_prices
      : MODEL_CONFIGURATION_INITIAL_VALUES.roundPrices
  );
  const [isActive, setIsActive] = useState<boolean>(
    configuration
      ? configuration.is_active
      : MODEL_CONFIGURATION_INITIAL_VALUES.isActive
  );

  const [isUsingSupplierSystemDiscounts, setIsUsingSupplierSystemDiscounts] =
    useState<boolean>(
      configuration
        ? configuration.is_using_supplier_system_discounts
        : MODEL_CONFIGURATION_INITIAL_VALUES.isUsingSupplierSystemDiscounts
    );

  const [baseMarkupError, setBaseMarkupError] = useState<string | null>(null);
  const [isBaseMarkupErrorVisible, setIsBaseMarkupErrorVisible] =
    useState(false);

  const [basePurchaseDiscountError, setBasePurchaseDiscountError] = useState<
    string | null
  >(null);
  const [
    isBasePurchaseDiscountErrorVisible,
    setIsBasePurchaseDiscountErrorVisible,
  ] = useState(false);

  const [surchargeMarkupError, setSurchargeMarkupError] = useState<
    string | null
  >(null);
  const [isSurchargeMarkupErrorVisible, setIsSurchargeMarkupErrorVisible] =
    useState(false);

  const [surchargePurchaseDiscountError, setSurchargePurchaseDiscountError] =
    useState<string | null>(null);
  const [
    isSurchargePurchaseDiscountErrorVisible,
    setIsSurchargePurchaseDiscountErrorVisible,
  ] = useState(false);

  const [fixedPriceError, setFixedPriceError] = useState<string | null>(null);
  const [isFixedPriceErrorVisible, setIsFixedPriceErrorVisible] =
    useState(false);

  const {
    mutate: createConfiguration,
    isLoading: createIsLoading,
    isSuccess: createIsSuccess,
  } = useCreateConfiguration();
  const {
    mutate: updateConfiguration,
    isLoading: updateIsLoading,
    isSuccess: updateIsSuccess,
  } = useUpdateConfiguration(configuration?.id);

  const hasErrors =
    !!baseMarkupError || !!fixedPriceError || !!surchargeMarkupError;

  const handleApply = () => {
    if (hasErrors) {
      setIsBaseMarkupErrorVisible(true);
      setIsBasePurchaseDiscountErrorVisible(true);
      setIsFixedPriceErrorVisible(true);
      setIsSurchargeMarkupErrorVisible(true);
      setIsSurchargePurchaseDiscountErrorVisible(true);
    } else {
      // Form is valid
      if (!configuration) {
        const createConfigurationData: ICreateConfigurationData = {
          model_id: model.id,
          base_markup: baseMarkup,
          base_purchase_discount: basePurchaseDiscount,
          surcharge_markup: surchargeMarkup,
          surcharge_purchase_discount: surchargePurchaseDiscount,
          fixed_amount: fixedPrice,
          exclude_surcharge: excludeSurcharge,
          round_prices: roundPrices,
          is_active: isActive,
          is_using_supplier_system_discounts: isUsingSupplierSystemDiscounts,
        };
        createConfiguration(createConfigurationData);
      } else {
        const updateConfigurationData: IUpdateConfigurationData = {
          base_markup: baseMarkup,
          base_purchase_discount: basePurchaseDiscount,
          surcharge_markup: surchargeMarkup,
          surcharge_purchase_discount: surchargePurchaseDiscount,
          fixed_amount: fixedPrice,
          exclude_surcharge: excludeSurcharge,
          round_prices: roundPrices,
          is_active: isActive,
          is_using_supplier_system_discounts: isUsingSupplierSystemDiscounts,
        };
        updateConfiguration(updateConfigurationData);
      }
    }
  };

  useHideErrorsAfterDelay(
    isBaseMarkupErrorVisible,
    setIsBaseMarkupErrorVisible,
    isBasePurchaseDiscountErrorVisible,
    setIsBasePurchaseDiscountErrorVisible,
    isFixedPriceErrorVisible,
    setIsFixedPriceErrorVisible,
    isSurchargeMarkupErrorVisible,
    setIsSurchargeMarkupErrorVisible,
    isSurchargePurchaseDiscountErrorVisible,
    setIsSurchargePurchaseDiscountErrorVisible
  );

  const hasEmptyValues = () => {
    const isEmpty = (value: any) => {
      return [undefined, null, ''].includes(value);
    };
    return (
      isEmpty(baseMarkup) ||
      isEmpty(basePurchaseDiscount) ||
      isEmpty(surchargeMarkup) ||
      isEmpty(surchargePurchaseDiscount) ||
      isEmpty(fixedPrice)
    );
  };

  return (
    <Container withoutLine={withoutLine} isFirst={index === 0}>
      <RowTop>
        <ModelNameTooltipWrapper>
          <ModelNameLabel>{model.general.name}</ModelNameLabel>
          <TooltipWrapper>
            <Tooltip content={getModelConfigurationTooltipText(model, t)}>
              <Icon svg={Info} color={COLORS.GREEN} />
            </Tooltip>
          </TooltipWrapper>
        </ModelNameTooltipWrapper>
        <RowTopRightPart>
          <ManageQuestionVisibilityLabel
            onClick={() => setManageVisibilityModelId(model.id)}
          >
            {t('Document visibility')}
          </ManageQuestionVisibilityLabel>
          <TestButton
            label={t('Test')}
            secondary
            height="40rem"
            onClick={() => {
              const testConfiguration: ITestCompanyModelConfiguration = {
                model_id: model.id,
                base_markup: baseMarkup,
                base_purchase_discount: basePurchaseDiscount,
                surcharge_markup: surchargeMarkup,
                surcharge_purchase_discount: surchargePurchaseDiscount,
                fixed_amount: fixedPrice,
                exclude_surcharge: excludeSurcharge,
                round_prices: roundPrices,
                is_using_supplier_system_discounts: supplierHasSystemIntegration
                  ? isUsingSupplierSystemDiscounts
                  : false,
                is_active: isActive,
              };
              dispatch(setTestCompanyModelConfiguration(testConfiguration));
              onTestClick(model.id, configuration ? configuration.id : null);
            }}
          />
          <ApplyButton
            disabled={createIsLoading || updateIsLoading || hasEmptyValues()}
            label={t('Apply')}
            primary
            height="40rem"
            onClick={handleApply}
          />
          <ToggleSwitchWrapper>
            <ToggleSwitchLabel isToggled={isActive}>
              {isActive ? t('On') : t('Off')}
            </ToggleSwitchLabel>
            <ToggleSwitch
              isChecked={isActive}
              onClick={() => {
                setIsActive(!isActive);
              }}
              size="large"
            />
          </ToggleSwitchWrapper>
          <CheckboxWrapper>
            <Checkbox
              isChecked={roundPrices}
              label={t('Round prices')}
              onChange={() => {
                setRoundPrices(!roundPrices);
              }}
            />
          </CheckboxWrapper>
        </RowTopRightPart>
        {supplierHasSystemIntegration ? (
          <IsUsingSupplierSystemDiscountsWrapper>
            <ToggleSwitch
              isChecked={isUsingSupplierSystemDiscounts}
              onClick={() => {
                setIsUsingSupplierSystemDiscounts(
                  !isUsingSupplierSystemDiscounts
                );
              }}
              size="large"
            />
            <ToggleSwitchLabel isToggled={isUsingSupplierSystemDiscounts}>
              {t('Supplier discount')}
            </ToggleSwitchLabel>
            <Tooltip
              content={t(
                'Use the discounts as available in the supplier system to calculate the net purchase price'
              )}
            >
              <Icon svg={Info} size={20} color={COLORS.GREEN} />
            </Tooltip>
          </IsUsingSupplierSystemDiscountsWrapper>
        ) : null}
      </RowTop>
      <RowBottom>
        <PriceInputWrapper>
          <Label>{t('Base purchase discount')}:</Label>
          <PriceInput
            value={basePurchaseDiscount}
            setValue={(value: number) => {
              if (
                validatePercentageInput(
                  value,
                  setBasePurchaseDiscountError,
                  setIsBasePurchaseDiscountErrorVisible,
                  'Base purchase discount',
                  t,
                  true
                )
              ) {
                setBasePurchaseDiscount(value);
              }
            }}
            symbol={SymbolTypeEnum.PERCENTAGE}
            symbolPlacement={SymbolPlacementEnum.AFTER}
            hasError={
              !!basePurchaseDiscountError && isBasePurchaseDiscountErrorVisible
            }
            validate={(value: number) =>
              validatePercentageInput(
                value,
                setBasePurchaseDiscountError,
                setIsBasePurchaseDiscountErrorVisible,
                'base purchase discount',
                t,
                true
              )
            }
          />
          <ErrorOverlay
            isErrorVisible={
              basePurchaseDiscountError && isBasePurchaseDiscountErrorVisible
            }
          >
            {basePurchaseDiscountError}
          </ErrorOverlay>
        </PriceInputWrapper>
        <PriceInputWrapper>
          <Label>{t('Base markup')}:</Label>
          <PriceInput
            value={baseMarkup}
            setValue={(value: number) => {
              if (
                validatePercentageInput(
                  value,
                  setBaseMarkupError,
                  setIsBaseMarkupErrorVisible,
                  'Base markup',
                  t
                )
              ) {
                setBaseMarkup(value);
              }
            }}
            symbol={SymbolTypeEnum.PERCENTAGE}
            symbolPlacement={SymbolPlacementEnum.AFTER}
            hasError={!!baseMarkupError && isBaseMarkupErrorVisible}
            validate={(value: number) =>
              validatePercentageInput(
                value,
                setBaseMarkupError,
                setIsBaseMarkupErrorVisible,
                'Base markup',
                t
              )
            }
          />
          <ErrorOverlay
            isErrorVisible={baseMarkupError && isBaseMarkupErrorVisible}
          >
            {baseMarkupError}
          </ErrorOverlay>
        </PriceInputWrapper>
        <FixedAmountWrapper>
          <Label>{t('Fixed amount')}:</Label>
          <PriceInput
            value={fixedPrice}
            setValue={(value: number) => {
              if (
                validateFixedPriceInput(
                  value,
                  setFixedPriceError,
                  setIsFixedPriceErrorVisible,
                  'fixed price',
                  t
                )
              ) {
                setFixedPrice(value);
              }
            }}
            symbol={SymbolTypeEnum.EURO}
            symbolPlacement={SymbolPlacementEnum.BEFORE}
            hasError={!!fixedPriceError && isFixedPriceErrorVisible}
            validate={(value: number) =>
              validateFixedPriceInput(
                value,
                setFixedPriceError,
                setIsFixedPriceErrorVisible,
                'fixed price',
                t
              )
            }
          />
          <ErrorOverlay
            isErrorVisible={fixedPriceError && isFixedPriceErrorVisible}
          >
            {fixedPriceError}
          </ErrorOverlay>
        </FixedAmountWrapper>
        <CheckboxWrapper>
          <Checkbox
            isChecked={excludeSurcharge}
            label={t('Exclude surcharge amount')}
            onChange={() => {
              setExcludeSurcharge(!excludeSurcharge);
            }}
          />
        </CheckboxWrapper>
        <PriceInputWrapper>
          <Label>{t('Surcharge purchase discount')}:</Label>
          <PriceInput
            value={surchargePurchaseDiscount}
            setValue={(value: number) => {
              if (
                validatePercentageInput(
                  value,
                  setSurchargePurchaseDiscountError,
                  setIsSurchargePurchaseDiscountErrorVisible,
                  'Surcharge purchase discount',
                  t,
                  true
                )
              ) {
                setSurchargePurchaseDiscount(value);
              }
            }}
            symbol={SymbolTypeEnum.PERCENTAGE}
            symbolPlacement={SymbolPlacementEnum.AFTER}
            hasError={
              !!surchargePurchaseDiscountError &&
              isSurchargePurchaseDiscountErrorVisible
            }
            validate={(value: number) =>
              validatePercentageInput(
                value,
                setSurchargePurchaseDiscountError,
                setIsSurchargePurchaseDiscountErrorVisible,
                'Surcharge purchase discount',
                t,
                true
              )
            }
          />
          <ErrorOverlay
            isErrorVisible={
              surchargePurchaseDiscountError &&
              isSurchargePurchaseDiscountErrorVisible
            }
          >
            {surchargePurchaseDiscountError}
          </ErrorOverlay>
        </PriceInputWrapper>
        <PriceInputWrapper>
          <Label>{t('Surcharge markup')}:</Label>
          <PriceInput
            value={surchargeMarkup}
            setValue={(value: number) => {
              if (
                validatePercentageInput(
                  value,
                  setSurchargeMarkupError,
                  setIsSurchargeMarkupErrorVisible,
                  'Surcharge markup',
                  t
                )
              ) {
                setSurchargeMarkup(value);
              }
            }}
            symbol={SymbolTypeEnum.PERCENTAGE}
            symbolPlacement={SymbolPlacementEnum.AFTER}
            hasError={!!surchargeMarkupError && isSurchargeMarkupErrorVisible}
            validate={(value: number) =>
              validatePercentageInput(
                value,
                setSurchargeMarkupError,
                setIsSurchargeMarkupErrorVisible,
                'Surcharge markup',
                t
              )
            }
          />
          <ErrorOverlay
            isErrorVisible={
              surchargeMarkupError && isSurchargeMarkupErrorVisible
            }
          >
            {surchargeMarkupError}
          </ErrorOverlay>
        </PriceInputWrapper>
      </RowBottom>
    </Container>
  );
};

export default ModelConfiguration;
