import { AxiosError } from 'axios';
import { queryClient } from 'index';
import moment from 'moment';
import {
  getValuesForMultipleListQuestion,
  getValuesForMultipleTableQuestion,
  getValuesForRadioQuestion,
  getValuesForSingleListQuestion,
  getValuesForSingleTableQuestion,
} from 'pages/ConfigurePage/ConfigureForm/helpers';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { duplicateCartLine } from 'services/ShoppingCartService';
import { LogyxError, getToastErrorMessage } from 'services/api/errors';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { QuestionType } from 'types/Question.types';

// Based on useGetValuesFromQuestionOutputs
// Takes the questions from the cart item model instead of the questions from the configure form store selector
export const useGetCartItemValuesFromQuestionOutputs = (questions: any) => {
  const valuesFromOutputs = {};
  for (const question of questions) {
    if (question.hidden) {
      continue;
    }
    switch (question.type) {
      case QuestionType.BOOLEAN:
        if (question.outputs) {
          // If output value was null, the boolean will be set to false
          valuesFromOutputs[question.initialKey] = !!question.outputs.value;
        } else {
          valuesFromOutputs[question.initialKey] = '';
        }
        break;
      case QuestionType.DATETIME:
        if (question.outputs && question.outputs.value) {
          valuesFromOutputs[question.initialKey] = moment(
            question.outputs.value
          ).toDate();
        } else {
          valuesFromOutputs[question.initialKey] = '';
        }
        break;
      case QuestionType.LIST:
        if (question.outputs) {
          if (question.multiple) {
            valuesFromOutputs[question.initialKey] =
              getValuesForMultipleListQuestion(question);
          } else {
            valuesFromOutputs[question.initialKey] =
              getValuesForSingleListQuestion(question);
          }
        } else {
          valuesFromOutputs[question.initialKey] = '';
        }
        break;
      case QuestionType.NUMBER:
        if (question.outputs && question.outputs?.value !== null) {
          valuesFromOutputs[question.initialKey] =
            question.outputs.value?.toString();
        } else {
          valuesFromOutputs[question.initialKey] = '';
        }
        break;
      case QuestionType.TEXT:
        if (question.outputs && question.outputs.value) {
          valuesFromOutputs[question.initialKey] = question.outputs.value;
        } else {
          valuesFromOutputs[question.initialKey] = '';
        }
        break;
      case QuestionType.TABLE:
        if (question.outputs) {
          if (question.multiple) {
            valuesFromOutputs[question.initialKey] =
              getValuesForMultipleTableQuestion(question);
          } else {
            valuesFromOutputs[question.initialKey] =
              getValuesForSingleTableQuestion(question);
          }
        } else {
          valuesFromOutputs[question.initialKey] = '';
        }
        break;
      case QuestionType.RADIO:
        if (question.outputs) {
          valuesFromOutputs[question.initialKey] =
            getValuesForRadioQuestion(question);
        } else {
          valuesFromOutputs[question.initialKey] = '';
        }
        break;
    }
  }

  return valuesFromOutputs;
};

export const useDuplicateCartLine = () => {
  const { t } = useTranslation();
  return useMutation((cartLineId: number) => duplicateCartLine(cartLineId), {
    onSuccess: () => {
      toast.success(t('Successfully duplicated shopping cart line'));
      queryClient.invalidateQueries([ReactQueryKeys.GET_SHOPPING_CART]);
    },
    onError: (error: LogyxError) => {
      toast.error(getToastErrorMessage(error));
    },
    onSettled: () => {
      // Finally
    },
    mutationKey: ReactMutationKeys.DUPLICATE_CART_LINE,
  });
};
