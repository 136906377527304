import { useGetSuppliersInfinite } from 'pages/ModelsPage/hooks';
import { useInfiniteQuery, useMutation } from 'react-query';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { SUPPLIERS_PER_PAGE } from '../SupplierSelectPage/constants';
import { getCompanies } from 'services/CompanyService';
import { ISupplier } from 'types/Supplier.types';
import { getDataFromPages } from 'utils/getDataFromPages';
import { toast } from 'react-toastify';
import { LogyxError, getToastErrorMessage } from 'services/api/errors';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { selectEntities } from 'services/UserService';

export const useGetCompaniesInfinite = (perPage: number, searchBy: string) =>
  useInfiniteQuery({
    queryKey: [ReactQueryKeys.GET_COMPANIES_INFINITE, perPage, searchBy],
    queryFn: ({ pageParam = 1 }) => getCompanies(pageParam, perPage, searchBy),
    getNextPageParam: (lastPage: any) => {
      if (!lastPage?.pagination) {
        return undefined;
      }
      if (
        lastPage.pagination.per_page * lastPage.pagination.page >=
        lastPage.pagination.total
      ) {
        // Return undefined here so hasNextPage equals to false
        return undefined;
      }
      return lastPage.pagination.page + 1;
    },
    onSuccess: (data) => {
      return data;
    },
  });

export const useHasSupplierOptionsAvailable = () => {
  const { data: suppliersData, isLoading: isLoadingGetSuppliers } =
    useGetSuppliersInfinite(SUPPLIERS_PER_PAGE, '');

  const suppliers: ISupplier[] = getDataFromPages(
    suppliersData?.pages,
    'suppliers'
  );

  return {
    suppliers,
    isLoadingSuppliers: isLoadingGetSuppliers,
  };
};

export interface ISelectEntitiesDTO {
  companyId?: number;
  supplierId?: number;
}

export const useSelectEntities = () =>
  useMutation((dto: ISelectEntitiesDTO) => selectEntities(dto), {
    onError: (error: LogyxError) => {
      toast.error(getToastErrorMessage(error));
    },
    mutationKey: ReactMutationKeys.SELECT_ENTITIES,
  });
