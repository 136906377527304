import {
  Container,
  CategoryNameAndChevronWrapper,
} from '../CategoryGrid.styled';
import { Skeleton } from '@mui/material';

interface ICategoryGridSkeletonProps {
  index: number;
  opacityCoeficient?: number;
  withoutLine: boolean;
}

const CategoryGridSkeleton = ({
  index,
  opacityCoeficient = 0.2,
  withoutLine,
}: ICategoryGridSkeletonProps) => {
  return (
    <Container
      withoutLine={withoutLine}
      opacityCoeficient={opacityCoeficient}
      opacityIndex={index}
      isSkeleton
    >
      <CategoryNameAndChevronWrapper>
        <Skeleton width="200rem" height={'30rem'} animation="wave" />
      </CategoryNameAndChevronWrapper>
    </Container>
  );
};

export default CategoryGridSkeleton;
