import { List, SquaresFour } from '@phosphor-icons/react';
import { Container, Option, StyledIcon } from './ViewTypePicker.styled';
import { COLORS } from 'assets/styled';
import { IPageFiltersData } from 'store/Filters/types';

export enum ViewType {
  GRID = 'GRID',
  LIST = 'LIST',
}

interface IViewTypePicker {
  selectedViewType: ViewType;
  setSelectedViewType: (viewType: ViewType) => {
    type: string;
    payload: IPageFiltersData;
  };
}

const ViewTypePicker = ({
  selectedViewType,
  setSelectedViewType,
}: IViewTypePicker) => {
  return (
    <Container>
      <Option onClick={() => setSelectedViewType(ViewType.GRID)}>
        <StyledIcon
          isSelected={selectedViewType === ViewType.GRID}
          svg={SquaresFour}
          color={
            selectedViewType === ViewType.GRID ? COLORS.GREEN : COLORS.BORDER
          }
          weight={selectedViewType === ViewType.GRID ? 'fill' : 'light'}
        />
      </Option>
      <Option onClick={() => setSelectedViewType(ViewType.LIST)}>
        <StyledIcon
          isSelected={selectedViewType === ViewType.LIST}
          svg={List}
          weight="bold"
          color={
            selectedViewType === ViewType.LIST ? COLORS.GREEN : COLORS.BORDER
          }
        />
      </Option>
    </Container>
  );
};

export default ViewTypePicker;
