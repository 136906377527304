// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import './swiper-styles.css';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  ArrowWrapper,
  Container,
  ContainerRelative,
  ErrorImage,
  LargeFormImageLayerImage,
  NextSlideChevronWrapper,
  PreviousSlideChevronWrapper,
  previewImageIconStyle,
} from './FormImagesSwiper.styled';
import { IImageSection } from 'pages/ConfigurePage/FormImages/hooks';
import NoImagesAvailable from 'assets/images/noImagesAvailable.png';
import ErrorLoadingMediaImage from 'assets/images/errorLoadingMedia.png';
import ErrorLoadingMediaImageDark from 'assets/images/errorLoadingMediaDark.png';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { ArrowCircleLeft, ArrowCircleRight } from '@phosphor-icons/react';
import Icon from 'components/Icon/Icon';
import { COLORS } from 'assets/styled';
import { LargeFormImage } from './LargeFormImage/LargeFormImage';
import { MagnifyingGlass as Search } from '@phosphor-icons/react';

interface IFormImagesSwiperProps {
  images: IImageSection[];
  activeSlide: any;
  setActiveSlide: any;
  singleImage?: boolean;
  setPreviewImage: (image: IImageSection | null) => void;
}

const FormImagesSwiper = ({
  images,
  activeSlide,
  setActiveSlide,
  singleImage,
  setPreviewImage,
}: IFormImagesSwiperProps) => {
  const swiperRef: any = useRef(null);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    activeSlideRef.current = activeSlide;
  }, [activeSlide]);
  const activeSlideRef = useRef<number>(activeSlide);

  const handlePrev = useCallback(() => {
    if (!swiperRef.current) return;

    if (activeSlideRef.current === 0) {
      swiperRef.current.swiper.slideToLoop(images.length - 1);
    } else {
      swiperRef.current.swiper.slidePrev();
    }
  }, [activeSlideRef.current]);
  const handleNext = useCallback(() => {
    if (!swiperRef.current) return;

    if (activeSlideRef.current === images.length - 1) {
      swiperRef.current.swiper.slideToLoop(0);
    } else {
      swiperRef.current.swiper.slideNext();
    }
  }, [activeSlideRef.current]);

  useEffect(() => {
    if (swiperRef && swiperRef.current.swiper.realIndex !== activeSlide) {
      swiperRef.current.swiper.slideToLoop(activeSlide);
    }
  }, [activeSlide]);

  useEffect(() => {
    setError(false);
  }, [images, activeSlide]);

  const { darkMode } = useSelector(
    (state: IRootReducerState) => state.commonInfo
  );

  return (
    <Container>
      <Swiper
        loop={true}
        ref={swiperRef}
        slidesPerView={'auto'}
        centeredSlides={true}
        allowTouchMove={singleImage ? false : true}
        spaceBetween={30}
        onSlideChange={(swiper: any) =>
          !Number.isNaN(swiper.realIndex) && setActiveSlide(swiper.realIndex)
        } // Use realIndex instead of activeIndex when loop == true
      >
        {images.length ? (
          images.map((image: IImageSection, slideIndex: number) => {
            if (error) {
              return (
                <SwiperSlide key={slideIndex}>
                  <ErrorImage
                    src={
                      darkMode
                        ? ErrorLoadingMediaImageDark
                        : ErrorLoadingMediaImage
                    }
                    alt=""
                  />
                </SwiperSlide>
              );
            }
            return (
              <SwiperSlide key={slideIndex}>
                <LargeFormImage image={image} setError={setError} />
              </SwiperSlide>
            );
          })
        ) : (
          // Unreachable
          <SwiperSlide>
            <ContainerRelative>
              <LargeFormImageLayerImage
                src={NoImagesAvailable}
                alt="No images"
                zIndex={1}
              />
            </ContainerRelative>
          </SwiperSlide>
        )}
      </Swiper>
      {images.length && !singleImage ? (
        <PreviousSlideChevronWrapper
          onClick={handlePrev}
          singleImage={singleImage}
        >
          <ArrowWrapper singleImage={singleImage}>
            <Icon svg={ArrowCircleLeft} color={COLORS.GREEN} size={60} />
          </ArrowWrapper>
        </PreviousSlideChevronWrapper>
      ) : null}
      {images.length && !singleImage ? (
        <NextSlideChevronWrapper onClick={handleNext} singleImage={singleImage}>
          <ArrowWrapper alt="NEXT" singleImage={singleImage}>
            <Icon svg={ArrowCircleRight} color={COLORS.GREEN} size={60} />
          </ArrowWrapper>
        </NextSlideChevronWrapper>
      ) : null}
      <Icon
        svg={Search}
        color={COLORS.GREEN}
        onClick={() => setPreviewImage(images[activeSlide])}
        size={30}
        wrapperStyle={previewImageIconStyle}
      />
    </Container>
  );
};

export default FormImagesSwiper;
