import { COLORS } from 'assets/styled';
import styled, { css } from 'styled-components';

interface StyledProps {
  isDisabled: boolean;
}

export const Container = styled.div``;
export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${COLORS.BORDER};
  padding-bottom: 20rem;

  &:not(:first-of-type) {
    padding-top: 20rem;
  }

  &:last-of-type {
    border-bottom: none;
    padding-bottom: 0;
  }
`;

export const FieldNameWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 60rem;
`;

export const FieldName = styled.label`
  font-size: 18rem;
  color: ${COLORS.BLACK};
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 5rem;
`;

export const FieldIconsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20rem;
`;

export const FieldEditWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5rem;
  cursor: pointer;
`;

export const FieldDeleteWrapper = styled(FieldEditWrapper)``;

export const FieldEdit = styled.span`
  display: block;
  font-size: 18rem;
  color: ${COLORS.GREEN};

  ${(props: StyledProps) =>
    props.isDisabled &&
    css`
      cursor: not-allowed;
      opacity: 0.5;
    `}
`;

export const FieldDelete = styled(FieldEdit)`
  color: ${COLORS.RED_100};
`;

export const MatrixName = styled.span`
  display: block;
  font-size: 18rem;
  color: ${COLORS.BLACK};
`;

export const MatrixModalText = styled.span`
  color: ${COLORS.BLACK};
  font-size: 16rem;
  text-align: center;
  line-height: 1.5;
`;

export const MatrixModalTextBold = styled(MatrixModalText)`
  display: block;
  font-weight: bold;
`;
