import { RoutesConfig } from 'navigation/routes';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { AppMode } from 'store/Common/reducers/common';
import { IRootReducerState } from 'store/store';

interface ISharedRouteProps {
  appModes: AppMode[];
  redirectTo?: string;
}

const SharedRoute = ({
  appModes,
  redirectTo = RoutesConfig.Configure.fullPath,
}: ISharedRouteProps) => {
  const appMode: AppMode = useSelector(
    (state: IRootReducerState) => state.commonInfo.appMode
  );

  const { queryParamsProcessed } = useSelector(
    (state: IRootReducerState) => state.commonInfo
  );
  // If the query params are not yet processed, don't make a navigation decision.
  if (!queryParamsProcessed) {
    return null;
  }

  return appModes.includes(appMode) ? <Outlet /> : <Navigate to={redirectTo} />;
};

export default SharedRoute;
