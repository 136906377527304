import styled, { css } from 'styled-components';

interface StyledProps {
  isDragging: boolean;
}

export const Container = styled.div`
  opacity: 1;
  transition: 0.3s ease;

  ${(props: StyledProps) =>
    props.isDragging &&
    css`
      opacity: 0.5;
    `}
`;
