import {
  Container,
  NameLabel,
  RowCenter,
  StyledSkeleton,
} from '../ModelCard.styled';
import { Skeleton } from '@mui/material';

interface IModelCardSkeletonProps {
  index: number;
  opacityCoeficient?: number;
}

const ModelCardSkeleton = ({
  index,
  opacityCoeficient = 0.2,
}: IModelCardSkeletonProps) => {
  return (
    <Container opacityIndex={index} opacityCoeficient={opacityCoeficient}>
      <StyledSkeleton
        width={'100%'}
        height={'200rem'}
        animation="wave"
        variant="rectangular"
      />
      <RowCenter>
        <NameLabel>
          <Skeleton width={'100%'} animation="wave" />
        </NameLabel>
      </RowCenter>
    </Container>
  );
};
export default ModelCardSkeleton;
