import Icon from 'components/Icon/Icon';
import { CSSProperties } from 'react';
import styled from 'styled-components';

interface StyledProps {
  favoriteIconStyle: CSSProperties;
}

export const LoaderWrapper = styled.div`
  ${(props: StyledProps) => props.favoriteIconStyle};
`;

export const StyledIcon = styled(Icon)`
  ${(props: StyledProps) => props.favoriteIconStyle};
`;
