import { useTranslation } from 'react-i18next';
import {
  Container,
  DropArea,
  DropText,
  Fields,
  Note,
  NoteWrapper,
} from './InputFields.styled';
import { useDrop } from 'react-dnd';
import { Dispatch, SetStateAction, useState } from 'react';
import {
  FieldTypes,
  ICustomModel,
  IInputField,
  IListInputField,
  ILongTextInputField,
  INumberInputField,
  IRadioInputField,
  IShortTextInputField,
  IToggleInputField,
} from 'types/Builder.types';
import { IFieldDrag } from 'pages/Builder/InputFieldsOptions/helper';
import { createNewField } from './helpers';
import DraggableField from './DraggableField/DraggableField';
import ShortTextField from './FieldsComponents/ShortTextField';
import LongTextField from './FieldsComponents/LongTextField';
import ListField from './FieldsComponents/ListField';
import NumberField from './FieldsComponents/NumberField';
import ToggleField from './FieldsComponents/ToggleField';
import RadioField from './FieldsComponents/RadioField';
import { DangerModal } from 'components/Modals/DangerModal/DangerModal';

interface IProps {
  isAccordionOpen: boolean;
  model: ICustomModel;
  setModel: Dispatch<SetStateAction<ICustomModel>>;
  setSelectedFieldId: Dispatch<SetStateAction<string>>;
}

const InputFields = ({
  isAccordionOpen,
  model,
  setModel,
  setSelectedFieldId,
}: IProps) => {
  const { t } = useTranslation();
  const [draggedId, setDraggedId] = useState<number | null>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [fieldId, setFieldId] = useState<string>('');

  const handleDeleteField = (id: string) => {
    setModel((prevModel) => ({
      ...prevModel,
      input_fields: prevModel.input_fields.filter((field) => field.id !== id),
    }));
  };

  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'input-option',
    drop: (item: IFieldDrag) => {
      const newField = createNewField(item, model);
      if (newField) {
        setModel((prevModel: ICustomModel) => ({
          ...prevModel,
          input_fields: [...prevModel.input_fields, newField].filter(Boolean),
        }));
        setSelectedFieldId(newField.id);
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }));

  const renderFieldComponent = (
    field: IInputField,
    { dragRef, onDragStart }: { dragRef: any; onDragStart: () => void }
  ) => {
    switch (field.field_type) {
      case FieldTypes.ShortText:
        return (
          <ShortTextField
            field={field as IShortTextInputField}
            model={model}
            onDelete={() => {
              setFieldId(field.id);
              setIsDeleteModalOpen(true);
            }}
            drag={dragRef}
            onDragStart={onDragStart}
          />
        );
      case FieldTypes.LongText:
        return (
          <LongTextField
            field={field as ILongTextInputField}
            model={model}
            onDelete={() => {
              setFieldId(field.id);
              setIsDeleteModalOpen(true);
            }}
            drag={dragRef}
            onDragStart={onDragStart}
          />
        );
      case FieldTypes.List:
        return (
          <ListField
            field={field as IListInputField}
            model={model}
            onDelete={() => {
              setFieldId(field.id);
              setIsDeleteModalOpen(true);
            }}
            drag={dragRef}
            onDragStart={onDragStart}
          />
        );
      case FieldTypes.Number:
        return (
          <NumberField
            field={field as INumberInputField}
            model={model}
            onDelete={() => {
              setFieldId(field.id);
              setIsDeleteModalOpen(true);
            }}
            drag={dragRef}
            onDragStart={onDragStart}
          />
        );
      case FieldTypes.Toggle:
        return (
          <ToggleField
            field={field as IToggleInputField}
            model={model}
            onDelete={() => {
              setFieldId(field.id);
              setIsDeleteModalOpen(true);
            }}
            drag={dragRef}
            onDragStart={onDragStart}
          />
        );
      case FieldTypes.Radio:
        return (
          <RadioField
            field={field as IRadioInputField}
            model={model}
            onDelete={() => {
              setFieldId(field.id);
              setIsDeleteModalOpen(true);
            }}
            drag={dragRef}
            onDragStart={onDragStart}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      {isAccordionOpen && (
        <Container>
          <NoteWrapper>
            <Note>{t('Drag and drop fields from the input fields tab')}.</Note>
          </NoteWrapper>
          <Fields>
            {model.input_fields.map((field, index) => (
              <DraggableField
                key={index}
                index={index}
                setModel={setModel}
                draggedId={draggedId}
                setDraggedId={setDraggedId}
                setSelectedFieldId={setSelectedFieldId}
                fieldId={field.id}
              >
                {(refs) => renderFieldComponent(field, refs)}
              </DraggableField>
            ))}
          </Fields>
          <DropArea ref={drop} isDropAreaActive={isOver}>
            <DropText>{t('Drag and drop fields here')}</DropText>
          </DropArea>

          <DangerModal
            isOpen={isDeleteModalOpen}
            setIsOpen={setIsDeleteModalOpen}
            title=""
            submit={{
              onClick: () => {
                handleDeleteField(fieldId);
                setIsDeleteModalOpen(false);
                setFieldId('');
              },
              text: t('Confirm'),
              disabled: false,
            }}
            cancel={{
              onClick: () => {
                setIsDeleteModalOpen(false);
                setFieldId('');
              },
            }}
            description={t('Are you sure you want to delete input field?')}
          />
        </Container>
      )}
    </>
  );
};

export default InputFields;
