import {
  ButtonContainer,
  Title,
  TitleContainer,
  TopContainer,
  Margin,
  Description,
} from './ConfirmModal.styled';
import Button from 'components/Button/Button';
import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction } from 'react';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';
import { Modal } from '../Modal/Modal';

interface IConfirmProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onConfirm: () => void;
  onCancel?: () => void;
  description?: string;
  title: string;
  disabled?: boolean;
}

export const ConfirmModal = ({
  isOpen,
  setIsOpen,
  onConfirm,
  onCancel,
  description,
  title,
  disabled = false,
}: IConfirmProps) => {
  const { t } = useTranslation();
  const { isSmallPhone } = useBreakpointFlag();

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      modalStyle={{
        position: 'fixed',
        margin: 'auto',
        width: isSmallPhone ? '90%' : '500rem',
        minWidth: isSmallPhone ? '90%' : 'auto',
      }}
    >
      <TopContainer>
        <TitleContainer>
          <Title>{title}</Title>
        </TitleContainer>
        <TitleContainer>
          <Description>{description}</Description>
        </TitleContainer>
      </TopContainer>
      <ButtonContainer>
        <Margin>
          <Button
            disabled={disabled}
            onClick={() => {
              onCancel && onCancel();
            }}
            secondary
            width="200rem"
            label={t('Cancel')}
          />
        </Margin>
        <Button
          disabled={disabled}
          primary
          width="200rem"
          label={t('Confirm')}
          onClick={() => {
            onConfirm && onConfirm();
          }}
        />
      </ButtonContainer>
    </Modal>
  );
};
