import React, { CSSProperties } from 'react';
import { Heart } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';
import SmallLoader from 'components/SmallLoader/SmallLoader';
import { useFavoriteToggle } from './hooks';
import { LoaderWrapper, StyledIcon } from './FavoriteButton.styled';

interface FavoriteButtonProps {
  favoriteIconStyle: CSSProperties;
  modelId: number;
  initialIsFavorite: boolean;
  onToggleSuccess?: () => void;
}

export const FavoriteButton: React.FC<FavoriteButtonProps> = ({
  favoriteIconStyle,
  modelId,
  initialIsFavorite,
  onToggleSuccess,
}) => {
  const { isFavorite, isUpdating, toggleFavorite } = useFavoriteToggle({
    initialIsFavorite,
    modelId,
    onToggleSuccess,
  });

  if (isUpdating) {
    return (
      <LoaderWrapper favoriteIconStyle={favoriteIconStyle}>
        <SmallLoader />
      </LoaderWrapper>
    );
  }

  return (
    <StyledIcon
      favoriteIconStyle={favoriteIconStyle}
      size={26}
      svg={Heart}
      color={COLORS.GREEN}
      weight={isFavorite ? 'fill' : 'light'}
      onClick={(e: React.MouseEvent) => {
        e.stopPropagation();
        toggleFavorite();
      }}
    />
  );
};
