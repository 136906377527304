import { ISupplier } from 'types/Supplier.types';
import {
  CategoriesWrapper,
  Container,
  FirstRow,
  NoContentLabel,
  NoContentLabelWrapper,
  PaginationWrapper,
  SupplierName,
} from './SupplierCategoriesList.styled';
import { useTranslation } from 'react-i18next';
import { ICategory } from 'types/Category.types';
import { useState } from 'react';
import { CATEGORIES_PER_PAGE } from './constants';
import { useGetCategories } from './hooks';
import Input from 'components/Input/Input';
import Pagination from 'components/Pagination/Pagination';
import { useDebounce } from 'utils/hooks/useDebounce';
import CategoryList from './CategoryList/CategoryList';
import CategoryListSkeleton from './CategoryList/CategoryListSkeleton/CategoryListSkeleton';
import { MagnifyingGlass as Search } from '@phosphor-icons/react';

interface ISupplierCategoriesList {
  supplier: ISupplier;
}

const SupplierCategoriesList = ({ supplier }: ISupplierCategoriesList) => {
  const { t } = useTranslation();
  const [page, setPage] = useState<number>(1);
  const [searchBy, setSearchBy] = useState<string>('');
  const debouncedSearchBy = useDebounce(searchBy);

  const { data: categoriesData, isLoading: isLoadingCategories } =
    useGetCategories(supplier.id, page, CATEGORIES_PER_PAGE, debouncedSearchBy);

  const categories: ICategory[] = categoriesData?.categories || [];

  const showPagination =
    categoriesData?.pagination?.total &&
    categoriesData?.pagination?.total >= CATEGORIES_PER_PAGE;

  return (
    <Container>
      <FirstRow>
        <SupplierName>{supplier.name}</SupplierName>
        <Input
          placeholder={t('Search supplier categories')}
          width={'300rem'}
          onChange={(e: any) => setSearchBy(e.target.value)}
          icon={Search}
        />
      </FirstRow>
      {categories.length ? (
        <CategoriesWrapper>
          {categories.map((category: ICategory, index: number) => (
            <CategoryList
              supplierId={supplier.id}
              category={category}
              key={`categorylist-${index}`}
              withoutLine={categories.length - 1 <= index}
            />
          ))}
        </CategoriesWrapper>
      ) : isLoadingCategories ? (
        <CategoriesWrapper isSkeleton>
          {Array.from({ length: 3 }, (_, index) => (
            <CategoryListSkeleton
              key={index}
              index={index}
              withoutLine={2 === index}
            />
          ))}
        </CategoriesWrapper>
      ) : (
        <NoContentLabelWrapper>
          <NoContentLabel>
            {t('No categories found for this supplier')}
          </NoContentLabel>
        </NoContentLabelWrapper>
      )}
      {showPagination ? (
        <PaginationWrapper>
          <Pagination
            page={page}
            perPage={CATEGORIES_PER_PAGE}
            total={categoriesData?.pagination?.total}
            setPage={setPage}
          />
        </PaginationWrapper>
      ) : null}
    </Container>
  );
};

export default SupplierCategoriesList;
