import styled from 'styled-components';

export const SingleValueContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ColorImage = styled.img`
  height: 24rem;
  width: 24rem;
  border-radius: 100%;
`;
