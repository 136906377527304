import { COLORS, marginLg } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: min-content;
  padding: 0 15rem;
  height: 100%;

  ${respondTo.bigPhone`
    width: 100%;
    max-width: 100%;
  `}
`;

export const Logo = styled.img`
  margin-top: 100rem;
  width: 179rem;
  height: 261rem;
  margin-bottom: ${marginLg};

  ${respondTo.smallTablet`
    width: 150rem;
    height: 240rem;
    margin-bottom: 20rem;
    margin-top: 0;
  `}

  ${respondTo.bigPhone`
    width: 120rem;
    height: 200rem;
    margin-bottom: 20rem;
    margin-top: 0;
  `}
`;

export const Label = styled.label`
  font-size: 50rem;
  line-height: 75rem;
  font-weight: 500rem;
  margin-bottom: ${marginLg};
  color: ${COLORS.BLACK};
  text-align: center;

  ${respondTo.smallTablet`
    font-size: 40rem;
    margin-bottom: 20rem;
  `}

  ${respondTo.bigPhone`
    font-size: 30rem;
    line-height: 1.2;
  `}
`;

export const AbsoluteWrapper = styled.div`
  position: absolute;
  right: 0rem;
  top: 100rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${respondTo.smallTablet`
    top: 0;
  `}
`;

export const AbsoluteItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50rem;
`;
