import { useMutation } from 'react-query';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { toast } from 'react-toastify';
import { editUserData } from 'services/UserService';
import { useTranslation } from 'react-i18next';

export interface IEditUserData {
  firstName?: string;
  lastName?: string;
  currentPassword?: string;
  newPassword?: string;
}

export const useEditUserData = () => {
  const { t } = useTranslation();
  return useMutation(
    (params: IEditUserData) =>
      editUserData(
        params.firstName,
        params.lastName,
        params.currentPassword,
        params.newPassword
      ),
    {
      onError: (error: any) => {
        const errorMessage =
          error.code === 'ERR_BAD_REQUEST' ? 'Wrong password.' : error.message;
        toast.error(errorMessage, {
          toastId: ReactMutationKeys.EDIT_USER_DATA,
        });
      },
      onSuccess: () => {
        toast.success(t('User data changed successfully'));
      },
      mutationKey: ReactMutationKeys.EDIT_USER_DATA,
    }
  );
};
