import { queryClient } from 'index';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { uploadCompanyLogo } from 'services/CompanyService';
import { uploadSupplierLogo } from 'services/SupplierService';
import { LogyxError, getToastErrorMessage } from 'services/api/errors';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';

export const useUploadCompanyLogo = (companyId: number) =>
  useMutation(
    (blob: Blob) => {
      const formData = new FormData();
      formData.append('logo', blob);
      return uploadCompanyLogo(companyId, formData);
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries([ReactQueryKeys.GET_COMPANY]);
      },
      onError: (error: LogyxError) => {
        toast.error(getToastErrorMessage(error));
      },
      mutationKey: ReactMutationKeys.UPLOAD_COMPANY_LOGO,
    }
  );

export const useUploadSupplierLogo = (supplierId: number) =>
  useMutation(
    (blob: Blob) => {
      const formData = new FormData();
      formData.append('logo', blob);
      return uploadSupplierLogo(supplierId, formData);
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries([ReactQueryKeys.GET_SUPPLIER]);
      },
      onError: (error: LogyxError) => {
        toast.error(getToastErrorMessage(error));
      },
      mutationKey: ReactMutationKeys.UPLOAD_SUPPLIER_LOGO,
    }
  );
