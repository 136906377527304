import { AnyAction } from '@reduxjs/toolkit';
import { LOGOUT } from '../../constants';
import { ViewType } from 'components/ViewTypePicker/ViewTypePicker';
import { IModelsPageFilters } from '../types';
import { SAVE_PAGE_FILTERS } from '../constants';

export interface IFiltersReducerState {
  modelsPage: IModelsPageFilters;
}

const INIT_STATE: IFiltersReducerState = {
  modelsPage: { selectedViewType: ViewType.GRID },
};

const filtersReducer = (state = INIT_STATE, action: AnyAction) => {
  switch (action.type) {
    case SAVE_PAGE_FILTERS: {
      const { page, data } = action.payload;
      return {
        ...state,
        [page]: data,
      };
    }
    case LOGOUT: {
      return {
        ...INIT_STATE,
      };
    }
    default:
      return state;
  }
};

export default filtersReducer;
