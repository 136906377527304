import { COLORS } from 'assets/styled';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20rem;
`;

export const Headline = styled.span`
  display: block;
  color: ${COLORS.BLACK};
  font-size: 20rem;
  margin-bottom: 20rem;
`;

export const Options = styled.div`
  display: flex;
  gap: 30rem;
  align-items: center;
  margin-bottom: 20rem;
`;

export const Option = styled.div`
  display: flex;
  gap: 5rem;
  align-items: center;
`;

export const OptionLabel = styled.label`
  font-size: 16rem;
  color: ${COLORS.BLACK};
`;
