import { COLORS, marginSm } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import { marginLg, marginMd, marginXs } from 'assets/styled/variables';
import { Form } from 'formik';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const Logo = styled.img`
  margin-top: 100rem;
  width: 179rem;
  height: 261rem;
  margin-bottom: ${marginLg};
`;

export const EyeWrapper = styled.div`
  user-select: none;
  position: absolute;
  top: 50%;
  right: 15rem;
  transform: translateY(-50%);
`;

export const PasswordInputWrapper = styled.div`
  position: relative;
  margin-bottom: ${marginXs};
  width: 580rem;

  ${respondTo.bigPhone`
    width: 100%;
    max-width: 100%;
  `}
`;

export const ButtonContainer = styled.div`
  width: 100%;
  margin-top: ${marginSm};
  margin-bottom: ${marginMd};
`;

export const Label = styled.label`
  font-size: 16rem;
  font-weight: 400;
  line-height: 25rem;
  margin-left: ${marginXs};
  color: ${COLORS.BLACK};
`;

export const FieldWrapper = styled.div`
  width: 580rem;
  margin-bottom: ${marginSm};

  ${respondTo.bigPhone`
    width: 100%;
    max-width: 100%;
  `}
`;

export const StyledForm = styled(Form)`
  ${respondTo.bigPhone`
    width: 100%;
  `}
`;
