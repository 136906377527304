import styled, { css } from 'styled-components';
import { COLORS } from 'assets/styled/colors';
import { borderRadiusSm } from 'assets/styled';
import Icon from 'components/Icon/Icon';

interface StyledProps {
  withoutBorderBottom: boolean;
  flexValue: number;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CustomTable = styled.table`
  thead {
    position: sticky;
    top: 0;
  }
  th {
    background: ${COLORS.GREEN};
    color: ${COLORS.WHITE};
    font-weight: 300;
  }
  th:first-child {
    border-top-left-radius: ${borderRadiusSm};
    border-radius: 20rem 0 0 20rem;
  }
  th:last-child {
    border-top-right-radius: ${borderRadiusSm};
    border-radius: 0 20rem 20rem 0;
  }
  tbody {
    padding: 0 20rem;
  }

  th {
    padding: 10rem 10rem;
    vertical-align: middle;
    text-align: center;
    font-weight: 500;
  }
  td {
    padding: 20rem 0;
    vertical-align: middle;
    text-align: center;
    font-weight: 500;
    border-bottom: 1rem solid ${COLORS.BORDER};
  }

  td {
    background-color: ${COLORS.WHITE};
  }
  td:first-child {
    border: none;
    width: 30rem;
  }
  td:first-child + td {
    // Checkbox td
    width: 14rem;
  }
  td:last-child {
    border: none;
    width: 30rem;
  }

  tr:last-child {
    border-color: transparent;

    td {
      border: none;
    }
    td:first-child {
      border-bottom-left-radius: ${borderRadiusSm};
    }
    td:last-child {
      border-bottom-right-radius: ${borderRadiusSm};
    }
  }
`;

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: ${borderRadiusSm};
  max-height: 700rem;
  overflow: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  &::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }
`;

export const TD = styled.td`
  border-bottom: ${(props: StyledProps) =>
    props.withoutBorderBottom ? 'none' : '1rem solid ${COLORS.BORDER}'};
  ${(props: StyledProps) =>
    props.flexValue &&
    css`
      flex: ${props.flexValue};
    `};
`;

export const TdLabel = styled.label`
  color: ${COLORS.BLACK};
  font-weight: 300;
`;

export const TH = styled.th`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${(props: StyledProps) =>
    props.flexValue &&
    css`
      flex: ${props.flexValue};
    `};
`;

export const ThLabel = styled.label`
  vertical-align: middle;
  text-align: center;
  font-weight: 500;
  cursor: pointer;
  user-select: none;
  position: relative;
  word-break: normal;
`;

export const ThWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TR = styled.tr`
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
`;

export const StyledIcon = styled(Icon)`
  &:hover {
    transform: scale(1.1);
    transition: transform ease-in-out 0.1s;
  }
`;

export const THead = styled.thead`
  z-index: 1;
`;
