import { createGlobalStyle } from 'styled-components';
import { COLORS } from './colors';
import { borderRadiusSm } from './variables';
import 'react-datepicker/dist/react-datepicker.css';
import { selectStyles } from 'components/Select/Select.styled';

const GlobalStyle = createGlobalStyle`
    :root {
    --color-white: #ffffff;
    --color-black: #000000;
    --color-backdrop: rgba(31, 41, 55, 0.6);
    --color-green: #3FA34D;
    --color-green-disabled: #A1DAA9;
    --color-red: #D80027;
    --color-red-100: #EF233C;
    --color-grey-100: #D7D7D7;
    --color-grey-200: #A3A3A3;
    --color-grey-300: #EEEEEE;
    --color-light-green: #f6fcf7;
    --color-teal: #00D9C6;
    --color-salmon: #F9715D;
    --color-purple: #9C4F8E;
    --color-yellow: #FFC716;
    --color-blue: #6084A4;
    --color-strict-white: #ffffff;
    --color-strict-black: #000000;
    --color-background: #f6fcf7;
    --color-border: #D7D7D7;
    --color-form-element-hover: #EEEEEE;
    --color-ripple-effect-opacity: rgba(0, 0, 0, 0.08);
    --color-ripple-child-effect-opacity: rgba(0, 0, 0, 0.2);
  }

  [data-theme='dark'] {
    --color-white: #1B1927;
    --color-black: #ffffff;
    --color-backdrop: rgba(55, 65, 81, 0.6);
    --color-green: #3FA34D;
    --color-green-disabled: #7AAA81;
    --color-red: #D80027;
    --color-red-100: #EF233C;
    --color-grey-100: #1f2937;
    --color-grey-200: #374151;
    --color-grey-300: #4b5563;
    --color-light-green: #f6fcf7;
    --color-teal: #00D9C6;
    --color-salmon: #F9715D;
    --color-purple: #9C4F8E;
    --color-yellow: #FFC716;
    --color-blue: #6084A4;
    --color-strict-white: #ffffff;
    --color-strict-black: #000000;
    --color-background: #222737;
    --color-border: #4b5563;
    --color-form-element-hover: #222737;
    --color-ripple-effect-opacity: rgba(255, 255, 255, 0.08);
    --color-ripple-child-effect-opacity: rgba(255, 255, 255, 0.2);
  }

  html, body {
    background-color: ${COLORS.BACKGROUND};
    height: 100%;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'OpenSans';
    * {
      box-sizing: inherit;
      margin: 0;
    }
    font-size: 1px;
  }

  table {
    border-collapse: collapse;
  }

  body {
    font-size: 16px;

    /* @media screen and (min-width: 1300px) and (max-width: 1900px)  {
      font-size: 13px;
    }

    @media screen and (min-width: 1900px)  {
      font-size: 16px;
    } */
  }

  label {
    user-select: none;
    font-weight: 600;
  }

  a {
    text-decoration: none;
  }

  a,
  label {
    user-select: none;
  }

  input:disabled {
    cursor: auto;

    ~ label {
      cursor: auto;
    }
  }
    /* Chrome, Safari, Edge, Opera */ // Hide arrows on number input
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */ // Hide arrows on number input
    input[type=number] {
      -moz-appearance: textfield;
    }

  button {
    cursor: pointer;
  }

  #root {
    height: 100%;

  }

  // React date picker
  .react-datepicker {
    font-size: 1em;
    border-radius: ${borderRadiusSm};
  }
  .react-datepicker__header {
    padding-top: 0.8em;
    border-bottom: none;
    border-top-right-radius: ${borderRadiusSm} !important;
    border-top-left-radius: ${borderRadiusSm} !important;
    background-color: ${COLORS.WHITE}    
  }
  .react-datepicker__month {
    margin: 0.4em 1em;
  }
  .react-datepicker__day-name, .react-datepicker__day {
    width: 1.9em;
    line-height: 1.9em;
    margin: 0.166em;
  }
  .react-datepicker__current-month {
    font-size: 1em;
  }
  .react-datepicker__navigation {
    top: 1em;
    line-height: 1.7em;
  }
  .react-datepicker__navigation--previous {
    border-right-color: #ccc;
    left: 1em;
  }
  .react-datepicker__navigation--next {
    border-left-color: #ccc;
    right: 1em;
  }
  .react-datepicker__triangle{
    padding-right: 130rem;
    :after{
      border-bottom-color: white !important;
    }
  }
  .react-datepicker__day-name {
    color: ${COLORS.GRAY_200};
  }
  .react-datepicker__day:hover {
    border-radius: 5rem;
  }
  .react-datepicker__day--selected {
    border-radius: 5rem;
    background-color: ${COLORS.GREEN};
    border: 1rem solid white;
    :hover {
      border-radius: 5rem;
      border: 1rem solid ${COLORS.GREEN};
      background-color: ${COLORS.GREEN};
    }
  }
  .react-datepicker__day--keyboard-selected {
    background-color: white;
    border-radius: 5rem;
    :hover {
      background-color: rgb(238, 238, 238)
    }
  }

    // Icon
  .icon-ripple-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
  }

  .icon-pointer-events-none {
    pointer-events: none;
  }

  /* Select global styles */
  ${selectStyles}
`;

export default GlobalStyle;
