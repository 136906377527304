import { COLORS } from 'assets/styled';
import styled, { css } from 'styled-components';

interface StyledProps {
  isActive: boolean;
}

export const StyledTabs = styled.div`
  display: flex;
  gap: 10rem;
  align-items: center;
  padding-left: 10rem;
`;

export const Tab = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${COLORS.BLACK};
  border-top-left-radius: 20rem;
  border-top-right-radius: 20rem;
  border: 1px solid ${COLORS.BORDER};
  border-bottom: none;
  padding: 2rem 20rem;
  font-size: 16rem;
  cursor: pointer;
  transition: 0.3s ease;

  ${(props: StyledProps) =>
    props.isActive &&
    css`
      background-color: ${COLORS.GREEN};
      color: ${COLORS.WHITE};
    `}
`;
