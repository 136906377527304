import { IDealerPanelModel } from 'types/Model.types';
import { capitalizeFirstLetter } from 'utils/stringUtils';

export const validatePercentageInput = (
  value: number,
  setError: React.Dispatch<React.SetStateAction<string | null>>,
  setErrorVisible: React.Dispatch<React.SetStateAction<boolean>>,
  type: string,
  t: (str: string) => string,
  isBetweenZeroAndHundred = false // used for base and surcharge purchase discount variables
) => {
  if (isNaN(value)) {
    setError(
      t('Invalid {{TYPE}} value. It must be a number').replace(
        '{{TYPE}}',
        t(type)
      )
    );
    setErrorVisible(true);
    return false;
  }
  if (isBetweenZeroAndHundred) {
    if (value < 0 || value > 100) {
      setError(
        capitalizeFirstLetter(
          t('{{TYPE}} value must be between 0 and 100').replace(
            '{{TYPE}}',
            t(type)
          )
        )
      );
      setErrorVisible(true);
      return false;
    }
  } else {
    if (value < -100 || value > 1000) {
      setError(
        capitalizeFirstLetter(
          t('{{TYPE}} value must be between -100 and 1000').replace(
            '{{TYPE}}',
            t(type)
          )
        )
      );
      setErrorVisible(true);
      return false;
    }
  }

  const stringValue = value.toString();
  const [integerPart, decimalPart] = stringValue.split('.');

  if (decimalPart && decimalPart.length > 2) {
    setError(
      capitalizeFirstLetter(
        t('{{TYPE}} value can have a maximum of 2 decimal places').replace(
          '{{TYPE}}',
          t(type)
        )
      )
    );
    setErrorVisible(true);
    return false;
  }
  setError(null);
  setErrorVisible(false);
  return true;
};

export const validateFixedPriceInput = (
  value: number,
  setError: React.Dispatch<React.SetStateAction<string | null>>,
  setErrorVisible: React.Dispatch<React.SetStateAction<boolean>>,
  type: string,
  t: (str: string) => string
) => {
  if (isNaN(value)) {
    setError(
      t('Invalid {{TYPE}} value. It must be a number').replace(
        '{{TYPE}}',
        t(type)
      )
    );
    setErrorVisible(true);
    return false;
  }
  if (value < -10000 || value > 10000) {
    setError(
      capitalizeFirstLetter(
        t('{{TYPE}} value must be between -10000 and 10000').replace(
          '{{TYPE}}',
          t(type)
        )
      )
    );
    setErrorVisible(true);
    return false;
  }
  const stringValue = value.toString();
  const [integerPart, decimalPart] = stringValue.split('.');

  if (decimalPart && decimalPart.length > 2) {
    setError(
      capitalizeFirstLetter(
        t('{{TYPE}} value can have a maximum of 2 decimal places').replace(
          '{{TYPE}}',
          t(type)
        )
      )
    );
    setErrorVisible(true);
    return false;
  }
  setError(null);
  setErrorVisible(false);
  return true;
};

export const getModelConfigurationTooltipText = (
  model: IDealerPanelModel,
  t: (str: string) => string
) => {
  const pricelist = model.general.pricelist;
  const pricelistVersion = model.general.pricelist_version;

  const pricelistTooltipText = pricelist
    ? `, ${t('Pricelist')}: ${pricelist},`
    : '';

  const pricelistVersionTooltipText = pricelistVersion
    ? `${t('Pricelist Version')}: ${pricelistVersion}`
    : '';

  const modelVersionText = model.version_number
    ? ` ${t('Version ID')}: ${model.version_number}`
    : ' ';

  return `${t('ID')}: ${model.id},
              ${modelVersionText}${pricelistTooltipText} ${pricelistVersionTooltipText}`;
};
