import { Grid, HorizontalLine } from './UserCard.styled';
import { Skeleton } from '@mui/material';

interface IUserCardSkeleton {
  isLast?: boolean;
}

export const UserCardSkeleton = ({ isLast }: IUserCardSkeleton) => {
  return (
    <>
      <Grid>
        <Skeleton width={'100rem'} animation="wave" />
        <Skeleton width={'100rem'} animation="wave" />
        <Skeleton width={'100rem'} animation="wave" />
        <Skeleton width={'100rem'} animation="wave" />
        <Skeleton width={'100rem'} animation="wave" />
        <Skeleton width={'100rem'} animation="wave" />
        <Skeleton width={'100rem'} animation="wave" />
        <Skeleton width={'100rem'} animation="wave" />
        <Skeleton width={'100rem'} animation="wave" />
        <Skeleton width={'100rem'} animation="wave" />
      </Grid>
      {isLast ? null : <HorizontalLine />}
    </>
  );
};
