import styled, { css } from 'styled-components';
import { COLORS } from 'assets/styled/colors';
import { borderRadiusSm, paddingMd } from 'assets/styled';

interface StyledProps {
  optionCount: number;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CustomTable = styled.table`
  thead {
    position: sticky;
    top: 0;
  }
  th {
    background: ${COLORS.GREEN};
    color: ${COLORS.WHITE};
    font-weight: 300;
  }
  th:first-child {
    border-top-left-radius: ${borderRadiusSm};
    border-radius: 20rem 0 0 20rem;
  }
  th:last-child {
    border-top-right-radius: ${borderRadiusSm};
    border-radius: 0 20rem 20rem 0;
  }
  tbody {
    padding: 0 20rem;
  }

  th {
    padding: 10rem 10rem;
    vertical-align: middle;
    text-align: center;
    font-weight: 500;
  }
  td {
    padding: 20rem 0;
    vertical-align: middle;
    text-align: center;
    font-weight: 500;
  }

  td {
    background-color: ${COLORS.WHITE};
    border-bottom: 1rem solid ${COLORS.BORDER};
  }
  td:first-child {
    border: none;
    width: 30rem;
  }
  td:first-child + td {
    // Checkbox td
    width: 14rem;
  }
  td:last-child {
    border: none;
    width: 30rem;
  }

  tr:last-child {
    border-color: transparent;

    td {
      border: none;
    }
    td:first-child {
      border-bottom-left-radius: ${borderRadiusSm};
    }
    td:last-child {
      border-bottom-right-radius: ${borderRadiusSm};
    }
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 430rem;
  align-items: center;
  justify-content: space-between;
  align-self: center;
  padding-top: ${paddingMd};
`;

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${({ optionCount }: StyledProps) =>
    optionCount !== 0 &&
    css`
      border-left: 1rem solid ${COLORS.BORDER};
      border-right: 1rem solid ${COLORS.BORDER};
      border-bottom: 1rem solid ${COLORS.BORDER};
    `}

  border-radius: ${borderRadiusSm};
  max-height: 700rem;
  overflow: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  &::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }
`;

interface StyledCheckboxProps {
  isSelected: boolean;
}

export const Checkbox = styled.div`
  cursor: pointer;
  width: 24rem;
  height: 24rem;
  border-radius: 2rem;
  align-self: center;
  border: 1px solid ${COLORS.BORDER};
  background-color: ${COLORS.WHITE};
  ${(props: StyledCheckboxProps) =>
    props.isSelected &&
    css`
      border: 1px solid ${COLORS.GREEN};
      background-color: ${COLORS.GREEN} !important;
    `};
`;

export const HCell = styled.div`
  display: inline-flex;
  gap: 10rem;
  align-items: center;
`;

export const TD = styled.td`
  color: ${COLORS.BLACK};
`;

export const TdLabel = styled.label`
  color: ${COLORS.BLACK};
  font-weight: 300;
`;
