import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';
import {
  FormWrapper,
  HorizontalLine,
  RowContainer,
} from '../SettingsRow.styled';
import { Skeleton } from '@mui/material';

export enum SettingsRowType {
  NAME = 'Name',
  EMAIL = 'Email',
  PHONE_NUMBER = 'Phone number',
  WEBSITE = 'Website',
  LOGO = 'Logo',
}

interface ISettingsRowProps {
  withoutLine?: boolean;
}

export const SettingsRowSkeleton = ({
  withoutLine = false,
}: ISettingsRowProps) => {
  const { isSmallPhone } = useBreakpointFlag();
  return (
    <>
      <RowContainer>
        <Skeleton
          width={'100rem'}
          animation="wave"
          style={{ marginRight: '30rem' }}
        />
        <FormWrapper>
          <Skeleton width={'150rem'} animation="wave" />
          {isSmallPhone ? null : <Skeleton width={'75rem'} animation="wave" />}
        </FormWrapper>
      </RowContainer>
      {withoutLine ? null : <HorizontalLine />}
    </>
  );
};
