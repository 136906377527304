import { useTranslation } from 'react-i18next';
import {
  AddLabel,
  AddWrapper,
  Container,
  FirstRow,
  InputWrapper,
  LeftSide,
  RightSide,
  Title,
} from './SupplierCompaniesPage.styled';
import Input from 'components/Input/Input';
import { useEffect, useState } from 'react';
import ViewTypePicker, {
  ViewType,
} from 'components/ViewTypePicker/ViewTypePicker';
import { useSelectedViewType } from 'pages/ModelsPage/hooks';
import { useDebounce } from 'utils/hooks/useDebounce';
import SupplierCompaniesGrid from './SupplierCompaniesGrid/SupplierCompaniesGrid';
import SupplierCompaniesList from './SupplierCompaniesList/SupplierCompaniesList';
import { Plus, MagnifyingGlass as Search } from '@phosphor-icons/react';
import { usePageTitle } from 'utils/hooks/usePageTitle';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';
import Icon from 'components/Icon/Icon';
import { AddSupplierCompanyModal } from 'components/Modals/AddSupplierCompanyModal/AddSupplierCompanyModal';
import { useDeleteSupplierCompany } from './hooks';
import { DangerModal } from 'components/Modals/DangerModal/DangerModal';
import { COLORS } from 'assets/styled';

const SupplierCompaniesPage = () => {
  const { t } = useTranslation();
  const [searchBy, setSearchBy] = useState<string>('');
  const debouncedSearchBy = useDebounce(searchBy);
  const { selectedViewType, setSelectedViewType } = useSelectedViewType();
  const [isAddCompanyModalOpen, setIsAddCompanyModalOpen] =
    useState<boolean>(false);
  const [
    isDeleteSupplierCompanyModalOpen,
    setIsDeleteSupplierCompanyModalOpen,
  ] = useState<boolean>(false);
  const [supplierCompanyIdForDeletion, setSupplierCompanyIdForDeletion] =
    useState<number | null>(null);

  usePageTitle('Companies');

  const { isMidTablet } = useBreakpointFlag();

  useEffect(() => {
    if (isMidTablet && selectedViewType === ViewType.LIST) {
      setSelectedViewType(ViewType.GRID);
    }
  }, [isMidTablet]);
  const {
    mutate: deleteSupplierCompany,
    isLoading: isLoadingDeleteSupplierCompany,
  } = useDeleteSupplierCompany();

  return (
    <Container>
      <FirstRow>
        <LeftSide>
          <Title>{t('Companies')}</Title>
        </LeftSide>
        <RightSide>
          <InputWrapper>
            <Input
              placeholder={t('Search companies')}
              width={'100%'}
              onChange={(e: any) => setSearchBy(e.target.value)}
              icon={Search}
              wrapperStyles={{ width: '100%' }}
            />
          </InputWrapper>
          {!isMidTablet && (
            <ViewTypePicker
              selectedViewType={selectedViewType}
              setSelectedViewType={setSelectedViewType}
            />
          )}
          <AddWrapper onClick={() => setIsAddCompanyModalOpen(true)}>
            <Icon svg={Plus} color={COLORS.GREEN} size={20} />
            <AddLabel>{t('Add Company')}</AddLabel>
          </AddWrapper>
        </RightSide>
      </FirstRow>
      {selectedViewType === ViewType.GRID ? (
        <SupplierCompaniesGrid
          debouncedSearchBy={debouncedSearchBy}
          onDelete={(supplierCompanyId: number) => {
            setSupplierCompanyIdForDeletion(supplierCompanyId);
            setIsDeleteSupplierCompanyModalOpen(true);
          }}
        />
      ) : (
        <SupplierCompaniesList
          debouncedSearchBy={debouncedSearchBy}
          onDelete={(supplierCompanyId: number) => {
            setSupplierCompanyIdForDeletion(supplierCompanyId);
            setIsDeleteSupplierCompanyModalOpen(true);
          }}
        />
      )}
      <AddSupplierCompanyModal
        isOpen={isAddCompanyModalOpen}
        setIsOpen={setIsAddCompanyModalOpen}
      />
      <DangerModal
        isOpen={isDeleteSupplierCompanyModalOpen}
        setIsOpen={setIsDeleteSupplierCompanyModalOpen}
        title={t('Confirm removal')}
        submit={{
          onClick: () => {
            deleteSupplierCompany(supplierCompanyIdForDeletion!);
            setIsDeleteSupplierCompanyModalOpen(false);
            setSupplierCompanyIdForDeletion(null);
          },
          text: t('Remove'),
          disabled: isLoadingDeleteSupplierCompany,
        }}
        cancel={{
          onClick: () => {
            setIsDeleteSupplierCompanyModalOpen(false);
            setSupplierCompanyIdForDeletion(null);
          },
        }}
        description={t(
          'Are you sure you want to revoke model access for this Company?'
        )}
      />
    </Container>
  );
};

export default SupplierCompaniesPage;
