import Loader from 'components/Loader/Loader';
import {
  EntityList,
  EntityRow,
  HorizontalLine,
  InputWrapper,
  NoContentLabel,
} from './ScrollableList.styled';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { MagnifyingGlass as Search } from '@phosphor-icons/react';
import Input from 'components/Input/Input';
import { useLoadNextPage } from 'utils/hooks/useLoadNextPage';
import { FetchNextPageOptions, InfiniteQueryObserverResult } from 'react-query';

interface IScrollableListProps {
  items: any[];
  isLoading: boolean;
  isFetching?: boolean;
  noContentLabel?: string;
  searchLabel?: string;
  onClick: (item: any) => void;
  onSearch?: (str: string) => void;
  hasNextPage?: boolean;
  fetchNextPage?: (
    options?: FetchNextPageOptions | undefined
  ) => Promise<InfiniteQueryObserverResult<any, unknown>>;
}

const ScrollableList = ({
  items,
  isLoading, // Replaces items with loader
  isFetching, // Displays absolute loader
  noContentLabel,
  searchLabel,
  onClick,
  onSearch,
  hasNextPage,
  fetchNextPage,
}: IScrollableListProps) => {
  const loadMoreRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  useLoadNextPage(loadMoreRef, hasNextPage, fetchNextPage, isLoading);

  return (
    <>
      {onSearch ? (
        <InputWrapper>
          <Input
            placeholder={searchLabel || t('Search')}
            width={'100%'}
            onChange={(e: any) => onSearch(e.target.value)}
            icon={Search}
            wrapperStyles={{ width: '100%' }}
          />
        </InputWrapper>
      ) : null}
      {isFetching && !isLoading ? <Loader position="absolute" /> : null}
      {isLoading ? (
        <Loader />
      ) : items.length ? (
        <EntityList>
          {items?.map((item: any, index: number) => (
            <div key={index} onClick={() => onClick(item)}>
              <EntityRow>{item?.name}</EntityRow>
              {items?.length - 1 > index ? <HorizontalLine /> : null}
            </div>
          ))}
          <div
            ref={loadMoreRef}
            style={{
              height: '1px',
            }}
          />
        </EntityList>
      ) : (
        <NoContentLabel>{noContentLabel}</NoContentLabel>
      )}
    </>
  );
};

export default ScrollableList;
