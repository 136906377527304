import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Container, Title } from './AddCompanyToModelModal.styled';
import { Modal } from '../Modal/Modal';
import { useTranslation } from 'react-i18next';
import ScrollableList from 'components/ScrollableList/ScrollableList';
import { ICompany } from 'types/Company.types';
import { useDebounce } from 'utils/hooks/useDebounce';
import {
  ICreateSupplierCompanyModelDTO,
  useCreateSupplierCompanyModel,
  useGetEligibleCompaniesForCreatingSupplierCompanyModel,
} from './hooks';
import { IRootReducerState } from 'store/store';
import { useSelector } from 'react-redux';
import { ELIGIBLE_COMPANIES_FOR_CREATING_SUPPLIER_COMPANY_MODEL_PER_PAGE } from './constants';
import { getDataFromPages } from 'utils/getDataFromPages';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';

export interface IAddCompanyToModelModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  modelId: number;
}

export function AddCompanyToModelModal({
  isOpen,
  setIsOpen,
  modelId,
}: IAddCompanyToModelModalProps) {
  const { t } = useTranslation();
  const [searchBy, setSearchBy] = useState<string>('');
  const debouncedSearchByCompany = useDebounce(searchBy);
  const { supplier } = useSelector(
    (state: IRootReducerState) => state.userInfo
  );

  const { data: companiesData, isLoading: isLoadingGetCompanies } =
    useGetEligibleCompaniesForCreatingSupplierCompanyModel(
      ELIGIBLE_COMPANIES_FOR_CREATING_SUPPLIER_COMPANY_MODEL_PER_PAGE,
      modelId,
      supplier.id,
      debouncedSearchByCompany,
      isOpen
    );

  const companies: ICompany[] = getDataFromPages(
    companiesData?.pages,
    'companies'
  );

  const {
    mutate: createSupplierCompanyModel,
    isLoading: isLoadingCreateSupplierCompanyModel,
  } = useCreateSupplierCompanyModel();

  const handleSelect = (company: ICompany) => {
    const dto: ICreateSupplierCompanyModelDTO = {
      companyId: company.id,
      supplierId: supplier.id,
      modelId: modelId,
    };
    createSupplierCompanyModel(dto, { onSuccess: () => setIsOpen(false) });
  };

  useEffect(() => {
    if (!isOpen) {
      setSearchBy('');
    }
  }, [isOpen]);

  const { isSmallPhone } = useBreakpointFlag();

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      modalStyle={{
        margin: 'auto',
        width: isSmallPhone ? '90%' : '600rem',
        minWidth: isSmallPhone ? '90%' : 'auto',
        position: 'fixed',
      }}
    >
      <Container>
        <Title>{t('Select company for granting access')}</Title>
        <ScrollableList
          items={companies || []}
          isLoading={
            isLoadingGetCompanies || isLoadingCreateSupplierCompanyModel
          }
          noContentLabel={t('No companies found')}
          onClick={(company: ICompany) => handleSelect(company)}
          onSearch={(str: string) => setSearchBy(str)}
        />
      </Container>
    </Modal>
  );
}
