import { SingleValueProps } from 'react-select';
import { components } from 'react-select';
import { SingleValueContainer } from './DefaultSingleValue.styled';

export const DefaultSingleValue = (props: SingleValueProps<any>) => {
  return (
    <components.SingleValue {...props}>
      <SingleValueContainer>
        {/* Label is calculated in getSelectOptionsFromQuestion */}
        {props.data.label}
      </SingleValueContainer>
    </components.SingleValue>
  );
};
