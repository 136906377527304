import { queryClient } from 'index';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { updateCompanySettings } from 'services/CompanyService';
import { updateSupplierSettings } from 'services/SupplierService';
import { LogyxError, getToastErrorMessage } from 'services/api/errors';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';

export interface IUpdateCompanySettingsDTO {
  name?: string;
  email?: string;
  phone_number?: string;
  website?: string;
}

export const useUpdateCompanySettings = () => {
  const { t } = useTranslation();
  return useMutation(
    (params: { companyId: number; dto: IUpdateCompanySettingsDTO }) =>
      updateCompanySettings(params.companyId, params.dto),
    {
      onSuccess: () => {
        toast.success(t('Company settings updated'));
        queryClient.invalidateQueries([ReactQueryKeys.GET_COMPANY]);
      },
      onError: (error: LogyxError) => {
        toast.error(getToastErrorMessage(error));
      },
      mutationKey: ReactMutationKeys.UPDATE_COMPANY_SETTINGS,
    }
  );
};

export interface IUpdateSupplierSettingsDTO {
  name?: string;
  email?: string;
  phone_number?: string;
  website?: string;
}

export const useUpdateSupplierSettings = () => {
  const { t } = useTranslation();
  return useMutation(
    (params: { supplierId: number; dto: IUpdateSupplierSettingsDTO }) =>
      updateSupplierSettings(params.supplierId, params.dto),
    {
      onSuccess: () => {
        toast.success(t('Supplier settings updated'));
        queryClient.invalidateQueries([ReactQueryKeys.GET_SUPPLIER]);
      },
      onError: (error: LogyxError) => {
        toast.error(getToastErrorMessage(error));
      },
      mutationKey: ReactMutationKeys.UPDATE_SUPPLIER_SETTINGS,
    }
  );
};
