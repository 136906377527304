import { Dispatch, SetStateAction } from 'react';
import {
  Container,
  Title,
  SubTitle,
  ButtonWrapper,
} from './CartItemEditFinishedModal.styled';
import { Modal } from '../Modal/Modal';
import Button from 'components/Button/Button';
import { useNavigateWithQuery } from 'utils/hooks/useNavigateWithQuery';
import { useTranslation } from 'react-i18next';
import { RoutesConfig } from 'navigation/routes';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';

export interface ICartItemEditFinishedModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export function CartItemEditFinishedModal({
  isOpen,
  setIsOpen,
}: ICartItemEditFinishedModalProps) {
  const { t } = useTranslation();
  const navigateWithQuery = useNavigateWithQuery();

  const { isPhone } = useBreakpointFlag();

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      modalStyle={{
        margin: 'auto',
        width: isPhone ? '90%' : '600rem',
        minWidth: isPhone ? '90%' : 'auto',
        position: 'fixed',
      }}
    >
      <Container>
        <Title>{t('The cart item was successfully edited')}</Title>
        <SubTitle>
          {t(
            'Do you want to configure another product or continue to the cart'
          ) + '?'}
        </SubTitle>
        <ButtonWrapper>
          <Button
            secondary
            label={t('Configure new product')}
            width="230rem"
            onClick={() => {
              navigateWithQuery(RoutesConfig.Models.fullPath);
              setIsOpen(false);
            }}
          />
          <Button
            primary
            label={t('Proceed to cart')}
            width="230rem"
            onClick={() => {
              navigateWithQuery(RoutesConfig.ShoppingCart.fullPath);
              setIsOpen(false);
            }}
          />
        </ButtonWrapper>
      </Container>
    </Modal>
  );
}
