import {
  SET_CURRENTLY_EDITING_SHOPPING_CART_LINE_ID,
  TRIGGER_ADD_TO_SHOPPING_CART,
  TRIGGER_EDIT_CART_ITEM,
} from 'store/constants';

export const triggerAddToShoppingCart = (trigger: boolean) => ({
  type: TRIGGER_ADD_TO_SHOPPING_CART,
  payload: trigger,
});

export const setCurrentlyEditingShoppingCartLineId = (id: number | null) => ({
  type: SET_CURRENTLY_EDITING_SHOPPING_CART_LINE_ID,
  payload: id,
});

export const triggerEditCartItem = (trigger: boolean) => ({
  type: TRIGGER_EDIT_CART_ITEM,
  payload: trigger,
});
