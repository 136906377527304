import { IModel } from 'types/Model.types';
import {
  Container,
  Label,
  LeftPart,
  ModelNameLabel,
  RightPart,
  StyledIcon,
} from './ModelRow.styled';
import Button from 'components/Button/Button';
import { useTranslation } from 'react-i18next';
import Icon from 'components/Icon/Icon';
import Tooltip from 'components/Tooltip/Tooltip';
import { Archive, Info } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';

interface IModelRowProps {
  model: IModel;
  withoutLine: boolean;
  onArchiveClick: (id: number, name: string) => void;
  onViewClick: (id: number) => void;
  onRestoreClick: (id: number, name: string) => void;
}

const ModelRow = ({
  model,
  withoutLine,
  onArchiveClick,
  onViewClick,
  onRestoreClick,
}: IModelRowProps) => {
  const { t } = useTranslation();

  return (
    <Container withoutLine={withoutLine}>
      <LeftPart>
        <ModelNameLabel>{model.general.name}</ModelNameLabel>
        <Tooltip
          content={
            model.general.description ||
            model.general.descriptions.join(', ') ||
            t('No description')
          }
        >
          <Icon svg={Info} size={20} color={COLORS.GREEN} />
        </Tooltip>
      </LeftPart>
      <RightPart>
        <Label>
          {t('ID')}: {model.id}
        </Label>
        <Label>
          {t('Version')}: {model.version_number}
        </Label>
        {model.is_deleted ? (
          <Button
            label={t('Restore')}
            primary
            height="25rem"
            onClick={() => onRestoreClick(model.id, model.general.name)}
          />
        ) : (
          <>
            <StyledIcon
              size={26}
              svg={Archive}
              color={COLORS.RED_100}
              onClick={() => onArchiveClick(model.id, model.general.name)}
            />
            <Button
              label={t('View')}
              primary
              height="25rem"
              onClick={() => onViewClick(model.id)}
            />
          </>
        )}
      </RightPart>
    </Container>
  );
};

export default ModelRow;
