import { AnyAction } from '@reduxjs/toolkit';
import {
  SET_IS_FORM_VALID,
  SET_ARE_ALL_FIELDS_TOUCHED,
  SET_TOUCHED_QUESTION_NAME,
  RESET_TOUCHED_QUESTION_NAMES,
  SET_MODEL_NOT_FOUND,
} from '../../constants';

export interface IFormReducerState {
  isFormValid: boolean;
  areAllFieldsTouched: boolean;
  touchedQuestionNames: string[];
  modelNotFound: boolean;
}

const INIT_STATE: IFormReducerState = {
  modelNotFound: false,
  isFormValid: false,
  areAllFieldsTouched: false,
  touchedQuestionNames: [],
};

const formReducer = (state = INIT_STATE, action: AnyAction) => {
  switch (action.type) {
    case SET_TOUCHED_QUESTION_NAME: {
      if (state.touchedQuestionNames.includes(action.payload)) {
        return { ...state };
      } else {
        const touchedQuestionNames = [
          ...state.touchedQuestionNames,
          action.payload,
        ];
        return {
          ...state,
          touchedQuestionNames: touchedQuestionNames,
        };
      }
    }
    case RESET_TOUCHED_QUESTION_NAMES:
      return {
        ...state,
        touchedQuestionNames: [],
      };
    case SET_ARE_ALL_FIELDS_TOUCHED:
      return {
        ...state,
        areAllFieldsTouched: action.payload,
      };
    case SET_IS_FORM_VALID:
      return {
        ...state,
        isFormValid: action.payload,
      };
    case SET_MODEL_NOT_FOUND:
      return {
        ...state,
        modelNotFound: action.payload,
      };
    default:
      return state;
  }
};

export default formReducer;
