import { COLORS } from 'assets/styled';
import styled, { keyframes } from 'styled-components';

// Define the keyframes for the spin animation
const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

// Create a styled component for the loader
export const Loader = styled.div`
  border: 3rem solid ${COLORS.GRAY_100};
  border-top: 3rem solid ${COLORS.GREEN};
  border-radius: 50%;
  width: 26rem;
  height: 26rem;
  animation: ${spin} 2s linear infinite;
`;
