import apiClient from 'services/api/apiService';

const BASE_URL = '/v1/favorites';

export const favoriteModel = async (modelId: number): Promise<any> => {
  const url = `${BASE_URL}/${modelId}`;
  const { data } = await apiClient.post<any>(url);
  return data.data;
};

export const unfavoriteModel = async (modelId: number): Promise<any> => {
  const url = `${BASE_URL}/${modelId}`;
  const { data } = await apiClient.delete<any>(url);
  return data.data;
};
