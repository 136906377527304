export const getUrlWithQueryParams = (
  urlWithoutQueryParams: string,
  queryParamsObj: object
) => {
  let url = urlWithoutQueryParams;
  let index = 1;
  // Include query parameter in the url if it is not a falsy value
  for (const queryParamObjectKey of Object.keys(queryParamsObj)) {
    if (queryParamsObj[queryParamObjectKey as keyof object]) {
      url = `${url}${index === 1 ? `?` : `&`}${queryParamObjectKey}=${
        queryParamsObj[queryParamObjectKey as keyof object]
      }`;
      index++;
    }
  }
  return url;
};
