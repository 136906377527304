import { useTranslation } from 'react-i18next';
import {
  FirstRow,
  InputWrapper,
  LeftSide,
  RightSide,
  SelectWrapper,
  Title,
} from './AllModelsPage.styled';
import Input from 'components/Input/Input';
import ViewTypePicker, {
  ViewType,
} from 'components/ViewTypePicker/ViewTypePicker';
import { useEffect, useState } from 'react';
import Icon from 'components/Icon/Icon';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'utils/hooks/useDebounce';
import AllModelsListView from './AllModelsListView/AllModelsListView';
import AllModelsGridView from './AllModelsGridView/AllModelsGridView';
import { Select } from 'components/Select/Select';
import { useGetCategoryOptions } from './hooks';
import { useSelectedViewType } from 'pages/ModelsPage/hooks';
import { RoutesConfig } from 'navigation/routes';
import { CaretLeft, MagnifyingGlass as Search } from '@phosphor-icons/react';
import { usePageTitle } from 'utils/hooks/usePageTitle';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';
import { COLORS } from 'assets/styled';

const AllModelsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { selectedViewType, setSelectedViewType } = useSelectedViewType();
  const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(
    null
  );

  const [searchBy, setSearchBy] = useState<string>('');
  const debouncedSearchBy = useDebounce(searchBy);
  const [categoriesSearchBy, setCategoriesSearchBy] = useState<string>('');
  const debouncedCategoriesSearchBy = useDebounce(categoriesSearchBy);

  const { categoryOptions, fetchNextPage, hasNextPage } = useGetCategoryOptions(
    debouncedCategoriesSearchBy
  );

  usePageTitle('All Models');

  const { isMidTablet } = useBreakpointFlag();

  useEffect(() => {
    if (isMidTablet && selectedViewType === ViewType.LIST) {
      setSelectedViewType(ViewType.GRID);
    }
  }, [isMidTablet]);

  return (
    <>
      <FirstRow>
        <LeftSide onClick={() => navigate(RoutesConfig.Models.fullPath)}>
          <Icon
            svg={CaretLeft}
            fill={COLORS.GREEN}
            weight="bold"
            size={27}
            onClick={() => null}
          />
          <Title>{t('All Models')}</Title>
        </LeftSide>
        <RightSide>
          <InputWrapper>
            <Input
              placeholder={t('Search models')}
              width={'100%'}
              wrapperStyles={{ width: '100%' }}
              onChange={(e: any) => setSearchBy(e.target.value)}
              icon={Search}
            />
          </InputWrapper>
          <SelectWrapper>
            <Select
              width="100%"
              defaultValue={{ value: '', label: 'All categories' }}
              name="category-select"
              placeholder={t('Category')}
              options={categoryOptions}
              onChange={(selectedOption: any) =>
                setSelectedCategoryId(selectedOption.value)
              }
              onInputChange={(value: string) => setCategoriesSearchBy(value)}
              onMenuScrollToBottom={() => hasNextPage && fetchNextPage()}
            />
          </SelectWrapper>
          {!isMidTablet && (
            <ViewTypePicker
              selectedViewType={selectedViewType}
              setSelectedViewType={setSelectedViewType}
            />
          )}
        </RightSide>
      </FirstRow>

      {selectedViewType === ViewType.GRID ? (
        <AllModelsGridView
          debouncedSearchBy={debouncedSearchBy}
          selectedCategoryId={selectedCategoryId}
        />
      ) : (
        <AllModelsListView
          debouncedSearchBy={debouncedSearchBy}
          selectedCategoryId={selectedCategoryId}
        />
      )}
    </>
  );
};

export default AllModelsPage;
