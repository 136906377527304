import { SingleValueProps } from 'react-select';
import { components } from 'react-select';
import { ColorImage, SingleValueContainer } from './ColorSingleValue.styled';

export const ColorSingleValue = (props: SingleValueProps<any>) => {
  return (
    <components.SingleValue {...props}>
      <SingleValueContainer>
        {/* Label is calculated in getSelectOptionsFromQuestion */}
        {props.data.label}
        {/* Color is injected in injectDataIntoOptionBasedOnDropdownType */}
        <ColorImage src={props.data.color_small_image} alt="color" />
      </SingleValueContainer>
    </components.SingleValue>
  );
};
