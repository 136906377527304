import styled, { css } from 'styled-components';
import { COLORS } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';

interface StyledProps {
  isExpanded: boolean;
}

export const Container = styled.div`
  position: relative;
  border-bottom: 1rem solid ${COLORS.BORDER};
  display: flex;
  align-items: center;
  margin: 0 30rem;
  padding: 30rem 0;
  :nth-last-child(1) {
    border-bottom: 0;
  }

  ${(props: StyledProps) =>
    props.isExpanded &&
    css`
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 20rem;

      ${respondTo.bigPhone`
        gap: 10rem;
      `}
    `}

  ${respondTo.bigPhone`
    flex-wrap: wrap;
    padding: 20rem 0;
  `}
`;

export const Label = styled.div`
  font-size: 18rem;
  line-height: 27rem;
  font-weight: 600;
  width: 147rem;
  color: ${COLORS.BLACK};

  ${respondTo.bigPhone`
    order: 1;
    font-size: 16rem;
  `}
`;
export const LabelValue = styled.div`
  font-size: 18rem;
  line-height: 27rem;
  font-weight: 400rem;
  width: 481rem;
  color: ${COLORS.BLACK};

  ${respondTo.bigPhone`
    order: 3;
    font-size: 16rem;
  `}
`;

export const ButtonWrapper = styled.div`
  ${respondTo.bigPhone`
    order: 2;
    margin-left: auto;
  `}
`;

export const ArrowWrapper = styled.div`
  position: absolute;
  top: 24rem;
  right: 0;
  cursor: pointer;
`;

export const EyeWrapper = styled.div`
  user-select: none;
  position: absolute;
  top: 20%;
  right: 15rem;
`;

export const PasswordInputWrapper = styled.div`
  position: relative;
  width: 300rem;

  ${respondTo.smallPhone`
    width: 100%;
  `}
`;

export const PasswordErrorMessage = styled.label`
  position: absolute;
  font-weight: 400;
  font-size: 12rem;
  color: ${COLORS.RED_100};
  bottom: 80rem;
`;

export const PasswordProfileCardRowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20rem;
  margin-bottom: 20rem;

  ${respondTo.bigPhone`
    order: 2;
    width: 100%;
  `}
`;

export const InputWrapper = styled.div`
  width: 300rem;

  ${respondTo.bigPhone`
    order: 2;
  `}

  ${respondTo.smallPhone`
    width: 100%;
  `}
`;
