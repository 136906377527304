import ErrorLoadingMediaModelCardImage from 'assets/images/errorLoadingMediaModelCard.png';
import ErrorLoadingMediaModelCardImageDark from 'assets/images/errorLoadingMediaModelCardDark.png';
import { AdaptiveImageType } from './AdaptiveImage';
import NoImagesAvailableModelCard from 'assets/images/noImagesAvailableModelCard.png';
import NoImagesAvailableModelCardDark from 'assets/images/noImagesAvailableModelCardDark.png';
import NoImagesAvailableCompanyCard from 'assets/images/noImagesAvailableCompanyCard.png';
import NoImagesAvailableCompanyCardDark from 'assets/images/noImagesAvailableCompanyCardDark.png';
import NoImagesAvailableSupplierCard from 'assets/images/noImagesAvailableSupplierCard.png';
import NoImagesAvailableSupplierCardDark from 'assets/images/noImagesAvailableSupplierCardDark.png';
import ErrorLoadingColorImage from 'assets/images/errorLoadingColorImage.png';
import ErrorLoadingColorImageDark from 'assets/images/errorLoadingColorImageDark.png';

export const getErrorLoadingMediaImage = (darkMode: boolean) => {
  return darkMode
    ? ErrorLoadingMediaModelCardImageDark
    : ErrorLoadingMediaModelCardImage;
};

export const getNoImagesAvailableImage = (
  darkMode: boolean,
  type: AdaptiveImageType
) => {
  switch (type) {
    case AdaptiveImageType.COMPANY:
      return darkMode
        ? NoImagesAvailableCompanyCardDark
        : NoImagesAvailableCompanyCard;
    case AdaptiveImageType.SUPPLIER:
      return darkMode
        ? NoImagesAvailableSupplierCardDark
        : NoImagesAvailableSupplierCard;
    case AdaptiveImageType.MODEL:
      return darkMode
        ? NoImagesAvailableModelCardDark
        : NoImagesAvailableModelCard;
    case AdaptiveImageType.COLOR:
      return darkMode ? ErrorLoadingColorImageDark : ErrorLoadingColorImage;
  }
};
