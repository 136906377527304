import { COLORS, borderRadiusSm } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import { MODEL_CARD_CONTAINER_HEIGHT } from 'components/ModelCard/ModelCard.styled';
import styled, { css } from 'styled-components';

interface StyledProps {
  isExpanded: boolean;
  numberOfModelCardRows: number;
  withoutLine: boolean;
  isSkeleton: boolean;
}

export const Container = styled.div`
  position: relative;
  background-color: ${COLORS.WHITE};
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding-left: 20rem;
  padding-right: 20rem;
  border-radius: ${borderRadiusSm};
  user-select: none;
  ${(props: StyledProps) =>
    props.isSkeleton &&
    css`
      background-color: ${COLORS.WHITE};
    v`}
`;

export const CategoryNameLabel = styled.label`
  font-weight: 400;
  font-size: 18rem;
  line-height: 24.5rem;
  color: ${COLORS.BLACK};

  ${respondTo.smallTablet`
    font-size: 16rem;
  `}
`;

export const CategoryNameAndChevronWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 84rem;
  cursor: pointer;
  z-index: 1;
  position: relative;

  ${respondTo.smallTablet`
    height: 60rem;
  `}
`;

export const ChevronImgWrapper = styled.div`
  position: absolute;
  right: 20rem;
  top: 50%;
  transform: translateY(-50%);
  width: 30rem;
  height: 30rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ChevronImg = styled.div`
  width: 30rem;
  height: 30rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: ${({ isExpanded }: StyledProps) =>
    isExpanded ? 'rotate(180deg)' : 'rotate(0)'};

  ${respondTo.midTablet`
    width: 20rem;
    height: 20rem;
  `}
`;

export const ModelsWrapper = styled.div`
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
`;

export const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: ${MODEL_CARD_CONTAINER_HEIGHT}rem;
`;

export const FlexWrap = styled.div`
  display: grid;
  grid-gap: 15rem;
  justify-content: space-between;
  background-color: ${COLORS.WHITE};
  grid-template-columns: repeat(
    auto-fill,
    300rem
  ); // MODEL_CARD_CONTAINER_WIDTH === 300rem
  overflow: scroll;
  padding-top: ${({ isExpanded }: StyledProps) =>
    isExpanded
      ? '2.5rem'
      : 0}; // When hovering over a ModelCard it gets translated by 2rem upwards, this is to prevent overflow
  transition: all 0.3s ease-in-out;
  ${(props: StyledProps) =>
    props.isExpanded
      ? css`
          max-height: 890rem;

          ${respondTo.bigTablet`
            max-height: 587rem;
          `}
        `
      : css`
          max-height: 0;
        `};

  ${respondTo.bigTablet`
    grid-template-columns: repeat(auto-fill, 30%);
  `}

  ${respondTo.smallTablet`
    grid-template-columns: repeat(auto-fill, 48%);
  `}

  ${respondTo.bigPhone`
    grid-template-columns: repeat(auto-fill, 100%);
    justify-content: center;
  `}
`;

export const NoContentLabel = styled.label`
  width: 100%;
  text-align: center;
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
  margin-bottom: 20rem;
  color: ${COLORS.BLACK};

  ${respondTo.bigPhone`
    font-size: 16rem;
  `}
`;
