import { COLORS, marginMd, marginSm, marginXs } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

export const PasswordInputWrapper = styled.div`
  position: relative;
  margin-bottom: ${marginSm};
  width: 580rem;

  ${respondTo.bigPhone`
    width: 100%;
    max-width: 100%;
  `}
`;

export const EyeWrapper = styled.div`
  user-select: none;
  position: absolute;
  top: 50%;
  right: 15rem;
  transform: translateY(-50%);
`;

export const ButtonContainer = styled.div`
  width: 100%;
  margin-top: ${marginXs};
  margin-bottom: ${marginMd};
`;

export const PasswordErrorMessage = styled.label`
  position: absolute;
  font-weight: 400;
  font-size: 12rem;
  color: ${COLORS.RED_100};
  bottom: 90rem;
  right: 0;
`;
