import { IImageLayer } from 'pages/ConfigurePage/FormImages/hooks';
import { SmallFormImageLayerImage } from './SmallFormImage.styled';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { adjustImageSourceToUseS3ImageTransformator } from 'utils/adjustImageSourceToUseS3ImageTransformator';

interface ISmallFormImageLayerProps {
  layerKey: string;
  layer: IImageLayer;
  setError: Dispatch<SetStateAction<boolean>>;
  singleImage?: boolean;
}

export const SmallFormImageLayer = ({
  layerKey,
  layer,
  setError,
  singleImage,
}: ISmallFormImageLayerProps) => {
  const [isUsingFallbackImage, setIsUsingFallbackImage] =
    useState<boolean>(false);

  useEffect(() => {
    if (isUsingFallbackImage) {
      setIsUsingFallbackImage(false);
    }
  }, [layer]);
  return (
    <SmallFormImageLayerImage
      key={layerKey}
      src={adjustImageSourceToUseS3ImageTransformator(
        isUsingFallbackImage && layer.fallback ? layer.fallback : layer.source,
        152
      )}
      onError={() => {
        console.log('Image failed to load: ', layer.source);
        if (!isUsingFallbackImage && layer.fallback) {
          setIsUsingFallbackImage(true);
        } else {
          setError(true);
        }
      }}
      zIndex={layer.offset}
      singleImage={singleImage}
    />
  );
};
