import { IUser, IUserTableDTO } from 'types/User.types';

export const formatUsersTableData = (users: IUser[]) => {
  const userTableDTOs: IUserTableDTO[] = [];

  users.forEach((user: IUser) => {
    const dto: IUserTableDTO = {
      id: user.id,
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      is_active: user.is_active.toString(),
    };
    userTableDTOs.push(dto);
  });

  return userTableDTOs;
};
