import * as Yup from 'yup';

// Name validation schema
export const settingsNameValSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Name must be at least 2 characters')
    .max(50, 'Name must not exceed 50 characters')
    .required('Name is required'),
});

// Email validation schema
export const settingsEmailValSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email format')
    .max(250, 'Email must not exceed 250 characters')
    .required('Email is required'),
});

// Phone number validation schema
export const settingsPhoneValSchema = Yup.object().shape({
  phone_number: Yup.string()
    .matches(
      /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
      'Invalid phone number format'
    )
    .max(50, 'Phone number must not exceed 50 characters')
    .nullable(),
});

// Website validation schema
export const settingsWebsiteValSchema = Yup.object().shape({
  website: Yup.string()
    .url('Invalid URL format')
    .max(100, 'Website URL must not exceed 100 characters')
    .nullable(),
});
