import {
  LOGOUT,
  SET_COMPANY,
  SET_SUPPLIER,
  SET_USER_INFO,
  SET_UPDATED_USER_INFO,
  SET_IS_LOGGED_IN,
} from 'store/constants';
import { IUserData } from '../types';
import { ICompany } from 'types/Company.types';
import { ISupplier } from 'types/Supplier.types';
import { IEditUserData } from 'pages/ProfilePage/hooks';

export const setUserInfo = (userData: IUserData) => ({
  type: SET_USER_INFO,
  payload: userData,
});

export const logout = () => ({
  type: LOGOUT,
});

export const setCompany = (company: ICompany | null) => ({
  type: SET_COMPANY,
  payload: company,
});

export const setSupplier = (supplier: ISupplier | null) => ({
  type: SET_SUPPLIER,
  payload: supplier,
});

export const setUpdatedUserInfo = (userData: IEditUserData) => ({
  type: SET_UPDATED_USER_INFO,
  payload: userData,
});

export const setIsLoggedIn = (isLoggedIn: boolean) => ({
  type: SET_IS_LOGGED_IN,
  payload: isLoggedIn,
});
