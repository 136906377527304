import { CSSProperties, useState } from 'react';
import {
  Image,
  RelativeContainer,
  SkeletonWrapper,
} from './AdaptiveImage.styled';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { adjustImageSourceToUseS3ImageTransformator } from 'utils/adjustImageSourceToUseS3ImageTransformator';
import { Skeleton } from '@mui/material';
import {
  getErrorLoadingMediaImage,
  getNoImagesAvailableImage,
} from './helpers';

export enum AdaptiveImageType {
  MODEL = 'MODEL',
  COMPANY = 'COMPANY',
  SUPPLIER = 'SUPPLIER',
  COLOR = 'COLOR',
}

interface IAdaptiveImageProps {
  containerStyle?: CSSProperties;
  absoluteComponent?: React.ReactNode;
  imageStyle: CSSProperties;
  onClick?: () => void;
  imageUrl: string;
  withoutTransformator?: boolean;
  type: AdaptiveImageType;
  isSuccess?: boolean; // Has fetched neccessary data to present the image
}

export const AdaptiveImage = ({
  containerStyle,
  absoluteComponent,
  imageStyle,
  onClick,
  imageUrl,
  withoutTransformator = false,
  type,
  isSuccess = true,
}: IAdaptiveImageProps) => {
  const [error, setError] = useState(false);
  const [imageLoaded, setImageLoaded] = useState<boolean>(false);
  const { darkMode } = useSelector(
    (state: IRootReducerState) => state.commonInfo
  );

  return (
    <RelativeContainer containerStyle={containerStyle}>
      {imageLoaded && isSuccess ? null : (
        <SkeletonWrapper
          containerStyle={containerStyle}
          onClick={onClick}
          hasOnClick={!!onClick}
        >
          <Skeleton
            variant="rectangular"
            height={'100%'}
            width={'300rem'}
            animation="wave"
            style={{ borderRadius: '20rem', backgroundColor: 'transparent' }}
          />
        </SkeletonWrapper>
      )}
      <Image
        hasOnClick={!!onClick}
        imageStyle={imageStyle}
        imageLoaded={imageLoaded && isSuccess}
        src={
          error
            ? getErrorLoadingMediaImage(darkMode)
            : imageUrl
            ? withoutTransformator
              ? imageUrl
              : adjustImageSourceToUseS3ImageTransformator(imageUrl, 300)
            : getNoImagesAvailableImage(darkMode, type)
        }
        onClick={onClick}
        onError={() => setError(true)}
        onLoad={() => setImageLoaded(true)}
      />
      {(imageLoaded && isSuccess) || error ? absoluteComponent : null}
    </RelativeContainer>
  );
};
