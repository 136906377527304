import { Skeleton } from '@mui/material';
import { COLORS, borderRadiusSm, marginXs } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';
import { css } from 'styled-components';

export const MODEL_CARD_CONTAINER_HEIGHT = 285;
export const MODEL_CARD_CONTAINER_WIDTH = 300;

interface StyledProps {
  opacityIndex: number;
  opacityCoeficient: number;
  imageLoaded: boolean;
}

export const Container = styled.div`
  width: ${MODEL_CARD_CONTAINER_WIDTH}rem;
  height: ${MODEL_CARD_CONTAINER_HEIGHT}rem;
  position: relative;
  display: flex;
  flex-direction: column;
  ${(props: StyledProps) =>
    props.opacityIndex !== undefined &&
    css`
      opacity: ${1 - (props.opacityIndex * props.opacityCoeficient + 0.2)};
    `}
  transition: transform ease-in-out 50ms;
  &:hover {
    transform: translateY(-2rem);
  }

  ${respondTo.smallLaptop`
    max-width: 300rem;
    width: 100%;
  `}

  ${respondTo.bigPhone`
    max-width: 100%;
    height: auto;
    margin-bottom: 20rem;
  `}
`;

export const imageStyle = css`
  cursor: pointer;
  width: 100%;
  ${(props: StyledProps) =>
    props.imageLoaded
      ? css`
          min-height: 170rem;
          max-width: 100%;
          object-fit: contain;
          border-radius: ${borderRadiusSm};
          cursor: pointer;
          border: 1px solid ${COLORS.BORDER};
          ${respondTo.smallLaptop`
            min-height: 200rem;
          `}
        `
      : css`
          width: 0;
          height: 0;
          opacity: 0;
        `};
`;

export const NameLabel = styled.label`
  margin-top: ${marginXs};
  font-weight: 400;
  font-size: 24rem;
  line-height: 32rem;
  cursor: pointer;
  color: ${COLORS.BLACK};
  width: 100%;

  ${respondTo.smallLaptop`
    font-size: 20rem;
    line-height: 28rem;
  `}

  ${respondTo.bigTablet`
    font-size: 18rem;
  `}
`;

export const RowCenter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
`;

export const AbsoluteCircleWrapper = styled.label`
  z-index: 1;
  position: absolute;
  display: flex;
  left: 0rem;
  top: 0rem;
  background-color: ${COLORS.GREEN_DISABLED};
  border-bottom-right-radius: ${borderRadiusSm};
  border-top-left-radius: ${borderRadiusSm};
  padding-left: 5rem;
  padding-right: 5rem;
`;

export const ModelIdLabel = styled.label`
  color: ${COLORS.STRICT_BLACK};
  margin-left: 3rem;
  margin-right: 3rem;
`;

export const StyledSkeleton = styled(Skeleton)`
  border-radius: ${borderRadiusSm};
`;

export const favoriteIconStyle = css`
  position: absolute;
  z-index: 1;
  right: 9rem;
  &:hover {
    transform: scale(1.2);
    transition: transform ease-in-out 0.1s;

    ${respondTo.bigTablet`
      transition: none;
      transform: none;
    `}
  }
  bottom: 14rem;
`;

export const AbsoluteVersionWrapper = styled.div`
  z-index: 1;
  position: absolute;
  display: flex;
  right: 0rem;
  top: 0rem;
  background-color: ${COLORS.GREEN_DISABLED};
  border-bottom-left-radius: ${borderRadiusSm};
  border-top-right-radius: ${borderRadiusSm};
  padding-left: 10rem;
  padding-right: 10rem;

  font-size: 16rem;
  font-weight: 400;
  color: ${COLORS.STRICT_BLACK};
`;
