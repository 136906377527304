import { useTranslation } from 'react-i18next';
import { Container, Title } from './FavoritesSection.styled';
import { ViewType } from 'components/ViewTypePicker/ViewTypePicker';
import FavoritesSectionGrid from './FavoritesSectionGrid/FavoritesSectionGrid';
import FavoritesSectionList from './FavoritesSectionList/FavoritesSectionList';

interface IFavoritesSectionProps {
  selectedViewType: ViewType;
}

const FavoritesSection = ({ selectedViewType }: IFavoritesSectionProps) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Title>{t('Favourites')}</Title>
      {selectedViewType === ViewType.GRID ? (
        <FavoritesSectionGrid />
      ) : (
        <FavoritesSectionList />
      )}
    </Container>
  );
};

export default FavoritesSection;
