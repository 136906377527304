import { createTheme } from '@mui/material';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';

export const useAppTheme = () => {
  const { darkMode } = useSelector(
    (state: IRootReducerState) => state.commonInfo
  );

  const calcFontSize = () => {
    const width = window.innerWidth;
    if (width > 1900) return 1;
    if (width > 1300) return 0.9;
    if (width > 1180) return 0.8;
    return 0.7;
  };

  const commonThemeOptions = useMemo(
    () => ({
      typography: {
        htmlFontSize: calcFontSize(),
      },
      zIndex: {
        mobileStepper: 9000,
        fab: 9050,
        speedDial: 9050,
        appBar: 9100,
        drawer: 9200,
        modal: 9300,
        snackbar: 9400,
        tooltip: 9500,
      },
      dark: darkMode,
    }),
    [window.innerWidth, darkMode]
  );

  const theme = useMemo(
    () =>
      createTheme({
        ...commonThemeOptions,
        palette: {
          mode: darkMode ? 'dark' : 'light',
        },
      }),
    [commonThemeOptions, darkMode]
  );

  return theme;
};
