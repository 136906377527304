import { useQuery } from 'react-query';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { getCustomVersion } from 'services/BuilderService';

export const useGetCustomVersion = (id: string) => {
  return useQuery({
    queryKey: [ReactQueryKeys.GET_CUSTOM_VERSION, id],
    queryFn: () => {
      return getCustomVersion(id);
    },
    staleTime: 0,
  });
};
