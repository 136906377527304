import { COLORS } from 'assets/styled';
import styled from 'styled-components';

export const MenuListWrapper = styled.div`
  overflow-y: auto;
  max-height: 200rem;
`;

export const ButtonWrapper = styled.div`
  position: sticky;
  bottom: 0;
  border-top: 1px solid ${COLORS.GRAY_300};
  margin-top: 10rem;
`;

export const Menu = styled.div``;
