import LoadingScreenOverlay from 'components/LoadingScreenOverlay/LoadingScreenOverlay';
import { Outlet } from 'react-router';
import { useShowLoadingScreen } from './hooks';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';

const LoadingScreenOverlayProvider = () => {
  const showLoadingScreen = useShowLoadingScreen();
  const { darkMode } = useSelector(
    (state: IRootReducerState) => state.commonInfo
  );
  return (
    <>
      {showLoadingScreen ? (
        <LoadingScreenOverlay isDarkMode={darkMode} />
      ) : null}
      <Outlet />
    </>
  );
};

export default LoadingScreenOverlayProvider;
