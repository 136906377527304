import { FormikContextType } from 'formik';
import { IQuestion } from 'types/Question.types';
import React from 'react';
import {
  AdditionalWrapper,
  Container,
  ErrorContainer,
  ErrorMessage,
  PriceContainer,
  PriceLabel,
  PriceValue,
} from './FormItemWrapper.styled';
import { useTranslation } from 'react-i18next';

interface IFormWrapperProps {
  question: IQuestion;
  context: FormikContextType<any>;
  isTouched: boolean;
  children: React.ReactNode;
  questionPrice: number | undefined;
}

const FormItemWrapper = ({
  question,
  context,
  isTouched,
  children,
  questionPrice,
}: IFormWrapperProps) => {
  const { t } = useTranslation();
  const errorMessage = context.errors[question.initialKey];

  const isAddedPriceNegative =
    typeof questionPrice == 'number' && questionPrice < 0;

  return (
    <Container>
      {children}
      <AdditionalWrapper>
        {isTouched && (
          <ErrorContainer>
            <ErrorMessage>{errorMessage}</ErrorMessage>
          </ErrorContainer>
        )}
        {questionPrice !== undefined && (
          <PriceContainer>
            <PriceLabel>
              {isAddedPriceNegative ? t('Discount') : t('Added price')}:
            </PriceLabel>
            <PriceValue>€{questionPrice?.toFixed(2)}</PriceValue>
          </PriceContainer>
        )}
      </AdditionalWrapper>
    </Container>
  );
};

export default FormItemWrapper;
