import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import {
  Container,
  Title,
  ButtonWrapper,
  ErrorMessage,
  RelativeWrapper,
} from './EmailInputModal.styled';
import { Modal } from '../Modal/Modal';
import Button from 'components/Button/Button';
import { useTranslation } from 'react-i18next';
import { Form, Formik, FormikProps } from 'formik';
import { emailInputModalValidationSchema } from './validation';
import { Input } from 'components/Input/InputFormik';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';

export interface IEmailInputModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onConfirm: (email: string) => void;
  title: string;
}

export function EmailInputModal({
  isOpen,
  setIsOpen,
  onConfirm,
  title,
}: IEmailInputModalProps) {
  const { t } = useTranslation();
  const formikRef = useRef<FormikProps<any> | any>();
  const [areAllFieldsTouched, setAreAllFieldsTouched] =
    useState<boolean>(false);

  const { isPhone } = useBreakpointFlag();
  const handleSubmit = () => {
    if (formikRef.current.isValid) {
      setIsOpen(false);
      onConfirm(formikRef.current.values.email);
    } else if (!areAllFieldsTouched) {
      setAreAllFieldsTouched(true);
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setAreAllFieldsTouched(false);
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      modalStyle={{
        margin: 'auto',
        width: isPhone ? '90%' : '600rem',
        minWidth: isPhone ? '90%' : 'auto',
        position: 'fixed',
      }}
    >
      <Container>
        <Title>{title}</Title>
        <Formik
          innerRef={formikRef}
          initialValues={{
            email: '',
          }}
          validationSchema={emailInputModalValidationSchema}
          validateOnChange={true}
          validateOnMount={true}
          validateOnBlur={true}
          onSubmit={() => {
            //
          }}
        >
          {({ errors }) => {
            return (
              <Form>
                <RelativeWrapper>
                  <Input
                    name="email"
                    placeholder={t('E-mail')}
                    height={'41rem'}
                    wrapperStyles={{ width: '300rem' }}
                  />
                  <ErrorMessage>
                    {areAllFieldsTouched ? errors['email'] : null}
                  </ErrorMessage>
                </RelativeWrapper>
              </Form>
            );
          }}
        </Formik>
        <ButtonWrapper>
          <Button
            secondary
            label={t('Cancel')}
            width={isPhone ? '100rem' : '200rem'}
            onClick={() => {
              setIsOpen(false);
            }}
          />
          <Button
            primary
            label={t('Confirm')}
            width={isPhone ? '100rem' : '200rem'}
            onClick={() => handleSubmit()}
          />
        </ButtonWrapper>
      </Container>
    </Modal>
  );
}
