import { COLORS, marginSm } from 'assets/styled';
import styled from 'styled-components';

export const Container = styled.div`
  height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const PageNotFoundLabel = styled.label`
  font-size: 30rem;
  font-weight: 200;
  margin-bottom: ${marginSm};
  color: ${COLORS.BLACK};
`;
