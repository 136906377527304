import {
  ButtonWrapper,
  Container,
  NoContentLabel,
  Row,
  ClearShoppingCartWrapper,
  Title,
  ClearShoppingCartLabel,
  PriceWrapper,
  PriceLabel,
  PriceValueLabel,
  SupplierName,
  Wrapper,
} from './ShoppingCartPage.styled';
import ShoppingCartCard from './ShoppingCartCard/ShoppingCartCard';
import Button from 'components/Button/Button';
import { DangerModal } from 'components/Modals/DangerModal/DangerModal';
import { useState } from 'react';
import {
  IPostCartOrderDTO,
  useDeleteItemFromShoppingCart,
  useDeleteShoppingCart,
  useGetSalesOrderTypeOptions,
  useGetShoppingCart,
  usePostCartOrder,
} from './hooks';
import Loader from 'components/Loader/Loader';
import Input from 'components/Input/Input';
import { useTranslation } from 'react-i18next';
import { ICartItem } from 'types/CartItem.types';
import { useDispatch } from 'react-redux';
import { setCurrentlyEditingShoppingCartLineId } from 'store/ShoppingCart/actions/shoppingCart';
import {
  ISupplierLine,
  calculateTotalNetPriceForCart,
  groupLinesBySupplier,
  useAreAllConfigurationsInternal,
} from './helpers';
import ShoppingCartCardSkeletons from './ShoppingCartCardSkeletons/ShoppingCartCardSkeletons';
import { Select } from 'components/Select/Select';
import { IOption } from 'components/Select/type';
import { usePageTitle } from 'utils/hooks/usePageTitle';

const ShoppingCartPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [reference, setReference] = useState<string>('');

  const [isRemoveItemDangerModalOpen, setIsRemoveItemDangerModalOpen] =
    useState(false);
  const [cartItemIdForRemoval, setCartItemIdForRemoval] = useState<
    number | null
  >(null);
  const [selectedSalesOrderTypeOption, setSelectedSalesOrderTypeOption] =
    useState<IOption | null>(null);
  const [salesOrderNo, setSalesOrderNo] = useState<string>('');

  const { data: cartData, isLoading: getShoppingCartIsLoading } =
    useGetShoppingCart();
  const supplierLines: ISupplierLine[] = groupLinesBySupplier(
    cartData?.lines || []
  );

  const { mutate: postCartOrder, isLoading: postCartOrderIsLoading } =
    usePostCartOrder();

  const { mutate: deleteShoppingCart, isLoading: deleteShoppingCartIsLoading } =
    useDeleteShoppingCart();

  const {
    mutate: deleteItemFromShoppingCart,
    isLoading: deleteItemFromShoppingCartIsLoading,
  } = useDeleteItemFromShoppingCart();

  const salesOrderTypeOptions: IOption[] = useGetSalesOrderTypeOptions();

  usePageTitle('Shopping cart');

  const areAllConfigurationsInternal = useAreAllConfigurationsInternal(
    cartData?.lines || []
  );

  return (
    <Wrapper>
      <Container>
        <Row>
          <Title>{t('Shopping cart')}</Title>
        </Row>
        {postCartOrderIsLoading && <Loader />}
        {cartData?.lines?.length && !getShoppingCartIsLoading ? (
          <ClearShoppingCartWrapper>
            <ClearShoppingCartLabel
              onClick={() => {
                if (!deleteShoppingCartIsLoading) {
                  deleteShoppingCart();
                  dispatch(setCurrentlyEditingShoppingCartLineId(null));
                }
              }}
            >
              {t('Clear shopping cart')}
            </ClearShoppingCartLabel>
          </ClearShoppingCartWrapper>
        ) : null}
        {supplierLines.map((supplierLine: ISupplierLine) => {
          return (
            <>
              <SupplierName>{supplierLine.supplier.name}</SupplierName>
              {supplierLine?.lines?.map((item: ICartItem, index: number) => (
                <ShoppingCartCard
                  item={item}
                  key={`cart-item-${index}`}
                  onRemove={() => {
                    setIsRemoveItemDangerModalOpen(true);
                    setCartItemIdForRemoval(item.id);
                  }}
                  isLast={index === supplierLine?.lines.length - 1}
                />
              ))}
            </>
          );
        })}
        {!cartData?.lines?.length ? (
          getShoppingCartIsLoading ? (
            <ShoppingCartCardSkeletons />
          ) : (
            <NoContentLabel>
              {t('Shopping cart is currently empty')}
            </NoContentLabel>
          )
        ) : (
          <>
            <PriceWrapper>
              <PriceLabel>
                {t('Total net price') + ':'}
                <PriceValueLabel>
                  &nbsp;
                  {'€' +
                    calculateTotalNetPriceForCart(
                      cartData?.lines || []
                    ).toFixed(2)}
                </PriceValueLabel>
              </PriceLabel>
            </PriceWrapper>
            <ButtonWrapper>
              {areAllConfigurationsInternal ? (
                <>
                  <Input
                    height={'42rem'}
                    width={'200rem'}
                    placeholder={t('Sales order no')}
                    value={salesOrderNo}
                    onChange={(e) => {
                      if (e.target.value.length <= 20) {
                        setSalesOrderNo(e.target.value);
                      }
                    }}
                  />
                  <Select
                    width={'200rem'}
                    name="salesOrderType"
                    placeholder={t('Sales order type')}
                    options={salesOrderTypeOptions}
                    onChange={(selectedOption: IOption) =>
                      setSelectedSalesOrderTypeOption(selectedOption)
                    }
                  />
                </>
              ) : null}
              <Input
                height={'42rem'}
                placeholder={t('External reference')}
                value={reference}
                width={'200rem'}
                onChange={(e) => setReference(e.target.value)}
              />
              <Button
                pwId="confirm-shopping-cart"
                disabled={postCartOrderIsLoading || !reference}
                primary
                label={t('Confirm')}
                width="200rem"
                onClick={() => {
                  const postCardOrderDTO: IPostCartOrderDTO = {
                    order_reference: reference,
                    ...(areAllConfigurationsInternal &&
                      selectedSalesOrderTypeOption && {
                        sales_type: selectedSalesOrderTypeOption.value,
                      }),
                    ...(areAllConfigurationsInternal &&
                      salesOrderNo && {
                        sales_no: salesOrderNo,
                      }),
                  };
                  postCartOrder(postCardOrderDTO);
                }}
              />
            </ButtonWrapper>
          </>
        )}
        <DangerModal
          isOpen={isRemoveItemDangerModalOpen}
          setIsOpen={setIsRemoveItemDangerModalOpen}
          title={t('Confirm removal')}
          submit={{
            onClick: () => {
              if (cartItemIdForRemoval !== null) {
                deleteItemFromShoppingCart(cartItemIdForRemoval);
                setIsRemoveItemDangerModalOpen(false);
              } else {
                alert('cartItemIdForRemoval is null');
              }
            },
            text: t('Remove'),
            disabled: deleteItemFromShoppingCartIsLoading,
          }}
          cancel={{
            onClick: () => {
              setCartItemIdForRemoval(null);
              setIsRemoveItemDangerModalOpen(false);
            },
          }}
          description={
            t('Are you sure you want to remove this Product from the cart') +
            '?'
          }
        />
      </Container>
    </Wrapper>
  );
};

export default ShoppingCartPage;
