export enum MobileMessageTypes {
  CLOSE_LOGYX = 'CLOSE_LOGYX',
}

export interface IMobileMessage {
  type: MobileMessageTypes;
  payload?: string | object;
}

export const postMobileMessage = (message: IMobileMessage) => {
  (window as any).ReactNativeWebView?.postMessage(JSON.stringify(message));
};
