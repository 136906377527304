import FormImagesSwiper from 'components/FormImagesSwiper/FormImagesSwiper';
import {
  StickyContainer,
  SmallFormImagesWrapper,
  SwiperWrapper,
  DescriptionLabel,
  DescriptionsWrapper,
  FormImagesContainer,
} from './FormImages.styled';
import { IImageSection } from './hooks';
import { useState } from 'react';
import { getSmallImageIndices } from './helpers';
import SmallFormImage from 'components/SmallFormImage/SmallFormImage';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { PreviewImageModal } from 'components/Modals/PreviewImageModal/PreviewImageModal';

interface IFormImagesProps {
  visibleImages: IImageSection[];
  singleImage?: boolean;
}

const FormImages = ({ visibleImages, singleImage }: IFormImagesProps) => {
  const [isPreviewImageModalOpen, setIsPreviewImageModalOpen] =
    useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState<IImageSection | null>(null);

  const [activeSlide, setActiveSlide] = useState<number>(0);

  const smallImageIndices = getSmallImageIndices(
    activeSlide,
    visibleImages.length
  );

  const general = useSelector(
    (state: IRootReducerState) => state.modelInfo.general
  );

  const descriptions: string[] = general?.descriptions || [];

  return (
    <FormImagesContainer
      isVisible={visibleImages.length}
      singleImage={singleImage}
    >
      <StickyContainer>
        <SwiperWrapper>
          <FormImagesSwiper
            singleImage={singleImage}
            images={visibleImages}
            activeSlide={activeSlide}
            setActiveSlide={setActiveSlide}
            setPreviewImage={(image: IImageSection | null) => {
              setPreviewImage(image);
              setIsPreviewImageModalOpen(true);
            }}
          />
        </SwiperWrapper>
        {visibleImages.length && !singleImage ? (
          <SmallFormImagesWrapper>
            {smallImageIndices?.map((index: number) => (
              <SmallFormImage
                image={visibleImages[index]}
                key={index}
                onClick={() => setActiveSlide(index)}
                singleImage={singleImage}
              />
            ))}
          </SmallFormImagesWrapper>
        ) : null}
        {/* {!!descriptions.length && ( // Temporarily removed 
          <DescriptionsWrapper>
            {descriptions.map((description: string, index: number) => (
              <DescriptionLabel key={index}>{description}</DescriptionLabel>
            ))}
          </DescriptionsWrapper>
        )} */}
      </StickyContainer>
      <PreviewImageModal
        isOpen={isPreviewImageModalOpen}
        setIsOpen={setIsPreviewImageModalOpen}
        image={previewImage}
      />
    </FormImagesContainer>
  );
};

export default FormImages;
