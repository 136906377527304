import {
  CircleSkeleton,
  Container,
  Label,
  LeftPart,
  MidPart,
  RightPart,
  StyledSkeleton,
} from '../VersionRow.styled';
import { Skeleton } from '@mui/material';

interface IVersionRowSkeletonProps {
  index: number;
  opacityCoeficient?: number;
  withoutLine: boolean;
}

const VersionRowSkeleton = ({
  index,
  opacityCoeficient = 0.2,
  withoutLine,
}: IVersionRowSkeletonProps) => {
  return (
    <Container
      opacityIndex={index}
      opacityCoeficient={opacityCoeficient}
      withoutLine={withoutLine}
    >
      <LeftPart>
        <Skeleton width={'150rem'} animation="wave" />
        <Skeleton width={'80rem'} animation="wave" />
      </LeftPart>
      <MidPart>
        <Skeleton width={'200rem'} animation="wave" />
      </MidPart>
      <RightPart>
        <Label>
          <Skeleton width={'70rem'} animation="wave" />
        </Label>
        <Label>
          <Skeleton width={'70rem'} animation="wave" />
        </Label>
        <StyledSkeleton width={'100rem'} height={'35rem'} animation="wave" />
        <CircleSkeleton
          animation="wave"
          variant="rounded"
          height={'26rem'}
          width={'26rem'}
        />
      </RightPart>
    </Container>
  );
};

export default VersionRowSkeleton;
