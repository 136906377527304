import {
  ButtonWrapper,
  Container,
  HorizontalLine,
  QuestionKeyLabel,
  SubTitle,
  Title,
  TitleWrapper,
} from './EditConfigurationValidationWarningsModal.styled';
import { Modal } from '../Modal/Modal';
import Button from 'components/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { resetEditConfigurationValidationWarnings } from 'store/Common/actions/common';
import { useTranslation } from 'react-i18next';

export function EditConfigurationValidationWarningsModal() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const editConfigurationValidationWarnings = useSelector(
    (state: IRootReducerState) =>
      state.commonInfo.editConfigurationValidationWarnings
  );

  const isOpen =
    editConfigurationValidationWarnings?.missingQuestionsKeys?.length > 0 ||
    editConfigurationValidationWarnings?.extraQuestionsKeys?.length > 0 ||
    editConfigurationValidationWarnings?.matchingQuestionsWithInvalidOutputsKeys
      ?.length > 0;

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => dispatch(resetEditConfigurationValidationWarnings())}
      modalStyle={{ margin: 'auto', width: '772rem' }}
    >
      <Container>
        <TitleWrapper>
          <Title>
            {t(
              'There were some differences between the versions of the same model'
            )}
          </Title>
        </TitleWrapper>
        {editConfigurationValidationWarnings?.missingQuestionsKeys?.length ? (
          <>
            <SubTitle>
              {t('The following fields cannot be found in the new version:')}
            </SubTitle>
            {editConfigurationValidationWarnings.missingQuestionsKeys.map(
              (key: string, index: number) => (
                <QuestionKeyLabel key={index}>{key}</QuestionKeyLabel>
              )
            )}
            {editConfigurationValidationWarnings?.extraQuestionsKeys?.length ||
            editConfigurationValidationWarnings
              .matchingQuestionsWithInvalidOutputsKeys?.length ? (
              <HorizontalLine />
            ) : null}
          </>
        ) : null}
        {editConfigurationValidationWarnings?.extraQuestionsKeys?.length ? (
          <>
            <SubTitle>
              {t(
                'The following fields cannot be found in the original version:'
              )}
            </SubTitle>
            {editConfigurationValidationWarnings.extraQuestionsKeys.map(
              (key: string, index: number) => (
                <QuestionKeyLabel key={index}>{key}</QuestionKeyLabel>
              )
            )}
            {editConfigurationValidationWarnings
              .matchingQuestionsWithInvalidOutputsKeys?.length && (
              <HorizontalLine />
            )}
          </>
        ) : null}
        {editConfigurationValidationWarnings
          ?.matchingQuestionsWithInvalidOutputsKeys?.length ? (
          <>
            <SubTitle>
              {t('The following inputs are not valid in the new version:')}
            </SubTitle>
            {editConfigurationValidationWarnings.matchingQuestionsWithInvalidOutputsKeys.map(
              (key: string, index: number) => (
                <QuestionKeyLabel key={index}>{key}</QuestionKeyLabel>
              )
            )}
          </>
        ) : null}
        <ButtonWrapper>
          <Button
            secondary
            label={t('Continue')}
            width="220rem"
            onClick={() => dispatch(resetEditConfigurationValidationWarnings())}
          />
        </ButtonWrapper>
      </Container>
    </Modal>
  );
}
