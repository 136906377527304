import { COLORS } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled, { css } from 'styled-components';

interface StyledProps {
  isMyModels: boolean;
}

export const Title = styled.label`
  font-weight: 600;
  font-size: 50rem;
  line-height: 68rem;
  font-weight: 300;
  color: ${COLORS.BLACK};

  ${respondTo.smallLaptop`
    font-size: 40rem;
    line-height: 50rem;
  `}

  ${respondTo.smallTablet`
    font-size: 30rem;
  `}
`;

export const FirstRow = styled.div`
  margin: 50rem 50rem 20rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  ${respondTo.smallLaptop`
    flex-direction: column;
    align-items: flex-start;
    margin: 50rem 30rem 30rem;
  `}

  ${respondTo.smallTablet`
    margin: 30rem 15rem 20rem;
  `}
`;

export const LeftSide = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15rem;

  ${respondTo.smallLaptop`
    margin-bottom: 20rem;
  `};

  ${(props: StyledProps) =>
    !props.isMyModels &&
    css`
      &:hover {
        ${Title} {
          cursor: pointer;
          text-decoration: underline;
          text-decoration-thickness: 1rem;
        }
      }
    `}
`;

export const RightSide = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30rem;

  ${respondTo.smallTablet`
    gap: 15rem;
    flex-wrap: wrap;
  `}

  ${respondTo.bigPhone`
    width: 100%;
  `}
`;

export const InputWrapper = styled.div`
  width: 300rem;

  ${respondTo.bigPhone`
    width: 100%;
  `}
`;

export const SelectWrapper = styled.div`
  width: 200rem;

  ${respondTo.bigPhone`
    width: 100%;
  `}
`;
