import { COLORS, marginSm, marginXs } from 'assets/styled';
import styled, { css } from 'styled-components';
import { IBreakpointFlags } from 'utils/hooks/useBreakpointFlag';
import { getModelConfigurationHeightBasedOnBreakpointFlags } from './helpers';
import { respondTo } from 'assets/styled/breakpoints';

interface StyledProps {
  isExpanded: boolean;
  numberOfModelsInCategory: number;
  withoutLine: boolean;
  breakpointFlags: IBreakpointFlags;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  ${(props: StyledProps) =>
    !props.withoutLine &&
    css`
      border-bottom: 1rem solid ${COLORS.BORDER};
    `};
`;

export const CategoryNameLabel = styled.label`
  font-weight: 400;
  font-size: 18rem;
  line-height: 24.5rem;
  color: ${COLORS.BLACK};
`;

export const CategoryNameAndChevronWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 84rem;
  cursor: pointer;
  user-select: none;
`;

export const ChevronImg = styled.img`
  width: 30rem;
  height: 30rem;
  margin-bottom: 5rem;
  margin-left: 8rem;
  cursor: pointer;
  ${(props: StyledProps) =>
    props.isExpanded &&
    css`
      transform: rotate(180deg);
    `}
`;

export const CATEGORY_EXPAND_TRANSITION_TIME = 300;

export const ModelConfigurationsWrapper = styled.div`
  max-height: ${(props: StyledProps) => {
    const totalConfigurationsHeightInt =
      props.numberOfModelsInCategory *
      getModelConfigurationHeightBasedOnBreakpointFlags(props.breakpointFlags);
    return props.isExpanded
      ? css`
          ${totalConfigurationsHeightInt}rem
        `
      : '0';
  }};
  overflow: hidden;
  transition: max-height ${CATEGORY_EXPAND_TRANSITION_TIME}ms ease-in-out;
  margin-left: ${marginSm};
  padding-right: ${marginSm};
  ${respondTo.bigPhone`
    margin-left: ${marginXs};
    padding-right: ${marginXs};
  `}
`;
