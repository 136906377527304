import React from 'react';
import * as Sentry from '@sentry/react';
import { EnvironmentType, config } from 'config';

interface ISentryProviderProps {
  children: React.ReactNode;
}

if (config.sentry.dsnUrl && config.environment !== EnvironmentType.LOCAL) {
  Sentry.init({
    dsn: config.sentry.dsnUrl,
    environment: config.environment,
    integrations: [Sentry.browserTracingIntegration()],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/api-dev.logyx.it/,
      /^https:\/\/api-staging.logyx.it/,
      /^https:\/\/api.logyx.it/,
    ],
  });
}

const SentryProvider = ({ children }: ISentryProviderProps) => {
  return <>{children}</>;
};

export default SentryProvider;
