import {
  Container,
  FirstRow,
  InputWrapper,
  LoaderWrapper,
  NoContentLabel,
  RightSide,
  Title,
  ViewAllModelsLabel,
  ViewAllModelsWrapper,
} from './ModelsPage.styled';
import { useGetSuppliersInfinite, useSelectedViewType } from './hooks';
import { useTranslation } from 'react-i18next';
import Input from 'components/Input/Input';
import { useEffect, useRef, useState } from 'react';
import ViewTypePicker, {
  ViewType,
} from 'components/ViewTypePicker/ViewTypePicker';
import ModelsPageList from './ModelsPageList/ModelsPageList';
import ModelsPageGrid from './ModelPageGrid/ModelPageGrid';
import Icon from 'components/Icon/Icon';
import { useNavigate } from 'react-router-dom';
import { SUPPLIERS_PER_PAGE } from './constants';
import { useLoadNextPage } from 'utils/hooks/useLoadNextPage';
import Loader from 'components/Loader/Loader';
import { useDebounce } from 'utils/hooks/useDebounce';
import RecentlyViewedSection from './RecentlyViewedSection/RecentlyViewedSection';
import FavoritesSection from './FavoritesSection/FavoritesSection';
import { RoutesConfig } from 'navigation/routes';
import { CaretRight, MagnifyingGlass as Search } from '@phosphor-icons/react';
import { usePageTitle } from 'utils/hooks/usePageTitle';
import { ISupplier } from 'types/Supplier.types';
import { getDataFromPages } from 'utils/getDataFromPages';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';
import { COLORS } from 'assets/styled';

const ModelsPage = () => {
  const { t } = useTranslation();
  const loadMoreRef = useRef<HTMLDivElement>(null);
  const [searchBy, setSearchBy] = useState<string>('');
  const debouncedSearchBy = useDebounce(searchBy);

  const { isMidTablet } = useBreakpointFlag();

  useEffect(() => {
    if (isMidTablet && selectedViewType === ViewType.LIST) {
      setSelectedViewType(ViewType.GRID);
    }
  }, [isMidTablet]);

  const { selectedViewType, setSelectedViewType } = useSelectedViewType();

  const navigate = useNavigate();

  usePageTitle('Models');

  const {
    data: suppliersData,
    isLoading: isLoadingSuppliers,
    isFetching: isFetchingSuppliers,
    hasNextPage,
    fetchNextPage,
  } = useGetSuppliersInfinite(SUPPLIERS_PER_PAGE, debouncedSearchBy);

  const suppliers: ISupplier[] = getDataFromPages(
    suppliersData?.pages,
    'suppliers'
  );

  useLoadNextPage(loadMoreRef, hasNextPage, fetchNextPage, isFetchingSuppliers);

  return (
    <Container>
      <FirstRow>
        <Title>{t('Models')}</Title>
        <RightSide>
          <InputWrapper>
            <Input
              placeholder={t('Search suppliers')}
              width={'100%'}
              onChange={(e: any) => setSearchBy(e.target.value)}
              icon={Search}
              wrapperStyles={{ width: '100%' }}
            />
          </InputWrapper>
          {!isMidTablet && (
            <ViewTypePicker
              selectedViewType={selectedViewType}
              setSelectedViewType={setSelectedViewType}
            />
          )}
          <ViewAllModelsWrapper
            onClick={() => navigate(RoutesConfig.AllModels.fullPath)}
          >
            <ViewAllModelsLabel>{t('View all models')}</ViewAllModelsLabel>
            <Icon
              svg={CaretRight}
              color={COLORS.BLACK}
              size={15}
              weight="bold"
              onClick={() => null}
            />
          </ViewAllModelsWrapper>
        </RightSide>
      </FirstRow>
      <RecentlyViewedSection selectedViewType={selectedViewType} />
      <FavoritesSection selectedViewType={selectedViewType} />
      {selectedViewType === ViewType.GRID ? (
        <ModelsPageGrid suppliers={suppliers} />
      ) : (
        <ModelsPageList suppliers={suppliers} />
      )}
      {isLoadingSuppliers ? (
        <LoaderWrapper>
          <Loader position="relative" />
        </LoaderWrapper>
      ) : suppliers.length ? null : (
        <NoContentLabel>{t('No suppliers found')}</NoContentLabel>
      )}
      <div ref={loadMoreRef} style={{ height: '1px' }} />
    </Container>
  );
};

export default ModelsPage;
