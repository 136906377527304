import { useTranslation } from 'react-i18next';
import {
  Container,
  FieldDelete,
  FieldDeleteWrapper,
  FieldEdit,
  FieldEditWrapper,
  FieldIconsWrapper,
  FieldName,
  FieldNameWrapper,
  MatrixName,
  Row,
} from './Pricing.styled';
import Icon from 'components/Icon/Icon';
import { COLORS } from 'assets/styled';
import { Info, PencilSimple, Trash } from '@phosphor-icons/react';
import Button from 'components/Button/Button';
import { ICustomModel } from 'types/Builder.types';
import { Dispatch, SetStateAction, useState } from 'react';
import ImportExportPriceModal from './Modals/ImportExportPriceModal/ImportExportPriceModal';
import ImportPriceModal from './Modals/ImportPriceModal/ImportPriceModal';
import EditPriceModal from './Modals/EditPriceModal/EditPriceModal';
import { checkFieldTypes } from './helpers';
import Tooltip from 'components/Tooltip/Tooltip';
import { DangerModal } from 'components/Modals/DangerModal/DangerModal';

interface IProps {
  isAccordionOpen: boolean;
  model: ICustomModel;
  setModel: Dispatch<SetStateAction<ICustomModel>>;
}

const Pricing = ({ isAccordionOpen, model, setModel }: IProps) => {
  const { t } = useTranslation();
  const [importModalOpen, setImportModalOpen] = useState<boolean>(false);
  const [editPriceModalOpen, setEditPriceModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [importExportModalOpen, setImportExportModalOpen] =
    useState<boolean>(false);

  const deleteMatrix = () => {
    setModel((prevModel) => ({
      ...prevModel,
      price_matrices: [
        {
          title: '',
          x_axis_id: '',
          x_axis_min: null,
          y_axis_id: '',
          y_axis_min: null,
          matrix: null,
          filetype: null,
        },
      ],
    }));
  };

  return (
    <>
      {isAccordionOpen && (
        <Container>
          <Row>
            <FieldName>
              {t('Import Price Matrix')}
              <Tooltip content={t('Import the price matrix to use')}>
                <Icon
                  svg={Info}
                  color={COLORS.GREEN}
                  size={20}
                  weight="regular"
                />
              </Tooltip>
            </FieldName>
            <Tooltip
              content={
                !checkFieldTypes(model.input_fields)
                  ? t(
                      'You need to add at least two input fields (types: Radio, List, Number)'
                    )
                  : undefined
              }
            >
              <Button
                primary
                width="150rem"
                onClick={() => setImportExportModalOpen(true)}
                label={t('Import')}
                disabled={!checkFieldTypes(model.input_fields)}
              />
            </Tooltip>
          </Row>
          <Row>
            <FieldNameWrapper>
              <FieldName>{t('Price Matrix name')}</FieldName>
              <MatrixName>{model.price_matrices?.[0]?.title}</MatrixName>
            </FieldNameWrapper>
            <FieldIconsWrapper>
              <FieldEditWrapper>
                <Icon
                  svg={PencilSimple}
                  color={COLORS.GREEN}
                  size={20}
                  weight="regular"
                  onClick={() => setEditPriceModalOpen(true)}
                  disabled={!model.price_matrices?.[0].title.length}
                />
                <FieldEdit
                  onClick={() => {
                    if (model.price_matrices?.[0].title.length) {
                      setEditPriceModalOpen(true);
                    }
                  }}
                  isDisabled={!model.price_matrices?.[0].title.length}
                >
                  {t('Edit')}
                </FieldEdit>
              </FieldEditWrapper>
              <FieldDeleteWrapper>
                <Icon
                  svg={Trash}
                  color={COLORS.RED_100}
                  size={20}
                  weight="regular"
                  onClick={() => setEditPriceModalOpen(true)}
                  disabled={!model.price_matrices?.[0].title.length}
                />
                <FieldDelete
                  onClick={() => {
                    if (model.price_matrices?.[0].title.length) {
                      setIsDeleteModalOpen(true);
                    }
                  }}
                  isDisabled={!model.price_matrices?.[0].title.length}
                >
                  {t('Delete')}
                </FieldDelete>
              </FieldDeleteWrapper>
            </FieldIconsWrapper>
          </Row>

          {importExportModalOpen && (
            <ImportExportPriceModal
              isOpen={importExportModalOpen}
              setIsOpen={setImportExportModalOpen}
              onNext={() => setImportModalOpen(true)}
              setModel={setModel}
              model={model}
            />
          )}

          {importModalOpen && (
            <ImportPriceModal
              isOpen={importModalOpen}
              setIsOpen={setImportModalOpen}
              model={model}
              setModel={setModel}
              finishImport={() => setImportExportModalOpen(false)}
            />
          )}

          {editPriceModalOpen && (
            <EditPriceModal
              key={`${model}`}
              isOpen={editPriceModalOpen}
              setIsOpen={setEditPriceModalOpen}
              model={model}
              setModel={setModel}
            />
          )}

          <DangerModal
            isOpen={isDeleteModalOpen}
            setIsOpen={setIsDeleteModalOpen}
            title={t('Price matrix')}
            submit={{
              onClick: () => {
                deleteMatrix();
                setIsDeleteModalOpen(false);
              },
              text: t('Confirm'),
              disabled: false,
            }}
            cancel={{
              onClick: () => {
                setIsDeleteModalOpen(false);
              },
            }}
            description={t('Are you sure you want to delete price matrix?')}
          />
        </Container>
      )}
    </>
  );
};

export default Pricing;
