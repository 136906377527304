import { useEffect, useRef, useState } from 'react';
import Button from 'components/Button/Button';
import { useTranslation } from 'react-i18next';
import { Form, Formik, FormikProps } from 'formik';
import { Input } from 'components/Input/InputFormik';
import * as Yup from 'yup';
import {
  ButtonWrapper,
  ChangeLabel,
  Container,
  ErrorMessage,
  LabelValue,
  RelativeWrapper,
  inputWrapperStyling,
} from './SettingsRowForm.styled';
import { SettingsRowType } from '../SettingsRow';
import { getFieldnameBasedOnType } from './helpers';
import SmallLoader from 'components/SmallLoader/SmallLoader';

export interface ISettingsRowFormProps {
  type: SettingsRowType;
  initialData: string;
  validationSchema: Yup.ObjectSchema<any>;
  onConfirm: (data: any) => void;
  isLoading: boolean;
}

export function SettingsRowForm({
  type,
  initialData,
  validationSchema,
  onConfirm,
  isLoading,
}: ISettingsRowFormProps) {
  const { t } = useTranslation();
  const fieldName: string = getFieldnameBasedOnType(type);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const formikRef = useRef<FormikProps<any> | any>();
  const [areAllFieldsTouched, setAreAllFieldsTouched] =
    useState<boolean>(false);

  const handleSubmit = () => {
    if (formikRef.current.isValid) {
      setIsExpanded(false);
      onConfirm(formikRef.current.values[fieldName]);
    } else if (!areAllFieldsTouched) {
      setAreAllFieldsTouched(true);
    }
  };

  useEffect(() => {
    if (!isExpanded) {
      setAreAllFieldsTouched(false);
    }
  }, [isExpanded]);

  return (
    <Container>
      {isExpanded ? (
        <>
          <Formik
            innerRef={formikRef}
            initialValues={{
              [fieldName]: initialData,
            }}
            validationSchema={validationSchema}
            validateOnChange={true}
            validateOnMount={true}
            validateOnBlur={true}
            onSubmit={() => {
              //
            }}
          >
            {({ errors }) => {
              return (
                <Form>
                  <RelativeWrapper>
                    <Input
                      name={fieldName}
                      placeholder={type}
                      height={'41rem'}
                      wrapperStyles={inputWrapperStyling}
                    />
                    <ErrorMessage>
                      {areAllFieldsTouched ? errors[fieldName] : null}
                    </ErrorMessage>
                  </RelativeWrapper>
                </Form>
              );
            }}
          </Formik>
          <ButtonWrapper>
            <Button
              secondary
              label={t('Cancel')}
              width={'100rem'}
              onClick={() => {
                setIsExpanded(false);
              }}
            />
            <Button
              primary
              label={t('Confirm')}
              width={'100rem'}
              onClick={() => handleSubmit()}
            />
          </ButtonWrapper>
        </>
      ) : (
        <>
          <LabelValue>{initialData}</LabelValue>
          <ButtonWrapper>
            {isLoading ? (
              <SmallLoader />
            ) : (
              <ChangeLabel onClick={() => setIsExpanded(true)}>
                {t('Change')}
              </ChangeLabel>
            )}
          </ButtonWrapper>
        </>
      )}
    </Container>
  );
}
