import { COLORS, paddingMd } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

export const Container = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${paddingMd};

  ${respondTo.bigPhone`
    padding: 15rem;
  `}
`;

export const CancelImage = styled.img`
  position: absolute;
  width: 30rem;
  height: 30rem;
  top: 20rem;
  right: 20rem;
  cursor: pointer;

  ${respondTo.bigPhone`
    top: 15rem;
    right: 15rem;
  `}
`;

export const Title = styled.label`
  padding: 0 30rem 30rem;
  font-weight: 400;
  font-size: 24rem;
  color: ${COLORS.BLACK};
  text-align: center;

  ${respondTo.smallTablet`
    font-size: 20rem;
  `}

  ${respondTo.bigPhone`
    font-size: 18rem;
    padding-top: 10rem;
  `}
`;
