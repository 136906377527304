import { ISupplier } from 'types/Supplier.types';
import {
  AbsoluteCircleWrapper,
  Container,
  SupplierIdLabel,
  NameLabel,
  RowCenter,
  imageStyle,
} from './SupplierCard.styled';
import { RoutesConfig } from 'navigation/routes';
import { CaretRight } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';
import Icon from 'components/Icon/Icon';
import { useNavigateWithQuery } from 'utils/hooks/useNavigateWithQuery';
import {
  AdaptiveImage,
  AdaptiveImageType,
} from 'components/AdaptiveImage/AdaptiveImage';
import { IDealerPanelSupplierDataFromNavigationState } from 'pages/DealerPanel/DealerPanelModelsPage/hooks';

interface ISupplierCardProps {
  supplier: ISupplier;
}

const SupplierCard = ({ supplier }: ISupplierCardProps) => {
  const navigate = useNavigateWithQuery();

  const handleOnClick = () => {
    const supplierData: IDealerPanelSupplierDataFromNavigationState = {
      supplierId: supplier.id,
      supplierName: supplier.name,
      supplierHasSystemIntegration: supplier.has_system_integration,
    };
    navigate(RoutesConfig.DealerPanelModels.fullPath, {
      state: supplierData,
    });
  };

  return (
    <Container
      data-testid={`supplier-card-${supplier.id}`}
      onClick={handleOnClick}
    >
      <AdaptiveImage
        imageStyle={imageStyle}
        onClick={() => null}
        withoutTransformator
        imageUrl={supplier.logo_url}
        type={AdaptiveImageType.SUPPLIER}
        absoluteComponent={
          <AbsoluteCircleWrapper>
            <SupplierIdLabel>{supplier.id}</SupplierIdLabel>
          </AbsoluteCircleWrapper>
        }
      />
      <RowCenter>
        <NameLabel>
          {supplier.name ? supplier.name : 'Unnamed supplier company'}
        </NameLabel>
        <Icon svg={CaretRight} color={COLORS.GREEN} onClick={() => null} />
      </RowCenter>
    </Container>
  );
};
export default SupplierCard;
