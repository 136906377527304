import { COLORS } from 'assets/styled';
import styled from 'styled-components';
import { css } from 'styled-components';

interface StyledProps {
  imageStyle: boolean;
  containerStyle: boolean;
  hasOnClick: boolean;
}

export const RelativeContainer = styled.div`
  position: relative;
  height: fit-content;
  ${(props: StyledProps) => props.containerStyle}
`;

export const SkeletonWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-start;
  height: 180rem;
  ${(props: StyledProps) =>
    props.hasOnClick
      ? css`
          cursor: pointer;
        `
      : css`
          cursor: default;
        `};
  ${(props: StyledProps) => props.containerStyle}
`;

export const Image = styled.img`
  background-color: ${COLORS.BACKGROUND};
  height: 100%;
  width: 100%;
  ${(props: StyledProps) =>
    props.hasOnClick
      ? css`
          cursor: pointer;
        `
      : css`
          cursor: default;
        `};
  ${(props: StyledProps) => props.imageStyle}
`;
