import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import {
  disableOrderSupplierCompanyModel,
  disableQuerySupplierCompanyModel,
  enableOrderSupplierCompanyModel,
  enableQuerySupplierCompanyModel,
} from 'services/SupplierCompanyModelsService';
import { LogyxError, getToastErrorMessage } from 'services/api/errors';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';

export const useEnableOrderSupplierCompanyModel = () => {
  const { t } = useTranslation();
  return useMutation(
    (supplierCompanyModelId: number) => {
      return enableOrderSupplierCompanyModel(supplierCompanyModelId);
    },
    {
      onSuccess: () => {
        toast.success(t('Enabled model ordering'));
      },
      onError: (error: LogyxError) => {
        toast.error(getToastErrorMessage(error));
      },
      mutationKey: ReactMutationKeys.ENABLE_ORDER_SUPPLIER_COMPANY_MODEL,
    }
  );
};

export const useDisableOrderSupplierCompanyModel = () => {
  const { t } = useTranslation();
  return useMutation(
    (supplierCompanyModelId: number) => {
      return disableOrderSupplierCompanyModel(supplierCompanyModelId);
    },
    {
      onSuccess: () => {
        toast.success(t('Disabled model ordering'));
      },
      onError: (error: LogyxError) => {
        toast.error(getToastErrorMessage(error));
      },
      mutationKey: ReactMutationKeys.DISABLE_ORDER_SUPPLIER_COMPANY_MODEL,
    }
  );
};

export const useEnableQuerySupplierCompanyModel = () => {
  const { t } = useTranslation();
  return useMutation(
    (supplierCompanyModelId: number) => {
      return enableQuerySupplierCompanyModel(supplierCompanyModelId);
    },
    {
      onSuccess: () => {
        toast.success(t('Enabled model querying'));
      },
      onError: (error: LogyxError) => {
        toast.error(getToastErrorMessage(error));
      },
      mutationKey: ReactMutationKeys.ENABLE_QUERY_SUPPLIER_COMPANY_MODEL,
    }
  );
};

export const useDisableQuerySupplierCompanyModel = () => {
  const { t } = useTranslation();
  return useMutation(
    (supplierCompanyModelId: number) => {
      return disableQuerySupplierCompanyModel(supplierCompanyModelId);
    },
    {
      onSuccess: () => {
        toast.success(t('Disabled model querying'));
      },
      onError: (error: LogyxError) => {
        toast.error(getToastErrorMessage(error));
      },
      mutationKey: ReactMutationKeys.DISABLE_QUERY_SUPPLIER_COMPANY_MODEL,
    }
  );
};
