import styled from 'styled-components';
import { Modal } from '@mui/material';
import { COLORS } from 'assets/styled';

interface StyledProps {
  width: number;
  height: number;
}

export const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalContent = styled.div`
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 90vw;
  height: 90vh;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 8rem;
  overflow: hidden;
`;

export const ImageContainer = styled.div<{ width: number; height: number }>`
  position: relative;
  width: ${(props: StyledProps) => props.width}rem;
  height: ${(props: StyledProps) => props.height}rem;
`;

export const ErrorMessage = styled.label`
  color: ${COLORS.STRICT_WHITE};
`;
