import {
  PriceLabel,
  PriceLabelValue,
  ContainerAbsolute,
  TooltipPriceLabelWrapper,
  GridContainer,
  Margin34,
} from './PricingSection.styled';
import { IFormattedPricingItem } from '../hooks';
import { useShouldShowSpecificPrices } from './hooks';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from 'components/Tooltip/Tooltip';
import Icon from 'components/Icon/Icon';
import { Info } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';

interface IPricingSectionProps {
  formattedPrices: IFormattedPricingItem[];
}

const PricingSection = ({ formattedPrices }: IPricingSectionProps) => {
  const { showNet, showGross, isUsingSupplierSystemDiscounts } =
    useShouldShowSpecificPrices();

  const { t } = useTranslation();

  return (
    <ContainerAbsolute>
      <GridContainer areBothPriceTypesShown={showNet && showGross}>
        <div />
        {showGross && <PriceLabel>{t('Gross')}</PriceLabel>}
        {showNet && (
          <PriceLabel>
            {isUsingSupplierSystemDiscounts ? t('Gross') : t('Net')}
          </PriceLabel>
        )}
        {formattedPrices.map(
          (formattedPrice: IFormattedPricingItem, index: number) => {
            const shouldShowPricingItem =
              (showGross && formattedPrice.grossItem?.value) ||
              (showNet && formattedPrice.netItem?.value);

            return shouldShowPricingItem ? (
              <Fragment key={index}>
                <TooltipPriceLabelWrapper>
                  {formattedPrice.netItem.description ? (
                    <>
                      <Tooltip
                        content={
                          formattedPrice.netItem.description
                            ? formattedPrice.netItem.description
                            : t('No additional info')
                        }
                      >
                        <Icon svg={Info} size={20} color={COLORS.GREEN} />
                      </Tooltip>
                    </>
                  ) : (
                    <Margin34 />
                  )}
                  <PriceLabel>
                    {`${
                      formattedPrice.name
                        ? formattedPrice.name
                        : formattedPrice.key.replace('P_', '')
                    }:`}
                  </PriceLabel>
                </TooltipPriceLabelWrapper>
                {showGross && (
                  <PriceLabelValue>
                    {formattedPrice.grossItem?.value
                      ? '€' + formattedPrice.grossItem?.value?.toFixed(2)
                      : '/'}
                  </PriceLabelValue>
                )}
                {showNet && (
                  <PriceLabelValue>
                    {formattedPrice.netItem?.value
                      ? '€' + formattedPrice.netItem?.value?.toFixed(2)
                      : '/'}
                  </PriceLabelValue>
                )}
              </Fragment>
            ) : null;
          }
        )}
      </GridContainer>
    </ContainerAbsolute>
  );
};

export default PricingSection;
