import {
  ButtonContainer,
  Title,
  TitleContainer,
  TopContainer,
  Description,
  Container,
} from './LeavePageModal.styled';
import Button from 'components/Button/Button';
import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction } from 'react';
import { Level } from '../Modal/type';
import { Modal } from '../Modal/Modal';

interface ILeavePageModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onYes: () => void;
  onNo: () => void;
  onX: () => void;
  description?: string;
  title: string;
  level?: Level;
  disabled?: boolean;
}

export const LeavePageModal = ({
  isOpen,
  setIsOpen,
  onYes,
  onNo,
  onX,
  description,
  title,
  level = 'FIRST',
  disabled,
}: ILeavePageModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onNo && onNo();
        setIsOpen(false);
      }}
      modalStyle={{
        margin: 'auto',
        width: '600rem',
        minWidth: 'auto',
        position: 'fixed',
      }}
      level={level}
      onX={onX}
    >
      <Container>
        <TopContainer>
          <TitleContainer>
            <Title>{title}</Title>
          </TitleContainer>
          <TitleContainer>
            <Description>{description}</Description>
          </TitleContainer>
        </TopContainer>
        <ButtonContainer>
          <Button
            disabled={disabled}
            onClick={() => {
              onNo && onNo();
            }}
            secondary
            width="150rem"
            label={t('No')}
          />
          <Button
            disabled={disabled}
            primary
            width="150rem"
            label={t('Yes')}
            onClick={() => {
              onYes && onYes();
            }}
          />
        </ButtonContainer>
      </Container>
    </Modal>
  );
};
