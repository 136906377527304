import { useLocation, useNavigate } from 'react-router-dom';

export const useNavigateWithQuery = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const navigateWithQuery = (
    path: string,
    options = {},
    queryParamsObj?: Record<string, string>
  ) => {
    const currentParams = new URLSearchParams(location.search);

    // Remove the 'lang' parameter if it exists
    currentParams.delete('lang');

    if (queryParamsObj) {
      Object.entries(queryParamsObj).forEach(([key, value]) => {
        currentParams.set(key, value);
      });
    }

    const newSearch = currentParams.toString();
    const queryString = newSearch ? `?${newSearch}` : '';

    navigate(`${path}${queryString}`, options);
  };

  return navigateWithQuery;
};
