import {
  PasswordInputWrapper,
  EyeWrapper,
  ButtonContainer,
  Label,
  FieldWrapper,
  StyledForm,
} from './RegisterPage.styled';
import { useEffect, useRef, useState } from 'react';
import Button from 'components/Button/Button';
import { useRegister } from './hooks';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AuthWrapper from '../AuthWrapper/AuthWrapper';
import PageNotFound from 'pages/PageNotFound/PageNotFound';
import { COLORS } from 'assets/styled';
import Icon from 'components/Icon/Icon';
import { RoutesConfig } from 'navigation/routes';
import { Field, Formik, FormikProps } from 'formik';
import { registerValidationSchema } from './validation';
import { Input } from 'components/Input/InputFormik';
import i18n from 'providers/i18n/i18n';
import { usePageTitle } from 'utils/hooks/usePageTitle';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';
import { Eye, EyeSlash } from '@phosphor-icons/react';

const RegisterPage = () => {
  const formikRef = useRef<FormikProps<any> | any>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [hidePassword, setHidePassword] = useState<boolean>(true);
  const { isPhone } = useBreakpointFlag();

  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');

  const handleEyeClick = () => {
    setHidePassword(!hidePassword);
  };

  usePageTitle('Register');

  const { mutate: register, isLoading, isSuccess } = useRegister();

  useEffect(() => {
    if (isSuccess) {
      navigate(RoutesConfig.SuccessfulRegistration.fullPath);
    }
  }, [isSuccess]);

  if (!token) {
    return <PageNotFound />;
  }

  const handleEnterKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    const { email, firstName, lastName, password } = formikRef.current.values;
    register({ email, firstName, lastName, password, token });
  };

  const lang = searchParams.get('lang');
  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang);
    }
  }, [lang]);

  return (
    <AuthWrapper title={t('Register')}>
      <Formik
        innerRef={formikRef}
        initialValues={{
          email: '',
          firstName: '',
          lastName: '',
          password: '',
        }}
        validationSchema={registerValidationSchema}
        validateOnChange={true}
        validateOnMount={true}
        validateOnBlur={true}
        onSubmit={() => {
          //
        }}
      >
        {({ setFieldValue }) => {
          return (
            <StyledForm>
              <Label>{t('E-mail')}</Label>
              <FieldWrapper>
                <Field
                  name="email"
                  as={Input}
                  height={'55rem'}
                  placeholder={t('E-mail')}
                  onChange={(str: string) => setFieldValue('email', str)}
                  wrapperStyles={{ width: '100%' }}
                />
              </FieldWrapper>
              <Label>{t('First name')}</Label>
              <FieldWrapper>
                <Field
                  name="firstName"
                  as={Input}
                  height={'55rem'}
                  placeholder={t('First name')}
                  onChange={(str: string) => setFieldValue('firstName', str)}
                  wrapperStyles={{ width: '100%' }}
                />
              </FieldWrapper>
              <Label>{t('Last name')}</Label>
              <FieldWrapper>
                <Field
                  name="lastName"
                  as={Input}
                  height={'55rem'}
                  placeholder={t('Last name')}
                  onChange={(str: string) => setFieldValue('lastName', str)}
                  wrapperStyles={{ width: '100%' }}
                />
              </FieldWrapper>
              <Label>{t('Password')}</Label>
              <PasswordInputWrapper>
                <Field
                  name="password"
                  as={Input}
                  height={'55rem'}
                  placeholder={t('Password')}
                  type={hidePassword ? 'password' : ''}
                  onChange={(str: string) => setFieldValue('password', str)}
                  wrapperStyles={{ width: '100%' }}
                  onKeyDown={handleEnterKeyPress}
                />
                <EyeWrapper onClick={() => handleEyeClick()}>
                  <Icon
                    svg={hidePassword ? Eye : EyeSlash}
                    color={COLORS.BLACK}
                    size={20}
                  />
                </EyeWrapper>
              </PasswordInputWrapper>
            </StyledForm>
          );
        }}
      </Formik>
      <ButtonContainer>
        <Button
          label={t('Continue')}
          primary
          disabled={isLoading}
          width={isPhone ? '100%' : '580rem'}
          height={'55rem'}
          onClick={() => handleSubmit()}
        />
      </ButtonContainer>
    </AuthWrapper>
  );
};

export default RegisterPage;
