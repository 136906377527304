import { AnyAction } from '@reduxjs/toolkit';
import { Dispatch } from 'react';
import { UseMutateFunction } from 'react-query';
import { LogyxError } from 'services/api/errors';
import { ISelectEntitiesDTO } from './hooks';
import LocalStorageService from 'services/LocalStorageService';
import { LOCAL_STORAGE_ACCESS_TOKEN } from 'services/api/constants';
import { setIsLoggedIn } from 'store/User/actions/user';

export const handleSelectEntities = (
  dispatch: Dispatch<AnyAction>,
  selectEntities: UseMutateFunction<
    any,
    LogyxError,
    ISelectEntitiesDTO,
    unknown
  >,
  companyId?: number,
  supplierId?: number
) => {
  const dto: ISelectEntitiesDTO = {
    companyId: companyId,
    supplierId: supplierId,
  };
  selectEntities(dto, {
    onSuccess: async (data) => {
      if (data?.access_token) {
        await LocalStorageService.setItem(
          LOCAL_STORAGE_ACCESS_TOKEN,
          data.access_token
        );
      }
      dispatch(setIsLoggedIn(true));
    },
  });
};
