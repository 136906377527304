import { useMutation } from 'react-query';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { toast } from 'react-toastify';
import { resetPassword } from 'services/AuthService';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { RoutesConfig } from 'navigation/routes';
import { LogyxError, getToastErrorMessage } from 'services/api/errors';

interface IResetPasswordProps {
  newPassword: string;
  confirmPassword: string;
  token: string;
}
export const useResetPassword = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return useMutation(
    (params: IResetPasswordProps) =>
      resetPassword(params.newPassword, params.confirmPassword, params.token),
    {
      onSuccess: () => {
        toast.success(t('Password successfully reset'), {
          toastId: ReactMutationKeys.RESET_PASSWORD,
        });
        navigate(RoutesConfig.Login.fullPath);
      },
      onError: (error: LogyxError) => {
        toast.error(getToastErrorMessage(error));
      },
      mutationKey: ReactMutationKeys.RESET_PASSWORD,
    }
  );
};
