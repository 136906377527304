import { queryClient } from 'index';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { addSupplierUser, deleteSupplierUser } from 'services/SupplierService';
import { LogyxError, getToastErrorMessage } from 'services/api/errors';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';

export const useAddSupplierUser = (supplierId: number) => {
  const { t } = useTranslation();
  return useMutation((email: string) => addSupplierUser(email, supplierId), {
    onError: (error: LogyxError) => {
      toast.error(getToastErrorMessage(error));
    },
    onSuccess: (data: { message: string }) => {
      if (data.message.includes('Registration link sent')) {
        toast.success(t('Registration link sent'));
      } else {
        toast.success(t('Added user to supplier'));
        queryClient.invalidateQueries([ReactQueryKeys.GET_SUPPLIER_USERS]);
        queryClient.invalidateQueries([
          ReactQueryKeys.GET_SUPPLIER_USERS_INFINITE,
        ]);
      }
    },
    mutationKey: ReactMutationKeys.ADD_SUPPLIER_USER,
  });
};

export const useDeleteSupplierUser = (supplierId: number) => {
  const { t } = useTranslation();
  return useMutation(
    (userId: number) => deleteSupplierUser(userId, supplierId),
    {
      onError: (error: LogyxError) => {
        toast.error(getToastErrorMessage(error));
      },
      onSuccess: () => {
        toast.success(t('Removed user from supplier'));
        queryClient.invalidateQueries([ReactQueryKeys.GET_SUPPLIER_USERS]);
        queryClient.invalidateQueries([
          ReactQueryKeys.GET_SUPPLIER_USERS_INFINITE,
        ]);
      },
      mutationKey: ReactMutationKeys.DELETE_SUPPLIER_USER,
    }
  );
};
