import { ButtonContainer, Label } from './SuccessfulRegistrationPage.styled';
import Button from 'components/Button/Button';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AuthWrapper from '../AuthWrapper/AuthWrapper';
import { RoutesConfig } from 'navigation/routes';
import { usePageTitle } from 'utils/hooks/usePageTitle';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';

const SuccessfulRegistrationPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isPhone } = useBreakpointFlag();

  usePageTitle('Successful registration');

  return (
    <AuthWrapper title={t('Successful registration')}>
      <Label>{t('Please log in to continue')}</Label>
      <ButtonContainer>
        <Button
          label={t('Back to Login')}
          primary
          width={isPhone ? '100%' : '580rem'}
          height={'55rem'}
          onClick={() => navigate(RoutesConfig.Login.fullPath)}
        />
      </ButtonContainer>
    </AuthWrapper>
  );
};

export default SuccessfulRegistrationPage;
