import { useMutation } from 'react-query';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { toast } from 'react-toastify';
import { login } from 'services/AuthService';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';

interface IUseLoginDTO {
  email: string;
  password: string;
}
export const useLogin = () => {
  const { t } = useTranslation();
  return useMutation(
    (params: IUseLoginDTO) => login(params.email, params.password),
    {
      onSuccess: (data) => {
        // Success
      },
      onError: (error: AxiosError) => {
        toast.error(t('Invalid credentials'), {
          toastId: ReactMutationKeys.LOGIN,
        });
      },
      onSettled: () => {
        // Finally
      },
      mutationKey: ReactMutationKeys.LOGIN,
    }
  );
};
