import { Container } from './NumberInputArrows.styled';
import Icon from 'components/Icon/Icon';
import { COLORS } from 'assets/styled';
import { CaretDown, CaretUp } from '@phosphor-icons/react';

interface INumberInputArrowsProps {
  onIncrease: any;
  onDecrease: any;
  onMouseEnter: any;
  onMouseLeave: any;
}

const NumberInputArrows = ({
  onIncrease,
  onDecrease,
  onMouseEnter,
  onMouseLeave,
}: INumberInputArrowsProps) => {
  return (
    <Container onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <Icon
        svg={CaretUp}
        size={20}
        onMouseDown={(e: any) => {
          e.preventDefault();
          e.stopPropagation();
          onIncrease(e);
        }}
        color={COLORS.BLACK}
      />
      <Icon
        svg={CaretDown}
        size={20}
        onMouseDown={(e: any) => {
          e.preventDefault();
          e.stopPropagation();
          onDecrease(e);
        }}
        color={COLORS.BLACK}
      />
    </Container>
  );
};

export default NumberInputArrows;
