import {
  CircleInner,
  CircleOuter,
  CircleWhite,
  HalfCircle,
} from './Loader.styled';

interface ILoaderProps {
  pwId?: string;
  position?: string;
}

const Loader = ({ pwId, position = 'fixed' }: ILoaderProps) => {
  return (
    <CircleOuter data-testid={pwId} position={position}>
      <CircleWhite>
        <CircleInner />
        <HalfCircle />
      </CircleWhite>
    </CircleOuter>
  );
};

export default Loader;
