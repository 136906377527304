import { Circle, Container } from './ToggleSwitch.styled';

interface IToggleSwitchProps {
  isChecked: boolean;
  onClick: () => void;
  size?: 'small' | 'large';
  disabled?: boolean;
}

const ToggleSwitch = ({
  isChecked,
  onClick,
  size = 'small',
  disabled,
}: IToggleSwitchProps) => {
  return (
    <Container
      onClick={() => !disabled && onClick()}
      isChecked={isChecked}
      size={size}
      disabled={disabled}
    >
      <Circle isChecked={isChecked} size={size} disabled={disabled} />
    </Container>
  );
};

export default ToggleSwitch;
