import styled, { css } from 'styled-components';
import { COLORS } from 'assets/styled/colors';
import { borderRadiusSm, marginSm, paddingXs } from 'assets/styled';
import { CustomScrollbar, paddingMd, paddingSm } from 'assets/styled/variables';
import { respondTo } from 'assets/styled/breakpoints';

interface StyledProps {
  isExpanded: boolean;
  isLast: boolean;
}

export const Container = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.WHITE};
  margin-top: 75rem;
  right: 40rem;
  top: 10rem;
  padding: ${paddingMd} 20rem;
  padding-bottom: 10rem;
  border-radius: ${borderRadiusSm};
  z-index: 2;
  border: 1rem solid ${COLORS.GREEN};

  min-width: 300rem;

  ${respondTo.smallTablet`
    right: 0;  
  `}

  ${respondTo.smallPhone`
    width: 100%;  
    min-width: auto;
  `}
`;

export const FullNameLabel = styled.label`
  align-self: center;
  max-width: 100%;
  width: 100%;
  display: inline-block;
  word-break: break-word;
  text-align: center;
  margin-bottom: ${marginSm};
  font-weight: 400;
  font-size: 18rem;
  color: ${COLORS.BLACK};
  ${respondTo.bigPhone`
    font-size: 16rem;
  `}
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10rem;
  align-self: center;
  gap: 10rem;
  max-width: 80%;
  width: 100%;
`;

export const OtherOptionLabel = styled.label`
  text-align: left;
  width: 100%;
  font-size: 14rem;
  font-weight: 400;
  color: ${COLORS.BLACK};
  cursor: pointer;
`;

export const SelectLabel = styled.label`
  font-size: 14rem;
  font-weight: 400;
  color: ${COLORS.GREEN};
  cursor: pointer;
`;

export const OtherOptionsWrapper = styled.div`
  ${(props: StyledProps) =>
    props.isExpanded
      ? css`
          max-height: calc(20vh + 40rem); // 40 <= input height + input margin
        `
      : css`
          max-height: 0;
        `}
  transition: max-height 200ms ease-in-out;
  overflow: hidden;
`;

export const OtherOptionsWrapperScrollable = styled.div`
  height: 100%;
  margin-right: 4rem;
  ${CustomScrollbar}
  max-height: 20vh;
`;

export const CurrentSelectionLabel = styled.div`
  color: ${COLORS.GREEN};
  width: 100%;
  font-size: 18rem;
  ${respondTo.bigPhone`
    font-size: 16rem;
  `}
`;

export const OtherOptionsLabel = styled.label`
  font-size: 14rem;
  font-weight: 400;
  text-align: left;
  color: ${COLORS.BLACK};
  width: 100%;
  cursor: pointer;
`;

export const RowCenterSpaceBetween = styled.div`
  margin-top: 10rem;
  margin-bottom: 10rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

export const chevronRotateStyle = css`
  transform: rotate(180deg);
`;

export const OtherOptionsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  ${(props: StyledProps) =>
    !props.isLast &&
    css`
      margin-bottom: 10rem;
    `};
  &:hover {
    ${SelectLabel} {
      text-decoration: underline;
    }
  }
`;

export const NoContentLabel = styled.label`
  font-weight: 300;
  font-size: 14rem;
  margin-top: 10rem;
  margin-bottom: 10rem;
  color: ${COLORS.BLACK};
`;

export const NoContentLabelWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const inputWrapperStyling = css`
  height: 30rem;
  margin-bottom: 10rem;
  width: 100%;
`;

export const ToggleDarkModeWrapperAbsolute = styled.div`
  position: absolute;
  top: 10rem;
  right: 10rem;
`;
