import { IQuestion } from 'types/Question.types';
import { SET_TABLE_MODAL_DATA, CLOSE_TABLE_MODAL } from '../tableModal';

export const closeTableModal = () => ({
  type: CLOSE_TABLE_MODAL,
});

export const setTableModalData = (question: IQuestion) => ({
  type: SET_TABLE_MODAL_DATA,
  payload: { question },
});
