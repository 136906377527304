import { SortOrder } from 'components/Table/types';

export const sortOptions = [
  {
    label: 'Status Published',
    value: { field: 'state_id', order: SortOrder.DESC },
  },
  {
    label: 'Status Draft',
    value: { field: 'state_id', order: SortOrder.ASC },
  },
  {
    label: 'First updated',
    value: { field: 'updated_at', order: SortOrder.ASC },
  },
  {
    label: 'Last updated',
    value: { field: 'updated_at', order: SortOrder.DESC },
  },
  {
    label: 'Version number (old - new)',
    value: { field: 'number', order: SortOrder.ASC },
  },
  {
    label: 'Version number (new - old)',
    value: { field: 'number', order: SortOrder.DESC },
  },
  { label: 'Active', value: { field: 'active', order: SortOrder.DESC } },
  {
    label: 'Inactive',
    value: { field: 'active', order: SortOrder.ASC },
  },
  {
    label: 'Locked',
    value: { field: 'locked_by_user_id', order: SortOrder.DESC },
  },
  {
    label: 'Unlocked',
    value: { field: 'locked_by_user_id', order: SortOrder.ASC },
  },
];
