import {
  RESET_EDIT_CONFIGURATION_VALIDATION_WARNINGS,
  SET_APP_MODE,
  SET_EDIT_CONFIGURATION_VALIDATION_WARNINGS,
  SET_IS_DARK_MODE,
  SET_IS_MOBILE_APP,
  SET_QUERY_PARAMS_PROCESSED,
} from 'store/constants';
import {
  AppMode,
  EditConfigurationValidationWarnings,
} from '../reducers/common';

export const setEditConfigurationValidationWarnings = (
  editConfigurationValidationWarnings: EditConfigurationValidationWarnings
) => ({
  type: SET_EDIT_CONFIGURATION_VALIDATION_WARNINGS,
  payload: editConfigurationValidationWarnings,
});

export const resetEditConfigurationValidationWarnings = () => ({
  type: RESET_EDIT_CONFIGURATION_VALIDATION_WARNINGS,
});

export const setDarkMode = (darkMode: boolean) => ({
  type: SET_IS_DARK_MODE,
  payload: darkMode,
});

export const setAppMode = (appMode: AppMode) => ({
  type: SET_APP_MODE,
  payload: appMode,
});

export const setQueryParamsProcessed = (boolean: boolean) => ({
  type: SET_QUERY_PARAMS_PROCESSED,
  payload: boolean,
});

export const setIsMobileApp = (isMobileApp: boolean) => ({
  type: SET_IS_MOBILE_APP,
  payload: isMobileApp,
});
