import { IBreakpointFlags } from 'utils/hooks/useBreakpointFlag';
import { MODEL_CONFIGURATION_CONTAINER_HEIGHT } from './ModelConfiguration/ModelConfiguration.styled';

// flags
// Used for adjusting ModelConfigurationsWrapper isExpanded transition speed based on the height of the responsive ModelConfiguration container
export const getModelConfigurationHeightBasedOnBreakpointFlags = (
  breakpointFlags: IBreakpointFlags
) => {
  const {
    isSmallPhone,
    isPhone,
    isSmallTablet,
    isMidTablet,
    isBigTablet,
    isSmallLaptop,
    isBigLaptop,
    isDesktop,
    isWideScreen,
  } = breakpointFlags;
  // Values are approximate sizes of a single responsive ModelConfiguration container based on screen size so that the expanding motion of the accordion appears smooth

  // breakpoints.ts
  // desktop: '1920px',
  // bigLaptop: '1440px',
  // smallLaptop: '1345px',
  // bigTablet: '1023px',
  // midTablet: '990px',
  // smallTablet: '767px',
  // bigPhone: '640px',
  // smallPhone: '460px',

  // console.log('-----');
  // console.log('isSmallPhone: ', isSmallPhone);
  // console.log('isPhone: ', isPhone);
  // console.log('isSmallTablet: ', isSmallTablet);
  // console.log('isMidTablet: ', isMidTablet);
  // console.log('isBigTablet: ', isBigTablet);
  // console.log('isSmallLaptop: ', isSmallLaptop);
  // console.log('isBigLaptop: ', isBigLaptop);
  // console.log('isDesktop: ', isDesktop);
  // console.log('isWideScreen: ', isWideScreen);
  // console.log('-----');

  switch (true) {
    case isSmallPhone:
      return 1020;
    case isPhone:
      return 900;
    case isSmallTablet:
      return 810;
    case isMidTablet:
      return 550;
    case isBigTablet:
      return 550;
    case isSmallLaptop:
      return 500;
    case isBigLaptop:
      return 420;
    case isDesktop:
      return 310;
    case isWideScreen:
      return MODEL_CONFIGURATION_CONTAINER_HEIGHT;
  }
  return 0;
};
