// Used to extract array data from infinite query pages
export const getDataFromPages = (
  pages: any[] | undefined,
  key = 'data',
  secondKey?: string
) => {
  if (pages?.length) {
    return pages
      .map((page) => {
        if (secondKey) {
          return page[key][secondKey];
        } else {
          return page[key];
        }
      })
      .flat();
  }
  return [];
};
