import {
  ByCodeflexText,
  Close,
  Container,
  DownWrapper,
  SwitchersWrapper,
  Wrapper,
} from './SideMenu.styled';
import { Dispatch, forwardRef, SetStateAction } from 'react';
import SwitchAppMode from 'components/SwitchAppMode/SwitchAppMode';
import { AppMode } from 'store/Common/reducers/common';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { useTranslation } from 'react-i18next';
import { NavigationLabel } from '../Header.styled';
import { useNavigateWithQuery } from 'utils/hooks/useNavigateWithQuery';
import { RoutesConfig } from 'navigation/routes';
import { ReactComponent as CodeflexLogo } from 'assets/icons/codeflexLogoTextRight.svg';
import Icon from 'components/Icon/Icon';
import { COLORS } from 'assets/styled';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';
import ToggleDarkMode from 'components/ToggleDarkMode/ToggleDarkMode';
import LanguageSwitcher from 'components/LanguageSwitcher/LanguageSwitcher';
import { GearSix as SettingsIcon, X } from '@phosphor-icons/react';

interface ISideMenuProps {
  active: boolean;
  setSideMenuIsOpen: Dispatch<SetStateAction<boolean>>;
  ref: any;
}

const SideMenu = forwardRef<HTMLDivElement, ISideMenuProps>(
  ({ active, setSideMenuIsOpen }, ref) => {
    const { t } = useTranslation();
    const appMode: AppMode = useSelector(
      (state: IRootReducerState) => state.commonInfo.appMode
    );
    const navigateWithQuery = useNavigateWithQuery();

    const closeSideMenu = () => {
      setSideMenuIsOpen(false);
    };

    const { isSmallPhone } = useBreakpointFlag();

    return (
      <Container active={active} ref={ref}>
        <Close onClick={closeSideMenu}>
          <Icon svg={X} color={COLORS.GREEN} />
        </Close>

        <Wrapper>
          {[AppMode.SUPPLIER, AppMode.COMPANY].includes(appMode) ? (
            <SwitchAppMode />
          ) : null}
          {appMode === AppMode.COMPANY ? (
            <NavigationLabel
              onClick={() => {
                navigateWithQuery(RoutesConfig.Models.fullPath);
                closeSideMenu();
              }}
              isSelected={
                location.pathname.includes(RoutesConfig.Models.fullPath) ||
                location.pathname.includes(
                  RoutesConfig.CustomModels.fullPath
                ) ||
                location.pathname.includes(RoutesConfig.Configure.fullPath) ||
                location.pathname.includes(RoutesConfig.AllModels.fullPath)
              }
            >
              {t('Models')}
            </NavigationLabel>
          ) : null}
          {appMode === AppMode.SUPPLIER ? (
            <NavigationLabel
              onClick={() => {
                navigateWithQuery(RoutesConfig.MyModels.fullPath);
                closeSideMenu();
              }}
              isSelected={location.pathname.includes(
                RoutesConfig.MyModels.fullPath
              )}
            >
              {t('My models')}
            </NavigationLabel>
          ) : null}
          {[AppMode.SUPPLIER, AppMode.DEALER_PANEL].includes(appMode) ? (
            <NavigationLabel
              onClick={() => {
                navigateWithQuery(RoutesConfig.CustomModels.fullPath);
                closeSideMenu();
              }}
              isSelected={location.pathname.includes(
                RoutesConfig.CustomModels.fullPath
              )}
            >
              {t('Custom models')}
            </NavigationLabel>
          ) : null}
          {appMode === AppMode.SUPPLIER ? (
            <NavigationLabel
              onClick={() => {
                navigateWithQuery(RoutesConfig.SupplierCompanies.fullPath);
                closeSideMenu();
              }}
              isSelected={location.pathname.includes(
                RoutesConfig.SupplierCompanies.fullPath
              )}
            >
              {t('Companies')}
            </NavigationLabel>
          ) : null}
          {[AppMode.SUPPLIER, AppMode.COMPANY].includes(appMode) ? (
            <NavigationLabel
              onClick={() => {
                navigateWithQuery(RoutesConfig.Users.fullPath);
                closeSideMenu();
              }}
              isSelected={location.pathname.includes(
                RoutesConfig.Users.fullPath
              )}
            >
              {t('Users')}
            </NavigationLabel>
          ) : null}
          {appMode === AppMode.DEALER_PANEL ? (
            <NavigationLabel
              onClick={() => {
                navigateWithQuery(RoutesConfig.DealerPanelSuppliers.fullPath);
                closeSideMenu();
              }}
              isSelected={
                location.pathname.includes(
                  RoutesConfig.DealerPanelSuppliers.fullPath
                ) ||
                location.pathname.includes(
                  RoutesConfig.DealerPanelModels.fullPath
                )
              }
            >
              {t('Suppliers')}
            </NavigationLabel>
          ) : null}
          {[AppMode.COMPANY, AppMode.SUPPLIER].includes(appMode) &&
          isSmallPhone ? (
            <Icon
              svg={SettingsIcon}
              onClick={() => {
                navigateWithQuery(RoutesConfig.Settings.fullPath);
                closeSideMenu();
              }}
              color={COLORS.GREEN}
              size={30}
              wrapperStyle={{ zIndex: 0 }}
            />
          ) : null}
          {isSmallPhone ? (
            <SwitchersWrapper>
              <LanguageSwitcher />
            </SwitchersWrapper>
          ) : null}
        </Wrapper>
        <DownWrapper>
          <ByCodeflexText>{t('Powered by')}:</ByCodeflexText>
          <Icon svg={CodeflexLogo} size={100} fill={COLORS.BLACK} />
        </DownWrapper>
      </Container>
    );
  }
);

SideMenu.displayName = 'SideMenu';

export default SideMenu;
