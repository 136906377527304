import {
  BackLabel,
  Container,
  BackWrapper,
  Title,
  RowPaddingBottom,
  CategoriesContainer,
  SupplierCompanyName,
  NoContentLabel,
  NoContentLabelWrapper,
  InputWrapper,
  StyledInput,
  TitleAndSupplierLabelsWrapper,
  SkeletonWrapper,
  TooltipWrapper,
} from './DealerPanelModelsPage.styled';
import debounce from 'lodash.debounce';
import { ChangeEvent, useState } from 'react';
import {
  IDealerPanelSupplierDataFromNavigationState,
  useGetModelConfigurations,
  useGetSupplierDataFromNavigationState,
} from './hooks';
import { useTranslation } from 'react-i18next';
import { formatModelsIntoCategories } from './helpers';
import ManageModelQuestionDocumentVisibilityModal from './ManageModelQuestionDocumentVisibilityModal/ManageModelQuestionDocumentVisibilityModal';
import { COLORS } from 'assets/styled';
import Icon from 'components/Icon/Icon';
import { MagnifyingGlass, CaretLeft, Question } from '@phosphor-icons/react';
import { RoutesConfig } from 'navigation/routes';
import { useNavigateWithQuery } from 'utils/hooks/useNavigateWithQuery';
import { IDealerPanelCategory } from 'types/Category.types';
import DealerPanelCategory from './DealerPanelCategory/DealerPanelCategory';
import CategoryListSkeleton from 'pages/ModelsPage/ModelsPageList/SupplierCategoriesList/CategoryList/CategoryListSkeleton/CategoryListSkeleton';
import Tooltip from 'components/Tooltip/Tooltip';
import { SelectVATAmountModal } from './SelectVATAmountModal/SelectVATAmountModal';
import { DealerPanelFormulaTooltipContent } from './DealerPanelFormulaTooltipContent/DealerPanelFormulaTooltipContent';

export const DealerPanelModelsPage = () => {
  const navigate = useNavigateWithQuery();
  const { t } = useTranslation();
  const [searchBy, setSearchBy] = useState<string>('');
  const [modelIdForTest, setModelIdForTest] = useState<number | null>(null);
  const debouncedSearchBy = debounce(
    (searchBy: string) => setSearchBy(searchBy),
    500
  );
  const {
    supplierId,
    supplierName,
    supplierHasSystemIntegration,
  }: IDealerPanelSupplierDataFromNavigationState =
    useGetSupplierDataFromNavigationState();

  const { data, isLoading } = useGetModelConfigurations(supplierId, searchBy);
  const [existingConfigurationId, setExistingConfigurationId] = useState<
    number | null
  >(null);
  const categories: IDealerPanelCategory[] = formatModelsIntoCategories(data);

  const [manageVisibilityModelId, setManageVisibilityModelId] = useState<
    number | null
  >(null);

  const handleOnClick = () => {
    navigate(RoutesConfig.DealerPanelSuppliers.fullPath);
  };

  const renderLoader = () => {
    return (
      <SkeletonWrapper>
        {Array.from({ length: 5 }, (_, index) => (
          <CategoryListSkeleton
            key={`skeleton-row-${index}`}
            index={index}
            withoutLine={2 === index}
          />
        ))}
      </SkeletonWrapper>
    );
  };

  return (
    <Container>
      <BackWrapper onClick={handleOnClick}>
        <Icon svg={CaretLeft} color={COLORS.GREEN} onClick={handleOnClick} />
        <BackLabel>{t('Back')}</BackLabel>
      </BackWrapper>
      <RowPaddingBottom>
        <TitleAndSupplierLabelsWrapper>
          <Title>{t('Models')}</Title>
          <SupplierCompanyName>
            {supplierName ? `(${supplierName})` : ''}
          </SupplierCompanyName>
        </TitleAndSupplierLabelsWrapper>
        <InputWrapper>
          <TooltipWrapper>
            <Tooltip content={<DealerPanelFormulaTooltipContent />}>
              <Icon
                svg={Question}
                color={COLORS.GREEN}
                size={26}
                paddingLeft="5rem"
              />
            </Tooltip>
          </TooltipWrapper>
          <StyledInput
            icon={MagnifyingGlass}
            placeholder={t('Search')}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              debouncedSearchBy(e.target.value)
            }
            wrapperStyles={{ width: '100%' }}
          />
        </InputWrapper>
      </RowPaddingBottom>
      {categories.length ? (
        <CategoriesContainer>
          {categories.map((category: IDealerPanelCategory, index: number) => (
            <DealerPanelCategory
              key={index}
              category={category}
              withoutLine={categories.length - 1 === index}
              setManageVisibilityModelId={setManageVisibilityModelId}
              onTestClick={(
                id: number,
                existingConfigurationId: number | null
              ) => {
                setModelIdForTest(id);
                setExistingConfigurationId(existingConfigurationId);
              }}
              supplierHasSystemIntegration={supplierHasSystemIntegration}
            />
          ))}
        </CategoriesContainer>
      ) : isLoading ? (
        renderLoader()
      ) : (
        <NoContentLabelWrapper>
          {!isLoading && (
            <NoContentLabel>
              {t('No models found for this supplier')}
            </NoContentLabel>
          )}
        </NoContentLabelWrapper>
      )}
      <ManageModelQuestionDocumentVisibilityModal
        manageVisibilityModelId={manageVisibilityModelId}
        setManageVisibilityModelId={setManageVisibilityModelId}
      />
      <SelectVATAmountModal
        modelIdForTest={modelIdForTest}
        setModelIdForTest={setModelIdForTest}
        supplierHasSystemIntegration={supplierHasSystemIntegration}
        existingConfigurationId={existingConfigurationId}
      />
    </Container>
  );
};
