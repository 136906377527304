import { COLORS, marginSm } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20rem;

  ${respondTo.bigPhone`
    padding-top: 40rem;
  `}
`;

export const Title = styled.label`
  font-weight: 400;
  font-size: 24rem;
  line-height: 32rem;
  color: ${COLORS.BLACK};
  text-align: center;
  margin-bottom: 20rem;

  ${respondTo.bigPhone`
    font-size: 20rem;
    line-height: 1.3;
  `}
`;

export const SubTitle = styled.label`
  font-weight: 400;
  font-size: 16rem;
  line-height: 22rem;
  margin-bottom: 20rem;
  color: ${COLORS.BLACK};
  text-align: center;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${marginSm};

  ${respondTo.bigPhone`
    flex-direction: column;
    gap: 10rem;
  `}
`;
