import { useInfiniteQuery } from 'react-query';
import { getSupplierCompanies } from 'services/SupplierService';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';

export const useGetSupplierCompaniesInfinite = (
  supplierId: number,
  perPage: number,
  searchBy: string
) =>
  useInfiniteQuery({
    queryKey: [
      ReactQueryKeys.GET_SUPPLIER_COMPANIES,
      supplierId,
      perPage,
      searchBy,
    ],
    queryFn: ({ pageParam = 1 }) =>
      getSupplierCompanies(supplierId, pageParam, perPage, searchBy),
    getNextPageParam: (lastPage: any) => {
      if (!lastPage?.pagination) {
        return undefined;
      }
      if (
        lastPage.pagination.per_page * lastPage.pagination.page >=
        lastPage.pagination.total
      ) {
        // Return undefined here so hasNextPage equals to false
        return undefined;
      }
      return lastPage.pagination.page + 1;
    },
    onSuccess: (data) => {
      return data;
    },
  });
