import { COLORS, paddingMd } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

export const Container = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${paddingMd};

  ${respondTo.bigPhone`
    padding: 15rem;
  `}
`;

export const Title = styled.label`
  padding-left: 30rem;
  padding-right: 30rem;
  padding-bottom: 30rem;
  font-weight: 400;
  font-size: 24rem;
  color: ${COLORS.BLACK};
  text-align: center;

  ${respondTo.bigPhone`
    font-size: 20rem;
  `}
`;
