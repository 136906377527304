import { useTranslation } from 'react-i18next';
import {
  Container,
  ErrorMessage,
  ErrorWrapper,
  Row,
  RowName,
  SwitchWrapper,
  ToggleText,
} from '../ConfigForms.styled';
import { Form, Formik, FormikProps } from 'formik';
import { useRef, useState } from 'react';
import { Input } from 'components/Input/InputFormik';
import Icon from 'components/Icon/Icon';
import { Asterisk, Circle } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import { IToggleInputField } from 'types/Builder.types';
import { ToggleValidationSchema } from '../validations';
import { Option, OptionLabel } from '../../Components/Price/Price.styled';

interface IProps {
  currentSelectedField: IToggleInputField;
  updateModel: (values: any) => void;
}

const ToggleForm = ({ currentSelectedField, updateModel }: IProps) => {
  const { t } = useTranslation();
  const formikRef = useRef<FormikProps<any> | any>();
  const [activeField, setActiveField] = useState<string | null>(null);

  const initialValues = {
    field_label: currentSelectedField.field_label,
    description: currentSelectedField.description,
    default: currentSelectedField.default,
    price: {
      ...currentSelectedField.price,
      amount: currentSelectedField.price.amount ?? null,
    },
  };

  return (
    <Container>
      <Formik
        innerRef={formikRef}
        enableReinitialize
        initialValues={initialValues}
        validationSchema={ToggleValidationSchema}
        validateOnChange={true}
        validateOnMount={true}
        validateOnBlur={true}
        onSubmit={() => {
          //
        }}
      >
        {({ handleBlur, setFieldValue, isValid, values, errors, touched }) => {
          if (
            isValid &&
            activeField === null &&
            JSON.stringify(values) !== JSON.stringify(initialValues)
          ) {
            updateModel(values);
          }

          const handleBlurFunction = (e: any, name: string, type?: string) => {
            let value = e.target.value.trim();

            if (name === 'price.amount') {
              value = value === '' ? null : Number(value);
            } else if (type === 'number') {
              value = Number(value);
            }

            setFieldValue(name, value);
            handleBlur(e);
            setActiveField(null);
          };

          // Block setModal while user focus field
          const handleFocus = (name: string) => {
            setActiveField(name);
          };

          return (
            <Form>
              <Row>
                <RowName>
                  {t('Field Label')}
                  <Icon svg={Asterisk} size={10} color={COLORS.RED_100} />
                </RowName>
                <Input
                  name="field_label"
                  placeholder={t('Field Label')}
                  height={'40rem'}
                  wrapperStyles={{ width: '100%' }}
                  onBlur={(e) => {
                    handleBlurFunction(e, 'field_label');
                  }}
                  onFocus={() => handleFocus('field_label')}
                />
                <ErrorWrapper>
                  <ErrorMessage>
                    {touched['field_label'] ? errors['field_label'] : null}
                  </ErrorMessage>
                </ErrorWrapper>
              </Row>
              <Row>
                <RowName>{t('Description')}</RowName>
                <Input
                  name="description"
                  isTextArea
                  placeholder={t('Description')}
                  height={'100rem'}
                  wrapperStyles={{ width: '100%' }}
                  onBlur={(e) => {
                    handleBlurFunction(e, 'description');
                  }}
                  onFocus={() => handleFocus('description')}
                />
                <ErrorWrapper>
                  <ErrorMessage>
                    {touched['description'] ? errors['description'] : null}
                  </ErrorMessage>
                </ErrorWrapper>
              </Row>
              <Row>
                <RowName>{t('Price')}</RowName>
                <SwitchWrapper>
                  <Option
                    onClick={() => {
                      setFieldValue('price.price_type', 'fixed');
                    }}
                  >
                    <Icon
                      svg={Circle}
                      color={COLORS.GREEN}
                      weight={
                        values.price.price_type === 'fixed' ? 'fill' : 'light'
                      }
                      size={25}
                    />
                    <OptionLabel>€</OptionLabel>
                  </Option>
                  <Option
                    onClick={() => {
                      if (values.price.amount !== null) {
                        if (values.price.amount < -100) {
                          setFieldValue('price.amount', -100);
                        }
                      }
                      setFieldValue('price.price_type', 'percentage');
                    }}
                  >
                    <Icon
                      svg={Circle}
                      color={COLORS.GREEN}
                      weight={
                        values.price.price_type === 'percentage'
                          ? 'fill'
                          : 'light'
                      }
                      size={25}
                    />
                    <OptionLabel>%</OptionLabel>
                  </Option>
                </SwitchWrapper>
                <Input
                  name="price.amount"
                  value={values.price.amount ?? ''}
                  type="number"
                  hideNumberArrows
                  placeholder={t('Amount')}
                  height={'40rem'}
                  wrapperStyles={{ width: '100%' }}
                  onBlur={(e) => {
                    handleBlurFunction(e, 'price.amount', 'number');
                  }}
                  onFocus={() => handleFocus('price.amount')}
                  onChange={(e) => {
                    const amountValue = (e as any) !== '' ? Number(e) : null;
                    setFieldValue('price.amount', amountValue);
                  }}
                />
                <ErrorWrapper>
                  <ErrorMessage>
                    {touched.price?.amount && errors.price?.amount
                      ? errors.price.amount
                      : null}
                  </ErrorMessage>
                </ErrorWrapper>
              </Row>
              <Row>
                <RowName>{t('Default state')}</RowName>
                <SwitchWrapper>
                  <ToggleText isOff={values.default}>
                    {values.default ? t('On') : t('Off')}
                  </ToggleText>
                  <ToggleSwitch
                    isChecked={values.default}
                    size="large"
                    onClick={() => setFieldValue('default', !values.default)}
                  />
                </SwitchWrapper>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
};

export default ToggleForm;
