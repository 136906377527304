import Input from 'components/Input/Input';
import OptionsTable from 'components/OptionsTable/OptionsTable';
import { useDispatch, useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { closeTableModal } from 'store/TableModal/actions/tableModal';
import { ITableModalReducerState } from 'store/TableModal/reducers/tableModal';
import { Modal } from '../Modal/Modal';
import { Container, InputWrapper, Title } from './TableModal.styled';
import { MagnifyingGlass as Search } from '@phosphor-icons/react';
import { getIn, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { applySearchFilter } from './helpers';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';
import OptionsList from 'components/OptionsList/OptionsList';

export function TableModal() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isOpen, question }: ITableModalReducerState = useSelector(
    (state: IRootReducerState) => state.tableModalInfo
  );
  const context = useFormikContext();
  const [searchBy, setSearchBy] = useState<string>('');
  const { isMidTablet, isSmallLaptop } = useBreakpointFlag();

  if (!question || !context) {
    return null; // Modal not opened
  }

  const handleSubmit = (selectedOptions: any[]) => {
    context.setFieldValue(question.initialKey, selectedOptions);
    setSearchBy('');
    dispatch(closeTableModal());
  };
  const handleCancel = () => {
    setSearchBy('');
    dispatch(closeTableModal());
  };

  const initialSelectedOptions = getIn(
    context.values,
    `${question.initialKey}`
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => handleCancel()}
      modalStyle={{
        position: 'fixed',
        margin: 'auto',
        width: isSmallLaptop ? '90%' : '1400rem',
        minWidth: isSmallLaptop ? '90%' : 'auto',
      }}
    >
      <Container>
        <Title>{t('Choose the options which you would like')}</Title>
        <InputWrapper>
          <Input
            icon={Search}
            placeholder={t('Search')}
            width={'100%'}
            wrapperStyles={{ width: '100%' }}
            onChange={(e: any) => setSearchBy(e.target.value)}
          />
        </InputWrapper>
        {!isMidTablet && (
          <OptionsTable
            isMultiple={question.multiple}
            initialSelectedOptions={
              initialSelectedOptions === '' ? [] : initialSelectedOptions
            }
            tableRows={applySearchFilter(question.options, searchBy)}
            headerData={Object.keys(question.outputs)}
            onSubmit={(selectedOptions) => handleSubmit(selectedOptions)}
            onCancel={() => handleCancel()}
          />
        )}
        {isMidTablet && (
          <OptionsList
            isMultiple={question.multiple}
            initialSelectedOptions={
              initialSelectedOptions === '' ? [] : initialSelectedOptions
            }
            tableRows={applySearchFilter(question.options, searchBy)}
            headerData={Object.keys(question.outputs)}
            onSubmit={(selectedOptions) => handleSubmit(selectedOptions)}
            onCancel={() => handleCancel()}
          />
        )}
      </Container>
    </Modal>
  );
}
