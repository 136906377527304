import { forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import {
  CalendarWrapper,
  Container,
  DateInputContainer,
} from './DateInput.styled';
import Input from '../Input';
import { useValidateDateTime } from './hooks';
import { IQuestion } from 'types/Question.types';
import { COLORS } from 'assets/styled';
import { CalendarBlank } from '@phosphor-icons/react';
import Icon from 'components/Icon/Icon';

interface IDateInputProps {
  question: IQuestion;
  value: Date | null | undefined;
  pwId: string;
  isTouched: boolean;
  context: any;
}

const DateInput = ({
  question,
  value,
  pwId,
  isTouched,
  context,
}: IDateInputProps) => {
  const errorMessage = useValidateDateTime(question, context);

  const StyledDateInput = forwardRef((props: any, ref: any) => {
    return (
      <DateInputContainer onClick={props.onClick} ref={ref}>
        <Input
          onChange={props.onChange}
          value={props.value}
          placeholder={props.placeholder}
          errorMessage={isTouched ? errorMessage : ''}
        />
      </DateInputContainer>
    );
  });
  StyledDateInput.displayName = 'StyledDateInput';

  return (
    <Container>
      <DatePicker
        data-testid={pwId}
        calendarStartDay={1}
        selected={value}
        onChange={(date: any) => {
          if (!isNaN(new Date(date).getTime())) {
            context.setFieldValue(question.initialKey, date);
          }
        }}
        customInput={<StyledDateInput />}
        placeholderText={'DD/MM/YYYY'}
        strictParsing={true}
      />
      <CalendarWrapper>
        <Icon svg={CalendarBlank} size={20} color={COLORS.BLACK} />
      </CalendarWrapper>
    </Container>
  );
};

export default DateInput;
