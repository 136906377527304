import { COLORS, borderRadiusSm } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';
import { css } from 'styled-components';

interface StyledProps {
  gap: string;
  fontWeight: string;
  fontSize: string;
  color: string;
  marginBottom: string;
  imageLoaded: boolean;
}

export const Container = styled.div`
  padding: 50rem;

  ${respondTo.smallLaptop`
    padding: 30rem;
  `}

  ${respondTo.smallTablet`
    padding: 30rem 15rem;
  `}
`;

export const Back = styled.label`
  margin-left: 5rem;
  font-size: 18rem;
  color: ${COLORS.BLACK};

  ${respondTo.bigPhone`
    font-size: 16rem;
  `}
`;

export const FirstRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5rem;
  margin-bottom: 20rem;
  width: fit-content;
  cursor: pointer;
  &:hover {
    ${Back} {
      cursor: pointer;
      text-decoration: underline;
      text-decoration-thickness: 1rem;
    }
  }
`;

export const ModelName = styled.h2`
  font-weight: 300;
  font-size: 50rem;
  color: ${COLORS.BLACK};

  ${respondTo.bigTablet`
    font-size: 40rem;
  `}

  ${respondTo.smallTablet`
    font-size: 30rem;
  `}

  ${respondTo.smallTablet`
    text-align: center;
  `}
`;

export const InfoText = styled.span`
  font-weight: 400;
  font-size: 18rem;
  display: inline-block;
  color: ${COLORS.BLACK};
  width: 50%;

  ${respondTo.smallTablet`
    font-size: 16rem;
  `}
`;

export const chevronRotateStyle = css`
  transform: rotate(90deg);
`;

export const imageContainerStyle = css`
  width: 100%;
  max-width: 300rem;
  ${respondTo.bigPhone`
    max-width: 100%;
  `};
`;

export const imageStyle = css`
  border-radius: ${borderRadiusSm};
  ${(props: StyledProps) =>
    props.imageLoaded
      ? css`
          border-radius: ${borderRadiusSm};
          border: 1px solid ${COLORS.BORDER};
        `
      : css`
          width: 0;
          height: 0;
          opacity: 0;
        `};
`;

export const RowGap = styled.div`
  display: flex;
  flex-direction: row;
  gap: 50rem;
  align-items: flex-start;

  ${respondTo.smallLaptop`
    gap: 30rem;
  `}

  ${respondTo.smallTablet`
    justify-content: space-between;
  `}

  ${(props: StyledProps) =>
    props.gap &&
    css`
      gap: ${props.gap};
    `}
  ${(props: StyledProps) =>
    props.marginBottom &&
    css`
      margin-bottom: ${props.marginBottom};
    `}
`;

export const ModelDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10rem;
  width: 100%;
`;

export const ContentWrapper = styled.div`
  margin-bottom: 50rem;
  display: flex;
  flex-direction: row;
  gap: 50rem;
  align-items: center;

  ${respondTo.smallLaptop`
    gap: 30rem;
  `}

  ${respondTo.smallTablet`
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `}

  ${respondTo.smallTablet`
    margin-bottom: 20rem;
  `}
`;
