import i18n from 'providers/i18n/i18n';
import { useQuery } from 'react-query';
import { getCategories } from 'services/CategoryService';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';

export const useGetCategories = (
  supplierId: number,
  page: number,
  perPage: number,
  searchBy: string
) =>
  useQuery({
    queryKey: [
      ReactQueryKeys.GET_CATEGORIES,
      supplierId,
      page,
      perPage,
      searchBy,
      i18n.resolvedLanguage,
    ],
    queryFn: () => {
      return getCategories(supplierId, page, perPage, searchBy);
    },
  });
