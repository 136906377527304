import { COLORS, borderRadiusSm } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50rem;
  gap: 30rem;

  ${respondTo.smallLaptop`
    padding: 30rem;
  `}

  ${respondTo.smallTablet`
    padding: 30rem 15rem;
  `}
`;

// Styles below are used in SupplierUsers and CompanyUsers
export const Card = styled.div`
  background-color: ${COLORS.WHITE};
  border-radius: ${borderRadiusSm};
  padding: 30rem;

  ${respondTo.bigPhone`
    padding: 15rem;
  `}
`;

export const Title = styled.label`
  font-size: 24rem;
  font-weight: 400;
  line-height: 32.68rem;
  color: ${COLORS.BLACK};

  ${respondTo.smallLaptop`
    font-size: 20rem;
    line-height: 1.2;
  `};

  ${respondTo.bigPhone`
    font-size: 18rem;
  `}
`;

export const FirstRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30rem;

  ${respondTo.midTablet`
    flex-direction: column;
    align-items: flex-start;
    gap: 20rem;
  `}
`;

export const RightSide = styled.div`
  display: flex;
  align-items: center;
  gap: 30rem;

  ${respondTo.midTablet`
    justify-content: flex-end;
    width: 100%;
  `}

  ${respondTo.bigPhone`
    flex-direction: column;
    gap: 20rem;
  `}
`;

export const InputWrapper = styled.div`
  width: 300rem;

  ${respondTo.bigPhone`
    width: 100%;
  `}
`;
