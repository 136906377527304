import { COLORS, paddingMd } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

export const Container = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${paddingMd};

  ${respondTo.bigPhone`
    padding: 15rem;
  `}
`;

export const Title = styled.label`
  padding-bottom: 15rem;
  font-weight: 400;
  font-size: 24rem;
  color: ${COLORS.BLACK};
  text-align: center;

  ${respondTo.bigPhone`
    font-size: 20rem;
  `}
`;

export const InputWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 100%;
  margin-bottom: 15rem;
`;

export const CategoriesList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  max-height: 300rem;
  overflow-y: auto;
`;

export const CategoryItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10rem;
  border-bottom: 1px solid ${COLORS.GRAY_100};
  transition: 0.3s ease;

  &:hover {
    background-color: ${COLORS.GRAY_300};
  }
`;

export const CategoryLabel = styled.label`
  color: ${COLORS.BLACK};
  font-size: 18rem;
  font-weight: 400;
`;

export const CategoryActions = styled.div`
  display: flex;
  align-items: center;
  gap: 15rem;
`;

export const ButtonWrapper = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-self: center;
  margin-top: 30rem;
`;
