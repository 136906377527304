import { borderRadiusSm } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled, { css } from 'styled-components';

interface StyledProps {
  disabled: boolean;
  zIndex: number;
  singleImage: boolean;
  $isPreview: boolean;
}

export const Container = styled.div`
  position: relative;
  border-radius: ${borderRadiusSm};
  overflow: hidden;
  width: 100%;
  max-width: 700rem;
  margin: 0 auto;
`;

export const ContainerRelative = styled.div`
  width: 100%;
  padding-top: 56.28%; // This maintains the aspect ratio of 700:394
  position: relative;
`;

export const LargeFormImageLayerImage = styled.img`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  user-select: none;
  border-radius: ${borderRadiusSm};
  ${(props: StyledProps) =>
    props.zIndex &&
    css`
      z-index: ${props.zIndex};
    `};
  ${(props: StyledProps) =>
    props.$isPreview &&
    css`
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    `}
`;

export const ErrorImage = styled.img`
  max-width: 700rem;
  width: 100%;
`;

export const PreviousSlideChevronWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100rem;
  width: 100rem;
  border-radius: 50rem;
  z-index: 5;
  user-select: none;
  &:hover {
    ${(props: StyledProps) =>
      !props.disabled
        ? css`
            transform: translateY(-50%) scale(1.1);

            ${respondTo.midTablet`
              transform: translateY(-50%);
            `}
          `
        : css`
            transform: translateY(-50%) scale(1.05);

            ${respondTo.midTablet`
              transform: translateY(-50%);
            `}
          `};
  }
  transition: 100ms;
  ${(props: StyledProps) =>
    props.singleImage &&
    css`
      width: 30rem;
    `};

  ${respondTo.midTablet`
    width: 40rem;
    height: 40rem;
  `}
`;

export const NextSlideChevronWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100rem;
  width: 100rem;
  border-radius: 50rem;
  z-index: 5;
  user-select: none;
  &:hover {
    ${(props: StyledProps) =>
      !props.disabled
        ? css`
            transform: translateY(-50%) scale(1.15);

            ${respondTo.midTablet`
              transform: translateY(-50%);
            `}
          `
        : css`
            transform: translateY(-50%) scale(1.05);

            ${respondTo.midTablet`
              transform: translateY(-50%);
            `}
          `};
  }
  transition: 100ms;
  ${(props: StyledProps) =>
    props.singleImage &&
    css`
      width: 30rem;
    `};

  ${respondTo.midTablet`
    width: 40rem;
    height: 40rem;
  `}
`;

export const ArrowWrapper = styled.div`
  ${(props: StyledProps) =>
    props.singleImage &&
    css`
      height: 30rem;
      width: 30rem;
      border-radius: 15rem;
    `};

  ${respondTo.midTablet`
    width: 40rem;
    height: 40rem;
  `}

  ${respondTo.bigPhone`
    width: 30rem;
    height: 30rem;
  `}
`;

export const previewImageIconStyle = css`
  position: absolute;
  right: 10rem;
  bottom: 10rem;
`;
