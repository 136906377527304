import { OptionProps } from 'react-select';
import { components } from 'react-select';
import { OptionContainer } from './DefaultOption.styled';

export const DefaultOption = (props: OptionProps<any>) => {
  return (
    <components.Option {...props}>
      <OptionContainer data-testid={props.data.pwId}>
        <div data-testid={props.data.pwIdLabel}>{props.data.label}</div>
      </OptionContainer>
    </components.Option>
  );
};
