import { AnyAction } from '@reduxjs/toolkit';
import { IQuestion } from 'types/Question.types';
import {
  QUERY_ATTEMPT,
  QUERY_ERROR,
  QUERY_RESET,
  QUERY_SUCCESS,
  RESET_CONFIGURE_FORM,
  SET_MODEL,
  SET_QUESTIONS,
  SET_PRICING,
  SET_MODELS,
  SET_TRIGGER_QUERY_REQUEST_AFTER_FORM_RESET,
  SET_IMAGES,
  SET_LANG,
  SET_QUERY_PAYLOAD,
  SET_IS_USING_SUPPLIER_SYSTEM_DISCOUNTS,
} from '../../constants';

export interface IModelReducerState {
  models: any[] | null;
  id: number | null;
  versionNumber: number | null;
  general: object | null;
  images: object | null;
  questions: IQuestion[];
  queryPayload: object | null;
  pricing: object | null;
  roundPricing: boolean;
  queryStatus: {
    attempt: boolean;
    success: boolean;
    error: string | null;
  };
  triggerQueryRequestAfterFormReset: boolean;
  lang: string;
  isUsingSupplierSystemDiscounts: boolean;
}

const INIT_STATE: IModelReducerState = {
  models: null,
  id: null,
  versionNumber: null,
  general: null,
  images: null,
  questions: [],
  queryPayload: null,
  pricing: null,
  roundPricing: false,
  queryStatus: {
    attempt: false,
    success: false,
    error: null,
  },
  triggerQueryRequestAfterFormReset: false, // Used in useValuesDidUpdate
  lang: 'en',
  isUsingSupplierSystemDiscounts: false,
};

export const parseQuestionsObjectResponseIntoArray = (questionsObj: object) => {
  return Object.keys(questionsObj).map((questionKey: string) => {
    return {
      ...questionsObj[questionKey],
      name: questionsObj[questionKey].name
        ? questionsObj[questionKey].name
        : questionKey,
      initialKey: questionsObj[questionKey].initialKey
        ? questionsObj[questionKey].initialKey
        : questionKey,
    };
  });
};

const modelReducer = (state = INIT_STATE, action: AnyAction) => {
  switch (action.type) {
    case SET_MODEL:
      return {
        ...state,
        id: action.payload.id,
        versionNumber: action.payload.version_number,
        general: action.payload.general,
        roundPricing: action.payload.round_pricing,
      };
    case SET_QUESTIONS: {
      if (!action.payload || typeof action.payload !== 'object') {
        return {
          ...state,
          questions: [],
        };
      }
      // If present, use question.name as name, else use question key as its name
      const questionsArray = parseQuestionsObjectResponseIntoArray(
        action.payload
      );
      return {
        ...state,
        questions: questionsArray,
      };
    }
    case SET_QUERY_PAYLOAD:
      return {
        ...state,
        queryPayload: action.payload,
      };
    case SET_IMAGES:
      return {
        ...state,
        images: action.payload,
      };
    case SET_PRICING:
      return {
        ...state,
        pricing: action.payload,
      };
    case QUERY_ATTEMPT:
      return {
        ...state,
        queryStatus: {
          attempt: true,
          success: false,
          error: null,
        },
      };
    case QUERY_SUCCESS:
      return {
        ...state,
        queryStatus: {
          attempt: false,
          success: true,
          error: null,
        },
      };
    case QUERY_ERROR:
      return {
        ...state,
        queryStatus: {
          attempt: false,
          success: false,
          error: action.payload,
        },
      };
    case QUERY_RESET:
      return {
        ...state,
        queryStatus: {
          attempt: false,
          success: false,
          error: null,
        },
      };
    case RESET_CONFIGURE_FORM:
      return {
        ...state,
        queryStatus: {
          attempt: false,
          success: false,
          error: null,
        },
        questions: [],
        triggerQueryRequestAfterFormReset: true,
      };
    case SET_TRIGGER_QUERY_REQUEST_AFTER_FORM_RESET: // Used in useValuesDidUpdate
      return {
        ...state,
        triggerQueryRequestAfterFormReset: action.payload,
      };
    case SET_MODELS:
      return {
        ...state,
        models: action.payload,
      };
    case SET_LANG:
      return {
        ...state,
        lang: action.payload,
      };
    case SET_IS_USING_SUPPLIER_SYSTEM_DISCOUNTS:
      return {
        ...state,
        isUsingSupplierSystemDiscounts: action.payload,
      };
    default:
      return state;
  }
};

export default modelReducer;
