import { useTranslation } from 'react-i18next';
import { Mode, Text, Wrapper } from './SwitchAppMode.styled';
import { useDispatch, useSelector } from 'react-redux';
import { AppMode } from 'store/Common/reducers/common';
import { IRootReducerState } from 'store/store';
import { setAppMode } from 'store/Common/actions/common';
import Tooltip from 'components/Tooltip/Tooltip';

const SwitchAppMode = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const appMode: AppMode = useSelector(
    (state: IRootReducerState) => state.commonInfo.appMode
  );
  const { company, supplier } = useSelector(
    (state: IRootReducerState) => state.userInfo
  );

  return (
    <Wrapper>
      <Tooltip
        placement="bottom"
        content={
          company?.id
            ? null
            : t(
                'Switching to company mode is disabled because no company is currently selected. Try logging in again and selecting a company.'
              )
        }
      >
        <Mode
          onClick={() => company?.id && dispatch(setAppMode(AppMode.COMPANY))}
          active={appMode === AppMode.COMPANY}
          isDisabled={!company?.id}
        >
          <Text>{t('Company View')}</Text>
        </Mode>
      </Tooltip>
      <Tooltip
        placement="bottom"
        content={
          supplier?.id
            ? null
            : t(
                'Switching to supplier mode is disabled because no supplier is currently selected. Try logging in again and selecting a supplier.'
              )
        }
      >
        <Mode
          onClick={() => supplier?.id && dispatch(setAppMode(AppMode.SUPPLIER))}
          active={appMode === AppMode.SUPPLIER}
          isDisabled={!supplier?.id}
        >
          <Text>{t('Supplier View')}</Text>
        </Mode>
      </Tooltip>
    </Wrapper>
  );
};

export default SwitchAppMode;
