import { COLORS } from 'assets/styled';
import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20rem;
`;

export const Title = styled.label`
  font-weight: 400;
  font-size: 26rem;
  color: ${COLORS.BLACK};
  margin-bottom: 20rem;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid ${COLORS.BORDER};
  padding: 20rem 0;
  width: 100%;
  max-width: 100%;
`;

export const RowTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5rem;
  min-height: 40rem;
`;

export const RowTitle = styled.span`
  font-size: 18rem;
  color: ${COLORS.BLACK};
  display: block;
  align-self: center;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 20rem;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 100%;
  margin-top: 25rem;
`;

export const FieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10rem;
  align-items: flex-end;
`;

export const FieldsButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10rem;
`;

export const InputWrapper = styled.div`
  gap: 5rem;
  display: flex;
  align-items: center;
`;

export const ButtonWrapper = styled.div`
  position: relative;
`;
