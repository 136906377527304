import { respondTo } from 'assets/styled/breakpoints';
import styled, { css } from 'styled-components';

export const LayoutColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
`;

export const LayoutRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

interface MarginTopHeaderHeightProps {
  isHeaderExpanded: boolean;
}
export const MarginTopHeaderHeight = styled.div`
  ${(props: MarginTopHeaderHeightProps) =>
    props.isHeaderExpanded
      ? css`
          margin-top: 150rem;

          ${respondTo.smallTablet`
            margin-top: 75rem;
          `}
        `
      : css`
          margin-top: 75rem;
        `}
`;
